import { reducerWithInitialState } from 'typescript-fsa-reducers';
import setAnalyticsUser from '../utils/setAnalyticsUser';
import login from './actions';
import { LoginState, INITIAL_STATE } from './types';

const reducer = reducerWithInitialState<LoginState>(INITIAL_STATE)
  .case(login.async.started, (state) => ({
    ...state,
    loggingIn: true,
    loginError: null,
  }))
  .case(login.async.done, (state, payload) => {
    const user = payload.result;
    setAnalyticsUser(user);
    return {
      ...state,
      loggingIn: false,
      loggedInUser: user,
    };
  })
  .case(login.async.failed, (state, payload) => ({
    ...state,
    loggingIn: false,
    loginError: payload.error.message ?? 'Something went wrong.',
  }));

export default reducer.build();
