/* eslint-disable no-restricted-syntax */
import { queryClient } from 'api/utils/queryClient';
import { getCurrentUserQueryKey } from 'api/queries/getCurrentUser';
import { client as ApiClient } from '../../helpers/ApiClient';
import { handleError } from './utils';

/**
 * @example
 *  import { AuthService } from 'services';
 *  AuthService.logout();
 */
export default async function AuthServiceLogout(): Promise<void> {
  try {
    await ApiClient.get('/auth/logout');
  } catch (e) {
    handleError('logout');
  }

  queryClient.invalidateQueries({
    queryKey: [getCurrentUserQueryKey],
    exact: true,
  });
}
