import { useEffect } from 'react';

export const useAutoSubmit = ({
  digits,
  submit,
}: {
  digits?: string;
  submit: (code: string) => Promise<void>;
}): void => {
  useEffect(() => {
    if (digits?.length === 6) {
      submit(digits);
    }
  }, [digits, submit]);
};
