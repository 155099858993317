import { QueryTrackingState } from 'state/modules/browse/queryTracking/types';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { setAAQueryId } from 'state/modules/browse/queryTracking/actions';

export const INITIAL_STATE: QueryTrackingState = Object.freeze({
  aaQueryId: null,
});

export const queryTrackingReducer = reducerWithInitialState<QueryTrackingState>(
  INITIAL_STATE
)
  .case(setAAQueryId, (state, payload) => ({
    ...state,
    aaQueryId: payload.aaQueryId,
  }))
  .build();
