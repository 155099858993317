import React, { useCallback } from 'react';
import { Search as SearchIcon } from 'react-feather';
import { config } from 'cameoConfig';
import { color } from 'design-system/style';
import { Avatar } from 'design-system/Components/Avatar';
import {
  SearchItem,
  BrowsePageUrlGenerator,
  ProfilePageUrlGenerator,
} from 'design-system/Components/SearchResults/types';
import NameWithHighlight from 'design-system/Components/SearchResults/NameWithHighlight';

import {
  AvatarContainer,
  Heading,
  Image,
  Item,
  Name,
  Profession,
  UserDetails,
} from 'design-system/Components/SearchResults/Styled';

type Props = {
  generateBrowsePageUrl: BrowsePageUrlGenerator;
  generateProfilePageUrl: ProfilePageUrlGenerator;
  highlighted: boolean;
  index: number;
  item?: SearchItem;
  onItemClick: (item: SearchItem, listIndex: number, itemType: string) => void;
  onMouseEnter: (index: number) => void;
  onMouseLeave: () => void;
  query: string;
};

const ResultItem = ({
  generateBrowsePageUrl,
  generateProfilePageUrl,
  highlighted,
  index,
  item,
  onItemClick,
  onMouseEnter,
  onMouseLeave,
  query,
}: Props) => {
  const handleMouseEnter = useCallback(
    () => onMouseEnter(index),
    [index, onMouseEnter]
  );

  const handleClick = useCallback(() => {
    const targetTypeMap = {
      category: 'Category',
      miniProfile: 'User',
      tag: 'Tag',
      title: 'Title',
      user: 'User',
    };
    onItemClick(item, index, targetTypeMap[item.type]);
  }, [item, index, onItemClick]);

  if (!item) return null;
  const { name } = item.data;

  const nameWithHighlight = (
    <NameWithHighlight copy={name} queryToMatch={query} />
  );

  if (item.type === 'category' || item.type === 'tag') {
    return (
      <Item
        highlighted={highlighted}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={onMouseLeave}
        to={generateBrowsePageUrl(item.data.slug, item.type)}
      >
        <Image as="div">
          <SearchIcon color={color.solids.MUTED} size={16} />
        </Image>
        <Name>{nameWithHighlight}</Name>
      </Item>
    );
  }

  if (item.type === 'title') {
    return (
      <Heading>
        <Name>{name}</Name>
      </Heading>
    );
  }

  if (item.type === 'miniProfile') {
    const { imageUrlKey, username, profession } = item.data;

    const title = item.explainBlock
      ? `ADMIN ONLY \n ${JSON.stringify(item.explainBlock, null, 2)}`
      : undefined;
    return (
      <Item
        title={title}
        highlighted={highlighted}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={onMouseLeave}
        to={generateProfilePageUrl(username, item._id, item?.aaQueryId)}
      >
        {imageUrlKey && (
          <AvatarContainer>
            <Avatar
              name={name}
              imageUrl={`${config.imageServerUrl}/${imageUrlKey}`}
              size={40}
            />
          </AvatarContainer>
        )}
        <UserDetails>
          <Name>{nameWithHighlight}</Name>
          {profession && <Profession>{profession}</Profession>}
        </UserDetails>
      </Item>
    );
  }

  if (item.type === 'user') {
    const { username } = item.data;
    const title = item.explainBlock
      ? `ADMIN ONLY \n ${JSON.stringify(item.explainBlock)}`
      : undefined;
    return (
      <Item
        title={title}
        highlighted={highlighted}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={onMouseLeave}
        to={generateProfilePageUrl(username, item._id, item?.aaQueryId)}
      >
        <UserDetails>
          <Name>{nameWithHighlight}</Name>
        </UserDetails>
      </Item>
    );
  }

  return null;
};

export default ResultItem;
