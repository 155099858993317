import { RequestPasswordResetParams } from 'state/modules/auth/requestPasswordReset/types';
import { LoginHeaders } from 'services/auth/utils/types';
import { client as ApiClient } from '../../helpers/ApiClient';
import { handleError } from './utils';

/**
 * Request password reset
 *
 * @example
 *  import { AuthService } from 'services';
 *  AuthService.requestPasswordReset({ email });
 */
export default function (data: RequestPasswordResetParams): Promise<void> {
  const body: {
    data: RequestPasswordResetParams;
    headers?: LoginHeaders;
  } = {
    data,
  };
  const path = '/auth/requestPasswordReset'; // legacy

  return ApiClient.post(path, body).catch(handleError('requestPasswordReset'));
}
