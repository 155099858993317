import React from 'react';
import styled, { StyledComponent, css } from 'styled-components';
import { SpaceProps } from 'styled-system';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { ThemeInterface } from 'domains/web/theme/types';
import { Box, BoxProps } from '../Box';
import { Text, TextProps } from '../Text';

type BaseProps = BoxProps;

export type FormProps = Omit<
  React.FormHTMLAttributes<HTMLFormElement>,
  keyof BaseProps
> &
  BaseProps;

// @ts-expect-error - Type this component so when used it detects it is a form
const FormContainer = styled(Box).attrs({
  as: 'form',
})<FormProps>`
  ${() => {
    return css`
      display: flex;
      flex-direction: column;
    `;
  }};
` as StyledComponent<'form', ThemeInterface, FormProps, never>;

const FormControl = styled(Box).attrs((props: BoxProps) => ({
  flexDirection: props.flexDirection || 'column',
}))<BoxProps & { hasError?: boolean; disabled?: boolean }>`
  ${({ theme: t, hasError }) => {
    const theme = t as unknown as ThemeInterface;
    return css`
      display: flex;

      .form-input {
        margin: ${theme.space[2]} 0;
      }

      .form-textarea-bottom-slots {
        margin-bottom: ${theme.space[2]};
      }

      .form-label {
        ${FormLabelErrorStyle};
      }

      .form-input {
        ${FormInputErrorStyle};
      }

      .form-error-text {
        display: ${hasError ? 'flex' : 'none'};
      }
    `;
  }}

  ${({ disabled }) => {
    return (
      disabled &&
      css`
        pointer-events: none;
        opacity: ${1 / 3};

        * {
          pointer-events: none;
        }
      `
    );
  }};
`;

// @ts-expect-error - Type this component so when used it detects it is a label
const FormLabel = styled<FormLabelProps>(Text).attrs({
  as: 'label',
  className: 'form-label',
  color: 'foreground.default',
  fontWeight: 'medium',
  variant: 'base_m',
})`` as StyledComponent<
  'label',
  ThemeInterface,
  TextProps & React.LabelHTMLAttributes<HTMLLabelElement>,
  never
>;

const FormHelpText = styled(Text).attrs({
  as: 'span',
  variant: 'base_s',
  color: 'foreground.muted',
  fontWeight: 'regular',
})`` as StyledComponent<
  'span',
  ThemeInterface,
  TextProps & React.HTMLAttributes<HTMLSpanElement>,
  never
>;

const FormErrorText = ({
  children,
  ...spaceProps
}: { children: string } & SpaceProps) => {
  if (!children) {
    return null;
  }

  return (
    <Box
      className="form-error-text"
      display="flex"
      flexDirection="row"
      color="danger.emphasis"
      gap={1}
      {...spaceProps}
    >
      <Box as="span" size="2rem" color="currentColor">
        <ExclamationTriangleIcon />
      </Box>
      <Box flex={1} color="currentColor">
        <Text variant="base_s" color="currentColor">
          {children}
        </Text>
      </Box>
    </Box>
  );
};

export const Form = {
  Container: FormContainer,
  Control: FormControl,
  Label: FormLabel,
  HelpText: FormHelpText,
  ErrorText: FormErrorText,
};

const FormInputErrorStyle = css<{ hasError?: boolean }>`
  ${({ theme: t, hasError }) => {
    const theme = t as unknown as ThemeInterface;
    return hasError
      ? css`
          border-color: ${theme.colors.danger.emphasis};
        `
      : ``;
  }}
`;

const FormLabelErrorStyle = css<{ hasError?: boolean }>`
  ${({ theme: t, hasError }) => {
    const theme = t as unknown as ThemeInterface;
    return hasError
      ? css`
          color: ${theme.colors.danger.emphasis};
        `
      : ``;
  }}
`;
