import { fontSizes, fontWeights, lineHeights } from "./tokens";

const textVariants = {
  "display-l": {
    fontSize: fontSizes["$5.5"],
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights["$8"],
  },
  "display-m": {
    fontSize: fontSizes["$4.5"],
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights["$6"],
  },
  "display-s": {
    fontSize: fontSizes["$4"],
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights["$5.5"],
  },
  "title-l": {
    fontSize: fontSizes["$3.5"],
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights["$5"],
  },
  "title-m": {
    fontSize: fontSizes["$3"],
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights["$4"],
  },
  "title-s": {
    fontSize: fontSizes["$2.5"],
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights["$3.5"],
  },
  "base-l": {
    fontSize: fontSizes["$2.25"],
    fontWeight: fontWeights.regular,
    lineHeight: lineHeights["$3"],
  },
  "base-lTall": {
    fontSize: fontSizes["$2.25"],
    fontWeight: fontWeights.regular,
    lineHeight: lineHeights["$3.5"],
  },
  "base-m": {
    fontSize: fontSizes["$2"],
    fontWeight: fontWeights.regular,
    lineHeight: lineHeights["$2.5"],
  },
  "base-mTall": {
    fontSize: fontSizes["$2"],
    fontWeight: fontWeights.regular,
    lineHeight: lineHeights["$3"],
  },
  "base-s": {
    fontSize: fontSizes["$1.75"],
    fontWeight: fontWeights.regular,
    lineHeight: lineHeights["$2.5"],
  },
  "base-xs": {
    fontSize: fontSizes["$1.5"],
    fontWeight: fontWeights.regular,
    lineHeight: lineHeights["$2.5"],
  },
  "base-xxs": {
    fontSize: fontSizes["$1.25"],
    fontWeight: fontWeights.regular,
    lineHeight: lineHeights["$2"],
  },
  "interactive-l": {
    fontSize: fontSizes["$2.25"],
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights["$3"],
  },
  "interactive-l-u": {
    fontSize: fontSizes["$2.25"],
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights["$3"],
    textDecoration: "underline",
  },
  "interactive-m": {
    fontSize: fontSizes["$2"],
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights["$2.5"],
  },
  "interactive-m-u": {
    fontSize: fontSizes["$2"],
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights["$2.5"],
    textDecoration: "underline",
  },
  "interactive-s": {
    fontSize: fontSizes["$1.75"],
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights["$2.5"],
  },
  "interactive-s-u": {
    fontSize: fontSizes["$1.75"],
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights["$2.5"],
    textDecoration: "underline",
  },
  "interactive-xs": {
    fontSize: fontSizes["$1.5"],
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights["$2.5"],
  },
  "interactive-xs-u": {
    fontSize: fontSizes["$1.5"],
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights["$2.5"],
    textDecoration: "underline",
  },
  "kicker-xs": {
    fontSize: fontSizes["$1.5"],
    fontWeight: fontWeights.bold,
    letterSpacing: "0.04em",
    lineHeight: lineHeights["$2.5"],
    textTransform: "uppercase",
  },
  "kicker-s": {
    fontSize: fontSizes["$1.75"],
    fontWeight: fontWeights.bold,
    letterSpacing: "0.04em",
    lineHeight: lineHeights["$2.5"],
    textTransform: "uppercase",
  },
} as const;

const textSemanticVariants = {
  "heading-1": {
    ...textVariants["title-l"],
    fontWeight: fontWeights.bold,
  },
  "heading-2": {
    ...textVariants["title-m"],
    fontWeight: fontWeights.bold,
  },
  "heading-3": {
    ...textVariants["title-s"],
    fontWeight: fontWeights.bold,
  },
  "heading-4": {
    ...textVariants["base-m"],
    fontWeight: fontWeights.bold,
  },
  body: {
    ...textVariants["base-mTall"],
    fontWeight: fontWeights.regular,
  },
  "body-s": {
    ...textVariants["base-s"],
    fontWeight: fontWeights.regular,
  },
} as const;

export const text = {
  ...textVariants,
  ...textSemanticVariants,
} as const;

export const buttons = {
  primary: {
    fontFamily: "body",
    cursor: "pointer",
    color: "foreground.default",
    borderRadius: "500px",
    px: 32,
    py: 16,
    bg: "accent.default",
    ...text["base-m"],
    "&:hover": {
      bg: "#6D2EBF",
    },
    "&:active": {
      bg: "#6D2EBFE6",
    },
  },
  secondary: {
    fontFamily: "body",
    cursor: "pointer",
    color: "foreground.default",
    borderColor: "grey-95",
    bg: "transparent",
  },
  tertiary: {
    fontFamily: "body",
    cursor: "pointer",
    color: "foreground.default",
    bg: "grey-20",
  },
  minimal: {
    fontFamily: "body",
    cursor: "pointer",
    color: "foreground.default",
    bg: "transparent",
  },
} as const;
