import reduceReducers from 'reduce-reducers';
import {
  reducer as toggleFanClubMemberReducer,
  toggleFanClubMember,
} from './toggleFanClubMember';
import {
  reducer as subscribeReducer,
  createSubscription,
  reactivateSubscription,
  ConfirmCardPaymentFailed as confirmCardPaymentFailed,
  ConfirmCardPaymentSucceeded as confirmCardPaymentSucceeded,
} from './subscribe';
import { reducer as unsubscribeReducer, unsubscribe } from './unsubscribe';
import { reducer as loadFanClubReducer, loadFanClub } from './load';
import { reducer as loadRelatedReducer, loadRelated } from './loadRelated';
import {
  reducer as loadContentReducer,
  loadContentPreview,
} from './loadContentPreview';
import {
  reducer as loadFollowingReducer,
  loadFollowing,
} from './loadFollowing';

import {
  reducer as loadSelectedFollowPostReducer,
  loadSelectedFollowPost,
} from './loadSelectedFollowPost';

import { FanClubsState } from './types';
import * as selectors from './selectors';

const reducer = reduceReducers<FanClubsState>(
  loadContentReducer,
  loadFanClubReducer,
  loadFollowingReducer,
  loadRelatedReducer,
  subscribeReducer,
  toggleFanClubMemberReducer,
  unsubscribeReducer,
  loadSelectedFollowPostReducer
);

const actions = {
  confirmCardPaymentFailed,
  confirmCardPaymentSucceeded,
  createSubscription,
  loadContentPreview,
  loadFanClub,
  loadFollowing,
  loadSelectedFollowPost,
  loadRelated,
  reactivateSubscription,
  toggleFanClubMember,
  unsubscribe,
};

export { actions, selectors };
export default reducer;
