// events.js - defines all tracked analytics events and maps to tracking clients
import validateTrackingEvents from './validate-events';

const events = {
  Action: {
    category: 'Action',
    clients: ['googleAnalytics', 'amplitude'],
    description: 'Tracks when an action was performed',
    type: 'event',
  },
  PurchaseAttempt: {
    category: 'Order',
    clients: ['branch', 'googleAnalytics', 'amplitude', 'googleTagManager'],
    description:
      'Tracks when a user tries to make a purchase of something on the Cameo Platform',
    type: 'event',
  },
  System: {
    category: 'System',
    clients: ['amplitude'],
    description: 'Tracks when a system activity occurs',
    type: 'event',
  },
  CancelCompletionEvent: {
    category: 'Order',
    clients: ['googleAnalytics', 'amplitude'],
    description:
      'Tracks when a user begins successfully completes order cancellation',
    type: 'event',
  },
  DownloadApp: {
    category: 'DownloadApp',
    clients: ['amplitude'],
    description: 'Tracks when a user clicks on a download app button',
    type: 'event',
  },
  ProfileViewEvent: {
    category: 'Profile',
    clients: ['googleAnalytics', 'amplitude', 'googleTagManager'],
    description: 'Tracks a profile view',
    type: 'event',
  },
  ClickSocialLink: {
    category: 'Footer',
    clients: ['googleAnalytics', 'amplitude'],
    description: 'Tracks when a user clicks a social link in the footer',
    type: 'event',
  },
  SignupComplete: {
    category: 'Profile',
    clients: ['googleTagManager', 'googleAnalytics', 'amplitude'],
    description: 'Tracks when a user has completed the account signup flow',
    type: 'event',
  },
  InitiateCheckout: {
    category: 'Order',
    clients: ['googleTagManager'],
    description:
      'Tracks when a user is on the first page of the booking process.',
    type: 'event',
  },
  Login: {
    category: 'Authentication',
    clients: ['amplitude'],
    description: 'Tracks when a user successfully logs in',
    type: 'event',
  },
  Navigation: {
    category: 'Load',
    clients: ['googleAnalytics', 'amplitude'],
    description:
      'Tracks when a location change event takes place. (ex. a user on the homepage clicks browse talent)',
    type: 'event',
  },
  TalentProfileNavShare: {
    category: 'TalentProfileNav',
    clients: ['amplitude'],
    description:
      'Tracks when a user presses the share button in the talent profile nav bar',
    type: 'event',
  },
  FanClubsJoin: {
    category: 'FanClubs',
    clients: ['amplitude', 'googleTagManager'],
    description: 'Track when user joins a Fan Club',
    type: 'event',
  },
  FanClubsLeave: {
    category: 'FanClubs',
    clients: ['amplitude'],
    description: 'Track when user leaves a Fan Club',
    type: 'event',
  },
  FanClubsTalentProfileCTAPress: {
    category: 'FanClubs',
    clients: ['amplitude'],
    description: 'Track when user presses the CTA in a Talent Profile',
    type: 'event',
  },
  TalentEnrollment: {
    category: 'Enrollment',
    clients: ['googleAnalytics', 'amplitude', 'googleTagManager'],
    type: 'event',
    description:
      'Tracks when a prospective talent successfully submits the enrollment form',
  },
};

export default validateTrackingEvents(events);
