import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { PublicReview } from 'types/src/api/models/review';

import {
  CreateReview,
  EditReview,
  GetOrderDetailSuccess,
  GetTopReviews,
  LoadOrderSuccess,
  LoadUserReviews,
  LoadUserReviewsPaginated,
} from './actions';

export type State = {
  error: string | null;
  loading: boolean;
  reviews: {
    [key: string]: PublicReview;
  };
};

const REVIEWS_INITIAL_STATE = Object.freeze<State>({
  error: null,
  loading: false,
  reviews: {},
});

const reviewsReducer = reducerWithInitialState(REVIEWS_INITIAL_STATE);

reviewsReducer.cases(
  [LoadOrderSuccess, GetOrderDetailSuccess],
  (state, action) => {
    if (!action.result.review) {
      return state;
    }

    return {
      ...state,
      reviews: {
        ...state.reviews,
        [action.result.review._id]: action.result.review,
      },
    };
  }
);

reviewsReducer.case(GetTopReviews.started, (state) => ({
  ...state,
  error: null,
  loading: true,
  reviews: {},
}));

reviewsReducer.cases(
  [
    CreateReview.started,
    LoadUserReviews.started,
    LoadUserReviewsPaginated.started,
  ],
  (state) => ({
    ...state,
    error: null,
    loading: true,
  })
);

reviewsReducer.case(CreateReview.done, (state, action) => ({
  ...state,
  error: null,
  loading: false,
  reviews: {
    ...state.reviews,
    [action.result._id]: action.result,
  },
}));

reviewsReducer.case(EditReview.done, (state, action) => {
  console.log(state, action);
  return {
    ...state,
    error: null,
    loading: false,
    reviews: {
      ...state.reviews,
      [action.result._id]: action.result,
    },
  };
});

reviewsReducer.cases([GetTopReviews.done], (state, action) => {
  const stateReviews = {
    ...state.reviews,
  };

  for (const review of action.result.reviews) {
    stateReviews[review._id] = review;
  }

  return {
    ...state,
    error: null,
    loading: false,
    reviews: stateReviews,
  };
});

reviewsReducer.cases(
  [LoadUserReviews.done, LoadUserReviewsPaginated.done],
  (state, action) => {
    const stateReviews = {
      ...state.reviews,
    };

    for (const review of action.result) {
      stateReviews[review._id] = review;
    }

    return {
      ...state,
      error: null,
      loading: false,
      reviews: stateReviews,
    };
  }
);

reviewsReducer.cases(
  [
    CreateReview.failed,
    GetTopReviews.failed,
    LoadUserReviews.failed,
    LoadUserReviewsPaginated.failed,
  ],
  (state, action) => ({
    ...state,
    error: action.error.message,
    loading: false,
  })
);

export default reviewsReducer.build();
