import { reducerWithInitialState } from 'typescript-fsa-reducers';
import Stripe from 'stripe';
import { FanClubSubscriptionTypes } from 'types/src/utils/enums';

import { actionCreator } from './utils';
import INITIAL_STATE from './initialState';
import { FanClubsState, Error } from './types';

export const initialState: Partial<FanClubsState> = {
  creatingSubscription: false,
  createSubscriptionError: null,
};

export const Subscribe = actionCreator.async<any, any, Error>('SUBSCRIBE');

export const ConfirmCardPaymentFailed = actionCreator<void>(
  'CONFIRM_CARD_PAYMENT_FAILED'
);

export const ConfirmCardPaymentSucceeded = actionCreator<void>(
  'CONFIRM_CARD_PAYMENT_SUCCEEDED'
);

export const reducer = reducerWithInitialState<FanClubsState>(INITIAL_STATE)
  .case(Subscribe.started, (state) => ({
    ...state,
    creatingSubscription: true,
    createSubscriptionError: null,
    subscriptionStatus: null,
    subscriptionPaymentIntent: null,
    selectedFanClubUserDetails: {
      ...state.selectedFanClubUserDetails,
      userSubscriptionType: null,
    },
  }))
  .case(Subscribe.done, (state, payload) => {
    const {
      status: subscriptionStatus,
      paymentIntent: subscriptionPaymentIntent,
      userSubscriptionType,
      fanClubId,
    } = payload.result;

    const membershipIDList = [...state.membershipIDList, fanClubId];

    const activeUserSubscriptions = {
      ...state.activeUserSubscriptions,
      [fanClubId]: userSubscriptionType,
    };

    return {
      ...state,
      creatingSubscription: false,
      subscriptionStatus,
      subscriptionPaymentIntent,
      activeUserSubscriptions,
      membershipIDList,
      selectedFanClubUserDetails: {
        ...state.selectedFanClubUserDetails,
        userPendingCancellation: false,
        userSubscriptionType,
      },
    };
  })
  .case(Subscribe.failed, (state, action) => ({
    ...state,
    creatingSubscription: false,
    createSubscriptionError: action.error,
  }))
  .case(ConfirmCardPaymentFailed, (state) => ({
    ...state,
    subscriptionPaymentIntent: null,
  }))
  .build();

export function createSubscription(data: {
  fanClubId: Cameo.Models.FanClub.PublicFanClub['_id'];
  token: Stripe.Token['id'];
}) {
  return {
    types: [Subscribe.started.type, Subscribe.done.type, Subscribe.failed.type],
    promise: (client) =>
      client.post(`/fanClub/${data.fanClubId}/subscribe`, {
        data: {
          token: data.token,
          subscriptionType: FanClubSubscriptionTypes.VIP,
        },
      }),
  };
}

export function reactivateSubscription(data: {
  fanClubId: Cameo.Models.FanClub.PublicFanClub['_id'];
}) {
  return {
    types: [Subscribe.started.type, Subscribe.done.type, Subscribe.failed.type],
    promise: (client) =>
      client.post(`/fanClub/${data.fanClubId}/reactivate`, {
        data: {
          subscriptionType: FanClubSubscriptionTypes.VIP,
        },
      }),
  };
}
