import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getInternationalInfo } from 'state/modules/clientInfo/i18n/selectors';

const useI18N = (): {
  currency: string;
  language: string;
  region: string;
} => {
  const {
    languageCode = 'en',
    countryCode = 'US',
    currencyCode = 'USD',
  } = useSelector(getInternationalInfo);
  const [currency] = useState(currencyCode);
  const [language] = useState(languageCode);
  const [region] = useState(countryCode);

  return {
    currency,
    language,
    region,
  };
};

export { useI18N };
