import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import { RecentlyViewedUsers } from './RecentlyViewedUsers';
import CategoryList from './CategoryList';
import { Wrapper } from './Styled';

import { getSearchNullState } from './queries';

type Props = {
  isVisible: boolean;
  closeSearchResultsDropdown?: () => void;
};

const SearchNullState = ({ isVisible, closeSearchResultsDropdown }: Props) => {
  const { t } = useTranslation('SearchNullState');

  const nullSearchQuery = useQuery(getSearchNullState({ enabled: isVisible }));

  const {
    browseAllCategories = [],
    popularCategories = [],
    recentlyViewedUsers = [],
  } = nullSearchQuery.data?.results || {};

  return (
    <Wrapper data-testid="SearchNullState" isVisible={isVisible}>
      <RecentlyViewedUsers
        users={recentlyViewedUsers}
        title={t('recentlyViewed', 'Recently viewed')}
        closeSearchResultsDropdown={closeSearchResultsDropdown}
      />
      <CategoryList
        categories={popularCategories}
        title={t('popularCategories', 'Popular categories')}
        closeSearchResultsDropdown={closeSearchResultsDropdown}
      />
      <CategoryList
        categories={browseAllCategories}
        title={t('browseAll', 'Browse all')}
        closeSearchResultsDropdown={closeSearchResultsDropdown}
      />
    </Wrapper>
  );
};

export default SearchNullState;
