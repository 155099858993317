import styled from 'styled-components';
import { sizing, media } from 'design-system/style';
import { Link } from 'components/CameoLink/Link';
import { theme } from 'domains/web/theme/og';
import { Wrapper as CategoryFlyoutWrapper } from '../CategoryFlyout/Styled';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DesktopButtons = styled.div`
  align-items: center;
  display: none;

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    display: flex;
  }
`;

export const MobileButtons = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    display: none;
  }
`;

export const NavButton = styled(Link)`
  align-items: center;
  border-radius: ${sizing(20)};
  color: ${theme.colors.foreground.default};
  display: flex;
  padding: ${sizing(8)} ${sizing(12)};

  div {
    white-space: nowrap;
  }

  &:hover {
    background-color: #2b262b;
    cursor: pointer;
  }

  & > *:last-child {
    line-height: normal;
  }

  &:active,
  &:focus,
  &:hover,
  &:visited {
    color: inherit;
    text-decoration: none;
  }
`;

export const NavButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  &:hover {
    ${NavButton} {
      background-color: #2b262b;
      cursor: pointer;
    }
  }
`;

export const FlyoutButtonContainer = styled(NavButtonContainer)<{
  isFlyoutVisible: boolean;
}>`
  ${CategoryFlyoutWrapper} {
    display: ${({ isFlyoutVisible }) => (isFlyoutVisible ? 'grid' : 'none')};
  }
`;

export const MenuButton = styled.div`
  padding: ${sizing(8)} ${sizing(12)} ${sizing(8)} 0;
  border-radius: ${sizing(20)};
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;
