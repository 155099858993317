import * as React from 'react';
import { colors as supernovaColors } from 'utils/supernova';
import {
  AlertOctagon,
  AlertCircle,
  AlertTriangle,
  ArrowLeft,
  ArrowRight,
  BarChart2,
  Bell,
  Bookmark,
  Calendar,
  Camera,
  Check,
  CheckCircle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Clock,
  CreditCard,
  Cpu,
  DollarSign,
  Download,
  Edit,
  ExternalLink,
  Facebook,
  FileText,
  Flag,
  Folder,
  Gift,
  Globe,
  Heart,
  HelpCircle,
  Icon as ReactFeatherIcon,
  Image,
  Info,
  Instagram,
  List,
  Lock,
  Mail,
  MessageCircle,
  MessageSquare,
  Mic,
  Minus,
  Menu,
  Pause,
  Play,
  PlayCircle,
  Plus,
  PlusCircle,
  Radio,
  RefreshCcw,
  RefreshCw,
  RotateCcw,
  RotateCw,
  Search,
  Settings,
  Send,
  Share,
  Shield,
  Smartphone,
  Smile,
  Star,
  StopCircle,
  Tag,
  ThumbsUp,
  TrendingUp,
  Tv,
  Twitter,
  Users,
  Video,
  Volume2,
  VolumeX,
  X,
  Youtube,
  Zap,
  Book,
} from 'react-feather';

/**
 * Custom SVG icons
 */
import type { CustomIconProps } from './svg/types';
import FireIcon from './svg/FireIcon';
import FlameIcon from './svg/FlameIcon';
import CharityFlagIcon from './svg/CharityFlagIcon';
import RecordIcon from './svg/RecordIcon';
import GraduationCapIcon from './svg/GraduationCapIcon';
import { TikTokIcon } from './svg/TikTokIcon';
import { PlayIconV2 } from './svg/PlayIconV2';
import { PauseIconV2 } from './svg/PauseIconV2';
import { PollIcon } from './svg/PollIcon';
import BirthdayCakeIcon from './svg/BirthdayCakeIcon';
import BirthdayCakeIconV2 from './svg/BirthdayCakeIconV2';
import BedIcon from './svg/BedIcon';
import ChristmasIcon from './svg/ChristmasIcon';
import DiwaliIcon from './svg/DiwaliIcon';
import EasterIcon from './svg/EasterIcon';
import HalloweenIcon from './svg/HalloweenIcon';
import HanukkahIcon from './svg/HanukhahIcon';
import KwanzaaIcon from './svg/KwanzaaIcon';
import PlusIcon from './svg/PlusIcon';
import SnowflakeIcon from './svg/SnowflakeIcon';
import ThanksgivingIcon from './svg/ThanksgivingIcon';
import ToiletIcon from './svg/ToiletIcon';
import ToothbrushIcon from './svg/ToothbrushIcon';
import FilterIcon from './svg/FilterIcon';
import UsersIconV2 from './svg/UsersIconV2';
import UserIcon from './svg/UserIcon';
import ImageIcon from './svg/ImageIcon';
import VideoIcon from './svg/VideoIcon';
import ChatBubbleLeftIcon from './svg/ChatBubbleLeftIcon';
import UserPlusIcon from './svg/UserPlusIcon';
import EyeIcon from './svg/EyeIcon';
import BellOnIcon from './svg/BellOnIcon';
import BellOffIcon from './svg/BellOffIcon';

import type { Color, IconName, IconProps } from './types';
import { sizeMap } from './constants';

export type { Color, IconName, IconProps };

type ReactFeatherIconData = {
  icon: ReactFeatherIcon;
  color: any;
  fill?: boolean;
};

type CustomIconData = {
  icon: React.FC<CustomIconProps>;
  custom: true;
};

type IconData = ReactFeatherIconData | CustomIconData;

const iconMap: Record<IconName, IconData> = {
  'alert-circle': { icon: AlertCircle, color: 'text' },
  'alert-octagon': { icon: AlertOctagon, color: 'text' },
  'alert-triangle': { icon: AlertTriangle, color: 'text' },
  'arrow-left': { icon: ArrowLeft, color: 'text' },
  'arrow-right': { icon: ArrowRight, color: 'text' },
  'bar-chart-2': { icon: BarChart2, color: 'yellowStarTurnt', fill: true },
  bed: { icon: BedIcon, custom: true },
  bell: { icon: Bell, color: 'text' },
  'bell-off': { icon: BellOffIcon, custom: true },
  'bell-on': { icon: BellOnIcon, custom: true },
  'birthday-cake-v2': { icon: BirthdayCakeIconV2, custom: true },
  'birthday-cake': { icon: BirthdayCakeIcon, custom: true },
  'bolt-o': { icon: Zap, color: 'text' },
  bolt: { icon: Zap, color: 'yellowStarTurnt', fill: true },
  book: { icon: Book, color: 'text' },
  'bookmark-o': { icon: Bookmark, color: 'text' },
  bookmark: { icon: Bookmark, color: 'text', fill: true },
  calendar: { icon: Calendar, color: 'text' },
  camera: { icon: Camera, color: 'text' },
  'charity-flag': { icon: CharityFlagIcon, custom: true },
  'chat-bubble-left': { icon: ChatBubbleLeftIcon, custom: true },
  'check-circle': { icon: CheckCircle, color: 'text' },
  check: { icon: Check, color: 'text' },
  'chevron-down': { icon: ChevronDown, color: 'text' },
  'chevron-left': { icon: ChevronLeft, color: 'text' },
  'chevron-right': { icon: ChevronRight, color: 'text' },
  'chevron-up': { icon: ChevronUp, color: 'text' },
  christmas: { icon: ChristmasIcon, custom: true },
  clock: { icon: Clock, color: 'text' },
  cpu: { icon: Cpu, color: 'text' },
  'credit-card': { icon: CreditCard, color: 'text' },
  diwali: { icon: DiwaliIcon, custom: true },
  'dollar-sign': { icon: DollarSign, color: 'text' },
  download: { icon: Download, color: 'text' },
  easter: { icon: EasterIcon, custom: true },
  edit: { icon: Edit, color: 'text' },
  'external-link': { icon: ExternalLink, color: 'text' },
  eye: { icon: EyeIcon, custom: true },
  facebook: { icon: Facebook, color: 'text' },
  'file-text': { icon: FileText, color: 'text' },
  filter: { icon: FilterIcon, custom: true },
  fire: { icon: FireIcon, custom: true },
  flag: { icon: Flag, color: 'pinkTurnt', fill: true },
  flame: { icon: FlameIcon, custom: true },
  folder: { icon: Folder, color: 'text' },
  gift: { icon: Gift, color: 'text' },
  globe: { icon: Globe, color: 'text' },
  'graduation-cap': { icon: GraduationCapIcon, custom: true },
  halloween: { icon: HalloweenIcon, custom: true },
  hanukkah: { icon: HanukkahIcon, custom: true },
  'heart-o': { icon: Heart, color: 'text' },
  heart: { icon: Heart, color: 'pinkTurnt', fill: true },
  'help-circle': { icon: HelpCircle, color: 'text' },
  image: { icon: Image, color: 'text' },
  'image-v2': { icon: ImageIcon, custom: true },
  info: { icon: Info, color: 'text' },
  instagram: { icon: Instagram, color: 'text' },
  kwanzaa: { icon: KwanzaaIcon, custom: true },
  list: { icon: List, color: 'text' },
  lock: { icon: Lock, color: 'text' },
  mail: { icon: Mail, color: 'text' },
  menu: { icon: Menu, color: 'text' },
  'message-circle': { icon: MessageCircle, color: 'text' },
  'message-square': { icon: MessageSquare, color: 'text' },
  mic: { icon: Mic, color: 'text' },
  minus: { icon: Minus, color: 'text' },
  'pause-circle-v2': { icon: PauseIconV2, custom: true },
  pause: { icon: Pause, color: 'black', fill: true },
  'play-circle-v2': { icon: PlayIconV2, custom: true },
  'play-circle': { icon: PlayCircle, color: 'text' },
  play: { icon: Play, color: 'text', fill: true },
  'plus-circle': { icon: PlusCircle, color: 'text' },
  'plus-v2': { icon: PlusIcon, custom: true },
  plus: { icon: Plus, color: 'text' },
  'poll-icon': { icon: PollIcon, custom: true },
  radio: { icon: Radio, color: 'text' },
  record: { icon: RecordIcon, custom: true },
  'refresh-ccw': { icon: RefreshCcw, color: 'text' },
  'refresh-cw': { icon: RefreshCw, color: 'text' },
  'rotate-ccw': { icon: RotateCcw, color: 'text' },
  'rotate-cw': { icon: RotateCw, color: 'text' },
  search: { icon: Search, color: 'text' },
  send: { icon: Send, color: 'text', fill: true },
  settings: { icon: Settings, color: 'text' },
  share: { icon: Share, color: 'text' },
  shield: { icon: Shield, color: 'text' },
  smartphone: { icon: Smartphone, color: 'text' },
  'smile-o': { icon: Smile, color: 'text' },
  snowflake: { icon: SnowflakeIcon, custom: true },
  'star-o': { icon: Star, color: 'yellowStarTurnt' },
  star: { icon: Star, color: 'yellowStarTurnt', fill: true },
  'stop-circle': { icon: StopCircle, color: 'text' },
  tag: { icon: Tag, color: 'pinkTurnt' },
  thanksgiving: { icon: ThanksgivingIcon, custom: true },
  thumbsUp: { icon: ThumbsUp, color: 'text' },
  tiktok: { icon: TikTokIcon, custom: true },
  toilet: { icon: ToiletIcon, custom: true },
  toothbrush: { icon: ToothbrushIcon, custom: true },
  'trending-up': { icon: TrendingUp, color: 'text' },
  tv: { icon: Tv, color: 'text' },
  twitter: { icon: Twitter, color: 'text' },
  'users-v2': { icon: UsersIconV2, custom: true },
  users: { icon: Users, color: 'text' },
  user: { icon: UserIcon, custom: true },
  'user-plus': { icon: UserPlusIcon, custom: true },
  video: { icon: Video, color: 'text' },
  'video-v2': { icon: VideoIcon, custom: true },
  'volume-2': { icon: Volume2, color: 'text', fill: true },
  'volume-x': { icon: VolumeX, color: 'text', fill: true },
  // eslint-disable-next-line id-length
  x: { icon: X, color: 'text' },
  youtube: { icon: Youtube, color: 'text' },
};

export const Icon = ({ name, color, size }: IconProps) => {
  const iconData = iconMap[name];

  if (!iconData) {
    throw new Error(
      `Unknown react-feather icon used: ${name}. Import it manually and add it to the icon map.
       This is done so webpack doesn't bundle unused icons.`
    );
  }

  const {
    icon: IconComponent,
    color: defaultColor,
    fill,
  } = iconData as ReactFeatherIconData;

  const iconColor =
    (color !== undefined && supernovaColors[color]) ||
    supernovaColors[defaultColor];

  // Rendering custom SVG icons
  if ((iconData as CustomIconData).custom) {
    const { icon: CustomIconComponent } = iconData as CustomIconData;
    const height = sizeMap[size];
    const width = sizeMap[size];

    return (
      <CustomIconComponent
        data-testid={`icon-${name}`}
        height={height}
        width={width}
        color={iconColor}
      />
    );
  }

  // Rendering our default React Feather icons
  if (Array.isArray(iconColor)) {
    throw new Error(
      `Passed a color type that resolved to an array in theme: ${name}`
    );
  }

  const iconProps = {
    color: iconColor,
    size: sizeMap[size],
    fill: fill ? iconColor : 'transparent',
  };

  return <IconComponent data-testid={`icon-${name}`} {...iconProps} />;
};
