import { config } from 'cameoConfig';
import { v4 as uuidv4 } from 'uuid';
import { EVENT_TS_KEY, SESSION_ID_KEY } from 'analytics/utils/constants';
import { dpCookieManager } from 'analytics/utils/dpCookieManager';
import { dpVisitorManager } from 'analytics/utils/dpVisitorManager';

class DpSessionManager {
  private lastAccessTimeStamp: number;

  private sessionId: string;

  // most recent seen user : current user or last seen user
  private userId: string;

  private isInitialized = false;

  private initialize() {
    if (typeof window === 'undefined' || this.isInitialized) return;

    const storedEventTimeStamp = window.localStorage.getItem(EVENT_TS_KEY);
    this.lastAccessTimeStamp = storedEventTimeStamp
      ? Number(storedEventTimeStamp)
      : -1;

    const storedSessionId = window.localStorage.getItem(SESSION_ID_KEY);
    this.sessionId = storedSessionId || null;

    window.addEventListener('storage', (event) => {
      if (event.key === SESSION_ID_KEY) {
        this.sessionId = window.localStorage.getItem(SESSION_ID_KEY);
      }

      if (event.key === EVENT_TS_KEY) {
        this.lastAccessTimeStamp = Number(
          window.localStorage.getItem(EVENT_TS_KEY)
        );
      }
    });

    dpCookieManager.setSessionIdCookie(this.sessionId);

    this.isInitialized = true;
  }

  public getSessionId(): string {
    this.initialize();

    if (this.prevSessionExpired()) {
      this.startNewSession();
      return this.sessionId;
    }

    this.lastAccessTimeStamp = Date.now();
    return this.sessionId;
  }

  private flushEventAccessTimeStampToDisk() {
    window.localStorage.setItem(EVENT_TS_KEY, String(this.lastAccessTimeStamp));
  }

  private flushSessionIdToDisk() {
    window.localStorage.setItem(SESSION_ID_KEY, this.sessionId);
  }

  public flushAllToDisk() {
    this.flushEventAccessTimeStampToDisk();
    this.flushSessionIdToDisk();
  }

  public setUserId(userId: string) {
    this.initialize();

    if (!userId) {
      return;
    }
    if (this.userChanged(userId)) {
      this.startNewSession();
    }
    this.userId = userId;
  }

  public getUserId(): string {
    return this.userId;
  }

  private prevSessionExpired(): boolean {
    const prevSessionExists = Boolean(this.sessionId);
    if (!prevSessionExists) {
      return true;
    }

    const timeDiffMs = Date.now() - this.lastAccessTimeStamp;
    return timeDiffMs > config.eventCollector.sessionThresholdMs;
  }

  private userChanged(newUserId: string): boolean {
    const prevUserExists = Boolean(this.userId);
    if (!prevUserExists) {
      return false;
    }

    return newUserId !== this.userId;
  }

  private startNewSession() {
    this.sessionId = uuidv4();
    this.lastAccessTimeStamp = Date.now();
    this.flushAllToDisk();

    dpCookieManager.setSessionIdCookie(this.sessionId);

    const visitorId = dpVisitorManager.getVisitorId();
    dpCookieManager.setVisitorIdCookie(visitorId);
  }

  // for test purpose only
  reset() {
    this.lastAccessTimeStamp = undefined;
    this.sessionId = undefined;
    this.userId = undefined;
    this.isInitialized = false;
  }
}

export const dpSessionManager = new DpSessionManager();
