import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { rehydrateLicense } from 'state/modules/businessVideos/utils/rehydrateLicense';
import { RehydratedLicenseGrant } from 'types/src/api/models/license';
import { GetOrderDetails, OrderDetailsResponse } from './actions';

export type OrderDetailsIndex = Partial<Record<string, OrderDetailsState>>;
export type OrderDetailsState = {
  loading: boolean;
  error: string | null;
  order: Omit<OrderDetailsResponse, 'license'> & {
    license?: RehydratedLicenseGrant;
  };
};

const InitialState = Object.freeze<OrderDetailsIndex>({});
export const DefaultOrderDetailsState = Object.freeze<OrderDetailsState>({
  loading: false,
  error: null,
  order: null,
});

const orderDetailsReducer = reducerWithInitialState(InitialState);

orderDetailsReducer
  .case(GetOrderDetails.started, (state, action) => ({
    ...state,
    [action.orderId]: {
      ...state[action.orderId],
      loading: true,
      error: null,
    },
  }))
  .case(GetOrderDetails.done, (state, action) => ({
    ...state,
    [action.params.orderId]: {
      ...state[action.params.orderId],
      loading: false,
      order: {
        ...action.result,
        license: action.result.license
          ? rehydrateLicense({ license: action.result.license })
          : undefined,
      },
    },
  }))
  .case(GetOrderDetails.failed, (state, action) => ({
    ...state,
    [action.params.orderId]: {
      ...state[action.params.orderId],
      loading: false,
      error: action.error.message,
    },
  }));

export { orderDetailsReducer };
