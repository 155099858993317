import { reducerWithInitialState } from 'typescript-fsa-reducers';
import requestPasswordTokenIsValid from './actions';
import { ResetPasswordTokenIsValidState, INITIAL_STATE } from './types';

const reducer = reducerWithInitialState<ResetPasswordTokenIsValidState>(
  INITIAL_STATE
)
  .case(requestPasswordTokenIsValid.async.started, (state) => ({
    ...state,
  }))
  .case(requestPasswordTokenIsValid.async.done, (state) => ({
    ...state,
  }))
  .case(requestPasswordTokenIsValid.async.failed, (state, payload) => ({
    ...state,
    resetPasswordError: payload.error.message ?? 'Something went wrong.',
  }));

export default reducer.build();
