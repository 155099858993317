import { theme as cameoTheme } from '@cameo/theme';

export type FontVariants = {
  base_xxs?: string;
  base_xs?: string;
  base_s?: string;
  base_m?: string;
  base_l?: string;
  title_s?: string;
  title_m?: string;
  title_l?: string;
  display_s?: string;
  display_m?: string;
  display_l?: string;
};

type FontSizesArray = Array<string> & FontVariants;

const fontSizes: FontSizesArray = [
  cameoTheme.fontSizes['$1.25'], // 10
  cameoTheme.fontSizes['$1.5'], // 12
  cameoTheme.fontSizes['$1.75'], // 14
  cameoTheme.fontSizes.$2, // 16
  cameoTheme.fontSizes['$2.25'], // 18
  cameoTheme.fontSizes['$2.5'], // 20
  cameoTheme.fontSizes.$3, // 24
  cameoTheme.fontSizes['$3.5'], // 28
  cameoTheme.fontSizes.$4, // 32
  cameoTheme.fontSizes['$4.5'], // 36
  cameoTheme.fontSizes['$5.5'], // 44
];

fontSizes.base_xxs = fontSizes['0']; // 10px
fontSizes.base_xs = fontSizes['1']; // 12px
fontSizes.base_s = fontSizes['2']; // 14px
fontSizes.base_m = fontSizes['3']; // 16px
fontSizes.base_l = fontSizes['4']; // 18px
fontSizes.title_s = fontSizes['5']; // 20px
fontSizes.title_m = fontSizes['6']; // 24px
fontSizes.title_l = fontSizes['7']; // 28px
fontSizes.display_s = fontSizes['8']; // 32px
fontSizes.display_m = fontSizes['9']; // 36px
fontSizes.display_l = fontSizes['10']; // 44px

const fontWeights = {
  ...cameoTheme.fontWeights,
};

const fontFamily = {
  ...cameoTheme.fonts,
};

interface LineHeightArray extends Array<string>, FontVariants {}

const lineHeights: LineHeightArray = [
  cameoTheme.lineHeights.$2, // [0] 16
  cameoTheme.lineHeights['$2.5'], // [1] 20
  cameoTheme.lineHeights.$3, // [2] 24
  cameoTheme.lineHeights['$3.5'], // [3] 28
  cameoTheme.lineHeights.$4, // [4] 32
  cameoTheme.lineHeights.$5, // [5] 40
  cameoTheme.lineHeights['$5.5'], // [6] 44
  cameoTheme.lineHeights.$6, // [7] 48
  cameoTheme.lineHeights.$8, // [8] 64
];

lineHeights.base_xxs = cameoTheme.lineHeights.$2;
lineHeights.base_xs = cameoTheme.lineHeights['$2.5'];
lineHeights.base_s = cameoTheme.lineHeights['$2.5'];
lineHeights.base_m = cameoTheme.lineHeights['$2.5'];
lineHeights.base_l = cameoTheme.lineHeights.$3;
lineHeights.title_s = cameoTheme.lineHeights['$3.5'];
lineHeights.title_m = cameoTheme.lineHeights.$4;
lineHeights.title_l = cameoTheme.lineHeights.$5;
lineHeights.display_s = cameoTheme.lineHeights['$5.5'];
lineHeights.display_m = cameoTheme.lineHeights.$6;
lineHeights.display_l = cameoTheme.lineHeights.$8;

export { fontSizes, fontWeights, fontFamily, lineHeights };
