import * as React from 'react';
import type { CustomIconProps } from './types';

function DiwaliIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M8.09883 9.10001H5.89883C5.59883 9.10001 5.29883 9.00001 5.29883 8.70001V4.20001C5.29883 3.90001 5.59883 3.70001 5.89883 3.70001H8.09883C8.39883 3.70001 8.69883 3.90001 8.69883 4.20001V8.70001C8.69883 9.00001 8.39883 9.10001 8.09883 9.10001Z"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M6.99961 2.6C6.49961 2.6 6.09961 2.3 6.09961 1.9V1.8C6.09961 1.4 7.09961 0.399999 7.29961 0.299999C7.29961 0.299999 7.39961 0.199999 7.49961 0.299999C7.59961 0.299999 7.59961 0.399999 7.59961 0.399999C7.59961 0.399999 7.39961 0.999999 7.79961 1.5C7.89961 1.6 7.99961 1.8 7.99961 1.9C7.89961 2.3 7.49961 2.6 6.99961 2.6Z"
        fill="#F2F1F3"
      />
      <path
        d="M12.5992 9.20001C12.7992 9.20001 12.9992 9.40001 12.9992 9.60001C12.7992 12.7 10.1992 15.2 7.09922 15.2C3.99922 15.2 1.39922 12.7 1.19922 9.60001C1.19922 9.40001 1.39922 9.20001 1.59922 9.20001H12.5992Z"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default DiwaliIcon;
