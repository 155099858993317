import React, { ChangeEvent } from 'react';
import { Check } from 'react-feather';

import { theme } from 'domains/web/theme/og';
import { Wrapper, Icon } from './Styled';

type Option = {
  id: string;
  text?: React.ReactNode;
  value: string;
  isDisabled?: boolean;
  isChecked?: boolean;
};

type Props = {
  name: string;
  option: Option;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isRound?: boolean;
  onChange: (value: any, checked?: boolean) => void;
  children?: React.ReactNode;
  testId?: string;
  className?: string;
  defaultChecked?: boolean;
  containerStyle?: React.CSSProperties;
};

export const Checkbox = ({
  name,
  option,
  onChange,
  onClick,
  isRound = false,
  children,
  testId,
  className,
  defaultChecked,
  containerStyle,
}: Props) => {
  const handleChange =
    (isDisabled: boolean) => (event: ChangeEvent<HTMLInputElement>) => {
      if (!isDisabled) {
        onChange(event.target.value, event.target.checked);
      }
    };

  return (
    <Wrapper
      className={className}
      isRound={isRound}
      style={containerStyle}
      onClick={onClick}
    >
      <input
        name={name}
        type="checkbox"
        defaultChecked={defaultChecked}
        id={option.id}
        value={option.value}
        checked={option.isChecked}
        disabled={option.isDisabled}
        onChange={handleChange(option.isDisabled)}
        data-testid={testId}
      />
      <label
        htmlFor={option.id}
        data-testid={`checkbox-button-for-${option.value}`}
      >
        <Icon>
          <Check size={16} color={theme.colors.foreground.default} />
        </Icon>
        {option.text && <span>{option.text}</span>}
        {children}
      </label>
    </Wrapper>
  );
};
