export const types = {
  start: 'auth/ENROLL_PARTNER',
  success: 'auth/ENROLL_PARTNER_SUCCESS',
  fail: 'auth/ENROLL_PARTNER_FAIL',
};

export const start = (state) => ({
  ...state,
  enrollingPartner: true,
  enrollPartnerError: null,
});

export const success = (state) => ({
  ...state,
  enrollingPartner: false,
  enrolledPartner: true,
});

export const fail = (state, action) => ({
  ...state,
  enrollingPartner: false,
  enrollPartnerError: action.error,
});

export const service = (data) => ({
  types: [types.start, types.success, types.fail],
  promise: (client) => {
    return client.post('/user/partnerRequest', {
      data,
    });
  },
});
