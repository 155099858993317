import { State } from 'state/modules/reducer';
import get from 'lodash/get';
import { RegionPreferences } from 'server/middlewares/clientInfoMiddleware';

// Headless UA test does not send a language header so it will fail without default cases
export const getInternationalInfo = (state: State): RegionPreferences => {
  let currencyCode = get(
    state,
    'clientInfo.i18n.regionPreferences.currencyCode',
    'USD'
  );
  let currencyRate = get(
    state,
    'clientInfo.i18n.regionPreferences.currencyRate',
    1
  );

  if (currencyCode.toUpperCase() === 'EUR') {
    currencyCode = 'USD';
    currencyRate = 1;
  }

  return {
    languageCode: get(
      state,
      'clientInfo.i18n.regionPreferences.languageCode',
      'en'
    ),
    countryCode: get(
      state,
      'clientInfo.i18n.regionPreferences.countryCode',
      'US'
    ),
    currencyCode,
    currencyRate,
  };
};

export const getClientLanguageCode = (
  state: State
): RegionPreferences['languageCode'] => {
  return get(state, 'clientInfo.i18n.regionPreferences.languageCode');
};
