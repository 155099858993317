import { Types } from 'mongoose';
import actionCreatorFactory from 'typescript-fsa';
import * as Collage from 'types/src/api/endpoints/collage';

const actionCreator = actionCreatorFactory('cameo/collage');

export const clearCollage = actionCreator('CLEAR_COLLAGE');

export const CreateCollage = actionCreator.async<
  void,
  Collage.Create.POST.Response,
  any
>('CREATE_COLLAGE');

export const createCollage = (data: Collage.Create.POST.RequestBody) => ({
  types: [
    CreateCollage.started.type,
    CreateCollage.done.type,
    CreateCollage.failed.type,
  ],
  promise: (client) => {
    return client.post('/collage/v1', { data });
  },
});

export const GetById = actionCreator.async<
  void,
  Collage.GetById.GET.Response,
  any
>('GET_BY_ID');

export const getById = (collageId: string | Types.ObjectId) => ({
  types: [GetById.started.type, GetById.done.type, GetById.failed.type],
  promise: (client) => {
    return client.get(`/collage/v1/${collageId}`);
  },
});

export const PollById = actionCreator.async<
  void,
  Collage.GetById.GET.Response,
  any
>('POLL_BY_ID');

export const pollById = (collageId: string | Types.ObjectId) => ({
  types: [PollById.started.type, PollById.done.type, PollById.failed.type],
  promise: (client) => {
    return client.get(`/collage/v1/${collageId}`);
  },
});

export const Invite = actionCreator.async<
  void,
  Collage.UpdateContributors.POST.Response,
  any
>('INVITE');

export const invite = (
  collageId: string | Types.ObjectId,
  data: Collage.UpdateContributors.POST.RequestBody
) => ({
  types: [Invite.started.type, Invite.done.type, Invite.failed.type],
  promise: (client) => {
    return client.post(`/collage/v1/${collageId}/contributors`, {
      data,
    });
  },
});

export const Share = actionCreator.async<void, any, any>('SHARE');
export const share = (collageId: string | Types.ObjectId, data: any) => ({
  types: [Share.started.type, Share.done.type, Share.failed.type],
  promise: (client) => {
    return client.post(`/collage/v1/${collageId}/share`, {
      data,
    });
  },
});

export const Upload = actionCreator.async<
  void,
  Collage.UploadContent.POST.Response,
  any
>('UPLOAD');

export const upload = (
  collageId: string | Types.ObjectId,
  data: Collage.UploadContent.POST.RequestBody
) => ({
  types: [Upload.started.type, Upload.done.type, Upload.failed.type],
  promise: (client) => {
    return client.post(`/collage/v1/${collageId}/upload`, { data });
  },
});

export const Publish = actionCreator.async<
  void,
  Collage.Publish.POST.Response,
  any
>('PUBLISH');

export const publish = (collageId: string | Types.ObjectId) => ({
  types: [Publish.started.type, Publish.done.type, Publish.failed.type],
  promise: (client) => {
    return client.post(`/collage/v1/${collageId}/publish`);
  },
});

export const GetByOrderId = actionCreator.async<
  void,
  Collage.GetByOrderId.GET.Response,
  any
>('GET_BY_ORDER_ID');

export const getByOrderId = (orderId: string | Types.ObjectId) => ({
  types: [
    GetByOrderId.started.type,
    GetByOrderId.done.type,
    GetByOrderId.failed.type,
  ],
  promise: (client) => {
    return client.get(`/collage/v1/order/${orderId}`);
  },
});

export const DeleteContent = actionCreator.async<
  void,
  Collage.DeleteContent.DELETE.Response,
  any
>('DELETE_CONTENT');

export const deleteContent = (
  collageId: string | Types.ObjectId,
  contentId: string | Types.ObjectId
) => ({
  types: [
    DeleteContent.started.type,
    DeleteContent.done.type,
    DeleteContent.failed.type,
  ],
  promise: (client) => {
    return client.del(`/collage/v1/${collageId}/content/${contentId}`);
  },
});

export const UpdateCollage = actionCreator.async<
  void,
  Collage.UpdateCollage.PATCH.Response,
  any
>('UPDATE_COLLAGE');

export const updateCollage = (
  collageId: string | Types.ObjectId,
  body: Partial<Collage.UpdateCollage.PATCH.RequestBody>
) => ({
  types: [
    UpdateCollage.started.type,
    UpdateCollage.done.type,
    UpdateCollage.failed.type,
  ],
  promise: (client) => {
    return client.patch(`/collage/v1/${collageId}`, { data: { ...body } });
  },
});

export const UpdateContent = actionCreator.async<
  void,
  Collage.UpdateContent.PATCH.Response,
  any
>('UPDATE_CONTENT');

export const updateContent = (
  contentId: string | Types.ObjectId,
  data: Collage.UpdateContent.PATCH.RequestBody
) => ({
  types: [
    UpdateContent.started.type,
    UpdateContent.done.type,
    UpdateContent.failed.type,
  ],
  promise: (client) => {
    return client.patch(`/collage/v1/content/${contentId}`, { data });
  },
});

export const ReorderCollage = actionCreator.async<
  void,
  Collage.ReorderCollage.PATCH.Response,
  any
>('REORDER_COLLAGE');

export const reorderCollage = (
  collageId: string | Types.ObjectId,
  body: Partial<Collage.ReorderCollage.PATCH.RequestBody>
) => ({
  types: [
    ReorderCollage.started.type,
    ReorderCollage.done.type,
    ReorderCollage.failed.type,
  ],
  promise: (client) => {
    return client.patch(`/collage/v1/${collageId}/reorder`, {
      data: { ...body },
    });
  },
});
