import { State } from 'state/modules/reducer';
import { createSelector } from 'reselect';
import { BookingFormLocationState } from 'containers/BookingForm/types';

function getRouterLocation(state: State) {
  return state.router.location;
}

export const isCheckoutPage = createSelector(
  getRouterLocation,
  ({ pathname }): boolean => pathname?.includes('/book')
);

export const isBusinessPage = createSelector(
  getRouterLocation,
  (routerLocation): boolean => {
    const { pathname, search, state } = routerLocation;

    return (
      pathname === '/business' ||
      pathname === '/tags/business' ||
      search.includes('business=true') ||
      pathname.includes('/business') ||
      (pathname.includes('/book') &&
        search.includes('recipientType=business')) ||
      (pathname.includes('/book') &&
        (state as BookingFormLocationState)?.bookedForBusinessOnly)
    );
  }
);

/**
 * C4B Briefcase path regex.
 * Add new paths to this regex as they are needed.
 */
const C4B_BRIEFCASE_PATH_REGEX =
  /\/business(\/[0-9a-fA-F]{24})?\/(talent\/?|videos\/?)/;
export const isBusinessBriefcasePage = createSelector(
  getRouterLocation,
  (routerLocation): boolean => {
    const { pathname } = routerLocation;

    return pathname.match(C4B_BRIEFCASE_PATH_REGEX) !== null;
  }
);

export const isCollagePage = createSelector(
  getRouterLocation,
  (routerLocation): boolean => routerLocation.pathname.includes('/collage')
);

export const isDigitalPostCardPage = createSelector(
  getRouterLocation,
  (routerLocation): boolean =>
    routerLocation.pathname.includes('/purchase-digital-card')
);

export const isContentFeedPage = createSelector(
  getRouterLocation,
  (routerLocation): boolean =>
    routerLocation.pathname.includes('/feed') ||
    (routerLocation.pathname.startsWith('/collage') &&
      routerLocation.pathname.includes('/play'))
);

export const isKidsPage = createSelector(
  getRouterLocation,
  (routerLocation): boolean => routerLocation.pathname === '/kids'
);

export const isPostCardPage = createSelector(
  getRouterLocation,
  (routerLocation): boolean =>
    routerLocation.pathname.includes('/purchase-card')
);

export const getCurrentPage = createSelector(
  getRouterLocation,
  isBusinessPage,
  (routerLocation, businessPage) => {
    const { pathname } = routerLocation;
    if (pathname === '/') {
      return 'home';
    }
    if (pathname === '/categories') {
      return 'seeAll';
    }
    if (pathname.startsWith('/browse/') || pathname.startsWith('/b/')) {
      return 'browse';
    }
    if (businessPage) {
      return 'business';
    }
    if (pathname.startsWith('/tags/') || pathname.startsWith('/t/')) {
      return 'tags';
    }
    return 'other';
  }
);

export const getCurrentPathname = createSelector(
  getRouterLocation,
  (routerLocation): string => routerLocation.pathname
);
