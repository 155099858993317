import styled from 'styled-components';
import { theme } from '@cameo/theme';
import { sizing, fonts, color, spacing } from 'design-system/style';
import { compose, space, SpaceProps } from 'styled-system';

export const Title = styled.label`
  ${fonts.mediumText['200']};
  font-weight: 600;
  color: ${color.solids.WHITE};
  > span:first-of-type {
    display: block;
    margin: ${sizing(4)} 0 ${sizing(8)} 0;
  }
`;

export const Subtitle = styled.span`
  ${fonts.regularText['200']};
  color: ${color.solids.MUTED};
  margin-bottom: ${sizing(20)};
  display: block;
`;

export const InputWrapper = styled.div<{
  isHidden?: boolean;
}>`
  position: relative;
  transition: max-height 0.4s ease-out, opacity 0.8s ease;
  max-height: ${({ isHidden }) => (isHidden ? '0' : sizing(1000))};
  opacity: ${({ isHidden }) => (isHidden ? '0' : '1')};
`;

export const InputStyled = styled.input<{
  hasError?: boolean;
  hasPlaceholder?: boolean;
  lightBackground?: boolean;
}>`
  ${({ hasPlaceholder }) =>
    hasPlaceholder &&
    `
      option[disabled] {
        color: ${color.solids.MUTED} !important;
      }
    `};
  ${({ hasError }) =>
    hasError && `border-color: ${theme.colors.danger.emphasis} !important;`};
  ${({ lightBackground }) =>
    lightBackground && `color: ${color.solids.BLACK} !important`};
`;

export const SideButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: ${sizing(14)} ${sizing(16)} ${sizing(14)} ${sizing(8)};
  justify-content: center;
  align-items: center;
  color: ${color.solids.WHITE};
  display: none;
  cursor: pointer;
`;

export const Emoji = styled.span`
  position: absolute;
  left: 0;
  bottom: ${sizing(5)};
  height: 100%;
  font-size: 25px;
  padding: ${sizing(14)} ${sizing(14)} ${sizing(14)} ${sizing(16)};
  justify-content: center;
  align-items: center;
`;

export const SelectCaret = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: ${sizing(14)} ${sizing(8)};
  justify-content: center;
  align-items: center;
  color: ${color.solids.WHITE};
  display: flex;
  cursor: pointer;
  pointer-events: none;
`;

export const Wrapper = styled.div<
  {
    hasEmoji?: boolean;
    hasError?: boolean;
  } & SpaceProps
>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: ${sizing(100)};
  margin-bottom: ${spacing.margin.medium};

  input:not([value='']):not(:disabled) + ${SideButton} {
    display: flex;
  }

  &:focus-within {
    input[type='text'],
    input[type='password'],
    input[type='email'],
    input[type='tel'],
    input[type='number'],
    ${InputStyled} {
      border-color: ${theme.colors['grey-95']} !important;
      border-width: ${theme.borderWidths['$0.25']} !important;
    }
  }

  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  ${InputStyled} {
    ${fonts.regularText['300']};
    appearance: none;
    background: ${theme.colors.background.level2};
    border-radius: ${theme.radii.$1};
    border: ${theme.borderWidths.px} solid ${theme.colors.border.default};
    color: ${theme.colors.foreground.default};
    font-family: ${theme.fonts.body};
    font-size: ${theme.text['interactive-m'].fontSize};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.text['interactive-m'].lineHeight};
    padding: ${theme.space.$2}px;
    transition: all 0.2s ease-in-out;
    width: 100%;

    &::placeholder {
      color: ${theme.colors.foreground.subtle};
    }

    &:focus,
    &:focus-visible {
      outline-color: ${theme.colors['grey-95']};
    }

    &:hover {
      border-color: ${theme.semanticColors.border.emphasis};
      transition: none;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }

    ${({ hasError }) =>
      hasError &&
      `
        border-color: ${theme.colors.danger.emphasis};
      `}

    ${({ hasEmoji = false }) =>
      `padding: ${sizing(14)}  ${sizing(36)} ${sizing(14)} ${
        hasEmoji ? sizing(50) : sizing(16)
      };`}
  }

  select {
    background-image: none;
  }

  textarea {
    ${fonts.regularText['300']};
    resize: none;
    padding: ${sizing(16)};
    border-radius: ${theme.radii.$1};
    border: solid ${sizing(1.5)} ${theme.colors.border.default};
    color: ${color.solids.WHITE};
    background-color: transparent;
    outline: none;
    appearance: none;
    width: 100%;
    box-shadow: none;
    transition: all 0.2s ease;

    &::placeholder {
      color: ${theme.colors['grey-30']};
    }
    &:focus {
      border-color: #bebbbf;
    }
    &:disabled {
      cursor: not-allowed;
      background-color: ${color.grays.GRAY50};
      border: solid ${sizing(1.5)} ${color.grays.GRAY50};
      color: ${color.grays.GRAY50};
    }
  }

  ${compose(space)}
`;

export const ErrorMessage = styled.p<{
  textAlign?: 'center' | 'right' | 'left';
}>`
  ${fonts.regularText['200']};
  justify-content: flex-end;
  text-align: ${(props) => props.textAlign ?? 'right'};
  color: ${theme.colors.danger.emphasis};
  padding: ${sizing(2)} 0;
  margin: ${spacing.margin.tiny} 0 0;
`;

export const OutsideInputCounter = styled.p<{
  center?: boolean;
  hasError?: boolean;
}>`
  font-family: ${theme.fonts.body};
  font-weight: ${theme.fontWeights.regular};
  font-size: ${theme.text['body-s'].fontSize};
  line-height: ${theme.text['body-s'].lineHeight};
  justify-content: flex-end;
  text-align: ${(props) => (props.center ? 'center' : 'right')};
  color: ${({ hasError }) =>
    hasError ? theme.colors.danger.default : theme.colors.foreground.muted};
  padding: ${sizing(2)} 0;
  margin: ${theme.space['$0.5']}px 0 0;
`;

export const TextAreaStyled = styled.textarea<{ hasError: boolean }>`
  ${({ hasError }) =>
    hasError && `border-color: ${theme.colors.danger.emphasis} !important;`};
`;

export const TextAreaBottom = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
`;
