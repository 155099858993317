import { config } from 'cameoConfig';
import { EventBaseFull } from '@cameo/events';
import superagent from 'superagent';
import { v4 as uuidv4 } from 'uuid';
import { TIMEOUT_RESPONSE_STATUS } from 'analytics/utils/constants';
/**
 * uploads the given events in an async manner, returning the response code received from the server.
 * @param events the list of events to be uploaded
 */
async function uploadEvents(
  events: EventBaseFull[],
  dispatchAttempt: number
): Promise<number> {
  const body = getRequestBody(events, dispatchAttempt);

  const timeouts = {
    response: config.eventCollector.uploadTimeoutMs,
    deadline: config.eventCollector.uploadTimeoutMs,
  };

  try {
    const response = await superagent
      .post(config.eventCollector.url)
      .send(body)
      .timeout(timeouts);
    return response.status;
  } catch (error) {
    if (error.response) {
      return error.response.status; // catches 400 and 500 errors
    }
    if (error.timeout) {
      return TIMEOUT_RESPONSE_STATUS; // timeout
    }
    console.error('dpUploader.uploadEvents.error:', error);
    throw error;
  }
}

/**
 * Uploads the given events in a manner that is safe for page close. Clients may rely on the request being sent, but
 * will not be returned a response code from the server; if a response code is desired, use {@link uploadEvents} instead
 * @param events the list of events to be uploaded
 */
function uploadEventsOnUnload(
  events: EventBaseFull[],
  dispatchAttempt: number
) {
  navigator.sendBeacon(
    config.eventCollector.url,
    JSON.stringify(getRequestBody(events, dispatchAttempt))
  );
}

function getRequestBody(events: EventBaseFull[], dispatchAttempt: number) {
  return {
    dispatchTs: new Date().getTime(),
    dispatchAttempt,
    batchId: uuidv4(),
    eventRecords: events,
  };
}

export const DpUploader = {
  uploadEvents,
  uploadEventsOnUnload,
};
