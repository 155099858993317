/* NOTE:
 * This middleware is not used by actions using typescript-fsa-redux-thunk.
 * If you are adding things here for a new action, consider using
 * typescript-fsa-redux-thunk instead?.
 */

const genericError = {
  message: 'Something went wrong.',
};

export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => {
    return (next) => (action) => {
      const { promise, types, cache, ...rest } = action;

      // If action is not async, dispatch it
      if (!promise) {
        return next(action);
      }

      // If async action is configured to use cache, memoize it
      const actionPromise = promise(client);

      // An async action contain 3 sub-actions, always dispatched in order
      const [REQUEST, SUCCESS, FAILURE] = types;

      const params = JSON.parse(JSON.stringify(rest));

      // The first sub-action is dispatched right away
      next({
        ...rest,
        type: REQUEST,
        payload: params,
      });

      actionPromise
        .then(
          // the success sub-action is dispatched when promise resolves
          (result) => {
            next({
              ...rest,
              payload: {
                params,
                result,
              },
              result,
              type: SUCCESS,
            });
          },
          (error) => {
            // the failure sub-action is dispatched if promise rejects
            return next({
              ...rest,
              payload: {
                params,
                error,
              },
              error: error || genericError,
              type: FAILURE,
            });
          }
        )
        .catch((error) => {
          // TODO: we should track these errors since they are bugs
          console.error('MIDDLEWARE ERROR:', error);
          next({
            ...rest,
            payload: {
              params,
              error,
            },
            error: error || genericError,
            type: FAILURE,
          });
        });

      return actionPromise;
    };
  };
}
