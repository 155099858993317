import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Globe } from 'react-feather';

import { Box, Button, Dialog, Select, Text } from 'domains/web/components';
import { getLoggedInUserId } from 'state/modules/auth/selectors';
import {
  updateConversionRateForCurrency,
  updateCurrency,
  updateLanguage,
  updateRegion,
  updateUserPreferences,
} from 'state/modules/clientInfo/i18n/actions';

import { useI18N } from 'utils/hooks/useI18N';
import { LOCALES } from 'utils/i18n';
import { Form } from 'domains/web/components/Form';
import { client } from 'helpers/ApiClient';

export interface InnerComponentProps {
  language: string;
  currency: string;
  region: string;
}

const LanguageRegionCurrencySelectorModal = (props) => {
  const dispatch = useDispatch();
  const [isSheetVisible, setSheetVisible] = useState(false);
  const { i18n, t } = useTranslation('LanguageRegionCurrency');
  const { currency, language, region } = useI18N();

  const userId = useSelector(getLoggedInUserId);

  const [preferredCurrency, setPreferredCurrency] = useState(currency);
  const [preferredLanguage, setPreferredLanguage] = useState(language);
  const [preferredRegion, setPreferredRegion] = useState(region);

  const [isInitLoad, setIsInitLoad] = useState(userId);
  useEffect(() => {
    if (userId && userId !== isInitLoad) {
      setIsInitLoad(userId);
      client
        .get(`/user/${userId}/preferences/locale`)
        .then((res) => {
          const dispatchedUpdates = [];
          const {
            language: userLanguage,
            currency: userCurrency,
            region: userRegion,
          } = res;
          if (userCurrency && preferredCurrency !== userCurrency) {
            setPreferredCurrency(userCurrency);
            dispatchedUpdates.push(dispatch(updateCurrency(userCurrency)));
            dispatchedUpdates.push(
              dispatch(updateConversionRateForCurrency(userCurrency))
            );
          }
          if (userLanguage && preferredLanguage !== userLanguage) {
            setPreferredLanguage(userLanguage);
            dispatchedUpdates.push(dispatch(updateLanguage(userLanguage)));
            i18n.changeLanguage(userLanguage);
          }
          if (userRegion && preferredRegion !== userRegion) {
            setPreferredRegion(userRegion);
            dispatchedUpdates.push(dispatch(updateRegion(userRegion)));
          }
          if (dispatchedUpdates.length) {
            // Promise.all(dispatchedUpdates).then(() => window.location.reload());
          }
        })
        .catch(console.error);
    }
  }, [userId, isInitLoad]);

  const localeItems = ['en', 'es', 'fr'].map((locale) => {
    return {
      label: LOCALES[locale].nativeName,
      value: LOCALES[locale].languageCode,
    };
  });

  const currencyItems = [
    { value: 'USD', label: '$ USD' },
    { value: 'AUD', label: '$ AUD' },
    { value: 'BRL', label: 'R$ BRL' },
    { value: 'CAD', label: '$ CAD' },
    { value: 'GBP', label: '£ GBP' },
    { value: 'MXN', label: '$ MXN' },
  ];

  const regionItems = [
    { value: 'US', label: 'United States' },
    { value: 'AU', label: 'Australia' },
    { value: 'CA', label: 'Canada' },
    { value: 'GB', label: 'United Kingdom' },
  ];

  const onClose = () => {
    setSheetVisible(false);
  };

  const showSheet = (event: React.MouseEvent) => {
    event.preventDefault();
    setSheetVisible(true);
  };

  const handleSubmit = async () => {
    await dispatch(updateConversionRateForCurrency(preferredCurrency));
    await dispatch(
      updateUserPreferences({
        userId,
        currency: preferredCurrency,
        region: preferredRegion,
        language: preferredLanguage,
      })
    );

    i18n.changeLanguage(preferredLanguage);
    onClose();
    window.location.reload();
  };

  const { label: currencyLabel } =
    currencyItems.find(
      (item) => item?.value?.toLowerCase() === preferredCurrency?.toLowerCase()
    ) || currencyItems[0];

  const { label: regionLabel } =
    regionItems.find(
      (item) => item?.value?.toLowerCase() === preferredRegion?.toLowerCase()
    ) || regionItems[0];

  const locale = LOCALES[preferredLanguage?.toLowerCase()] || LOCALES.en;

  const InnerComponent = props.innerComponent || Globe;

  return (
    <>
      <Box
        display={'flex'}
        margin={'0'}
        padding={'0'}
        onClick={showSheet}
        gap={2}
      >
        {
          <InnerComponent
            language={locale.localeName.toUpperCase()}
            region={regionLabel}
            currency={currencyLabel}
          />
        }
      </Box>
      <Dialog.Root
        onClose={onClose}
        onOpenChange={setSheetVisible}
        open={isSheetVisible}
        size="x-large"
        title={t('countryPreferences', 'Country Preferences')}
        // @ts-ignore - hack around to allow dropdowns to overflow the dialog
        css={`
          main {
            overflow: initial;
          }
        `}
      >
        <Text as="h3" variant="base_m" color="foreground.muted" mb={4}>
          {t(
            'instructions',
            'Select your preferred language, region, and the currency you want to pay with.'
          )}
        </Text>
        <Box
          display="grid"
          gap={4}
          gridTemplateColumns={{ _: '1fr', md: 'repeat(3, 1fr)' }}
        >
          <Form.Control>
            <Form.Label htmlFor="language-dropdown">
              {t('LangaugeDropdownLabel', 'Language')}
            </Form.Label>
            <Select.Root
              onValueChange={(val) => setPreferredLanguage(val)}
              defaultValue={preferredLanguage}
            >
              <Select.Trigger id="language-dropdown">
                <Select.Value
                  placeholder={t(
                    'LanguageDropdownPlaceholder',
                    'Your language'
                  )}
                />
              </Select.Trigger>
              <Select.Content>
                {localeItems.map((item) => (
                  <Select.Item key={item.value} value={item.value}>
                    {item.label}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="region-dropdown">
              {t('RegionDropdownLabel', 'Region')}
            </Form.Label>
            <Select.Root
              onValueChange={(val) => setPreferredRegion(val)}
              defaultValue={preferredRegion}
            >
              <Select.Trigger id="region-dropdown">
                <Select.Value
                  placeholder={t('RegionDropdownPlaceholder', 'Your region')}
                />
              </Select.Trigger>
              <Select.Content>
                {regionItems.map((item) => (
                  <Select.Item key={item.value} value={item.value}>
                    {item.label}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="currency-dropdown">
              {t('CurrencyDropdownLabel', 'Currency')}
            </Form.Label>
            <Select.Root
              onValueChange={(val) => setPreferredCurrency(val)}
              defaultValue={preferredCurrency}
            >
              <Select.Trigger id="currency-dropdown">
                <Select.Value
                  placeholder={t(
                    'CurrencyDropdownPlaceholder',
                    'Your currency'
                  )}
                />
              </Select.Trigger>
              <Select.Content>
                {currencyItems.map((item) => (
                  <Select.Item key={item.value} value={item.value}>
                    {item.label}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
          </Form.Control>
        </Box>
        <Dialog.Footer>
          <Button onClick={handleSubmit} size="large" variant="primary">
            {t('submit-button', 'Save selections')}
          </Button>
        </Dialog.Footer>
      </Dialog.Root>
    </>
  );
};

export { LanguageRegionCurrencySelectorModal };
