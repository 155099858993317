import { stringify } from 'qs';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { GET } from 'types/src/api/endpoints/fan-club/load';
import { CameoEnums } from 'types/src/utils/enums';
import { FanClubsState, Error } from './types';
import INITIAL_STATE from './initialState';
import { actionCreator } from './utils';

export const LoadFanClub = actionCreator.async<
  GET.QueryParams,
  GET.Response,
  Error
>('LOAD_FAN_CLUB');

export function loadFanClub(params: GET.QueryParams) {
  return {
    types: [
      LoadFanClub.started.type,
      LoadFanClub.done.type,
      LoadFanClub.failed.type,
    ],
    promise: (client) => client.get(`/fanClub/load?${stringify(params)}`),
  };
}

export const reducer = reducerWithInitialState<FanClubsState>(INITIAL_STATE)
  .case(LoadFanClub.started, (state) => ({
    ...state,
    loadingFanClub: true,
    loadingFanClubError: null,
  }))
  .case(LoadFanClub.done, (state, { result }) => {
    const {
      isUserMember,
      fanClub,
      userSubscriptionType,
      userStripeSubscriptionId,
      userPendingCancellation,
      userSubscriptionExpiresAt,
      userPricePaid,
      isUserOwner,
      additionalFanClubs,
    } = result;
    // Add primary fan club to membershipIDList
    const membershipIDList = isUserMember
      ? [...state.membershipIDList, fanClub._id]
      : state.membershipIDList;

    // Add additional (multi-owner) fan clubs to membershipIDList
    membershipIDList.push(
      ...additionalFanClubs
        .filter((additionalFanClubData) => additionalFanClubData.isUserMember)
        .map((additionalFanClubData) => additionalFanClubData.fanClub._id)
    );

    // Add primary fan club to activeUserSubscriptions
    const activeUserSubscriptions = userSubscriptionType
      ? {
          ...state.activeUserSubscriptions,
          [fanClub._id]: userSubscriptionType,
        }
      : state.activeUserSubscriptions;

    // Add additional (multi-owner) fan clubs to activeUserSubscriptions
    additionalFanClubs.forEach((additionalFanClubData) => {
      if (
        Object.values(CameoEnums.FanClubSubscriptionTypes).includes(
          additionalFanClubData.userSubscriptionType
        )
      ) {
        activeUserSubscriptions[additionalFanClubData.fanClub._id] =
          additionalFanClubData.userSubscriptionType;
      }
    });

    // Add primary and additional (multi-owner) fan clubs to ownershipIDList
    const ownershipIDList = [
      ...state.ownershipIDList,
      ...(isUserOwner ? [fanClub._id] : []),
      ...additionalFanClubs
        .filter((additionalFanClubData) => additionalFanClubData.isUserOwner)
        .map((additionalFanClubData) => additionalFanClubData.fanClub._id),
    ];

    // Add primary fan club to fanClubs
    const fanClubs = { ...state.fanClubs, [fanClub._id]: fanClub };

    // Add additional (multi-owner) fan clubs to fanClubs
    additionalFanClubs.forEach((additionalFanClubData) => {
      fanClubs[additionalFanClubData.fanClub._id] =
        additionalFanClubData.fanClub;
    });

    return {
      ...state,
      loadingFanClub: false,
      loadingFanClubError: null,
      membershipIDList: [...new Set(membershipIDList)],
      ownershipIDList: [...new Set(ownershipIDList)],
      activeUserSubscriptions,
      fanClubs,
      selectedFanClubUserDetails: {
        ...state.selectedFanClubUserDetails,
        userSubscriptionExpiresAt,
        userStripeSubscriptionId,
        userPendingCancellation,
        userSubscriptionType,
        userPricePaid,
      },
    };
  })
  .case(LoadFanClub.failed, (state, action) => ({
    ...state,
    loadingFanClub: false,
    loadingFanClubError: action.error,
  }))
  .build();
