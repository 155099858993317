export const SET_AUTH_VIEW = 'auth/SET_AUTH_VIEW';

export const setAuthView = (state, action) => ({
  ...state,
  showAuthView: action.view, // null - 'login' - 'signup' - 'signup-promocode'
  showAuthFlowData: action.flowData,
});

// Uses `flowData` to provide additional data to all
// screens in an the selected auth flow.
export const service = (
  view: 'signup' | 'signup-promocode' | 'login' | 'signup-on-receipt' | null,
  flowData = {}
) => ({
  type: SET_AUTH_VIEW,
  view,
  flowData,
});
