import * as React from 'react';
import type { CustomIconProps } from './types';

function ThanksgivingIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M14.2992 13.8C14.5992 13.7 14.6992 13.6 14.8992 13.4C15.0992 13.1 15.1992 12.8 15.1992 12.5C15.1992 12.2 15.0992 11.9 14.8992 11.6C14.5992 11.1 14.0992 10.7 13.4992 10.4C13.2992 10.3 12.8992 10.1 12.4992 9.69998C12.0992 9.39998 11.5992 8.99998 11.1992 8.59998C10.8992 8.39998 10.6992 8.19998 10.4992 7.89998C10.2992 7.69998 10.0992 7.39998 9.89922 7.19998C9.79922 7.09998 9.69922 6.89998 9.69922 6.79998C9.69922 6.69998 9.59922 6.59998 9.59922 6.49998C8.99922 4.89998 8.39922 3.49998 6.89922 2.09998C5.49922 0.999978 4.09922 0.999978 2.89922 1.59998C2.49922 1.79998 2.09922 2.09998 1.69922 2.49998C1.39922 2.89998 1.09922 3.29998 0.899218 3.79998C0.499218 4.99998 0.599218 6.49998 1.89922 7.59998C3.29922 8.79998 4.69922 9.19998 6.29922 9.59998C6.39922 9.59998 6.39922 9.59998 6.69922 9.69998C6.79922 9.69998 6.89922 9.79998 7.09922 9.79998C7.19922 9.89998 7.39922 9.99998 7.49922 10.1C7.59922 10.2 7.79922 10.3 7.89922 10.4C8.39922 10.8 8.89922 11.2 9.29922 11.7C9.39922 11.8 9.49922 12 9.69922 12.1C9.89922 12.1 9.99922 12.2 9.99922 12.3C10.0992 12.4 10.0992 12.4 10.1992 12.5C10.2992 12.6 10.2992 12.7 10.3992 12.8C10.4992 13 10.6992 13.1 10.7992 13.3L10.9992 13.5C11.5992 14.2 12.1992 14.7 12.6992 14.8C13.3992 14.9 13.8992 14.7 14.2992 13.8Z"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default ThanksgivingIcon;
