// In browser, returns named variable from window object
// On server, returns named environment variable
function globalVariable(varName, defaultValue) {
  // Not sure how/where, but process.env is mocked in the browser
  // so have to try `window` first and `process.env` in the catch
  try {
    return window[varName] || defaultValue;
  } catch {
    // @eslint-ignore
    return process.env[varName] || defaultValue;
  }
}
const NODE_ENV = globalVariable('NODE_ENV', 'localdev');
const HOST = globalVariable('HOST', 'localhost');
const APIHOST = globalVariable('APIHOST', 'localhost');
const PORT = globalVariable('PORT', 3000);
const APIPORT = globalVariable('APIPORT', 3030);
const eventCollectorFlushDelay = 5000;
const eventCollectorUploadTimeout = 5000;
const eventCollectorMaxBufferSize = 500;
const eventCollectorSessionThresholdMs = 30 * 60 * 1000; // session for inactive event expires in 30 mins
const AFFIRM_PUBLICKEY = globalVariable('AFFIRM_PUBLICKEY');
const APPLE_LOGIN_SECRET = globalVariable('APPLE_LOGIN_SECRET');
const USE_SMARTLING_CONTEXT = globalVariable('USE_SMARTLING_CONTEXT', false);

const production = {
  environment: 'production', // used to tell the client to show banner or not

  disableReactDevTools: true,

  affirm: {
    publicKey: AFFIRM_PUBLICKEY,
    script: 'https://cdn1.affirm.com/js/v2/affirm.js',
  },

  cloudfrontBaseUrl: 'https://cdn.cameo.com',

  optimizelySDK: 'STkozm2AvjqUkpr374En7e',
  eppoSDK: '_lm9dH3t-t28JtWxeSRbbrDTlTLQEuB-dUvqg_YeYEM',
  braze: {
    src: 'sdk.iad-06.braze.com',
    key: '2d02c0a6-019b-4f50-9090-065f4a4cf34d',
  },
  facebook: {
    appId: '359010004463989',
    pageId: '231378747265828',
  },
  pusher: {
    cluster: 'us3',
    appKey: '810d34d9f3165ee185f8',
  },
  eventCollector: {
    url: 'https://evt.cameo.com/batch',
    flushDelayMs: eventCollectorFlushDelay,
    uploadTimeoutMs: eventCollectorUploadTimeout,
    maxBufferSize: eventCollectorMaxBufferSize,
    sessionThresholdMs: eventCollectorSessionThresholdMs,
  },
  amplitudeApiKey: 'f2c85e5c601251665b314151eb204743',
  analytics: {
    label: 'config-prod-ga',
    id: 'G-97XW3EZCDW', // Cameo Web UA Property - GA4
    googleTagManagerId: 'GTM-W2MXZ9S',
  },
  branch: {
    src: 'https://cdn.branch.io/branch-latest.min.js',
    key: 'key_live_meIaIZKAAI7300VkdIQM4pahEFiftj4s',
    url: 'https://v.cameo.com',
  },
  linkedin: {
    partnerId: '2397073',
  },

  google: {
    placesAPIKey: 'AIzaSyD7NWlcfoBby7tZKkR-Zh71_uovyozp_IU',
  },
  fingerprintPro: {
    publicKey: 'fusBr9Nux613wNbf3Q3F',
    endpoint: 'https://www.cameo.com/i8fcMBdyjOhLJ9Yx/onsLKKogu6cvjAft',
    scriptUrlPattern:
      'https://www.cameo.com/i8fcMBdyjOhLJ9Yx/o8jRnJgmjaRfx8wq?apiKey=fusBr9Nux613wNbf3Q3F&version=3&loaderVersion=3.8.3',
    enabled: true,
  },
  rollbar: {
    accessToken: '5cdcef10b6dc47c7b5e187f7ce8e18d5',
    environment: 'production',
    autoInstrument: false,
    enabled: true,
    code_version: globalVariable('VERSION', 'not-available'),
  },
  instagramWebRedirectUri:
    'https://www.cameo.com/api/auth/instagram/callbackweb',
  stripe: {
    clientId: 'ca_A2Kb0Lt778K1rO6Gl3KGil8DcUcAlA1w',
    publicKey: 'pk_live_YR2G0JQsuTK4cw7mTUHl4TTW',
  },

  website: 'https://www.cameo.com',
  app: {
    title: 'Personalized videos from your favorite stars',
    description:
      'Browse thousands of celebrities and request a personalized video message for any occasion. Get creative with your request, especially for celebrations like birthdays, weddings, retirements, or bachelorettes that call for a big surprise.',
    head: {
      titleTemplate: '%s | Cameo',
      meta: [
        {
          name: 'description',
          content:
            'Browse thousands of celebrities and request a personalized video message for any occasion. Get creative with your request, especially for celebrations like birthdays, weddings, retirements, or bachelorettes that call for a big surprise.',
        },
        {
          property: 'og:image',
          content: `https://www.cameo.com/cdn-cgi/image/fit=cover,width=1024,height=512/https://cdn.cameo.com/resizer/9bd12dbf-5c48-466c-a186-7a132886d06c.png`,
        },
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:url', content: 'https://www.cameo.com' },
        { property: 'og:site_name', content: 'Cameo' },
        { property: 'fb:app_id', content: '359010004463989' },
        {
          property: 'og:title',
          content: 'Personalized videos from your favorite stars',
        },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content:
            'Browse thousands of celebrities and request a personalized video message for any occasion. Get creative with your request, especially for celebrations like birthdays, weddings, retirements, or bachelorettes that call for a big surprise.',
        },
        { property: 'og:card', content: 'summary' },
        { property: 'og:image:width', content: '1024' },
        { property: 'og:image:height', content: '512' },
        { property: 'twitter:card', content: 'summary_large_image' },
      ],
    },
  },

  appleLogin: {
    clientId: 'com.baronapp.cameoweb',
    cdn: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
    // NOTE: Apple requires https and so does not allow localhost as a valid domain.
    // During development, you will need to use cameo(-blue|pink).ngrok.io.
    // Additional URLs can be added here: https://developer.apple.com/account/resources/identifiers/serviceId/edit/NR44597HW8
    redirectURI: '/auth/apple/callback',
    secret: APPLE_LOGIN_SECRET,
  },
  oneTrust: {
    script: '57c3557f-2353-42d2-b34e-3f38f79ec215',
  },
  algolia: {
    appId: 'C19LEKNYRK',
    apiKey: '45159713115b5d3cb43b41686018c4a8',
    talentIndexName: 'prod_cameo_talent_v0',
  },
  agSDK: {
    sdkUrl: 'https://add-a-card.americangreetings.com/js/sdk.min.js',
    partnerKey: 'fd5ede58985c369de3e33627d4fbdabc',
  },
  envSpecificFeatureOverride: {
    c4bPasswordless: true,
    algolia: true,
  },
};
const development = {
  environment: 'development', // used to tell the client

  disableReactDevTools: false,

  affirm: {
    publicKey: AFFIRM_PUBLICKEY,
    script: 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js',
  },

  optimizelySDK: '5FG6WVQeLgMWKU3NH5H2xe',
  eppoSDK: 'tgXv1DLwnXl09B0baZmLDemPjyVGjE1SgIgYuNUZn0c',

  braze: {
    src: 'sdk.iad-06.braze.com',
    key: '842980d9-a839-4fcc-a5ee-c4cc49fae34e',
  },

  facebook: {
    appId: '390692721295717',
    pageId: '1053359501437249',
  },
  fingerprintPro: {
    publicKey: 'MBCfVZbRbamK1RBsfDRr',
    endpoint: 'https://www.cameo.com/i8fcMBdyjOhLJ9Yx/onsLKKogu6cvjAft',
    scriptUrlPattern:
      'https://www.cameo.com/i8fcMBdyjOhLJ9Yx/o8jRnJgmjaRfx8wq?apiKey=MBCfVZbRbamK1RBsfDRr&version=3&loaderVersion=3.8.3',
    enabled: true,
  },
  rollbar: {
    enabled: false,
    code_version: globalVariable('VERSION', 'not-available'),
  },
  pusher: {
    cluster: 'us3',
    appKey: '049ff9fda202e4286c0a',
  },

  amplitudeApiKey: '5177da655c91588444463b94d8315a67',
  analytics: {
    label: 'config-dev-ga',
    id: 'G-NCDNTJK5LH', // Dev Cameo - GA4
  },
  branch: {
    src: 'https://cdn.branch.io/branch-latest.min.js',
    key: 'key_test_diMdP3NyvRY057SofOH2kioauqamud2M',
    url: 'https://cameo.test-app.link',
  },

  google: {
    placesAPIKey: 'AIzaSyD7NWlcfoBby7tZKkR-Zh71_uovyozp_IU',
  },
  eventCollector: {
    url: 'https://dev-evt.cameo.com/batch',
    flushDelayMs: eventCollectorFlushDelay,
    uploadTimeoutMs: eventCollectorUploadTimeout,
    maxBufferSize: eventCollectorMaxBufferSize,
    sessionThresholdMs: eventCollectorSessionThresholdMs,
  },
  instagramWebRedirectUri:
    'https://dev.cameo.com/api/auth/instagram/callbackweb',

  stripe: {
    clientId: 'ca_A2Kbdzm0e29BkMC4tkk11Zlsm7dBfHPS',
    publicKey:
      'pk_test_519B80dFm3D5E2CnBqIFJLPfXXBO6mvJo1Ipj9syfNLJEAqqYLYVs0Rtn6yfx6BkwZdFgSp4MbKNa4Nrqx2RPC7wi00pQFvd7DY',
  },

  website: 'http://dev.cameo.com',

  app: {
    title: 'Personalized videos from your favorite stars',
    description:
      'Browse thousands of celebrities and request a personalized video message for any occasion. Get creative with your request, especially for celebrations like birthdays, weddings, retirements, or bachelorettes that call for a big surprise.',
    head: {
      titleTemplate: '%s | Cameo',
      meta: [
        {
          name: 'description',
          content:
            'Browse thousands of celebrities and request a personalized video message for any occasion. Get creative with your request, especially for celebrations like birthdays, weddings, retirements, or bachelorettes that call for a big surprise.',
        },
        {
          property: 'og:image',
          content: `https://www.cameo.com/cdn-cgi/image/fit=cover,width=1024,height=512/https://cdn.cameo.com/resizer/9bd12dbf-5c48-466c-a186-7a132886d06c.png`,
        },
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:url', content: 'https://dev.cameo.com' },
        { property: 'fb:app_id', content: '390692721295717' },
        {
          property: 'og:title',
          content: 'Personalized videos from your favorite stars',
        },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'Cameo' },
        {
          property: 'og:description',
          content:
            'Browse thousands of celebrities and request a personalized video message for any occasion. Get creative with your request, especially for celebrations like birthdays, weddings, retirements, or bachelorettes that call for a big surprise.',
        },
        { property: 'og:card', content: 'summary' },
        { property: 'og:site', content: '@bookcameo' },
        { property: 'og:creator', content: '@bookcameo' },
        { property: 'og:image:width', content: '1024' },
        { property: 'og:image:height', content: '512' },
        { property: 'twitter:site', content: '@bookcameo' },
        { property: 'twitter:card', content: 'summary_large_image' },
      ],
    },
  },

  appleLogin: {
    clientId: 'com.baronapp.cameoweb-staging',
    cdn: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
    // NOTE: Apple requires https and so does not allow localhost as a valid domain.
    // During development, you will need to use cameo(-blue|pink).ngrok.io.
    // Additional URLs can be added here: https://developer.apple.com/account/resources/identifiers/serviceId/edit/NR44597HW8
    redirectURI: '/auth/apple/callback',
    secret: APPLE_LOGIN_SECRET,
  },
  oneTrust: {
    script: '57c3557f-2353-42d2-b34e-3f38f79ec215-test',
  },
  algolia: {
    appId: 'testing584VE9R6GH',
    apiKey: 'af36c4720458f94c8b62e0f1259f7792',
    talentIndexName: 'dev_cameo_talent_v0',
  },
  agSDK: {
    sdkUrl: 'https://add-a-card.agpre.net/js/sdk.min.js',
    partnerKey: 'cameo_93caf30178fe4d36ad9a4a99349f2577',
  },
  envSpecificFeatureOverride: {
    c4bPasswordless: true,
    algolia: true,
  },
};
const localdev = {
  ...development,
  logAnalytics: false,
  apiBaseUrl: `http://${APIHOST}:${APIPORT}`,
  fingerprintPro: {
    publicKey: undefined,
    endpoint: undefined,
    scriptUrlPattern: undefined,
    enabled: false,
  },
  rollbar: {
    enabled: false,
    code_version: globalVariable('VERSION', 'not-available'),
  },
};

const test = {
  ...localdev,
  optimizelySDK: 'Q1WDbMitxeZyAmwPsqvqV',
  fingerprintPro: {
    publicKey: undefined,
    endpoint: undefined,
    scriptUrlPattern: undefined,
    enabled: false,
  },
  rollbar: {
    enabled: false,
    code_version: globalVariable('VERSION', 'not-available'),
  },
  envSpecificFeatureOverride: {
    c4bPasswordless: false,
    algolia: false,
  },
};

const configurations = {
  localdev,
  test,
  development,
  production,
};

export function getConfig(environment = NODE_ENV) {
  return Object.assign(
    {
      environment,
      company: 'Cameo',
      host: HOST,
      port: PORT,
      apiHost: APIHOST,
      apiPort: APIPORT,
      apiBaseUrl: `https://${APIHOST}:${APIPORT}/api`,
      cloudfrontBaseUrl: 'https://cdn-staging.cameo.com',
      smartling: {
        useContext: USE_SMARTLING_CONTEXT,
        helmetContext: {
          script: [
            {
              type: 'text/javascript',
              src: '//d2c7xlmseob604.cloudfront.net/tracker.min.js',
            },
            {
              type: 'text/javascript',
              innerHTML: `SmartlingContextTracker.init({
                orgId: 'xNuToZF+YNyuU9TMKtZhvQ'
              });`,
            },
          ],
        },
      },

      apple: {
        appId: '1258311581',
      },

      herokuTimeout: 29999,

      instagramClientId: '45f9fdf3922446adbd562d2093868427',
      instagramExpoRedirectUri: 'https://auth.expo.io/@devonspinnler/cameo4',

      // this is used for Facebook Login
      facebookDeveloper: {
        appId: '631781827408443',
      },

      maxFulfillmentPeriod: 1000 * 60 * 60 * 96,

      imageServerUrl: 'https://cdn.cameo.com/resizer',
      imageResizerRoot:
        'https://www.cameo.com/cdn-cgi/image/fit=cover,format=auto,',
      // https://www.cameo.com/cdn-cgi/image/fit=cover,width=500,height=500/https://cdn.cameo.com/resizer/n2vxgwko2ur91.jpg
      // default behavior is to fit and crop if aspect ratio is abnormal, user will need to provide height=N,width=N arguments on usage
      window: {
        // These variables will be defined on window
        // in the browser
        // prior to the execution of the client javascript bundle
        NODE_ENV,
        HOST,
        PORT,
        APIPORT,
        AFFIRM_PUBLICKEY,
        USE_SMARTLING_CONTEXT,
      },
      s3Bases: [
        'https://starboard-media.s3.amazonaws.com',
        'https://s3-us-west-2.amazonaws.com/starboard-media',
        'https://starboard-media.s3-us-west-2.amazonaws.com',
      ],
    },
    configurations[environment]
  );
}

export const config = getConfig();
