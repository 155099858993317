import React, { useCallback } from 'react';
import loadable from '@loadable/component';
/**
 * Be aware of this dependency coming from outside the domain.
 */
import { retry } from 'utils/loadable/retry';

import { useWindowDimensions } from 'domains/web/hooks/useWindowDimension';
import { DialogProps } from './types';
import { Modal } from './Modal';

export const Sheet = loadable(() => retry(() => import('./Sheet')));

/**
 * Component that will orchestrate the rendering of the modal or sheet
 * depending on the screen size.
 * 
 * @example  
 *  <Dialog.Root
      open={open}
      onOpenChange={setOpen}
      onClose={fn}
      title="Title"
    >
      {content}
    </Dialog.Root>
 */
export const Dialog = ({
  cancelLabel,
  children,
  confirmLabel,
  defaultOpen,
  onCancel,
  onConfirm,
  onOpenChange,
  onClose,
  open,
  title,
  size,
  ...htmlAttributes
}: DialogProps & Omit<React.HTMLAttributes<HTMLDivElement>, 'title'>) => {
  const windowDimensions = useWindowDimensions();
  /**
   * JS approach is needed to prevent issues with side effects on
   * Radix library and the Sheet component.
   *
   * Prevent the modal from opening on mobile as it will affect the functionality
   * (scroll, close on click within) of Sheet component due to a side effect JS on Radix Modal.
   */
  const shouldOpenModal = windowDimensions.width > 768;

  const handleOnOpenChange = useCallback(
    (_open: boolean) => {
      if (!_open) {
        onClose?.();
      }
      onOpenChange?.(_open);
    },
    [onClose, onOpenChange]
  );

  /**
   * Render nothing in SSR contexts to ensure
   * no rehydration errors that result in dangling sheet modal elements
   */
  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <>
      {shouldOpenModal ? (
        <Modal
          defaultOpen={defaultOpen}
          onOpenChange={handleOnOpenChange}
          open={open}
          title={title}
          cancelLabel={cancelLabel}
          confirmLabel={confirmLabel}
          onCancel={onCancel}
          onConfirm={onConfirm}
          size={size}
          {...htmlAttributes}
        >
          {children}
        </Modal>
      ) : (
        <Sheet
          defaultOpen={defaultOpen}
          onOpenChange={handleOnOpenChange}
          open={open}
          title={title}
          cancelLabel={cancelLabel}
          confirmLabel={confirmLabel}
          onCancel={onCancel}
          onConfirm={onConfirm}
          {...htmlAttributes}
        >
          {children}
        </Sheet>
      )}
    </>
  );
};
