import { SerializedLicenseGrant } from 'types/src/api/models/license';
import { actionCreator } from '../utils/actionCreator';

type ResponseError = {
  message: string;
  statusCode: number;
};

export type OrderDetailsResponse = Omit<
  Cameo.Endpoints.Order.GetOrderHistoryDetails.GET.Response,
  'license'
> & { license: SerializedLicenseGrant };

export const GetOrderDetails = actionCreator.async<
  Cameo.Endpoints.Order.GetOrderHistoryDetails.GET.QueryParams,
  OrderDetailsResponse,
  ResponseError
>('cameo/getOrderDetails/C4B_GET_ORDER_DETAILS');

export function getOrderDetails({ orderId }: { orderId: string }) {
  return {
    orderId,
    types: [
      GetOrderDetails.started.type,
      GetOrderDetails.done.type,
      GetOrderDetails.failed.type,
    ],
    promise: (client) => client.get(`/order/history/${orderId}`),
  };
}
