import Cameo from 'types';
import { RehydratedLicenseGrant } from 'types/src/api/models/license';
import { ApplyInitialActivationMutation } from 'domains/c4b/graphql/generated/graphql';

/**
 * Helper method that will hydrate a license with Date values
 * @param license
 */
export function rehydrateLicense({
  license,
}: {
  license:
    | Cameo.Models.License.SerializedLicenseGrant
    | ApplyInitialActivationMutation['ApplyInitialActivation'];
}): RehydratedLicenseGrant {
  return {
    ...license,
    createdAt: new Date(license.createdAt),
    updatedAt: new Date(license.updatedAt),
    activatesAt: license.activatesAt ? new Date(license.activatesAt) : null,
    expiresAt: license.expiresAt ? new Date(license.expiresAt) : null,
    activations: license.activations.map((activation) => ({
      ...activation,
      createdAt: new Date(activation.createdAt),
      updatedAt: new Date(activation.updatedAt),
      activatesAt: activation.activatesAt
        ? new Date(activation.activatesAt)
        : null,
    })),
    history: license.history.map((event) => ({
      ...event,
      createdAt: new Date(event.createdAt),
    })),
    notifications: Object.keys(license.notifications ?? {}).reduce(
      (bag, key) => ({
        ...bag,
        [key]: {
          ...license.notifications[key],
          lastSentAt: license.notifications[key].lastSentAt
            ? new Date(license.notifications[key].lastSentAt)
            : null,
        },
      }),
      {}
    ),
  };
}
