// eslint-disable-next-line import/no-cycle
import { PageKeys } from 'domains/c4b/pages/pages';
import { PathDescriptor } from 'domains/c4b/pages/App/hooks/usePathGenerator/types';
import { useSelector } from 'react-redux';
import { getLoggedInUser } from 'state/modules/auth/selectors';
import { AdminUser } from 'types/src/api/models/user';
import { generatePath } from './utils/generatePath';

/**
 * To be consumed outside the C4B domain.
 * Path Generator to create typesafe links to a typesafe Briefcase 💼 page
 */
export function _useInboundBriefcasePath<Page extends PageKeys>({
  includeBusinessId = true,
  ...desc
}: PathDescriptor<Page> & { includeBusinessId?: boolean }): string {
  // Should not depend on SessionProvider.
  const loggedInUser: AdminUser = useSelector(getLoggedInUser);
  const activeBusiness = loggedInUser?.briefcasePreferences
    ?.lastActiveBusinessId
    ? {
        _id: loggedInUser.briefcasePreferences.lastActiveBusinessId.toString(),
      }
    : null;

  return generatePath({
    desc: {
      page: desc.page,
      params: desc.params,
      query: desc.query,
      hash: desc.hash,
    },
    activeBusiness: includeBusinessId ? activeBusiness : undefined,
  });
}
