import actionCreatorFactory from 'typescript-fsa';
import { ActionError } from '../auth/types';

const actionCreator = actionCreatorFactory('cameo/contentFeed');

export function getContentFeed(postId: string) {
  return {
    types: [
      GetContentFeed.started.type,
      GetContentFeed.done.type,
      GetContentFeed.failed.type,
    ],
    promise: (client) => {
      return client.get(`/feed/myFeed/${postId}`);
    },
  };
}

export const GetContentFeed = actionCreator.async<
  void,
  Cameo.Endpoints.Feed.GetFeedItem.GET.Response,
  ActionError
>('GET_CONTENT_FEED');

export const clearContentFeed = actionCreator<void>('CLEAR_CONTENT_FEED');
