import * as React from 'react';
import type { CustomIconProps } from './types';

function CharityFlagIcon({ height, width }: CustomIconProps) {
  return (
    <svg height={height} width={width} viewBox="0 0 12 12" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7.5S2.5 7 4 7s2.5 1 4 1 2-.5 2-.5v-6S9.5 2 8 2 5.5 1 4 1s-2 .5-2 .5v6z"
        fill="#fff"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M2 11V7.5z" fill="#fff" />
      <path
        d="M2 11V7.5"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.768 2.978a1.1 1.1 0 00-1.556 0L6 3.19l-.212-.212a1.1 1.1 0 10-1.556 1.556l.212.212L6 6.302l1.556-1.556.212-.212a1.1 1.1 0 000-1.556z"
        fill="#FF0761"
        stroke="#FF0761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CharityFlagIcon;
