import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actionCreator, INITIAL_STATE } from './config';
import { BlockUserState } from './types';

export const ResetBlockedFromTalentIds = actionCreator(
  'LOAD_BLOCKED_BY_TALENT_IDS'
);

export const reducer = reducerWithInitialState<BlockUserState>(
  INITIAL_STATE
).case(ResetBlockedFromTalentIds, (state) => ({
  ...state,
  ...INITIAL_STATE,
}));
