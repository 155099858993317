import styled from 'styled-components';
import { animations, color, fonts, media, sizing } from '../../style';

export const SEARCH_HEIGHT = 40;
const SEARCH_WIDTH = 40;

export const Input = styled.input`
  ${fonts.regularText['300']};
  background-color: transparent;
  border: 0;
  color: ${color.solids.WHITE};
  line-height: ${sizing(34)};
  outline: none;
  margin: ${sizing(2)} ${sizing(8)};
  padding: ${sizing(1)};
  width: calc(100% - ${sizing(SEARCH_WIDTH)});
  -webkit-user-select: text;

  &::placeholder {
    ${fonts.regularText['300']};
    line-height: ${sizing(34)};
    color: ${color.solids.MUTED};
  }
`;

export const SearchButton = styled.button`
  align-items: center;
  border-radius: ${sizing(SEARCH_WIDTH)};
  border: 0;
  background-color: transparent;
  cursor: pointer;
  display: inline-flex;
  height: ${sizing(SEARCH_HEIGHT)};
  justify-content: center;
  transition: all ${animations.transition.NORMAL}ms ease;
  width: ${sizing(SEARCH_WIDTH)};
  padding: 0;
`;

export const ClearButton = styled.button<{ isVisible: boolean }>`
  align-items: center;
  background-color: transparent;
  border-radius: ${sizing(SEARCH_WIDTH)};
  border: 0;
  cursor: pointer;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  height: ${sizing(SEARCH_HEIGHT)};
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  transition: all ${animations.transition.NORMAL}ms ease;
  width: ${sizing(SEARCH_WIDTH)};
  padding: 0;
`;

export const Wrapper = styled.div<{
  isOpen: boolean;
  $hasDarkBackground: boolean;
  $isTransparent: boolean;
}>`
  transition: width ${animations.transition.NORMAL}ms,
    padding ${animations.transition.NORMAL}ms,
    margin ${animations.transition.NORMAL}ms,
    background-color ${animations.transition.NORMAL}ms;
  position: relative;
  background-color: ${({ $isTransparent, $hasDarkBackground }) => {
    // This background-color is dynamically overriden by the new NavBar on gradient enabled pages
    // based on scroll position via CSS child selector in NavBar/Styled.tsx.

    if ($isTransparent) {
      return color.background.LEVEL2TRANSPARENT40;
    }

    // Using the !important tag to override the precedence of the CSS child selector when the SearchBar is focused.
    return `${
      $hasDarkBackground ? color.background.LEVEL1 : color.solids.WHITE
    } !important`;
  }};
  border-radius: ${sizing(80)};
  display: flex;
  padding: 0 ${sizing(8)};
  width: 100%;

  form,
  ${Input} {
    width: calc(100% - ${sizing(SEARCH_WIDTH)});
  }

  ${Input} {
    margin-left: ${sizing(SEARCH_WIDTH)};
    color: ${({ $hasDarkBackground }) => {
      if ($hasDarkBackground) {
        return color.solids.WHITE;
      }

      return color.solids.BLACK;
    }};
  }

  ${SearchButton} {
    position: absolute;
    left: 0;
    top: 0;
  }

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    padding: 0 ${sizing(8)};
    width: 100%;

    ${Input} {
      margin: ${sizing(2)} ${sizing(8)};
      margin-left: ${sizing(SEARCH_WIDTH)};
      padding: ${sizing(1)};
      width: calc(100% - ${sizing(SEARCH_WIDTH)});
      color: ${({ $hasDarkBackground }) => {
        if ($hasDarkBackground) {
          return color.solids.WHITE;
        }

        return color.solids.BLACK;
      }};
    }

    ${SearchButton} {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`;
