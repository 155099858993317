import { AdminUser } from 'types/src/api/models/user';
import { Credentials, LoginMethod } from 'types/src/utils/enums';

export const SupportedLoginMethods = [
  LoginMethod.APPLE,
  LoginMethod.DATABASE,
  LoginMethod.FACEBOOK,
  LoginMethod.MAGIC_LINK_OPEN,
] as const;

export type SupportedLoginService = {
  [key in (typeof SupportedLoginMethods)[number]]: (
    credentials: Credentials[(typeof SupportedLoginMethods)[number]]
  ) => Promise<AdminUser>;
};

export type SupportedLoginParams = {
  connection: (typeof SupportedLoginMethods)[number];
  credentials: Credentials[(typeof SupportedLoginMethods)[number]];
};

export type ActionError = {
  message: string;
};

export type AuthState = {
  loaded: boolean;
  loginError: string;
  loggingIn: boolean;
  linkMessengerError: string;
  signupError: string;
  enrollError: string;
  enrolled: boolean;
  signingUp: boolean;
  enrolling: boolean;
  resettingPassword: boolean;
  resetPasswordError: string;
  loggedInUser: AdminUser;
  linkMessengerSuccess: boolean;
  showAuthView: null | 'login' | 'signup' | 'signup-promocode';
  loggingOut: boolean;
  logoutError: string;
  requestingPasswordReset: boolean;
  requestPasswordResetError: string;
  requestPasswordResetInfo: string;
  enrollPartnerError: string;
  enrolledPartner: boolean;
  enrollingPartner: boolean;
  authFlowVisible: boolean;
};

export const INITIAL_STATE: AuthState = Object.freeze({
  loaded: false,
  loginError: null,
  loggingIn: false,
  linkMessengerError: null,
  signupError: null,
  enrollError: null,
  enrolled: false,
  signingUp: false,
  enrolling: false,
  resettingPassword: false,
  resetPasswordError: null,
  loggedInUser: null,
  linkMessengerSuccess: false,
  showAuthView: null,
  loggingOut: false,
  logoutError: null,
  requestingPasswordReset: false,
  requestPasswordResetError: null,
  requestPasswordResetInfo: null,
  loginWithDeeplinkError: null,
  enrollPartnerError: null,
  enrolledPartner: false,
  enrollingPartner: false,
  authFlowVisible: false,
});
