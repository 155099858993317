export const DEFAULT_PLACEHOLDER_BIRTHDATE = 'MM/DD/YYYY';
export const DEFAULT_PLACEHOLDER_EMAIL = 'pepe@example.com';
export const DEFAULT_PLACEHOLDER_NAME = 'Mathew Doan';

export const LOCAL_STORAGE_KEYS = {
  preferredMicDeviceId: 'CAMEO_LIVE_PREFERRED_MIC_DEVICE_ID',
  preferredCameraDeviceId: 'CAMEO_LIVE_PREFERRED_CAMERA_DEVICE_ID',
};

export const KIDS_CHARACTER_PROFILE_PATHS = [
  '/graciescorner',
  '/redtitan',
  '/lovediana',
  '/ninjaryan',
  '/santaclaus',
  '/grumpybear',
  '/codyfromcocomelon',
  '/chestercheetah',
  '/cheerbear',
  '/divalolsurprise',
  '/blippi',
  '/ninafromcocomelon',
  '/combopanda',
  '/babyshark',
  '/toothfairy',
  '/emmatoysandcolors',
  '/theeasterbunny',
  '/franciscothefox',
  '/cookiemonster',
  '/cecefromcocomelon',
  '/jjfromcocomelon',
  '/queenbeelolsurprise',
  '/elmo',
  '/trueandtherainbowkingdom',
  '/redtitan-es',
];
