import { AdminUser } from 'types/src/api/models/user';
import { Credentials, LoginMethod } from 'types/src/utils/enums';
import { AuthState } from '../types';

export type LoginParams = Credentials[LoginMethod.DATABASE];

export type LoginResponse = AdminUser;

export type LoginState = Partial<AuthState>;

export const INITIAL_STATE: LoginState = Object.freeze({
  loggedInUser: {} as AdminUser,
  loginError: null,
  loggingIn: false,
});
