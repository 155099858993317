import { dpCookieManager } from 'analytics/utils/dpCookieManager';

class DataPlatformLanguageManager {
  private languageCode = null;

  private initialized = false;

  /**
   * This class is designed to help coordinate the language code
   * from the cookie with events by default
   * and then listens for the 'languageChanged' event on i18n to modify the stored value
   * when not re-initializing
   */
  private initialize() {
    this.initialized = true;
    this.languageCode = dpCookieManager.getLanguageCookie() ?? 'en';
  }

  public getLanguageCode() {
    if (!this.initialized) {
      this.initialize();
    }
    return this.languageCode;
  }

  public setLanguageCode(languageCode) {
    this.initialized = true;
    this.languageCode = languageCode;
  }

  public reset() {
    this.languageCode = null;
    this.initialized = false;
  }
}

export const dpLanguageManager = new DataPlatformLanguageManager();
