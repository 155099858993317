import { Action } from 'typescript-fsa';
import * as loadUser from './loadUser';
import * as saveUser from './saveUser';
import { signup } from '../auth/signup';
import { types as authLoadTypes } from '../auth/load';
import { resetPassword } from '../auth/resetPassword';
import * as selectors from './selectors';
import {
  reducer as getUserUnreadChannelCountReducer,
  getUserUnreadChannelCount,
} from './getUserUnreadChannelCount';
import {
  reducer as getUserGeoDataReducer,
  getUserGeoData,
} from './getUserGeoData';
import {
  reducer as loadRelatedUsersReducer,
  actionCreator as loadRelatedUsers,
} from './loadRelatedUsers';

const ORDER: Record<string, string> = {};
ORDER.LOAD_ORDER_SUCCESS = 'orders/LOAD_ORDER_SUCCESS';
ORDER.LOAD_SUCCESS = 'orders/LOAD_SUCCESS';

ORDER.LOAD_FEATURED = 'orders/LOAD_FEATURED';
ORDER.LOAD_FEATURED_SUCCESS = 'orders/LOAD_FEATURED_SUCCESS';
ORDER.LOAD_FEATURED_FAIL = 'orders/LOAD_FEATURED_FAIL';

ORDER.DETAIL_SUCCESS = 'orders/DETAIL_SUCCESS';

export const initialState = {
  loaded: false,
  loading: false,
  normalListLoaded: false,
  creating: false,
  users: {},
  userIds: {},
  editing: false,
  deleting: false,
  savingUser: false,
  saveError: {},
  error: null,
  refreshingStripe: false,

  stripeError: null,
  selectedUserId: null,
  relatedUsers: [],

  usersCharity: {},
  newUserCategories: [],

  talentVideoVariants: [],
};

const reducerObj = {
  ...getUserUnreadChannelCountReducer,
  ...loadRelatedUsersReducer,
  ...getUserGeoDataReducer,
};

export function reducer(
  state = initialState,
  action: Action<any> & { result: any; code: any; userId: any } = {
    type: '',
    payload: {},
    result: {},
    code: null,
    userId: null,
  }
) {
  /*
   * this is the new model for redux. eventually we want to move everything
   * into this reducer object
   */
  if (reducerObj[action.type]) {
    return reducerObj[action.type](state, action);
  }

  // legacy switch
  switch (action.type) {
    case loadUser.types.start:
      return loadUser.start(state, action);
    case loadUser.types.success:
      return loadUser.success(state, action);
    case loadUser.types.fail:
      return loadUser.fail(state, action);

    case saveUser.types.start:
      return saveUser.start(state, action);
    case saveUser.types.success:
      return saveUser.success(state, action);
    case saveUser.types.fail:
      return saveUser.fail(state, action);

    case authLoadTypes.success:
    case signup.async.done.type:
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        users: {
          ...state.users,
          [action.result._id]: action.result,
        },
        userIds: {
          ...state.userIds,
          [action.result.username]: action.result._id,
        },
      };
    case resetPassword.async.done.type:
      return state;
    /*
     * LOAD FEATURED ORDERS
     */
    case ORDER.LOAD_FEATURED_SUCCESS: {
      const usersObj2 = { ...state.users };
      const userIdsObj2 = { ...state.userIds };
      for (const user of action.result.users) {
        usersObj2[user._id] = user;
        userIdsObj2[user.username] = user._id;
      }
      return {
        ...state,
        users: usersObj2,
        userIds: userIdsObj2,
      };
    }

    case ORDER.LOAD_ORDER_SUCCESS:
      if (!action.result.owner) {
        return state;
      }
      return {
        ...state,
        selectedUserId: action.result.owner._id,
        users: {
          ...state.users,
          [action.result.owner._id]: action.result.owner,
        },
        userIds: {
          ...state.userIds,
          [action.result.owner.username]: action.result.owner._id,
        },
      };
    case ORDER.LOAD_SUCCESS: {
      if (!action.result.users) {
        return state;
      }
      const usersObjectOrderLoad = { ...state.users };
      const userIdsObjectOrderLoad = { ...state.userIds };
      for (const user of action.result.users) {
        if (user) {
          usersObjectOrderLoad[user._id] = user;
          userIdsObjectOrderLoad[user.username] = user._id;
        }
      }
      return {
        ...state,
        users: usersObjectOrderLoad,
        userIds: userIdsObjectOrderLoad,
      };
    }
    case ORDER.DETAIL_SUCCESS: {
      const returnedUser = action.result.user || {};
      const currentUser = state.users[returnedUser._id] || {};
      const savedUser = !returnedUser._id
        ? {}
        : {
            [returnedUser._id]: { ...currentUser, ...returnedUser },
          };
      return {
        ...state,
        users: {
          ...state.users,
          ...savedUser,
        },
      };
    }

    default:
      return state;
  }
}

const loadUserService = loadUser.service;
const saveUserService = saveUser.service;

const actions = {
  getUserUnreadChannelCount,
  getUserGeoData,
  loadRelatedUsers,
  loadUser: loadUserService,
  saveUser: saveUserService,
};

export { actions, selectors };
