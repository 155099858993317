import React, { useState, useEffect } from 'react';
import { intervalToDuration, parseISO } from 'date-fns';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getHasFinishedFirstRender } from 'state/modules/asyncConnectSelectors';
import { Text } from 'domains/web/components';
import { color, media } from '../../style';

const Clock = styled.span`
  display: none;
  color: ${color.solids.WHITE};
  font-weight: normal;

  @media only screen and (min-width: ${media.queries.TABLET}) {
    display: inline;
  }

  &:focus {
    outline: none;
  }
`;

// copied from: utils/userPromotion
const getTimeLeft = (expiresAt: string) => {
  const today = new Date();
  const endDate = parseISO(expiresAt);

  if (endDate < today) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  const duration = intervalToDuration({
    start: today,
    end: endDate,
  });

  return {
    days: duration.days,
    hours: duration.hours,
    minutes: duration.minutes,
    seconds: duration.seconds,
  };
};

interface Props {
  expiresAt: string;
}

/**
 *
 * @description NOTE: this component will not render on the server
 * to avoid likely SSR <> client mismatch issues.
 */
export const CountdownClock = ({ expiresAt }: Props) => {
  const hasFinishedFirstRender = useSelector(getHasFinishedFirstRender);
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(expiresAt));

  // componentWillMount
  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeLeft(getTimeLeft(expiresAt));
    }, 1000);

    // componentWillUnmount
    return () => {
      clearInterval(timerId);
    };
  }, [expiresAt]);

  /**
   *
   * rendering non-fixed time with SSR is difficult. We should
   * avoid rendering it on the server to avoid breaking SSR.
   */
  if (!hasFinishedFirstRender) {
    return null;
  }

  // copied from components/userPromotionBanner
  return (
    <Clock data-testid="countdown">
      <Text as="span" variant="base_m" fontWeight="bold">
        {!!timeLeft.days && `${timeLeft.days}d `}
        {`${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s left`}
      </Text>
    </Clock>
  );
};
