import React, { createContext, useContext, useMemo, useRef } from 'react';
import { generateQueryId } from 'components/GlobalSiteSearch/utils';
import {
  BrowsePageUrlGenerator,
  ProfilePageUrlGenerator,
  SearchPageUrlGenerator,
} from 'design-system/Components/SearchResults/types';
import { useSearchUrlGenerators } from 'utils/c4b/consumerOverrides/SearchNullStateOverridesProvider/useSearchUrlGenerators';

type SearchNullStateOverridesContextValue = {
  queryIdRef: React.MutableRefObject<string>;
  generateBrowsePageUrl: BrowsePageUrlGenerator;
  generateProfilePageUrl: ProfilePageUrlGenerator;
  generateSearchPageUrl: SearchPageUrlGenerator;
};

const SearchNullStateOverridesContext =
  createContext<SearchNullStateOverridesContextValue | null>(null);

const ContextFallback = {};

export const useSearchNullStateOverrides =
  (): Partial<SearchNullStateOverridesContextValue> => {
    const context = useContext(SearchNullStateOverridesContext);

    return context ?? ContextFallback;
  };

export const SearchNullStateOverridesProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const queryIdRef = useRef(`${generateQueryId()}`);

  const {
    generateBrowsePageUrl,
    generateProfilePageUrl,
    generateSearchPageUrl,
  } = useSearchUrlGenerators(queryIdRef);

  const context = useMemo(() => {
    const value: SearchNullStateOverridesContextValue = {
      queryIdRef,
      generateBrowsePageUrl,
      generateProfilePageUrl,
      generateSearchPageUrl,
    };

    return value;
  }, [generateBrowsePageUrl, generateProfilePageUrl, generateSearchPageUrl]);

  return (
    <SearchNullStateOverridesContext.Provider value={context}>
      {children}
    </SearchNullStateOverridesContext.Provider>
  );
};
