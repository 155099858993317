import * as React from 'react';
import type { CustomIconProps } from './types';

function FilterIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M9.25 10.75H2.5"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 10.75H11.75"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 12C11.1904 12 11.75 11.4404 11.75 10.75C11.75 10.0596 11.1904 9.5 10.5 9.5C9.80964 9.5 9.25 10.0596 9.25 10.75C9.25 11.4404 9.80964 12 10.5 12Z"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 5.25H2.5"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 5.25H7.75"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 6.5C7.19036 6.5 7.75 5.94036 7.75 5.25C7.75 4.55964 7.19036 4 6.5 4C5.80964 4 5.25 4.55964 5.25 5.25C5.25 5.94036 5.80964 6.5 6.5 6.5Z"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FilterIcon;
