import styled from 'styled-components';
import { animations, media, sizing, color, fonts } from 'design-system/style';
import {
  Wrapper as SearchResults,
  Items as SearchItemsList,
} from 'design-system/Components/SearchResults/Styled';
import { SEARCH_HEIGHT } from 'design-system/Components/SearchBar/Styled';

const RESULTS_MARGIN_TOP = 8;

export const Container = styled.div`
  display: flex;
  width: 100%;

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    position: relative;
    max-width: ${sizing(480)};
  }
`;

export const Wrapper = styled.div<{
  isOpen: boolean;
  isSearchBarFocused: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  position: static;
  width: 100%;

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    position: relative;
  }

  ${SearchResults} {
    height: ${() =>
      `calc(100vh - ${sizing(SEARCH_HEIGHT + RESULTS_MARGIN_TOP)})`};
    overflow-y: auto;
    padding-bottom: ${sizing(SEARCH_HEIGHT + RESULTS_MARGIN_TOP)};
    top: ${sizing(SEARCH_HEIGHT + RESULTS_MARGIN_TOP * 2)};

    @media screen and (min-width: ${media.queries.DESKTOP}) {
      height: auto;
      overflow-y: hidden;
      padding-bottom: 0;
    }
  }

  ${({ isSearchBarFocused }) =>
    isSearchBarFocused &&
    `
    ${SearchItemsList} {
      @media screen and (max-width: ${media.queries.TABLET}) {
        margin-bottom: 300px;
      }
    }
  `}
`;

export const Error = styled.div`
  ${fonts.boldText['200']};
  color: ${color.solids.ERROR};
  text-align: center;
`;

export const Link = styled.button`
  transition: opacity ${animations.transition.NORMAL}ms ease;
  ${fonts.regularText['400']};
  color: ${color.solids.WHITE};
  text-decoration: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin-left: ${sizing(10)};

  &:hover {
    color: ${color.solids.WHITE};
    text-decoration: none;
  }

  &:active {
    color: ${color.grays.GRAY20};
  }

  &:hover,
  &:active {
    opacity: 0.8;
  }
`;
