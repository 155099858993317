import { GET } from 'types/src/api/endpoints/businessVideo/getBusinessVideoById';
import { actionCreator } from '../utils/actionCreator';

type ResponseError = {
  message: string;
  statusCode: number;
};

interface Params {
  businessVideoId: string;
}

export const GetBusinessVideoById = actionCreator.async<
  GET.Params,
  GET.ClientResponse,
  ResponseError
>('cameo/getBusinessVideoById/GET_BUSINESS_VIDEO');

export function getBusinessVideoById({ businessVideoId }: Params) {
  return {
    types: [
      GetBusinessVideoById.started.type,
      GetBusinessVideoById.done.type,
      GetBusinessVideoById.failed.type,
    ],
    promise: (client) => {
      return client.get(`/c4b/business/video/${businessVideoId}`);
    },
  };
}
