import * as React from 'react';
import type { CustomIconProps } from './types';

function GraduationCapIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M11.6675 4.1101L1.38203 9.23673C1.01568 9.41933 1.01197 9.94071 1.37568 10.1285L11.6612 15.4391C11.8038 15.5128 11.9732 15.5135 12.1164 15.4409L22.6035 10.1303C22.9719 9.94378 22.9682 9.4164 22.5973 9.23506L12.1101 4.1084C11.9703 4.04007 11.8067 4.0407 11.6675 4.1101Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2728 13.2085V17.4382C18.2728 17.5814 18.2425 17.7229 18.1662 17.8441C17.7744 18.4662 16.2099 20.4135 11.979 20.4135C7.75761 20.4135 6.22019 18.4748 5.83357 17.8483C5.75704 17.7243 5.7288 17.5801 5.7317 17.4344L5.81121 13.4426"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.7998 12.03L2.7998 17.216"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default GraduationCapIcon;
