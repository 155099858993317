// eslint-disable-next-line import/no-cycle
import { sizing } from '../index';

const convertNumberToREMUnit = (number) => `${number}rem`;

// Matching sizes.
// Designs might not be up to date and might not match these sizes.
// In that scenario, consult with a friendly designer which approximate value from the ones provided should be used.
// If you want to talk in pixels, check the value of `PIXEL_TO_REM_RATIO` before running any calculations.
// **Note:** If you need a custom margin/padding after that conversation. It's fine.

type Margin = keyof typeof margin;
type Padding = keyof typeof margin;

type Spacing = {
  margin: { [K in Margin]: string };
  padding: { [K in Padding]: string };
  measureFromPixels: typeof measureFromPixels;
};

const margin = {
  tiny: convertNumberToREMUnit(1 / 4),
  xxsmall: convertNumberToREMUnit(1 / 2),
  xsmall: convertNumberToREMUnit(3 / 4),
  small: convertNumberToREMUnit(1),
  medium: convertNumberToREMUnit(1.5),
  large: convertNumberToREMUnit(2),
  xlarge: convertNumberToREMUnit(2.5),
  xxlarge: convertNumberToREMUnit(3),
  xxxlarge: convertNumberToREMUnit(4),
};

const padding = {
  tiny: convertNumberToREMUnit(1 / 4),
  xxsmall: convertNumberToREMUnit(1 / 2),
  xsmall: convertNumberToREMUnit(3 / 4),
  small: convertNumberToREMUnit(1),
  medium: convertNumberToREMUnit(1.5),
  large: convertNumberToREMUnit(2),
  xlarge: convertNumberToREMUnit(2.5),
  xxlarge: convertNumberToREMUnit(3),
  xxxlarge: convertNumberToREMUnit(4),
};

const measureFromPixels = (number: number) => sizing(number);

export const spacing: Spacing = {
  margin,
  padding,
  measureFromPixels,
};
