export function getParsedSizeForResolution(
  size: number | string,
  resolution: number
) {
  const parsedSize = Number.parseFloat(String(size));

  if (Number.isNaN(parsedSize)) {
    throw Error(`${parsedSize} is not a number`);
  }

  return Math.round(parsedSize * resolution);
}
