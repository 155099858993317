import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { loginWithApple as loginWithAppleService } from 'services';
import { AppleUser } from 'types/src/api/models/user';
import authActionCreatorFactory from '../authActionCreatorFactory';
import { ActionError } from '../types';
import { ContinueWithAppleParams, ContinueWithAppleResponse } from './types';

const asyncActionCreator =
  authActionCreatorFactory<ContinueWithAppleResponse>();

const loginWithApple = async (params: ContinueWithAppleParams) => {
  return {
    user: await loginWithAppleService(params),
  };
};

const continueWithApple = asyncActionCreator<
  ContinueWithAppleParams,
  ContinueWithAppleResponse,
  ActionError
>('CONTINUE_WITH_APPLE', loginWithApple);

export const service = (authorizationCode: string, appleUser: AppleUser) =>
  thunkToAction(continueWithApple)({ authorizationCode, appleUser });

export default continueWithApple;
