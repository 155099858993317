import Cameo from 'types';
import { SearchUsersResponse } from 'types/src/api/endpoints/search';
import { getSearchUrlBase } from 'utils/algoliaHelpers';
import { client } from '../helpers/ApiClient';

export type SearchQueryParams = Cameo.Endpoints.Search.GET.QueryParams;
export type SearchResponse = Cameo.Endpoints.Search.GET.Response;
export type SuggestionsQueryParams =
  Cameo.Endpoints.Search.Suggestions.GET.QueryParams;
export type SuggestionsResponse =
  Cameo.Endpoints.Search.Suggestions.GET.Response;

export function search(params: SearchQueryParams): Promise<SearchResponse> {
  const url = '/search';

  return client.get(url, { params });
}

export function searchFeatured(): Promise<SearchUsersResponse> {
  const url = '/search/users';

  return client.get(url, {
    params: {
      categorySlug: 'featured',
      sortField: 'Recommended',
    },
  });
}

export function searchUsers(
  params: SearchQueryParams,
  isAlgoliaEnabled?: boolean,
  isC4BAlgoliaEnabled?: boolean
): Promise<SearchUsersResponse> {
  const url = `${getSearchUrlBase(
    isAlgoliaEnabled,
    isC4BAlgoliaEnabled
  )}/users`;

  return client.get(url, {
    params,
  });
}

export function suggest(
  params: SuggestionsQueryParams,
  isAlgoliaEnabled?: boolean
): Promise<SuggestionsResponse> {
  const url = `${getSearchUrlBase(isAlgoliaEnabled, false)}/suggestions`;

  return client.get(url, { params });
}

export function suggestForBusiness(
  params: SuggestionsQueryParams,
  isC4BAlgoliaEnabled?: boolean
): Promise<SuggestionsResponse> {
  const url = `${getSearchUrlBase(false, true)}/suggestions`;

  return client.get(url, { params });
}
