import * as React from 'react';
import type { CustomIconProps } from './types';

export function PauseIconV2({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 64 64" fill="none">
      <rect
        x="1"
        y="1"
        width="62"
        height="62"
        rx="31"
        fill="#141114"
        fillOpacity="0.3"
        stroke="white"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1067 21.3333H29.5111V42.6667H24.1067V21.3333Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.9155 21.3333H40.32V42.6667H34.9155V21.3333Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
