import * as get from './get';
import * as update from './update';
import * as selectors from './selectors';

const initialState = {
  promotions: {},
  loadingPromotions: false,
};

export function reducer(state = initialState, action = {} as any) {
  switch (action.type) {
    case get.types.success:
      return get.success(state, action);
    case update.types.success:
      return update.success(state, action);
    default:
      return state;
  }
}

const getPromotion = get.service;
const updatePromotion = update.service;

export { getPromotion, updatePromotion, selectors };
