import { LoginMethod } from 'types/src/utils/enums';
import { LoginWithService } from 'state/modules/auth/loginWith/types';
import {
  loginWithApple,
  loginWithDeeplink,
  loginWithFacebook,
  loginWithPassword,
} from './utils';

/**
 * Authenticates the provided credentials for the given login method
 * and saves the associated database user to the request session
 *
 * @example
 *  import { AuthService } from 'services';
 *  import { LoginMethod } from 'types/src/utils/enums';
 *  AuthService.loginWith[LoginMethod.DATABASE]({ email, password });
 */

const loginWith: LoginWithService = {
  [LoginMethod.APPLE]: loginWithApple,
  [LoginMethod.DATABASE]: loginWithPassword,
  [LoginMethod.FACEBOOK]: loginWithFacebook,
  [LoginMethod.MAGIC_LINK_OPEN]: loginWithDeeplink,
};

export default loginWith;
