import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { setEppoInitialized } from './actions';

interface InitialState {
  isInitialized: boolean;
}

const INITIAL_STATE: InitialState = {
  isInitialized: false,
};

const eppoStateReducer = reducerWithInitialState(INITIAL_STATE)
  .case(setEppoInitialized, (state, action) => {
    return {
      ...state,
      isInitialized: action,
    };
  })
  .build();

export default eppoStateReducer;
