import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { rehydrateLicense } from 'state/modules/businessVideos/utils/rehydrateLicense';
import { GetBusinessVideoById } from './actions';

export type VideoDetailsState = {
  loading: boolean;
  error: string;
  businessVideo: Cameo.Models.BusinessVideo.SerializedPublicBusinessVideo;
  license: Cameo.Models.License.RehydratedLicenseGrant | null;
  talent: Cameo.Models.User.SerializedPublicUser;
};

const BUSINESS_VIDEO_INITIAL_STATE = Object.freeze<VideoDetailsState>({
  loading: false,
  error: null,
  license: null,
  businessVideo: null,
  talent: null,
});

const businessVideoDetailsReducer = reducerWithInitialState(
  BUSINESS_VIDEO_INITIAL_STATE
);

businessVideoDetailsReducer
  .case(GetBusinessVideoById.started, (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      license: null,
      businessVideo: null,
      talent: null,
    };
  })
  .case(GetBusinessVideoById.done, (state, action) => {
    return {
      ...state,
      loading: false,
      businessVideo: action.result.businessVideo,
      license: action.result.license
        ? rehydrateLicense({ license: action.result.license })
        : null,
      talent: action.result.talent,
    };
  })
  .case(GetBusinessVideoById.failed, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error.message,
    };
  });

export { businessVideoDetailsReducer };
