import { AdminUser } from 'types/src/api/models/user';
import { client as ApiClient } from '../../../helpers/ApiClient';
import handleError from './handleError';
import { LoginParams, LoginHeaders } from './types';

export default ({
  connection,
  credentials,
  headers,
}: LoginParams & { headers?: LoginHeaders }): Promise<AdminUser> => {
  const body: { data: LoginParams; headers?: LoginHeaders } = {
    data: { connection, credentials },
    headers,
  };

  return ApiClient.post('/auth/login', body).catch(
    handleError(['login', connection].join('.'))
  );
};
