/**
 * This file contains utilities for working with the deprecated Supernova library
 * It is intended to be used as a temporary bridge to the new domains/web library
 */
import { theme as cameoTheme } from '@cameo/theme';

/**
 * Supernova Colors + cameoTheme colors
 */
export const colors = (() => {
  const {
    foreground,
    background,
    border,
    accent,
    danger,
    success,
    ...nonSemanticColorTokens
  } = {
    ...cameoTheme.colors,
  };

  const cameoColorTokens = {
    ...nonSemanticColorTokens,
    foregroundDefault: foreground.default,
    foregroundMuted: foreground.muted,
    foregroundSubtle: foreground.subtle,
    backgroundLevel1: background.level1,
    backgroundLevel2: background.level2,
    backgroundLevel3: background.level3,
    borderDefault: border.default,
    borderSubtle: border.subtle,
    borderEmphasis: border.emphasis,
    accentDefault: accent.default,
    accentEmphasis: accent.emphasis,
    dangerDefault: danger.default,
    dangerEmphasis: danger.emphasis,
    successDefault: success.default,
    successEmphasis: success.emphasis,
  };

  return {
    // Supernova brand colors.
    pink: '#FF037C',
    pinkLight: '#FF58A6',
    pinkDark: '#A3214D',
    pinkTurnt: '#FF037C',
    purple: '#A434E5',
    purpleLight: '#DA67F7',
    purpleDark: '#7221B3',
    purpleTurnt: '#BD00FF',
    cyan: '#21CFE6',
    cyanLight: '#83F0FF',
    cyanDark: '#0AA9BE',
    cyanTurnt: '#00E1FF',
    goldCoin: '#F2A71F',
    goldCoinLight: '#FBDA73',
    goldCoinDark: '#CB8300',
    goldCoinTurnt: '#FFA500',
    yellowStar: '#EBE446',
    yellowStarLight: '#F5F384',
    yellowStarDark: '#C2BE3D',
    yellowStarTurnt: '#FFF400',
    keylime: '#5FDE6D',
    keylimeLight: '#90F0A2',
    keylimeDark: '#54B858',
    keylimeTurnt: '#61FF71',
    tomato: '#D94737',
    tomatoLight: '#ED8A76',
    tomatoDark: '#A12015',
    tomatoTurnt: '#FF3C26',
    cobalt: '#4719FF',
    cobaltLight: '#9873FF',
    cobaltDark: '#2132A6',
    cobaltTurnt: '#4718FF',

    // Supernova backgrounds.
    backgroundLevel0: '#141114',

    // Supernova text colors.
    muted: '#BEBBBF',
    extraMuted: '#413D40',

    // Supernova border colors.
    border: '#413D40',
    emphasis: '#BEBBBF',

    // Supernova Transparent Colors
    transparent: 'transparent',
    transparentWhite: '#FFFFFF10',
    transparentBlack: '#1411144D',

    // Dark color variants with 20% opacity for use with white text
    transparentGoldCoin: '#CB830033',
    transparentKeyLime: '#54B85833',
    transparentPurple: '#7221B333',

    white: '#FFFFFF',
    black: '#101010',

    textInputBackground: '#231F23',
    textInputBorder: 'rgba(65, 61, 64, 0.5)',

    // Supernova V2 colors -----------------------------------------

    // Body text colors
    textDefault: '#F2F1F3',
    textSubdued: '#BFBAC4',
    textMuted: '#817A8A',
    textLink: '#BE8DFC',
    textNegativeLink: '#FA826A',

    // Layout colors
    bgLevel1: '#121114',
    bgLevel2: '#201E23',
    bgLevel3: '#302D34',
    layoutBorder: '#3F3B45',

    // Action colors
    actionPrimary: '#8036E0',
    actionNeutral: '#4D4953',
    actionErrorState: '#CB2917',

    // Primary color palette
    purple1: '#BE8DFC',
    purple2: '#8036E0',
    purple3: '#331C51',

    neutral1: '#F2F1F3',
    neutral1Transparent: '#F2F1F31A',
    neutral2: '#D9D6DC',
    neutral3: '#BFBAC4',
    neutral4: '#A69FAD',
    neutral5: '#817A8A',
    neutral6: '#746E7D',
    neutral7: '#68616F',
    neutral8: '#4D4953',
    neutral9: '#3F3B45',
    neutral10: '#302D34',
    neutral11: '#201E23',
    neutral12: '#121114',

    // System colors
    red1: '#FA826A',
    red2: '#CB2917',
    red3: '#402427',

    yellow1: '#F5F384',
    yellow2: '#EBE446',
    yellow3: '#6A601C',

    green1: '#90F0A2',
    green2: '#5FDE6D',
    green3: '#114E14',

    // Extended colors
    cyan1: '#83F0FF',
    cyan2: '#21CFE6',
    cyan3: '#174A53',

    pink1: '#FF58A6',
    pink2: '#E5197B',
    pink3: '#511333',

    cobalt1: '#778DFF',
    cobalt2: '#4719FF',
    cobalt3: '#232855',

    politicalRed: '#EB3A42',
    politicalBlue: '#22418E',

    modalOverlay: 'rgba(0, 0, 0, 0.5)',

    ...cameoColorTokens,
  };
})();
