import { rgba } from 'polished';
import styled, { keyframes } from 'styled-components';

const color = '#FFF';

const flashing = keyframes`
    0% {
        background-color: ${color};
    }
    50%,
    100% {
        background-color: ${rgba(color, 0.2)};
    }
`;

export const Loading = styled.div`
  position: relative;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 5px;
  background-color: ${color};
  color: ${color};
  animation: ${flashing} 0.6s infinite linear alternate;
  animation-delay: 0.3s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -1rem;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 5px;
    background-color: ${color};
    color: ${color};
    animation: ${flashing} 0.6s infinite linear alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 1rem;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 5px;
    background-color: ${color};
    color: ${color};
    animation: ${flashing} 0.6s infinite linear alternate;
    animation-delay: 0.6s;
  }
`;
