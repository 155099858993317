/* eslint-disable dot-notation */
import { getQueryParamsFromString } from 'utils/queryParams';

export const utmParamsParser = (
  queryString: string
): {
  utmCampaign?: string;
  utmSource?: string;
  utmMedium?: string;
  utmTerm?: string;
  utmContent?: string;
} => {
  try {
    const queryStringParams = getQueryParamsFromString(queryString);
    const formattedQueryStrings = {};
    Object.entries(queryStringParams).forEach(([key, value]) => {
      formattedQueryStrings[key?.toLowerCase()] = value;
    });

    const getArrayOrStringParamValue = (key: string) => {
      return typeof formattedQueryStrings[key] === 'object'
        ? formattedQueryStrings[key]?.[0]
        : formattedQueryStrings[key];
    };

    return {
      utmCampaign: getArrayOrStringParamValue('utm_campaign'),
      utmSource: getArrayOrStringParamValue('utm_source'),
      utmMedium: getArrayOrStringParamValue('utm_medium'),
      utmTerm: getArrayOrStringParamValue('utm_term'),
      utmContent: getArrayOrStringParamValue('utm_content'),
    };
  } catch (error) {
    // Extra safeguard to ensure no unexpected errors come from this function
    return {};
  }
};
