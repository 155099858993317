export const getBreakpoint = (state) => state.client.breakpoint;
export const getScreenHeight = (state) => state.client.screenHeight;
export const getScreenSizeKnown = (state) => state.client.screenSizeKnown;
export const getScreenWidth = (state) => state?.client?.screenWidth;

// computed
export const getIsBreakpointKnown = (state) =>
  getBreakpoint(state) !== 'unknown';
export const getIsMobile = (state) => getBreakpoint(state) === 'mobile';
export const getIsTablet = (state) => getBreakpoint(state) === 'tablet';
export const getIsDesktop = (state) => getBreakpoint(state) === 'desktop';
export const getIsLargeDesktop = (state) =>
  getBreakpoint(state) === 'large-desktop';
