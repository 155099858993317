/* eslint-disable no-restricted-syntax */
import Cameo from 'types';
import { DeepPartial } from 'redux';
import { TrackFunc } from '../types';

export default function trackNavigation(trackFunc: TrackFunc) {
  return (data: DeepPartial<Cameo.Utils.Analytics.NavigationEventSchema>) => {
    trackFunc({
      eventName: 'Navigation',
      data,
    });
  };
}
