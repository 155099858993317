export enum ActiveExperiments {
  businessLegalRequirement = 'business-legal-requirement',
  characterCountUpsellExperiment = 'character-count-upsell-experiment',
  americanGreetingsUpsell = 'american-greetings-upsell',
  rightSquadExpressDeliveryExperiment = 'rs-3day-delivery-experiment',
  bookingFormV2 = 'booking-form-v2-followup-experiment-nonsocial-2',
  bookingFormV2AdminOverride = 'booking-form-v2-admin-override',
  c4bEnhancedBrowseFilters = 'c4b-enhanced-browse-filters',
  fourDayDeliveryExperiment = 'four-day-delivery-round-2-experiment',
  c4bLimitedSearch = 'c4b-limited-search',
  c4bBrowseToggle = 'c4b-browse-toggle',
}
