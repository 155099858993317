import React from 'react';
import analytics from 'analytics';
import isEmpty from 'lodash/isEmpty';
import {
  ItemGroup,
  SearchData,
  SearchItem,
} from 'design-system/Components/SearchResults/types';
import { DtlKeys } from 'types/src/utils/analytics';
import { SuggestionsResponse } from 'services/search';

type ReverseItemGroupsMap = {
  [k: string]: keyof typeof ItemGroup;
};

type QueryIdRef = React.MutableRefObject<number | string>;

const getReverseItemGroupsMap = (): ReverseItemGroupsMap =>
  Object.keys(ItemGroup).reduce(
    (acc, key) => ({ ...acc, [ItemGroup[key]]: key }),
    {}
  );

const getRenderedItemGroups = (
  data: SearchData,
  reverseItemGroupsMap: ReverseItemGroupsMap
) =>
  data.reduce(
    (itemGroups, { group }) => itemGroups.add(reverseItemGroupsMap[group]),
    new Set<keyof typeof ItemGroup>()
  );

const getFlattenedItems = (
  data: SearchData,
  reverseItemGroupsMap: ReverseItemGroupsMap
) =>
  data.map(({ data: itemData, group, type, _id }) => ({
    name: itemData?.name,
    username: itemData?.username,
    slug: itemData?.slug,
    group,
    groupName: reverseItemGroupsMap[group],
    type,
    _id,
  }));

const getExaminedItemsMap = (data: SearchData, listIndex: number) =>
  data.slice(0, listIndex).reduce<{ [index: string]: string }>(
    (agg, examinedItem, examinedItemIndex) => ({
      ...agg,
      [examinedItemIndex]: `${examinedItem.type}_${examinedItem._id}`,
    }),
    {}
  );

const getNumAvailable = (data: SearchData) =>
  data && data.length
    ? data.filter(
        (searchItem) =>
          // should not count titles
          searchItem.type !== 'title' &&
          // should not count ghost profile
          !isEmpty(searchItem.data)
      ).length
    : 0;

export const trackQueryResult = ({
  query,
  queryId,
  results,
  ltrEnabled = false,
  isBusiness = false,
}: {
  query: string;
  queryId: number | string;
  results: SuggestionsResponse;
  ltrEnabled?: boolean;
  isBusiness?: boolean;
}) => {
  const contents = {
    isBusiness,
  };

  results.results.forEach((res, index) => {
    contents[index] = res._id;
  });

  const queryResultEvent = {
    eventName: 'Engage',
    eventDtl: {
      queryId: queryId.toString(),
      eventType: 'QueryResult',
      queryParam: query.toLowerCase(),
      topResultType: results.results[0]?.type,
      contents,
    },
    objectDtl: {
      objectType: 'Textbox',
      containerType: 'Search',
    },
    positionDtl: {
      algorithmKey: ltrEnabled ? 'allan-ltr' : '',
    },
    targetDtl: {
      targetType: 'Profile',
      targetKey: 'User',
    },
  } as const;
  analytics.trackAction(queryResultEvent);
};

export const trackSearchBarClick = ({
  data,
  queryIdRef,
  algorithm = '',
  query,
  isBusiness = false,
}: {
  data: SearchData;
  queryIdRef: QueryIdRef;
  algorithm?: string;
  query: string;
  isBusiness?: boolean;
}) => {
  const reverseItemGroupsMap = getReverseItemGroupsMap();
  const renderedItemGroups = getRenderedItemGroups(data, reverseItemGroupsMap);
  const flattenedItems = getFlattenedItems(data, reverseItemGroupsMap);

  const focusEventData = {
    eventName: 'Engage',
    eventDtl: {
      queryId: queryIdRef.current.toString(),
      eventType: 'Focus',
      contents: {
        groups: [...renderedItemGroups],
        items: flattenedItems,
        isBusiness,
      },
    },
    objectDtl: {
      objectType: 'Textbox',
      containerType: 'Search',
    },
    positionDtl: {
      algorithmKey: algorithm,
    },
  } as const;

  analytics.trackAction(focusEventData);

  const systemData = {
    eventName: 'SearchRender',
    eventClass: 'ComponentLoad',
    objectDtl: {
      objectType: 'Search',
      containerType: 'Search',
      objectName: query ? '' : 'SearchNullState',
    },
  } as const;

  analytics.trackSystem(systemData);
};

export const createItemSelectionTracker = ({
  data,
  query,
  queryIdRef,
  algorithm = '',
  isBusiness = false,
}: {
  data: SearchData;
  query: string;
  queryIdRef: QueryIdRef;
  algorithm?: string;
  isBusiness?: boolean;
}) =>
  function trackItemSelection(
    item: SearchItem,
    listIndex: number,
    itemType: string
  ) {
    const examinedItemsMap = getExaminedItemsMap(data, listIndex);

    const clickEvent = {
      eventName: 'Navigate',
      eventDtl: {
        queryId: queryIdRef.current.toString(),
        eventType: 'Click',
        queryParam: query.toLowerCase(),
        query_length: query.length,
        topResultType: data[0]?.type,
        contents: {
          isBusiness,
        },
      },
      objectDtl: {
        objectType: 'Textbox',
        objectVersion: item.group,
        containerType: 'Search',
      },
      positionDtl: {
        row: listIndex,
        algorithmKey: algorithm,
      },
      targetDtl: {
        numAvailable: getNumAvailable(data),
        contents: examinedItemsMap,
        talent_price: item?.data?.price,
        targetKey: itemType as DtlKeys,
        targetValue: item._id,
        matchedFields: item.matchedFields,
      },
    } as const;
    analytics.trackAction(clickEvent);

    const sysData = {
      eventName: 'View',
      positionDtl: {
        row: listIndex,
        rowLabelText: 'Search...',
        rowLabelType: 'Master-Navigation',
      },
      objectDtl: {
        objectType: 'Textbox',
        containerType: 'Search',
      },
      sourceDtl: {
        sourceType: 'Search',
        sourceKey: 'User',
        sourceUrl: item?.data?.name,
      },
    } as const;
    analytics.trackSystem(sysData);
  };

export const createQuerySelectionTracker = ({
  data,
  query,
  queryIdRef,
  algorithm = '',
  isBusiness = false,
}: {
  data: SearchData;
  query: string;
  queryIdRef: QueryIdRef;
  algorithm?: string;
  isBusiness?: boolean;
}) =>
  function trackQuerySelection(queryName: string, listIndex: number) {
    const examinedItemsMap = getExaminedItemsMap(data, listIndex);

    const clickEvent = {
      eventName: 'Navigate',
      eventDtl: {
        queryId: queryIdRef.current.toString(),
        eventType: 'Click',
        queryParam: query.toLowerCase(),
        query_length: query.length,
        topResultType: data[0]?.type,
        contents: {
          isBusiness,
        },
      },
      objectDtl: {
        objectType: 'Textbox',
        objectVersion: 'query',
        containerType: 'Search',
      },
      positionDtl: {
        row: listIndex,
        algorithmKey: algorithm,
      },
      targetDtl: {
        numAvailable: getNumAvailable(data),
        contents: examinedItemsMap,
        targetType: 'Search',
        targetValue: queryName,
      },
    } as const;
    analytics.trackAction(clickEvent);

    const sysData = {
      eventName: 'View',
      positionDtl: {
        row: listIndex,
        rowLabelText: 'Search...',
        rowLabelType: 'Master-Navigation',
      },
      objectDtl: {
        objectType: 'Textbox',
        containerType: 'Search',
      },
      sourceDtl: {
        sourceType: 'Search',
        sourceKey: 'User',
        sourceUrl: queryName,
      },
    } as const;
    analytics.trackSystem(sysData);
  };

export const trackQueryChange = ({
  queryIdRef,
  query,
  algorithm = '',
  isEnterPressed,
  isBusiness = false,
}: {
  queryIdRef: QueryIdRef;
  query: string;
  algorithm?: string;
  isEnterPressed: boolean;
  isBusiness?: boolean;
}) => {
  const typeQueryEvent = {
    eventName: 'Engage',
    eventDtl: {
      queryId: queryIdRef.current.toString(),
      eventType: isEnterPressed ? 'EnterQuery' : 'TypeQuery',
      queryParam: (query || '').toLowerCase(),
      contents: {
        isBusiness,
      },
    },
    objectDtl: {
      objectType: 'Textbox',
      containerType: 'Search',
    },
    targetDtl: {
      targetType: 'Profile',
      targetKey: 'User',
    },
    positionDtl: {
      algorithmKey: algorithm,
    },
  } as const;

  analytics.trackAction(typeQueryEvent);
};
