import get from 'lodash/get';

// TODO move PromotionalBanner into cameo types package
export type PromotionalBanner = {
  title?: string;
  name: string;
  color?: string;
  startsAt: string;
  expiresAt: string;
  linkText?: string;
  linkUrl?: string;
  userPromotionSlug?: string;
};

export const getPromotions = (state) => ({ ...state.promotions.promotions });
export const getPromotionalBanner = (state): PromotionalBanner | void =>
  get(state, 'promotions.promotions.promotionalBanner');
