export const types = {
  start: 'auth/ENROLL',
  success: 'auth/ENROLL_SUCCESS',
  fail: 'auth/ENROLL_FAIL',
};

export const start = (state) => ({
  ...state,
  enrolling: true,
  enrollError: null,
});

export const success = (state) => ({
  ...state,
  enrolling: false,
  enrolled: true,
});

export const fail = (state, action) => ({
  ...state,
  enrolling: false,
  enrollError: action.error,
});

export const service = (data) => ({
  types: [types.start, types.success, types.fail],
  promise: (client) =>
    client.post('/userStub/create', {
      data,
    }),
});
