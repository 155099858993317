/* eslint-disable react/jsx-max-props-per-line */
import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { VerifyCodePage } from 'containers/VerifyCodePage/VerifyCodePage';
import { KIDS_CHARACTER_PROFILE_PATHS } from 'utils/constants';
import loadable from '@loadable/component';
import { retry } from 'utils/loadable/retry';
import { selectors as authSelectors } from './state/modules/auth';

// Domain-specific imports
const C4BApp = loadable(() => retry(() => import('domains/c4b/pages/App/App')));
const BookingPage = loadable(() => retry(() => import('domains/booking')));
const BookingEditPage = loadable(() =>
  retry(() => import('domains/booking/BookingEditContainer'))
);
const CollageLanding = loadable(() =>
  retry(() => import('domains/collage/pages/LandingPage'))
);
const CreateCollage = loadable(() =>
  retry(() => import('domains/collage/pages/CreatePage'))
);
const EditCollage = loadable(() =>
  retry(() => import('domains/collage/pages/ContributorPage'))
);
const Collage = loadable(() =>
  retry(() => import('domains/collage/pages/RecipientPage'))
);
const CollageFeed = loadable(() =>
  retry(() => import('domains/collage/pages/RecipientFeed'))
);
const TalentEnrollmentLandingPage = loadable(() =>
  retry(() => import('domains/talent-enrollment/pages/LandingPage'))
);

// Container imports
const AboutPage = loadable(() =>
  retry(() => import('containers/AboutPage/AboutPage'))
);
const Accessibility = loadable(() =>
  retry(() => import('containers/Accessibility'))
);
const ActBlueRedirect = loadable(() =>
  retry(() => import('containers/ActBlueRedirect'))
);
const BrowsePage = loadable(() =>
  retry(() => import('domains/browse/containers/BrowsePage'))
);
const ConfirmationPageV2 = loadable(() =>
  retry(() => import('containers/OrderConfirmationPage/ConfirmationPage'))
);
const DashboardPage = loadable(() =>
  retry(() => import('containers/DashboardPage/DashboardPage'))
);
const Inbox = loadable(() => retry(() => import('containers/Inbox')));
const ErrorPage = loadable(() => retry(() => import('containers/ErrorPage')));
const FollowingPage = loadable(() =>
  retry(() => import('containers/FollowingPage/FollowingPage'))
);
const FollowPostPage = loadable(() =>
  retry(() => import('containers/Follow/PostPage'))
);
const ForgotPasswordPage = loadable(() =>
  retry(() => import('containers/ForgotPasswordPage/ForgotPasswordPage'))
);
const GiftCardPurchase = loadable(() =>
  retry(() => import('containers/GiftCardPurchase'))
);
const GiftCardRedemption = loadable(() =>
  retry(() => import('containers/GiftCardRedemption'))
);
const GiftCardSuccess = loadable(() =>
  retry(() => import('containers/GiftCardSuccess'))
);
const PreviewPage = loadable(() =>
  retry(() => import('containers/LayoutPage/PreviewPage'))
);
const HomepageV2 = loadable(() => retry(() => import('containers/HomepageV2')));
const LandingPageBirthdayGift = loadable(() =>
  retry(() => import('containers/LandingPageBirthdayGift'))
);
const LeaderboardTop100Page = loadable(() =>
  retry(() => import('containers/LeaderboardTop100Page'))
);
const CuratedLeaderboardPage = loadable(() =>
  retry(() => import('containers/CuratedLeaderboardPage'))
);
const WizardLandingPage = loadable(() =>
  retry(() => import('containers/WizardLandingPage'))
);
const LandingPageSurvivor = loadable(() =>
  retry(() => import('containers/LandingPageSurvivor'))
);
const WizardLandingPageMatchGame = loadable(() =>
  retry(() => import('containers/WizardLandingPage/screens/MatchGame'))
);
const LandingPageBirthdayGiftV2 = loadable(() =>
  retry(() => import('containers/LandingPageBirthdayGiftV2'))
);
const LandingPageBirthdayGiftV3 = loadable(() =>
  retry(() => import('containers/LandingPageBirthdayGiftV3'))
);
const LandingPage = loadable(() =>
  retry(() => import('containers/LayoutPage/LandingPage'))
);
const OtpLogin = loadable(() => retry(() => import('containers/Otp/Login')));
const OtpSignUp = loadable(() => retry(() => import('containers/Otp/SignUp')));
const OrdersPage = loadable(() => retry(() => import('containers/OrdersPage')));
const LikesPage = loadable(() =>
  retry(() => import('containers/LikesPage/LikesPage'))
);
const OtpVerify = loadable(() => retry(() => import('containers/OtpVerify')));
const PrivacyPage = loadable(() =>
  retry(() => import('containers/PrivacyPage'))
);
const PhysicalCardLandingPage = loadable(() =>
  retry(() => import('containers/PhysicalCardLandingPage'))
);
const GuidelinesPage = loadable(() =>
  retry(() => import('containers/CommunityGuidelines'))
);
const ProfileFeed = loadable(() =>
  retry(() => import('containers/Feed/ProfileFeed'))
);
const QuizPage = loadable(() => retry(() => import('containers/QuizPage')));
const VideoCollectionFeed = loadable(() =>
  retry(() => import('containers/Feed/VideoCollectionFeed'))
);
const RecipientWrapper = loadable(() =>
  retry(() => import('containers/RecipientWrapper'))
);
const ResetPasswordPage = loadable(() =>
  retry(() => import('containers/ResetPasswordPage/ResetPasswordPage'))
);
const ResultsPage = loadable(() =>
  retry(() => import('containers/ResultsPage/ResultsPage'))
);
const ReviewPageWrapper = loadable(() =>
  retry(() => import('containers/ReviewPage'))
);
const ReviewReaction = loadable(() =>
  retry(() => import('containers/ReviewPage/Reaction'))
);
const ReviewScores = loadable(() =>
  retry(() => import('containers/ReviewPage/Scores'))
);
const SavePhoneNumberPage = loadable(() =>
  retry(() => import('containers/SavePhoneNumberPage'))
);
const SendSms = loadable(() =>
  retry(() => import('containers/SendSms/SendSms'))
);
const StripeOnboarding = loadable(() =>
  retry(() => import('containers/StripeOnboarding/StripeOnboarding'))
);
const TalentProfilePage = loadable(() =>
  retry(() => import('containers/TalentProfilePage'))
);
const TalentProfile = loadable(() =>
  retry(() => import('containers/TalentProfile'))
);
const TeamPage = loadable(() =>
  retry(() => import('containers/TeamPage/TeamPage'))
);
const TermsPage = loadable(() => retry(() => import('containers/Terms/Terms')));
const PartnerRegistrationPage = loadable(() =>
  retry(
    () => import('containers/PartnerRegistrationPage/PartnerRegistrationPage')
  )
);
const NewDirectMessagePage = loadable(() =>
  retry(() => import('containers/NewDirectMessage'))
);
const PurchaseCard = loadable(() =>
  retry(() => import('containers/PurchaseCardPage'))
);
const PurchaseDigitalCard = loadable(() =>
  retry(() => import('containers/PurchaseDigitalCardPage'))
);
const PreviewDigitalCard = loadable(() =>
  retry(() => import('containers/PreviewDigitalCard'))
);

interface RequireAuthProps {
  children?: React.ReactElement;
  redirectTo?: string;
}

function RequireAuth({ children, redirectTo }: RequireAuthProps) {
  const isAuthenticated = Boolean(useSelector(authSelectors.getLoggedInUser));
  // TODO: change to <Navigate to={redirectTo} /> when we upgrade to react-router v6
  return isAuthenticated ? children : <Redirect to={redirectTo} />;
}

type RequireAdminProps = RequireAuthProps;

function RequireAdmin({ children, redirectTo }: RequireAdminProps) {
  const user = useSelector(authSelectors.getLoggedInUser);
  const isAdmin = useMemo(() => user.role === 'admin', [user]);

  // TODO: change to <Navigate to={redirectTo} /> when we upgrade to react-router v6
  return isAdmin ? children : <Redirect to={redirectTo} />;
}

const routes = [
  /**
   * Please keep routes in alphabetical order
   */
  <Route
    exact
    path={['/', '/home']}
    render={() => <HomepageV2 />}
    key="/"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Home"
  />,
  <Route
    exact
    path="/ab_redirect"
    key="/ab_redirect"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Other"
    render={() => (
      <RequireAuth>
        <ActBlueRedirect />
      </RequireAuth>
    )}
  />,
  <Route
    exact
    path="/:pageType(browse|tags)/:slug*"
    key="/:pageType(browse|tags)/:slug*"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Browse"
    component={BrowsePage}
  />,
  <Route
    exact
    path="/:pageType(search)"
    component={BrowsePage}
    key="/:pageType(search)"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Search"
    render={() => <BrowsePage />}
  />,
  <Route
    exact
    path="/preview/:layoutKey"
    key="/preview/:layoutKey"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Other"
    render={() => (
      <RequireAdmin>
        <PreviewPage />
      </RequireAdmin>
    )}
  />,
  <Route
    exact
    path="/l/harry-potter"
    component={WizardLandingPage}
    key="/l/harry-potter"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Landing"
  />,
  <Route
    exact
    path="/survivor"
    component={LandingPageSurvivor}
    key="/survivor"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Landing"
  />,
  <Route
    exact
    path="/harry-potter"
    component={WizardLandingPage}
    key="/harry-potter"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Landing"
  />,
  <Route
    exact
    path="/l/harry-potter/match"
    component={WizardLandingPageMatchGame}
    key="/l/harry-potter/match"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Landing"
  />,
  <Route
    exact
    path="/l/fantasy-football"
    component={CuratedLeaderboardPage}
    key="/l/fantasy-football"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Landing"
  />,
  <Route
    exact
    path="/l/top-fantasy-football"
    component={LeaderboardTop100Page}
    key="/l/top-fantasy-football"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Landing"
  />,
  <Route
    exact
    path="/fantasy-football"
    component={CuratedLeaderboardPage}
    key="/fantasy-football"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Landing"
  />,
  <Route
    exact
    path="/l/:layoutKey"
    component={LandingPage}
    key="/l/:layoutKey"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Landing"
  />,
  <Route
    exact
    path="/kids"
    component={LandingPage}
    key="/kids"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Landing"
  />,
  <Route
    exact
    path="/landing/birthday-gift"
    component={LandingPageBirthdayGift}
    key="/landing/birthday-gift"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Landing"
  />,
  <Route
    exact
    path="/landing/birthday-gift-2"
    component={LandingPageBirthdayGiftV2}
    key="/landing/birthday-gift-2"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Landing"
  />,
  <Route
    exact
    path="/landing/birthday-gift-3"
    component={LandingPageBirthdayGiftV3}
    key="/landing/birthday-gift-3"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Landing"
  />,
  <Route
    exact
    path="/dashboard"
    key="/dashboard"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Other"
    render={(props) => (
      <RequireAuth>
        {/* @ts-expect-error AsyncConnect router types are not compatible with Router DOM types */}
        <DashboardPage {...props} />
      </RequireAuth>
    )}
  />,
  <Route
    exact
    path="/recipient/:orderId"
    component={RecipientWrapper}
    key="/recipient/:orderId"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Video"
  />,
  /* Routes */
  <Route
    exact
    path="/404"
    component={ErrorPage}
    // @ts-expect-error This is getting used on analytics to match routes
    status={404}
    key="/404"
    pageType="Other"
  />,
  <Route
    exact
    path="/about"
    component={AboutPage}
    key="/about"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="About"
  />,
  <Route
    exact
    path={['/apply', '/enroll']}
    component={TalentEnrollmentLandingPage}
    key="/"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Other"
  />,
  <Route
    exact
    path="/inbox"
    component={Inbox}
    key="/inbox"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Inbox"
  />,
  <Route
    exact
    path="/inbox/:channelId"
    component={Inbox}
    key="/inbox/:channelId"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Inbox"
  />,
  <Route
    exact
    path="/:username/dm"
    component={NewDirectMessagePage}
    key="/:username/dm"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="DM"
  />,
  <Route
    exact
    path="/:username/dm/:messageId"
    component={NewDirectMessagePage}
    key="/:username/dm/:messageId"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="DM"
  />,
  <Route
    exact
    path="/:username/book"
    component={BookingPage}
    key="/:username/book"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Booking"
  />,
  <Route
    exact
    path="/purchase-card/:orderId"
    key="/purchase-card/:orderId"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Post Card Purchase"
    render={(props) => (
      <RequireAuth>
        {/* @ts-expect-error AsyncConnect router types are not compatible with Router DOM types */}
        <PurchaseCard {...props} />
      </RequireAuth>
    )}
  />,
  <Route
    exact
    path="/purchase-digital-card/:orderId"
    key="/purchase-digital-card/:orderId"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Digital Post Card Purchase"
    render={(props) => (
      <RequireAuth>
        {/* @ts-expect-error AsyncConnect router types are not compatible with Router DOM types */}
        <PurchaseDigitalCard {...props} />
      </RequireAuth>
    )}
  />,
  <Route
    exact
    path="/purchase-digital-card/:orderId/preview"
    key="/purchase-digital-card/:orderId/preview"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Digital Post Card Purchase"
    render={(props) => (
      <RequireAuth>
        {/* @ts-expect-error AsyncConnect router types are not compatible with Router DOM types */}
        <PreviewDigitalCard {...props} />
      </RequireAuth>
    )}
  />,
  <Route
    exact
    path="/following"
    key="/following"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Following"
    render={(props) => (
      <RequireAuth>
        {/* @ts-expect-error AsyncConnect router types are not compatible with Router DOM types */}
        <FollowingPage {...props} />
      </RequireAuth>
    )}
  />,
  <Route
    exact
    path="/:username/follow/post/:postId"
    component={FollowPostPage}
    key="/:username/follow/post/:postId"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Following"
  />,
  <Route
    exact
    path="/orders"
    key="/orders"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Orders"
    render={(props) => (
      <RequireAuth>
        {/* @ts-expect-error AsyncConnect router types are not compatible with Router DOM types */}
        <OrdersPage {...props} />
      </RequireAuth>
    )}
  />,
  <Route
    exact
    path="/:username/feed/:orderId"
    component={ProfileFeed}
    key="/:username/feed/:orderId"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="ProfileFeed"
  />,
  <Route
    path="/business"
    key="C4BEntry"
    component={C4BApp}
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Business"
  />,
  <Route
    exact
    path="/partner"
    component={PartnerRegistrationPage}
    key="/partner"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Other"
  />,
  <Route
    exact
    path="/physical-cards"
    component={PhysicalCardLandingPage}
    key="/physical-cards"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Landing"
  />,
  <Route
    exact
    path="/giftcards"
    component={GiftCardPurchase}
    key="/giftcards"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="GiftCard"
  />,
  <Route
    exact
    path="/giftcards/redeem"
    component={GiftCardRedemption}
    key="/giftcards/redeem"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="GiftCard"
  />,
  <Route
    exact
    path="/giftcards/:action/success"
    component={GiftCardSuccess}
    key="/giftcards/:action/success"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="GiftCard"
  />,
  <Route
    exact
    path="/forgot"
    component={ForgotPasswordPage}
    key="/forgot"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Authentication"
  />,
  <Route
    exact
    path="/login"
    component={OtpLogin}
    key="/login"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Authentication"
  />,
  <Route
    exact
    path="/verify/code"
    component={VerifyCodePage}
    key="verify/code"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Authentication"
  />,
  <Route
    exact
    path="/save-phone-number"
    key="save-phone-number"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Authentication"
    render={() => (
      <RequireAuth>
        <SavePhoneNumberPage />
      </RequireAuth>
    )}
  />,
  <Route
    exact
    path="/complete-signup"
    component={OtpSignUp}
    key="/complete-signup"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Authentication"
  />,
  <Route
    exact
    path="/otp/:otp"
    component={OtpVerify}
    key="/otp/:otp"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Authentication"
  />,
  <Route
    exact
    path="/order/:orderId/:authToken?"
    component={ConfirmationPageV2}
    key="/order/:orderId/:authToken?"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Order Confirmation"
  />,
  <Route
    exact
    path="/edit-order/:orderId"
    component={BookingEditPage}
    key="/edit-order/:orderId"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Orders"
  />,
  <Route
    exact
    path="/privacy"
    component={PrivacyPage}
    key="/privacy"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Other"
  />,
  <Route
    exact
    path="/communityguidelines"
    component={GuidelinesPage}
    key="/communityguidelines"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Other"
  />,
  <Route
    exact
    path="/quiz"
    component={QuizPage}
    key="/quiz"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Other"
  />,
  <Route
    exact
    path="/reset/:token"
    component={ResetPasswordPage}
    key="/reset/:token"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Other"
  />,
  <Route
    exact
    path="/results"
    component={ResultsPage}
    key="/results"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Recommended"
  />,
  <Route
    exact
    path="/likes"
    key="/likes"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Other"
    render={() => (
      <RequireAuth>
        <LikesPage />
      </RequireAuth>
    )}
  />,
  <Route
    exact
    path="/review/:orderId/reaction"
    key="/review/:orderId/reaction"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Other"
    render={(props) => (
      <RequireAuth>
        {/* @ts-expect-error AsyncConnect router types are not compatible with Router DOM types */}
        <ReviewReaction {...props} />
      </RequireAuth>
    )}
  />,
  <Route
    exact
    path="/review/:orderId/scores"
    key="/review/:orderId/scores"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Other"
    render={(props) => (
      <RequireAuth>
        {/* @ts-expect-error AsyncConnect router types are not compatible with Router DOM types */}
        <ReviewScores {...props} />
      </RequireAuth>
    )}
  />,
  <Route
    exact
    path="/review/:orderId/:authEmailOrToken/:rating?"
    key="/review/:orderId/:authEmailOrToken/:rating?"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Other"
    render={(props) => (
      <RequireAuth>
        {/* @ts-expect-error AsyncConnect router types are not compatible with Router DOM types */}
        <ReviewPageWrapper {...props} />
      </RequireAuth>
    )}
  />,
  <Route
    exact
    path="/team"
    component={TeamPage}
    key="/team"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Other"
  />,
  <Route
    exact
    path="/text-me"
    component={SendSms}
    key="text-me"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Other"
  />,
  <Route
    exact
    path="/terms"
    component={TermsPage}
    key="/terms"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Other"
  />,
  <Route
    exact
    path="/accessibility"
    component={Accessibility}
    key="/accessibility"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Other"
  />,
  <Route
    exact
    path={['/feed/:shelfIdentifier', '/feed/:shelfIdentifier/:itemId']}
    component={VideoCollectionFeed}
    key="/feed/:shelfIdentifier/:itemId"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="VideoCollectionFeed"
  />,

  <Route
    exact
    path="/collage"
    component={CollageLanding}
    key="/collage"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Collage"
  />,
  <Route
    key="/collage/create"
    path="/collage/create"
    exact
    component={CreateCollage}
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Collage"
  />,
  <Route
    exact
    path="/collage/:collageId/edit"
    component={EditCollage}
    key="/collage/:collageId/edit"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Collage"
  />,
  <Route
    exact
    path="/collage/:collageId/play/:contentId?"
    component={CollageFeed}
    key="/collage/:collageId/play/:contentId?"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Collage"
  />,
  <Route
    exact
    path="/collage/:collageId"
    component={Collage}
    key="/collage/:collageId"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Collage"
  />,
  <Route
    component={TalentProfilePage}
    exact
    path={KIDS_CHARACTER_PROFILE_PATHS}
    key="/:username"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Profile"
  />,
  <Route
    component={TalentProfile}
    exact
    path="/:username"
    key="/:username"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Profile"
  />,
  <Route
    exact
    path="/stripeOnboarding/:result"
    component={StripeOnboarding}
    key="/stripeOnboarding/:result"
    // @ts-expect-error This is getting used on analytics to match routes
    pageType="Other"
  />,
  /* Catch all routes */
  <Route
    path="*"
    component={ErrorPage}
    // @ts-expect-error This is getting used on analytics to match routes
    status={404}
    key="*"
    pageType="Other"
  />,
];

// eslint-disable-next-line no-restricted-syntax
export default <Switch>{routes}</Switch>;

// keys of routes that contain subroutes / are pass through need to be filtered out to prevent incorrect match
const pathsWithSubroutes = ['/business'];

export const arrayOfRoutes = routes
  .filter((route) => {
    // path can be a string or array of strings
    // return false if any path is matched
    if (route.props.path instanceof Array) {
      return !(route.props.path as string[]).find((path) =>
        pathsWithSubroutes.includes(path)
      );
    }
    return !pathsWithSubroutes.includes(route.props.path);
  })
  .map((route) => route.props);
