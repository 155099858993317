import styled from 'styled-components';
import { sizing, spacing, media, color, fonts } from '../../style';

export const Title = styled.span`
  ${fonts.boldText['100']};
  color: ${color.solids.WHITE};
  display: block;
  font-size: ${sizing(18)};
  margin: ${spacing.margin.xxlarge} 0 ${spacing.margin.medium};
`;

export const Wrapper = styled.div<{
  isVisible: boolean;
}>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  padding: 0 ${sizing(12)};

  & > ${Title}:first-child {
    margin-top: ${spacing.margin.xxsmall};
  }

  @media (min-width: ${media.queries.MOBILE}) {
    padding: 0 ${sizing(20)};
  }

  @media (min-width: ${media.queries.DESKTOP}) {
    padding: 0 ${spacing.padding.medium};
  }

  @media (min-width: 1047px) {
    padding: 0 ${spacing.padding.large};
  }
`;
