import { RegionPreferences } from 'server/middlewares/clientInfoMiddleware';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import UniversalCookie from 'universal-cookie';
import {
  CAMEO_REGION_COOKIE,
  CAMEO_CURRENCY_COOKIE,
} from 'utils/i18n/constants';
import {
  UpdateConversionRateForCurrency,
  UpdateUserPreferences,
  updateCurrency,
  updateCurrencyRate,
  updateLanguage,
  updateRegion,
} from './actions';

export interface InitialState {
  regionPreferences: RegionPreferences;
}
const INITIAL_STATE: InitialState = null;

const i18nReducer = reducerWithInitialState(INITIAL_STATE);

i18nReducer.case(updateCurrency, (state, currencyCode) => {
  if (currencyCode) {
    new UniversalCookie().set(CAMEO_CURRENCY_COOKIE, currencyCode);
  }
  return {
    ...state,
    regionPreferences: { ...state.regionPreferences, currencyCode },
  };
});

i18nReducer.case(updateCurrencyRate, (state, currencyRate) => {
  return {
    ...state,
    regionPreferences: { ...state.regionPreferences, currencyRate },
  };
});

i18nReducer.case(updateLanguage, (state, languageCode) => {
  return {
    ...state,
    regionPreferences: { ...state.regionPreferences, languageCode },
  };
});

i18nReducer.case(updateRegion, (state, countryCode) => {
  if (countryCode) {
    new UniversalCookie().set(CAMEO_REGION_COOKIE, countryCode);
  }
  return {
    ...state,
    regionPreferences: { ...state.regionPreferences, countryCode },
  };
});

i18nReducer.case(UpdateUserPreferences.started, (state) => {
  return state;
});

i18nReducer.case(UpdateUserPreferences.failed, (state) => {
  return state;
});

i18nReducer.case(UpdateUserPreferences.done, (state, { result }) => {
  const cookies = new UniversalCookie();
  if (result.currency) cookies.set(CAMEO_CURRENCY_COOKIE, result.currency);
  if (result.region) cookies.set(CAMEO_REGION_COOKIE, result.region);
  return {
    ...state,
    regionPreferences: {
      ...state.regionPreferences,
      currencyCode: result.currency || state.regionPreferences.currencyCode,
      languageCode: result.language || state.regionPreferences.languageCode,
      countryCode: result.region || state.regionPreferences.countryCode,
    },
  };
});

i18nReducer.case(UpdateConversionRateForCurrency.started, (state) => {
  return state;
});

i18nReducer.case(UpdateConversionRateForCurrency.failed, (state) => {
  return state;
});

i18nReducer.case(UpdateConversionRateForCurrency.done, (state, { result }) => {
  return {
    ...state,
    regionPreferences: { ...state.regionPreferences, currencyRate: result },
  };
});

export default i18nReducer.build();
