import { toast } from 'design-system/Components/Toast';
import styled from 'styled-components';
import { Globe } from 'react-feather';
import { connect } from 'react-redux';
import { getLoggedInUser } from 'state/modules/auth/selectors';
import { getInternationalInfo } from 'state/modules/clientInfo/i18n/selectors';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { sizing } from 'design-system/style';
import {
  hasLocalizationToastCookie,
  setLocalizationToastCookie,
} from './utils';

const GlobeGlyph = styled(Globe)`
  position: relative;
  width: ${sizing(24)};
  height: ${sizing(24)};
  left: ${sizing(8)};
  top: ${sizing(8)};
`;

const LocalizationToastComponent = ({ loggedInUser, requestLocale }) => {
  const isFeatureEnabled = false;

  const { t } = useTranslation('LocalizationToast');

  useEffect(() => {
    const preferredLanguage =
      loggedInUser?.locale?.preferredLanguage?.toLowerCase();
    const preferredRegion =
      loggedInUser?.locale?.preferredRegion?.toLowerCase();

    const requestLanguage = requestLocale?.languageCode?.toLowerCase();
    const requestRegion = requestLocale?.countryCode?.toLowerCase();

    const languageDiffers =
      preferredLanguage &&
      requestLanguage &&
      preferredLanguage !== requestLanguage;

    const regionDiffers =
      preferredRegion && requestRegion && preferredRegion !== requestRegion;

    const shouldRenderTooltip =
      isFeatureEnabled &&
      !hasLocalizationToastCookie() &&
      (languageDiffers || regionDiffers);

    if (shouldRenderTooltip) {
      toast({
        Glyph: GlobeGlyph,
        message: t('newLocaleDetected', 'New locale detected'),
        description: t('updateLocaleSettings', 'Update locale settings'),
        actionText: t('update', 'Update'),
        onClick: null,
        variant: 'right-bottom',
      });
      setLocalizationToastCookie();
    }
  }, []);

  return null;
};

function mapStateToProps(state) {
  const loggedInUser = getLoggedInUser(state);
  const requestLocale = getInternationalInfo(state);
  return { loggedInUser, requestLocale };
}

export const LocalizationToast = connect(mapStateToProps)(
  LocalizationToastComponent
);
