import { reducerWithInitialState } from 'typescript-fsa-reducers';
import continueWithFacebook from './actions';
import { ContinueWithFacebookState, INITIAL_STATE } from './types';

const reducer = reducerWithInitialState<ContinueWithFacebookState>(
  INITIAL_STATE
)
  .case(continueWithFacebook.async.started, (state) => ({
    ...state,
    loginError: null,
    signupError: null,
    loggingIn: true,
  }))
  .case(continueWithFacebook.async.done, (state, payload) => ({
    ...state,
    loggedInUser: payload.result.user,
    loggingIn: false,
  }))
  .case(continueWithFacebook.async.failed, (state, payload) => ({
    ...state,
    loginError: payload.error.message ?? 'Something went wrong.',
    signupError: payload.error.message ?? 'Something went wrong.',
    loggingIn: false,
  }));

export default reducer.build();
