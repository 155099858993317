import React from 'react';
import {
  toast as centerBottomToast,
  ToastContainer as CenterBottomToastContainer,
} from './CenterBottomToast';
import {
  toast as rightBottomToast,
  ToastContainer as RightBottomToastContainer,
} from './RightBottomToast';

type Link = { text: string; onClick: () => void };
interface Props {
  message: string;
  actions?: Link[];
  autoClose?: false | number;
  Glyph?: any;
  onClose?: () => void;
  toastId?: string;
  closeButton?: boolean;
  variant?: string;
  description?: string;
  actionText?: string;
  onClick?: Function;
}

export const toast = (props: Props) => {
  const { variant } = props;

  if (variant === 'right-bottom') {
    // @ts-ignore
    rightBottomToast(props);
  } else {
    // @ts-ignore
    centerBottomToast(props);
  }
};

export const ToastContainer = () => {
  return (
    <React.Fragment>
      <CenterBottomToastContainer />
      <RightBottomToastContainer />
    </React.Fragment>
  );
};
