export enum ItemGroup {
  GhostProfileRelated = 'gpr',
  Recommended = 'rc',
  RecentlyViewed = 'rv',
  Suggestion = 'sg',
  RecentSearches = 'rs',
}

export type SearchItem = {
  _id: string;
  data: {
    imageUrlKey?: string;
    name: string;
    username?: string;
    profession?: string;
    price?: number;
    slug?: string;
  };
  group: ItemGroup;
  type: 'user' | 'category' | 'tag' | 'title' | 'miniProfile';
  matchedFields?: string[];
  explainBlock?: any;
  aaQueryId?: string;
  aaIndex?: string;
};

export type SearchData = SearchItem[];

export type BrowsePageUrlGenerator = (
  slug: string,
  type: 'category' | 'tag'
) => string;
export type ProfilePageUrlGenerator = (
  username: string,
  id?: string,
  aaQueryId?: string
) => string;
export type SearchPageUrlGenerator = (query: string) => string;
