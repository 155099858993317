import Cameo from 'types';
import { DeepPartial } from 'redux';
import { TrackFunc } from '../types';

export default function trackSystem(trackFunc: TrackFunc) {
  return (data: DeepPartial<Cameo.Utils.Analytics.SystemEventSchema>) => {
    trackFunc({
      eventName: 'System',
      data,
    });
  };
}
