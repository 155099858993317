import { reducerWithInitialState } from 'typescript-fsa-reducers';
import clearAuthErrors from './actions';
import { ClearAuthErrorsState, INITIAL_STATE } from './types';

const reducer = reducerWithInitialState<ClearAuthErrorsState>(
  INITIAL_STATE
).case(clearAuthErrors, (state) => ({
  ...state,
  loginError: null,
  signupError: null,
}));

export default reducer.build();
