import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { saveScrollState } from './actions';

interface InitialState {
  //  Scroll state object is of the form: { pathname: saved scroll position }
  scrollState: { [key: string]: number };
}

const INITIAL_STATE: InitialState = {
  scrollState: {},
};

const scrollStateReducer = reducerWithInitialState(INITIAL_STATE)
  .case(saveScrollState, (state, action) => {
    const newScrollState = {
      ...state.scrollState,
      [action.pathname]: action.scrollPosition,
    };

    return {
      ...state,
      scrollState: newScrollState,
    };
  })
  .build();

export default scrollStateReducer;
