export const types = {
  start: 'cameo/feature/get/start',
  success: 'cameo/feature/get/success',
  fail: 'cameo/feature/get/fail',
};

export const success = (state, action) => ({
  ...state,
  promotions: {
    ...state.promotions,
    [action.result.promotion.name]: action.result.promotion.data,
  },
});

export const service = (name) => ({
  types: [types.start, types.success, types.fail],
  promise: (client) => client.get(`/promotion/getPromotion?name=${name}`),
});
