/* eslint-disable id-length */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { DropDown } from 'design-system/Components/DropDown';
import { trackItemClick, trackLogInClick } from 'components/NavBar/analytics';
import { AdminUser } from 'types/src/api/models/user';
import { getInternationalInfo } from 'state/modules/clientInfo/i18n/selectors';
import { NotificationIndicator as DropDownNotificationIndicator } from 'design-system/Components/NotificationIndicator';
import { Avatar, Box, Button } from 'domains/web/components';
import { MenuItems, Notification } from 'state/modules/navbar/types';
import { useInboundBriefcasePath } from 'domains/c4b/pages/pages';
import {
  DropDownNotificationIndicatorWrapper,
  NotificationIndicator,
} from './Styled';
import { useIsUserBriefcaseEligible } from './hooks/useIsUserBriefcaseEligible';

type UserAvatarProps = {
  isAnyDesktop: boolean;
  loggedInUser?: AdminUser;
  notifications: Notification[];
  unreadChannelCount?: number;
  logout: () => void;
};

export const UserAvatar = ({
  isAnyDesktop,
  loggedInUser,
  logout,
  notifications,
  unreadChannelCount,
}: UserAvatarProps) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('NavBar');
  const links = useDropdownLinks({
    t,
    notifications,
    unreadChannelCount,
    logout,
  });
  const hasNonOrderNotifications = !!notifications.filter(
    (notification) => notification !== MenuItems.Orders
  ).length;

  const handleLogin = () => {
    trackLogInClick();
    history.push(`/login?redirect=${location.pathname}${location.search}`);
  };

  return (
    <Box alignItems="center" display="flex" flexShrink={0}>
      {loggedInUser ? (
        <DropDown
          toggler={
            <>
              <Avatar
                imageUrlKey={loggedInUser.imageUrlKey}
                size={40}
                username={loggedInUser.username}
              />
              {hasNonOrderNotifications && (
                <NotificationIndicator data-testid="notification-indicator" />
              )}
            </>
          }
          links={links}
          shouldDisplayIndicator={isAnyDesktop}
        />
      ) : (
        <Button
          size="small"
          onClick={handleLogin}
          data-testid="nav-login-button"
        >
          {t('dropdown.Login', 'Log in')}
        </Button>
      )}
    </Box>
  );
};

const useDropdownLinks = ({
  t,
  notifications,
  unreadChannelCount,
  logout,
}: {
  t: TFunction;
  notifications: Notification[];
  unreadChannelCount: number;
  logout: () => void;
}) => {
  const history = useHistory();
  const giftCardExcludedCountryCodes = ['jp', 'jpn'];
  const internationalInfo = useSelector(getInternationalInfo);
  const isBriefcaseEligible = useIsUserBriefcaseEligible();
  const businessVideoDashPath = useInboundBriefcasePath({
    page: 'BusinessBrowsePage',
    params: {
      pageType: 'browse',
    },
  });

  const nonOrderNotifications = notifications.filter(
    (notification) => notification !== MenuItems.Orders
  );
  const orderNotifications = notifications.filter(
    (notification) => notification === MenuItems.Orders
  );

  const c4bLabel = t('dropdown.cameoForBusiness', 'Cameo for Business');

  async function handleLogout() {
    trackItemClick('Logout', '/login', 'logout-nav-btn');
    await logout();
    const route = '/login';
    history.push(route);
  }

  return [
    {
      id: MenuItems.Dashboard,
      name: t('dropdown.account', 'Account'),
      url: '/dashboard',
      onClick: () => {
        trackItemClick('Account', '/dashboard', MenuItems.Dashboard);
      },
    },
    isBriefcaseEligible && {
      id: MenuItems.businessOrders,
      name: c4bLabel,
      url: businessVideoDashPath,
      onClick: () => {
        trackItemClick(
          c4bLabel,
          businessVideoDashPath,
          MenuItems.businessOrders
        );
      },
    },
    {
      id: MenuItems.Orders,
      notificationIndicator:
        orderNotifications.length && Boolean(nonOrderNotifications.length) ? (
          <DropDownNotificationIndicatorWrapper>
            <DropDownNotificationIndicator count={orderNotifications.length} />
          </DropDownNotificationIndicatorWrapper>
        ) : undefined,
      name: t('dropdown.orders', 'Orders'),
      url: `/orders`,
      onClick: () => {
        trackItemClick('Orders', `/orders`, MenuItems.Orders);
      },
    },
    {
      id: MenuItems.Inbox,
      notificationIndicator: unreadChannelCount ? (
        <DropDownNotificationIndicatorWrapper>
          <DropDownNotificationIndicator count={unreadChannelCount} />
        </DropDownNotificationIndicatorWrapper>
      ) : undefined,
      name: t('dropdown.inbox', 'Inbox'),
      url: `/inbox`,
      onClick: () => {
        trackItemClick('Inbox', '/inbox', MenuItems.Inbox);
      },
    },
    {
      id: MenuItems.Following,
      name: `${t('dropdown.following', 'Following')}`,
      notificationIndicator: undefined,
      url: '/following',
      onClick: () => {
        trackItemClick('Following', '/following', MenuItems.Following);
      },
    },

    !giftCardExcludedCountryCodes.includes(
      internationalInfo.countryCode?.toLowerCase()
    ) && {
      id: MenuItems.GiftCards,
      name: t('dropdown.giftCards', 'Gift cards'),
      url: '/giftcards',
      onClick: () => {
        trackItemClick('Gift cards', '/giftcards', MenuItems.GiftCards);
      },
    },
    {
      id: MenuItems.Likes,
      name: t('dropdown.likes', 'Liked videos'),
      url: '/likes',
      onClick: () => {
        trackItemClick('likes', '/likes', MenuItems.Dashboard);
      },
    },
    {
      id: MenuItems.Logout, // for UA tests
      name: t('dropdown.logout', 'Logout'),
      onClick: handleLogout,
      url: '',
    },
  ].filter(Boolean);
};
