/* eslint-disable no-restricted-syntax */
// Analytics tracking provider
// usage: analytics.track({ eventName, data });
import { config } from 'cameoConfig';
import { matchPath } from 'react-router-dom';
import memoize from 'lodash/memoize';
import { AdminUser } from 'types/src/api/models/user';
import { UserAgentData } from 'shared-utils/userAgent';
import { EventBase, PageViewWeb, PageView } from '@cameo/events';
import Cookies from 'universal-cookie';
import Braze from 'services/braze';
import { CAMEO_LANGUAGE_COOKIE } from 'utils/i18n/constants';
import { CAMEO_KIDS_SESSION_COOKIE_NAME } from 'shared-types/CameoKidsSessionCookie';
import { utmParamsParser } from 'analytics/utils/utmParamsParser';
import events from './events';
import trackAction from './events/action';
import trackNavigation from './events/navigation';
import trackSystem from './events/system';
import { PageType } from './types';
import DpLogger from './clients/dplogger';

interface TrackingClient {
  init: (...any) => any;
  track: (...any) => any;
  setUser: (...any) => any;
}

class Analytics {
  private static instance: Analytics;

  private routes: any[] = [];

  private trackingClients: { [name: string]: TrackingClient } = {};

  public getPageType = (pathname?: string): PageType => {
    if (!pathname && typeof window !== 'undefined') {
      pathname = window?.location?.pathname;
    }

    const matchedPath = this.routes.find((pathObj) => {
      return !!matchPath(pathname, pathObj);
    });

    return matchedPath ? matchedPath.pageType : 'Other';
  };

  private getDeviceType = memoize(() => {
    const { device } = new UserAgentData().getClientSideUserAgentAttributes();

    return device;
  });

  private getUserSelectedLang = function () {
    const cookies = new Cookies();

    return cookies.get(CAMEO_LANGUAGE_COOKIE);
  };

  private getEventCommonData = () => {
    const value = {
      cameoPlatform: 'web',
      currentPathname: '',
      currentQueryParams: '',
      currentPageType: this.getPageType(''),
      cameoDeviceType: this.getDeviceType(),
      cameoUserSelectedLang: this.getUserSelectedLang(),
    };

    if (typeof window === 'undefined') return value;

    const currentPathname = window.location.pathname;
    const queryString = window.location.search.slice(1);

    value.currentPathname = currentPathname;
    value.currentQueryParams = queryString;
    value.currentPageType = this.getPageType(currentPathname || '');

    return value;
  };

  public static getInstance(): Analytics {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics();
    }

    return Analytics.instance;
  }

  // *** Tracking clients must implement the following public methods: *** //
  // Initializes all available tracking clients
  public init(loggedInUser: AdminUser, trackingClients) {
    this.trackingClients = trackingClients || {};
    Object.keys(this.trackingClients).forEach((client) => {
      const currClient = this.trackingClients[client];
      // Ensure required client functions are defined
      if (
        typeof currClient.init !== 'function' ||
        typeof currClient.track !== 'function'
      ) {
        throw new Error(
          `Unable to initialize tracking client with name ${client}`
        );
      }

      currClient.init(config, this.getEventCommonData());
    });
    if (loggedInUser) {
      this.setUser({ email: loggedInUser.email, user: loggedInUser });
    }
  }

  // Sets the routes config from where metadata will be extracted and injected into tracked events
  public setRoutes(routes: any[]) {
    if (routes && routes.length > 0) {
      this.routes = routes;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public setStore(store) {
    DpLogger.setStore(store);
  }

  public setPageId() {
    DpLogger.setPageId();
  }

  // Sets the logged in user to be tracked.
  // eslint-disable-next-line class-methods-use-this
  public setUser({
    user,
    email,
  }: {
    email: string;
    user?: { email: string; _id: string };
  }) {
    Object.keys(this.trackingClients).forEach((client) => {
      const currClient = this.trackingClients[client];
      if (typeof currClient.setUser === 'function') {
        currClient.setUser({ user, email });
      }
    });
    if (user) {
      new Cookies().set('@cameo/userId', user._id, {
        path: '/',
      });
      DpLogger.setUser(user);
      Braze.changeUser(user._id);
      Braze.setLanguage(this.getUserSelectedLang());
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public unsetUser() {
    new Cookies().remove('@cameo/userId', {
      path: '/',
    });
    new Cookies().remove(CAMEO_KIDS_SESSION_COOKIE_NAME);
    DpLogger.setUser({ _id: null });
  }

  /**
   * @deprecated Please use one of the new track event types
   */
  public track({ data, eventName }: { eventName: string; data?: any }) {
    if (!Object.keys(events).includes(eventName)) {
      // Invalid event. Don't throw.
      console.log(
        `Warning: Tracking event with name ${eventName} cannot be found`
      );
      return;
    }

    const dataIsObject =
      typeof data === 'object' && !Array.isArray(data) && data !== null;
    if (!dataIsObject)
      console.warn(
        `Data for event ${eventName} is not object. Sending event without data.`
      );

    const eventData = {
      ...(dataIsObject ? data : null),
      ...this.getEventCommonData(),
    };

    if (eventData?.loggedInUser?.dob) {
      eventData.loggedInUser.dob = null;
    }

    const { clients, category, type } = events[eventName];
    clients.forEach((client) => {
      if (client in this.trackingClients) {
        this.trackingClients[client].track({
          type,
          data: eventData,
          eventName,
          category,
        });
      } else {
        // Invalid tracking client. Don't throw.
        console.log(
          `Warning: Specified tracking client of name ${client} cannot be found.`
        );
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public logEvent(event: EventBase) {
    DpLogger.logEvent(event);
  }

  // eslint-disable-next-line class-methods-use-this
  public logPageView(
    pageType: PageType,
    currentPath = undefined,
    queryString = undefined
  ) {
    const oldPageViewEvent: PageViewWeb = {
      eventName: 'PageViewWeb',
      pageType,
      currentPath: undefined,
      currentQueryParams: undefined,
    };

    const pageViewEvent: PageView = {
      eventName: 'PageView',
      pageType,
      currentPath: undefined,
      currentQueryParams: undefined,
      _utmCampaign: undefined,
      _utmMedium: undefined,
      _utmTerm: undefined,
      _utmSource: undefined,
      _utmContent: undefined,
    };

    if (currentPath) {
      oldPageViewEvent.currentPath = currentPath;
      pageViewEvent.currentPath = currentPath;
    }
    if (queryString) {
      oldPageViewEvent.currentQueryParams = queryString;

      pageViewEvent.currentQueryParams = queryString;
      const utmParams = utmParamsParser(queryString);
      pageViewEvent._utmCampaign = utmParams.utmCampaign;
      pageViewEvent._utmMedium = utmParams.utmMedium;
      pageViewEvent._utmContent = utmParams.utmContent;
      pageViewEvent._utmTerm = utmParams.utmTerm;
      pageViewEvent._utmSource = utmParams.utmSource;
    }
    DpLogger.logEvent(oldPageViewEvent);
    DpLogger.logEvent(pageViewEvent);
  }

  public trackAction = trackAction(this.track.bind(this));

  public trackNavigation = trackNavigation(this.track.bind(this));

  public trackSystem = trackSystem(this.track.bind(this));
}

export default Analytics.getInstance();
