// eslint-disable-next-line no-use-layout-effect
import { useEffect, useLayoutEffect } from 'react';

/**
 * @description
 * The React documentation says about useLayoutEffect:
 *
 * > The signature is identical to useEffect, but it fires synchronously after all DOM mutations.
 * > That means this hook is a browser hook. But React code could be generated from the server without the Window API.
 *
 * If you're using any SSR provider (like NextJS for example), you'll have this error message:
 *
 * > Warning: useLayoutEffect does nothing on the server, because its effect cannot be encoded into the server renderer's output format. This will lead to a mismatch between the initial, non-hydrated UI and the intended UI. To avoid this, useLayoutEffect should only be used in components that render exclusively on the client. See https://reactjs.org/link/uselayouteffect-ssr for common fixes.
 * > This hook fixes this problem by switching between useEffect and useLayoutEffect following the execution environment.
 *
 * @example
 * export default function Component() {
 *   useIsomorphicLayoutEffect(() => {
 *     console.log("In the browser, I'm an `useLayoutEffect`, but in SSR, I'm an `useEffect`.")
 *   }, [])
 *
 *   return <p>Hello, world</p>
 * }
 */
export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;
