import { useLocation } from 'react-router-dom';

export const useIsProminentSearchBar = () => {
  const location = useLocation();
  const whitelistedPathPrefixes = [
    '/browse',
    '/tags',
    '/about',
    '/search',
    '/landing',
    '/dashboard',
    '/recipient',
    '/inbox',
    '/following',
    '/orders',
    '/quiz',
    '/results',
    '/likes',
    '/team',
  ];

  const { pathname } = location;
  return (
    pathname === '/' ||
    whitelistedPathPrefixes.some((path) => pathname.includes(path))
  );
};
