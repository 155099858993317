export enum MediaSizes {
  MOBILE = 374,
  TABLET = 768,
  DESKTOP = 992,
  LARGE_DESKTOP = 1200,
}

const MediaQueries = {
  MOBILE: `${MediaSizes.MOBILE}px`,
  TABLET: `${MediaSizes.TABLET}px`,
  DESKTOP: `${MediaSizes.DESKTOP}px`,
  LARGE_DESKTOP: `${MediaSizes.LARGE_DESKTOP}px`,
};

export const media = {
  queries: MediaQueries,
};
