/* eslint-disable consistent-return */

import {
  parse,
  differenceInYears,
  isBefore,
  differenceInMonths,
  startOfToday,
  isValid,
  getYear,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

// synchronous validations
const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    value === '' ||
    (typeof value === 'string' && !value.trim().length)
  );
};
const join = (rules) => (value, data) =>
  rules
    .map((rule) => rule(value, data))
    .filter((error) => !!error)[0 /* first error */];

/**
 * Simple wrapper to override the error message
 * returned by `validator` to `newErrorMessage`.
 *
 * To be used with useTranslation to i18n errors.
 *
 * @param {(value: T) => (string | null | undefined)} validator A validator that accepts a value, and returns a string if the given value is invalid.
 * @param {string | ((value: T) => string)} newErrorMessage  A string (or function that returns a string) representing the error message to return instead of the default as given by `validator`
 * @returns {(value: T) => (string | null | undefined)} A new validator function with the same logic as `validator` that returns `newErrorMessage` on errors.
 * @template T
 */
const useOverridenValidator = (validator, newErrorMessage) =>
  useCallback(
    (value) => {
      const validationResult = validator(value);
      if (typeof validationResult === 'string') {
        return typeof newErrorMessage === 'function'
          ? newErrorMessage(value)
          : newErrorMessage;
      }
      return validationResult;
    },
    [validator, newErrorMessage]
  );

export const emailRegex =
  /^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;

export function email(value) {
  if (isEmpty(value) || !emailRegex.test(value)) {
    return 'Invalid email address';
  }
}

export const useEmail = () => {
  const { t } = useTranslation('FormValidation');
  const errorMessage = t('email.error', 'Invalid email address');
  return useOverridenValidator(email, errorMessage);
};

export function links(value) {
  if (isEmpty(value)) {
    return;
  }

  // @see https://regexr.com/3e6m0
  const linksExpression =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
  const normalizedValue = value.replace(/(\r\n|\n|\r)/gm, ' ');

  if (linksExpression.test(normalizedValue)) {
    return 'Must not include any links';
  }
}

export const useLinks = () => {
  const { t } = useTranslation('FormValidation');

  const errorMessage = t('links.error', 'Must not include any links');
  return useOverridenValidator(links, errorMessage);
};

export function validUrl(value) {
  if (isEmpty(value)) {
    return;
  }

  let isValidUrl = true;
  try {
    const normalizedUrl = value.toLowerCase();
    const urlWithProtocolString =
      normalizedUrl.startsWith('http://') ||
      normalizedUrl.startsWith('https://')
        ? normalizedUrl
        : `https://${value}`;

    const url = new URL(urlWithProtocolString);
    const urlParts = url.hostname.split('.').filter((part) => !!part);

    const requiredPartsNum = urlParts.includes('www') ? 3 : 2;
    if (urlParts.length < requiredPartsNum) {
      isValidUrl = false;
    }
  } catch (error) {
    isValidUrl = false;
  }

  if (!isValidUrl) {
    return 'Please enter a valid url';
  }
}

export function required(value) {
  if (isEmpty(value)) {
    return 'Required';
  }
}

export const useRequired = () => {
  const { t } = useTranslation('FormValidation');
  const errorMessage = t('required.error', 'Required');
  return useOverridenValidator(required, errorMessage);
};

function parseSimpleDate(simpleDate) {
  return parse(simpleDate, 'MM / dd / yyyy', new Date());
}

export function minAge(age) {
  return (date) => {
    if (differenceInYears(new Date(), parseSimpleDate(date)) < age) {
      return `Sorry, you are not eligible to create an account`;
    }
  };
}

export function getAge(dob, age) {
  if (differenceInYears(new Date(), parseSimpleDate(dob)) < age) {
    throw Error('Sorry, you are not eligible to create an account');
  }
}

export function todayOrAfter(date) {
  if (isBefore(parseSimpleDate(date), startOfToday())) {
    return 'Date must not be in the past';
  }
}

export function withinSixMonths(date) {
  if (differenceInMonths(parseSimpleDate(date), new Date()) >= 6) {
    return 'Date must be within 6 months of today';
  }
}

export function minLength(min) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`;
    }
  };
}

export const useMinLength = (min) => {
  const { t } = useTranslation('FormValidation');
  const validator = useMemo(() => minLength(min), [min]);
  const errorMessage = t(
    'minLength.error',
    'Must be at least {{min}} characters',
    { min }
  );

  return useOverridenValidator(validator, errorMessage);
};

export function maxLength(max) {
  return (value) => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${max} characters`;
    }
  };
}

export const useMaxLength = (max) => {
  const { t } = useTranslation('FormValidation');
  const validator = useMemo(() => maxLength(max), [max]);
  const errorMessage = t(
    'maxLength.error',
    'Must be no more than {{max}} characters',
    { max }
  );

  return useOverridenValidator(validator, errorMessage);
};

// This helpful hook can be used to validate maxLength for multiple fields in a single component
export const useMaxLengthValidatorCreator = () => {
  const { t } = useTranslation('FormValidation');
  return (max) => {
    const errorMessage = t(
      'maxLength.errors',
      'Must be no more than {{max}} characters',
      { max }
    );

    return (value) => {
      if (!isEmpty(value) && value.length > max) {
        return errorMessage;
      }
    };
  };
};

export function notGreaterThan(max) {
  return (value) => {
    if (!isEmpty(value) && !Number.isNaN(value) && value > max) {
      return `Must not be greater than ${max}`;
    }
  };
}

export function notLessThan(min) {
  return (value) => {
    if (!isEmpty(value) && !Number.isNaN(value) && value < min) {
      return `Must not be less than ${min}`;
    }
  };
}

export function integer(value) {
  if (!isEmpty(value) && !Number.isInteger(Number(value))) {
    return 'Must be a whole number';
  }
}

export const useInteger = () => {
  const { t } = useTranslation('FormValidation');
  const errorMessage = t('integer.error', 'Must be a whole number');
  return useOverridenValidator(integer, errorMessage);
};

export function number(value) {
  if (!isEmpty(value) && Number.isNaN(value)) {
    return 'Must be a number';
  }
}

export function rating(value) {
  if (
    !isEmpty(value) &&
    value !== '1' &&
    value !== '2' &&
    value !== '3' &&
    value !== '4' &&
    value !== '5'
  ) {
    return 'Rating must be either 1, 2, 3, 4, or 5';
  }
}

export function currency(value) {
  if (
    !isEmpty(value) &&
    !/^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/i.test(value)
  ) {
    return 'Invalid currency';
  }
}

export function username(value) {
  if (
    !isEmpty(value) &&
    !/^[a-zA-Z0-9]([._](?![._])|[a-zA-Z0-9]){0,28}[a-zA-Z0-9]$/i.test(value)
  ) {
    return 'Usernames can only contain letters, numbers, underscores and periods';
  }
}

export function validateHomePagePath(value) {
  const isNotUserPath = !isEmpty(value) && !/^\/[\w.]+$/.test(value);
  const isNotCategoryPath = !value.startsWith('/c/');

  if (isNotUserPath && isNotCategoryPath) {
    return 'Must be a valid path (should start with "/" or "/c/" if a category)';
  }
}

export function card(value) {
  if (
    !isEmpty(value) &&
    !/^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/i.test(
      value.replace(/\s/g, '')
    )
  ) {
    return 'Must be a valid card number';
  }
}

export function oneOf(enumeration) {
  return (value) => {
    if (enumeration.indexOf(value) === -1) {
      return `Must be one of: ${enumeration.join(', ')}`;
    }
  };
}

export function noWhiteSpace(value) {
  if (!isEmpty(value) && value.indexOf(' ') >= 0) {
    return 'Must not contain whitespace';
  }
}

export function checked(value) {
  if (!value) {
    return 'Required';
  }
}

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value !== data[field]) {
        return 'Do not match';
      }
    }
  };
}

export function zipcode(value) {
  // Zipcode regex from https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s14.html
  if (!isEmpty(value) && !/^[0-9]{5}(?:-[0-9]{4})?$/.test(value)) {
    return 'Must be a valid zip code';
  }
}

// async validations
export function checkExistingUser(body) {
  // const em = body;
  return fetch('/api/user/validation', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data.status === 403) {
        const errors = { email: data.message };
        throw errors;
      }
    });
}

export function createValidator(rules) {
  return (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
}

function validYearInput(date) {
  const year = getYear(date);
  return year.toString().length === 4;
}

export function validDate(date) {
  const parsedDate = parseSimpleDate(date);
  if (!validYearInput(parsedDate) || !isValid(parsedDate)) {
    return 'Must be a valid date';
  }
}

export const useValidDate = () => {
  const { t } = useTranslation('FormValidation');
  const errorMessage = t('date.error', 'Must be a valid date');
  return useOverridenValidator(validDate, errorMessage);
};

export function validDateOmitYear(date) {
  // Include hardcoded year to allow leap year
  if (date && !isValid(parse(`${date} / 1904`, 'MM / dd / yyyy', new Date()))) {
    return 'Must be a valid date. (MM / DD)';
  }
}

export const useValidDateOmitYear = () => {
  const { t } = useTranslation('FormValidation');
  const errorMessage = t(
    'dateOmitYear.error',
    'Must be a valid date. (MM / DD)'
  );
  return useOverridenValidator(validDateOmitYear, errorMessage);
};

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

// returns any error message to display to user when validating phone number
export const validatePhone = (value) => {
  if (value && value.replace(/[^\d]/g, '').length < 10) {
    return 'Must be a valid phone number.';
  }
  return null;
};

export const useValidatePhone = () => {
  const { t } = useTranslation('FormValidation');
  const errorMessage = t('phoneNumber.error', 'Must be a valid phone number.');
  return useOverridenValidator(validatePhone, errorMessage);
};

export const onlyLetters = (value) => {
  if (!/^[A-Z]+$/i.test(value?.trim())) {
    return 'Only letters allowed';
  }
};

export const onlyLettersCommasOrSpaces = (value) => {
  if (!/^[A-Za-z,\s]*$/.test(value?.trim())) {
    return 'Only letters allowed';
  }
};

export const getCartValidationResponse = (response) => {
  const validationResponse = response?.validationResponse;
  if (!validationResponse) {
    return;
  }
  const changes = [
    ...validationResponse?.changes?.cart,
    ...validationResponse?.changes?.lineItem,
  ];
  const userMessage =
    changes?.[0]?.userMessage ??
    'Cart state has changed. Please re-review before proceeding.';

  return {
    error: new Error(userMessage),
    ...validationResponse,
  };
};

/* eslint-enable consistent-return */
