import React from 'react';
import { X, ChevronRight, ChevronLeft, ArrowRight, Globe } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { MenuItems } from 'state/modules/navbar/types';
import { NavBarCategoryItem } from 'types/src/api/endpoints/explore/categories';
import {
  LanguageRegionCurrencySelectorModal,
  InnerComponentProps,
} from 'components/LanguageSelector/LanguageRegionCurrency';
import { Box, Button, Text } from 'domains/web/components';
import {
  Wrapper,
  Container,
  Menu,
  Header,
  ListHeader,
  List,
  ListItem,
  SubMenu,
  Title,
  BackButton,
  Content,
  SubList,
  Footer,
  CloseButton,
  NavigationLink,
  DarkenOverlay,
  DisableBodyScroll,
  LogoContainer,
  ViewAllItem,
  ArrowIconWrapper,
} from './Styled';
import { Logo } from '../Logo';
import { trackItemClick, trackItemClickWithoutTargetDtl } from '../analytics';

interface OwnProps {
  categories: NavBarCategoryItem[];
  handleDrawerMenuToggle: () => void;
  handleLogin: () => void;
  isUserLoggedIn: boolean;
  isVisible: boolean;
  shouldDisplayMerchLink: boolean;
  shouldDisplayKidsLink: boolean;
}

const LanguageSelectorComponent: React.FC<InnerComponentProps> = ({
  language,
  region,
  currency,
}) => (
  <Button variant="secondary" size="small">
    <Globe />
    <Box display="flex" gap={1}>
      {language}
      <span>{'|'}</span>
      {region}
      <span>{'|'}</span>
      {currency}
    </Box>
  </Button>
);

const NavDrawer = ({
  categories,
  handleDrawerMenuToggle,
  handleLogin,
  isUserLoggedIn,
  isVisible,
  shouldDisplayMerchLink,
  shouldDisplayKidsLink,
}: OwnProps) => {
  const isLangSelectorEnabled = true;
  const [l1CategoryExpanded, setL1CategoryExpanded] = React.useState(false);
  const [activeCategory, setActiveCategory] = React.useState(null);
  const { t } = useTranslation('NavDrawer');

  const resetActiveCategory = React.useCallback(() => {
    setActiveCategory(null);
  }, []);

  const closeAndResetDrawerState = React.useCallback(() => {
    handleDrawerMenuToggle();
    resetActiveCategory();
  }, [handleDrawerMenuToggle]);

  const handleL1CategoryPress = React.useCallback((category) => {
    trackItemClickWithoutTargetDtl(`item-navdrawer-${category.slug}`);
    setActiveCategory(category);
  }, []);

  const handleLoginButtonPress = React.useCallback(() => {
    handleLogin();
    closeAndResetDrawerState();
  }, [handleLogin, closeAndResetDrawerState]);

  const handleCloseButtonPress = React.useCallback(() => {
    trackItemClickWithoutTargetDtl(MenuItems.DrawerMenuClose);
    closeAndResetDrawerState();
  }, [closeAndResetDrawerState]);

  const handleBackButtonPress = React.useCallback(() => {
    trackItemClickWithoutTargetDtl(MenuItems.DrawerMenuBack);
    resetActiveCategory();
  }, [resetActiveCategory]);

  const handleNavigationButtonPress = React.useCallback(
    (page, path, label) => {
      trackItemClick(page, path, label);
      closeAndResetDrawerState();
    },
    [closeAndResetDrawerState]
  );

  const renderL2SubMenu = () => {
    return activeCategory ? (
      <SubMenu>
        <Menu isActive={activeCategory}>
          <Header>
            <Title>
              <BackButton onClick={handleBackButtonPress}>
                <ChevronLeft size={24} />
                <Text>{activeCategory.name}</Text>
              </BackButton>
            </Title>
            <CloseButton onClick={handleCloseButtonPress}>
              <X size={24} />
            </CloseButton>
          </Header>
          <Content>
            <SubList>
              {activeCategory.children.map((category) => (
                <NavigationLink
                  key={category.slug}
                  to={`/browse/${category.materializedPath}`}
                  onClick={() =>
                    handleNavigationButtonPress(
                      'Category-Browse',
                      `/browse/${category.materializedPath}`,
                      `item-navdrawer-${category.slug}`
                    )
                  }
                >
                  <ListItem>
                    <Text>{category.name}</Text>
                  </ListItem>
                </NavigationLink>
              ))}
              <NavigationLink
                to={`/browse/${activeCategory.materializedPath}`}
                onClick={closeAndResetDrawerState}
              >
                <ListItem>
                  <ViewAllItem>
                    <Text as="h3" variant="title_s" fontWeight="bold">
                      {t('viewAll', 'View all')}
                    </Text>
                    <ArrowIconWrapper>
                      <ArrowRight size={16} strokeWidth={4} />
                    </ArrowIconWrapper>
                  </ViewAllItem>
                </ListItem>
              </NavigationLink>
            </SubList>
          </Content>
        </Menu>
      </SubMenu>
    ) : null;
  };

  const shouldRenderFooter = isVisible && !isUserLoggedIn;

  return (
    <>
      {isVisible && (
        <>
          <DisableBodyScroll />
          <DarkenOverlay onClick={handleCloseButtonPress} />
        </>
      )}
      <Wrapper isVisible={isVisible} data-testid="nav-drawer">
        <Container
          isMainMenuActive={!activeCategory}
          shouldRenderFooter={shouldRenderFooter}
        >
          <Menu isActive={!activeCategory}>
            <Header>
              {isVisible && (
                <LogoContainer onClick={closeAndResetDrawerState}>
                  <Logo />
                </LogoContainer>
              )}
              <CloseButton onClick={handleCloseButtonPress}>
                <X size={24} />
              </CloseButton>
            </Header>
            <Content>
              <NavigationLink
                as="a"
                onClick={() => {
                  setL1CategoryExpanded(!l1CategoryExpanded);
                }}
                aria-haspopup="true"
              >
                <ListItem>
                  <Text>{t('categories', 'Categories')}</Text>
                  <Box
                    transition="default"
                    style={{
                      transform: l1CategoryExpanded ? 'rotate(90deg)' : '',
                    }}
                  >
                    <ChevronRight size={24} />
                  </Box>
                </ListItem>
              </NavigationLink>
              <L1CategoryMenu
                expanded={l1CategoryExpanded}
                categories={categories}
                onClickItem={handleL1CategoryPress}
              >
                <NavigationLink
                  to="/categories"
                  onClick={() =>
                    handleNavigationButtonPress(
                      'All-categories',
                      '/categories',
                      `item-navdrawer-${MenuItems.Categories}`
                    )
                  }
                >
                  <ListHeader>
                    <Text>{t('allCategories', 'All categories')}</Text>
                  </ListHeader>
                </NavigationLink>
              </L1CategoryMenu>
              {shouldDisplayMerchLink && (
                <NavigationLink
                  to=""
                  href="https://merch.cameo.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    handleNavigationButtonPress(
                      'Merch',
                      'https://merch.cameo.com',
                      `item-navdrawer-${MenuItems.Merch}`
                    )
                  }
                >
                  <ListHeader>
                    <Text>{t('merch', 'Merch')}</Text>
                  </ListHeader>
                </NavigationLink>
              )}
              {shouldDisplayKidsLink && (
                <NavigationLink
                  to="/kids"
                  onClick={() =>
                    handleNavigationButtonPress(
                      'Kids',
                      '/kids',
                      `item-navdrawer-${MenuItems.Kids}`
                    )
                  }
                >
                  <ListHeader>
                    <Text>{t('forKids', 'For kids')}</Text>
                  </ListHeader>
                </NavigationLink>
              )}
              <NavigationLink
                to="/business"
                onClick={() =>
                  handleNavigationButtonPress(
                    'Business',
                    '/business',
                    `item-navdrawer-${MenuItems.Business}`
                  )
                }
              >
                <ListHeader>
                  <Text>{t('forBusiness', 'For business')}</Text>
                </ListHeader>
              </NavigationLink>
              <NavigationLink
                to="/about"
                onClick={() =>
                  handleNavigationButtonPress(
                    'About',
                    '/about',
                    `item-navdrawer-${MenuItems.About}`
                  )
                }
              >
                <ListHeader>
                  <Text>{t('howItWorks', 'How it works')}</Text>
                </ListHeader>
              </NavigationLink>
              <NavigationLink
                to="/enroll"
                onClick={() =>
                  handleNavigationButtonPress(
                    'enroll-as-talent',
                    '/enroll',
                    `item-navdrawer-${MenuItems.Enroll}`
                  )
                }
              >
                <ListHeader>
                  <Text>{t('enroll-as-talent', 'Join as talent')}</Text>
                </ListHeader>
              </NavigationLink>
              {isLangSelectorEnabled && (
                <Box mt="10%">
                  <LanguageRegionCurrencySelectorModal
                    innerComponent={LanguageSelectorComponent}
                  />
                </Box>
              )}
            </Content>
          </Menu>
          {renderL2SubMenu()}
        </Container>
        {shouldRenderFooter && (
          <Footer>
            <Button
              variant="secondary"
              onClick={handleLoginButtonPress}
              size="medium"
            >
              {t('login', 'Log in')}
            </Button>
          </Footer>
        )}
      </Wrapper>
    </>
  );
};

const L1CategoryMenu = ({
  children,
  expanded,
  categories,
  onClickItem,
}: {
  children?: React.ReactNode;
  expanded?: boolean;
  categories: NavBarCategoryItem[];
  onClickItem: (category: NavBarCategoryItem) => void;
}) => {
  return expanded && categories?.length ? (
    <List
      aria-hidden={!expanded}
      aria-expanded={Boolean(expanded)}
      aria-label="Main categories"
    >
      {children}
      {categories.map((category) => (
        <ListItem
          key={category.slug}
          onClick={onClickItem.bind(null, category)}
        >
          <Text>{category.name}</Text>
          <ChevronRight size={24} />
        </ListItem>
      ))}
    </List>
  ) : null;
};

export default NavDrawer;
