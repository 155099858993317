import { config } from 'cameoConfig';

export const getSearchUrlBase = (
  isAlgoliaEnabled = false,
  isC4BAlgoliaEnabled = false
) => {
  if (isC4BAlgoliaEnabled) {
    return '/v2/search/business';
  }

  return isAlgoliaEnabled ? '/v2/search' : '/search';
};

export const isAlgoliaEnabled = (): boolean => {
  return config.envSpecificFeatureOverride.algolia;
};

export const useAlgoliaEnabled = (): boolean => {
  return config.envSpecificFeatureOverride.algolia;
};

export const isTalentNeuralSearchEnabled = (): boolean => {
  // TODO Optimizely to eppo. Feature is currently off and will re-add when we're ready to launch
  return false;
};

export const useTalentNeuralSearchEnabled = (): boolean => {
  // TODO Optimizely to eppo. Feature is currently off and will re-add when we're ready to launch
  return false;
};

export const isC4BAlgoliaEnabled = (): boolean => {
  return config.envSpecificFeatureOverride.algolia;
};

export const useC4BAlgoliaEnabled = (): boolean => {
  return config.envSpecificFeatureOverride.algolia;
};
