import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { AuthService } from 'services';
import authActionCreatorFactory from '../authActionCreatorFactory';
import { ActionError } from '../types';

const asyncActionCreator = authActionCreatorFactory<void>();

const logout = asyncActionCreator<void, void, ActionError>(
  'LOGOUT',
  AuthService.logout
);

export const service = thunkToAction(logout);

export default logout;
