import actionCreatorFactory from 'typescript-fsa';
import { LayoutsResponse } from 'types/src/api/endpoints/explore/endpoints/layouts';

const actionCreator = actionCreatorFactory('LAYOUTS');

export const GetLayout = actionCreator.async<void, LayoutsResponse, any>(
  'cameo/layouts/GET_LAYOUT'
);

export const GetShelf = actionCreator.async<
  void,
  LayoutsResponse['pageContent']['items'][number],
  any
>('cameo/layouts/GET_SHELF');

export const ResetLayout = actionCreator<void>('cameo/layouts/RESET_LAYOUT');

export function getLayout(location: string, identifier?: string) {
  return {
    types: [GetLayout.started.type, GetLayout.done.type, GetLayout.failed.type],
    promise: (client) => {
      const identifierString = identifier
        ? `&identifier=${identifier.split('?')[0]}`
        : '';
      return client.get(
        `/explore/layouts?location=${location}${identifierString}`
      );
    },
  };
}

export function getShelf(shelfIdentifier: string) {
  if (!shelfIdentifier) {
    return {
      type: GetShelf.failed.type,
      error: {
        error: 'Something went wrong',
      },
    };
  }

  return {
    types: [GetShelf.started.type, GetShelf.done.type, GetShelf.failed.type],
    promise: (client) => {
      return client.get(`/shelves/${shelfIdentifier}`);
    },
  };
}
