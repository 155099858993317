import * as tokens from "./tokens";
import * as variants from "./variants";
import * as styles from "./styles";
import { deepFreeze } from "./utils";

const theme = deepFreeze({
  ...tokens,
  ...variants,
  ...styles,
});

export type CameoTheme = typeof theme;

export default theme;
