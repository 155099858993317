/** @deprecated */
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import loginWithPassword from 'services/auth/utils/loginWithPassword';
import authActionCreatorFactory from '../utils/authActionCreatorFactory';
import { ActionError } from '../types';
import { LoginParams, LoginResponse } from './types';

const asyncActionCreator = authActionCreatorFactory<LoginResponse>();

export const legacyLogin = asyncActionCreator<
  LoginParams,
  LoginResponse,
  ActionError
>('LOGIN', loginWithPassword);

export const service = ({
  email,
  password,
  briefcaseCustomer,
}: {
  email: string;
  password: string;
  briefcaseCustomer?: boolean;
}) => thunkToAction(legacyLogin)({ email, password, briefcaseCustomer });

export default legacyLogin;
