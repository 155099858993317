const SPEED = 216; // in ms

export enum TransitionSpeed {
  FAST = SPEED / 2,
  NORMAL = SPEED,
  SLOW = SPEED * 2,
  TURTLE = SPEED * 4,
}

export const animations = {
  transition: TransitionSpeed,
};
