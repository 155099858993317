import { reducer as getScreenSize } from './getScreenSize';

const initialState = {
  screenHeight: 0,
  screenWidth: 0,
  screenSizeKnown: false,
  breakpoint: '',
};

const reducerObject = {
  ...getScreenSize,
};

/**
 * Flag to track the first render on the client.
 * @type {boolean}
 */
let firstRender = true;

export default function reducer(state = initialState, action = {}) {
  const validAction = !!reducerObject[action.type];
  const isClient = typeof window !== 'undefined';

  if (validAction) {
    const actionResult = reducerObject[action.type](state, action);
    /**
     * Log if screen size is different from SSR.
     */
    if (firstRender && isClient) {
      firstRender = false;
      const { breakpoint } = state;
      const newBreakpoint = actionResult.breakpoint;

      if (breakpoint !== newBreakpoint) {
        // Ignore console.error in the desktop vs large-desktop case for now
        // large-desktop will be removed in the near future
        if (breakpoint !== 'desktop' && newBreakpoint !== 'large-desktop') {
          console.error(
            'SSR breakpoint mismatch',
            `SSR: ${breakpoint}`,
            `Client: ${newBreakpoint}`
          );
        }
      }
    }

    return actionResult;
  }

  return state;
}
