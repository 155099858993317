import { reducerWithInitialState } from 'typescript-fsa-reducers';
import analytics from 'analytics';

import logout from './actions';
import { LogoutState, INITIAL_STATE } from './types';

const reducer = reducerWithInitialState<LogoutState>(INITIAL_STATE)
  .case(logout.async.started, (state) => ({
    ...state,
    loggingOut: true,
  }))
  .case(logout.async.done, (state) => {
    analytics.unsetUser();
    return {
      ...state,
      loggingOut: false,
      loggedInUser: null,
    };
  })
  .case(logout.async.failed, (state, payload) => ({
    ...state,
    loggingOut: false,
    logoutError: payload.error.message ?? 'Something went wrong.',
  }));

export default reducer.build();
