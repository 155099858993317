export enum TaxType {
  SalesTax = 'Sales',
  VAT = 'VAT',
}

export enum ModificationType {
  AddOn = 'addOn',
  Discount = 'discount',
}

export enum ValidateEntityType {
  Cart = 'cart',
  LineItem = 'lineItem',
}

export enum ValidationChangeType {
  // Cart Level
  AppliedCredits = 'appliedCredits',
  CurrencyConversionRate = 'currencyConversionRate',
  // Line Item Level
  ProductUnavailable = 'productUnavailable',
  ProductPrice = 'productPrice',
  ProductPriceModifierUnavailable = 'productPriceModifierUnavailable',
}

export enum CartReviewStatus {
  underReview = 'underReview',
  passed = 'passed',
  failed = 'failed',
}
