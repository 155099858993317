import actionCreatorFactory from 'typescript-fsa';

interface State {
  isLoading: boolean;
  geo: object;
}

const actionCreator = actionCreatorFactory('GET_USER_GEO_DATA');
export const GetUserGeoData = actionCreator.async<string, any, any>(
  'cameo/users/GET_USER_GEO_DATA'
);

export const reducer = {
  [GetUserGeoData.started.type]: (state: State) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [GetUserGeoData.done.type]: (state: State, action) => {
    return {
      ...state,
      isLoading: false,
      geo: action.payload.result,
    };
  },
  [GetUserGeoData.failed.type]: (state: State) => {
    return {
      ...state,
      isLoading: false,
    };
  },
};

export function getUserGeoData() {
  const endpoint = '/user/geo';
  return {
    types: [GetUserGeoData.started, GetUserGeoData.done, GetUserGeoData.failed],
    promise: (client) => client.get(endpoint),
  };
}
