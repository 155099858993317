// AvalaraCode are used with our sales tax API to calculate sales tax

export enum AvalaraCode {
  brandCameo = 'BCAM',
  cameoCall = 'CALL',
  cameoForBusiness = 'CAMB',
  cameoLive = 'CAML',
  coreCameo = 'CCAM',
  brandPartnership = 'BPTN',
  directMessage = 'DMSG',
  fanClub = 'FANC',
  giftCard = 'GIFT',
  serviceFee = 'SFEE',
  sticker = 'STIC',
  tip = 'TIPS',
  digitalPostCard = 'DPCD',
  postCard = 'PGDS',
  thankYouNote = 'TYNM', // TODO: get this code into Avalara
}
