import { Action } from 'typescript-fsa';
import { INITIAL_STATE } from './config';
import { BlockUserState } from './types';
import {
  reducer as loadBlockedReducer,
  LoadBlockedFromTalentIds,
} from './loadBlockedFromTalentIds';
import {
  reducer as resetBlockedReducer,
  ResetBlockedFromTalentIds,
} from './resetBlockedFromTalentIds';

export const reducer = (
  state: BlockUserState = INITIAL_STATE,
  action: Action<any>
): BlockUserState => {
  switch (action.type) {
    case LoadBlockedFromTalentIds.started.type:
    case LoadBlockedFromTalentIds.done.type:
    case LoadBlockedFromTalentIds.failed.type:
      return loadBlockedReducer(state, action);
    case ResetBlockedFromTalentIds.type:
      return resetBlockedReducer(state, action);
    default:
      return state;
  }
};
