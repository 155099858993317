import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors as navbarSelectors } from 'state/modules/navbar';
import { theme } from '@cameo/theme';

export const AppContainer = styled.div<{ blankBackground?: boolean }>`
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #101010 100%),
    linear-gradient(135deg, #bd00ff 0%, #ff3c26 100%);
  background-size: 100% 503px;
  background-repeat: no-repeat;
  background-color: ${theme.colors.background.level0};
  height: 100%;

  ${({ blankBackground }) =>
    blankBackground &&
    `
    background-image: unset;
    background-size: unset;
    background-repeat: unset;
  `}
`;

const AppContentComponent = styled.div<{
  isNavbarHidden: boolean;
  shouldMoveContentBehindNav: boolean;
  appNavHeight: number;
}>`
  position: relative;

  ${({ shouldMoveContentBehindNav, appNavHeight, isNavbarHidden }) =>
    `
      margin-top: ${shouldMoveContentBehindNav ? `-${appNavHeight}px` : '0px'};
      height: calc(100% - ${isNavbarHidden ? '0px' : `${appNavHeight}px`});
    `}
`;

export const AppContent = ({
  ...props
}: {
  shouldMoveContentBehindNav: boolean;
  appNavHeight: number;
}) => {
  const isNavbarHidden = useSelector(navbarSelectors.getIsNavbarHidden);
  return <AppContentComponent isNavbarHidden={isNavbarHidden} {...props} />;
};
