import actionCreatorFactory from 'typescript-fsa';

import { ORDER } from 'state/modules/orders';
import { PublicReview } from 'types/src/api/models/review';
import { ReviewScoreNames, ReviewScoreValues } from 'types/src/utils/enums';

const actionCreator = actionCreatorFactory('cameo/REVIEWS');

type EndpointError = {
  message: string;
  status: number;
};

export const LoadOrderSuccess = actionCreatorFactory()<{
  result: {
    review?: PublicReview;
  };
}>(ORDER.LOAD_ORDER_SUCCESS);

export const GetOrderDetailSuccess = actionCreatorFactory()<{
  result: {
    review?: PublicReview;
  };
}>(ORDER.DETAIL_SUCCESS);

export const LoadUserReviews = actionCreator.async<
  string,
  Array<PublicReview>,
  EndpointError
>('LOAD_USER_REVIEWS');

export function loadUserReviews(userId: string) {
  return {
    types: [
      LoadUserReviews.started.type,
      LoadUserReviews.done.type,
      LoadUserReviews.failed.type,
    ],
    promise: (client) => {
      return client.get(`/review/loadUser?userId=${userId}`);
    },
  };
}

type LoadUserReviewsPaginatedOptions = {
  userId: string;
  limit: number;
  offset: number;
  rating?: number;
};

export const LoadUserReviewsPaginated = actionCreator.async<
  LoadUserReviewsPaginatedOptions,
  Array<PublicReview>,
  EndpointError
>('LOAD_USER_REVIEWS_PAGINATED');

export function loadUserReviewsPaginated({
  userId,
  limit,
  offset,
  rating,
}: LoadUserReviewsPaginatedOptions) {
  return {
    types: [
      LoadUserReviewsPaginated.started.type,
      LoadUserReviewsPaginated.done.type,
      LoadUserReviewsPaginated.failed.type,
    ],
    promise: (client) => {
      let url = `/review/loadUser?userId=${userId}&limit=${limit}&offset=${offset}`;
      if (rating) url += `&rating=${rating}`;

      return client.get(url);
    },
  };
}

export type CreateReviewOptions = {
  title?: string;
  rating: number;
  tipAmount?: number;
  orderId: string;
  ownerId: string;
  orderAuthToken: string;
  tipId?: string;
  privateMessage?: string;
  scores?: Array<{
    name: ReviewScoreNames;
    score: ReviewScoreValues;
  }>;
};

export const CreateReview = actionCreator.async<
  CreateReviewOptions,
  PublicReview,
  EndpointError
>('CREATE_REVIEW');

export function createReview(reviewData: CreateReviewOptions) {
  return {
    types: [
      CreateReview.started.type,
      CreateReview.done.type,
      CreateReview.failed.type,
    ],
    promise: (client) => {
      return client.post('/review/create', {
        data: reviewData,
      });
    },
  };
}

export type EditReviewOptions = {
  reviewId: string;
  changes: {
    title?: string;
    rating?: number;
    privateMessage?: string;
    scores?: Array<{
      name: ReviewScoreNames;
      score: ReviewScoreValues;
    }>;
  };
  validateAsUser?: boolean;
};

export const EditReview = actionCreator.async<
  EditReviewOptions,
  PublicReview,
  EndpointError
>('EDIT_REVIEW');

export function editReview({
  reviewId,
  changes,
  validateAsUser = false,
}: EditReviewOptions) {
  let endpoint = '/review/edit';

  if (validateAsUser) {
    endpoint += `?validateAsUser=true`;
  }

  return {
    types: [
      EditReview.started.type,
      EditReview.done.type,
      EditReview.failed.type,
    ],
    promise: (client) => {
      return client.post(endpoint, {
        data: {
          reviewId,
          changes,
        },
      });
    },
  };
}

export const GetTopReviews = actionCreator.async<
  string,
  { reviews: Array<PublicReview> },
  EndpointError
>('GET_TOP_REVIEWS');

export function getTopReviews(locator: string) {
  return {
    types: [
      GetTopReviews.started.type,
      GetTopReviews.done.type,
      GetTopReviews.failed.type,
    ],
    promise: (client) => {
      return client.get(`/review/${locator}/loadTopReviews`);
    },
  };
}

export const actions = {
  createReview,
  editReview,
  getTopReviews,
  loadUserReviews,
  loadUserReviewsPaginated,
};
