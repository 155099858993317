import find from 'lodash/find';
import Cameo from 'types';

export const getError = (state) => state.users.error;

export const getLoading = (state) => state.users.loading;
export const getLoadingUser = (state) => state.users.loadingUser;
export const getLoadingUserError = (state) => state.users.loadingUserError;

export const getNewUserCategories = (state) => state.users.newUserCategories;

export const getRelatedUsers = (state) => [...state.users.relatedUsers];

export const getSaveUserError = (state) => state.users.saveUserError;
export const getSavingUser = (state) => state.users.savingUser;

export const getUsers = (state) => ({ ...state.users.users });
export const getUserByUsername = (value) => (state) =>
  find(state.users.users, ({ username }) => username === value);

export const getSelectedUserId = (state) => state.users.selectedUserId;
export const getSelectedUser = (state): Cameo.Models.User.PublicUser | null => {
  const selectedUserId = getSelectedUserId(state);
  if (!selectedUserId) return null;

  const selectedUser = getUsers(state)[selectedUserId];
  if (!selectedUser) return null;

  return selectedUser;
};

// computed selectors

export const getUserGeoData = (state) => state.users.geo;
