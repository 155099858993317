import * as React from 'react';
import type { CustomIconProps } from './types';

function ImageIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 36 28" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 4C0.5 1.92893 2.17893 0.25 4.25 0.25L31.75 0.25C33.8211 0.25 35.5 1.92893 35.5 4L35.5 24C35.5 26.0711 33.8211 27.75 31.75 27.75L4.25 27.75C2.17893 27.75 0.5 26.0711 0.5 24L0.5 4ZM3 20.7678L3 24C3 24.6904 3.55964 25.25 4.25 25.25L31.75 25.25C32.4404 25.25 33 24.6904 33 24L33 20.7678L28.5178 16.2855C27.5415 15.3092 25.9585 15.3092 24.9822 16.2855L23.5178 17.75L25.1339 19.3661C25.622 19.8543 25.622 20.6457 25.1339 21.1339C24.6457 21.622 23.8543 21.622 23.3661 21.1339L14.7678 12.5355C13.7915 11.5592 12.2085 11.5592 11.2322 12.5355L3 20.7678ZM19.875 7.75C19.875 6.71447 20.7145 5.875 21.75 5.875C22.7855 5.875 23.625 6.71447 23.625 7.75C23.625 8.78553 22.7855 9.625 21.75 9.625C20.7145 9.625 19.875 8.78553 19.875 7.75Z"
        fill="#F2F1F3"
      />
    </svg>
  );
}

export default ImageIcon;
