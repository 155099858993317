import React from 'react';
import styled from 'styled-components';
import LazyImage from 'design-system/Components/LazyImage';
import { formatMalformedImageUrl } from 'utils/getResizedImageUrl';

import { color, fonts, sizing } from '../../style';

export const Wrapper = styled.div<{
  size: number;
  backgroundColor?: string;
  onClick?: () => void;
}>`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ size }) => sizing(size * 2)};
  display: flex;
  height: ${({ size }) => sizing(size)};
  justify-content: center;
  overflow: hidden;
  width: ${({ size }) => sizing(size)};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};

  img {
    object-fit: cover;
    height: ${({ size }) => sizing(size)};
    width: ${({ size }) => sizing(size)};
  }

  span {
    ${fonts.boldText['200']};
    color: ${color.solids.WHITE};
    text-transform: uppercase;
  }
`;

Wrapper.defaultProps = {
  backgroundColor: color.solids.PRIMARY,
};

type Props = {
  name: string;
  imageUrl?: string;
  size?: number;
  onClick?: () => void;
  altText?: string;
};

type LazyAvatarProps = {
  name: string;
  imageUrl?: string;
  imageKey?: string;
  size?: number;
  backgroundColor?: string;
  altText?: string;
};

function getInitials(name: string) {
  const names = name.split(' ');
  let initials;
  if (names.length > 2) {
    initials = names.slice(0, 1).map((text) => text.substring(0, 1));
  } else {
    initials = names.map((text) => text.substring(0, 1));
  }
  return initials.join('');
}

export const Avatar = ({
  name = '',
  imageUrl,
  size = 40,
  onClick,
  altText,
}: Props) => {
  const alt = altText ?? `Avatar of ${name}`;
  return (
    <Wrapper
      data-testid={`Avatar-${name.split(' ').join('-')}`}
      size={size}
      onClick={onClick}
    >
      {imageUrl && (
        <img src={formatMalformedImageUrl(size, size, imageUrl)} alt={alt} />
      )}
      {!imageUrl && <span>{getInitials(name)}</span>}
    </Wrapper>
  );
};

export const LazyAvatar = ({
  name = '',
  imageUrl,
  imageKey,
  size = 40,
  backgroundColor = color.solids.PRIMARY,
  altText,
}: LazyAvatarProps) => {
  const hasImage = imageUrl || imageKey;
  const alt = altText ?? `Avatar of ${name}`;

  return (
    <Wrapper
      data-testid={`Avatar-${name.split(' ').join('-')}`}
      size={size}
      backgroundColor={backgroundColor}
    >
      {hasImage && (
        <LazyImage
          imageKey={imageKey}
          imageSrc={imageUrl}
          width={size * 2}
          height={size * 2}
          alt={alt}
        />
      )}
      {!hasImage && <span>{getInitials(name)}</span>}
    </Wrapper>
  );
};
