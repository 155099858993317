import { ProductPriceModifiers } from "./pricing";

export enum OrderExpeditedType {
  '24_HOUR' = '24-hour',
  '72_HOUR' = '72-hour',
}

// Cannot use enum for this
export const CartModifierTitles: Record<
  ProductPriceModifiers.expedited,
  Record<OrderExpeditedType, string>
> = Object.freeze({
  [ProductPriceModifiers.expedited]: {
    [OrderExpeditedType['24_HOUR']]: 'Express (24 hour delivery)',
    [OrderExpeditedType['72_HOUR']]: 'Express (3 day delivery)',
  },
});
