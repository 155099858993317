import { State } from 'state/modules/reducer';
import { LayoutsState } from './reducer';

/*
  This is code to support an experiment, and should be removed after the experiment is complete.
 */
export const isTheNewHeroPresent = (state: State): boolean => {
  const firstRow = state.browse?.layouts?.layoutData?.pageContent?.items[0];

  if (firstRow?.type === 'collection') {
    const { displayType } = firstRow.container;

    return displayType === 'heroRow';
  }

  return false;
};

export const isSlideHeroTopOfLayout = (state: State): boolean => {
  const { pathname } = state.router.location;
  const isCurrentPageHomepageOrLanding =
    pathname === '/' || pathname?.includes('/l/');
  const firstRow = state.browse?.layouts?.layoutData?.pageContent?.items[0];
  return isCurrentPageHomepageOrLanding && firstRow?.type === 'slideHero';
};

export const getLayout = (state: State): LayoutsState => state.browse.layouts;

export const getPageData = (state: State): LayoutsState['layoutData'] =>
  state.browse.layouts.layoutData;

export const getShelves = (
  state: State
): LayoutsState['layoutData']['pageContent']['items'] => {
  return state.browse.layouts.layoutError
    ? []
    : state.browse.layouts.layoutData.pageContent.items;
};

export const getShelf =
  (shelfIdentifier: string) =>
  (state: State): LayoutsState['layoutData']['pageContent']['items'][number] =>
    state.browse?.layouts?.layoutData?.pageContent?.items?.find((item) => {
      return item._id === shelfIdentifier || item.key === shelfIdentifier;
    });

export const getLayoutError = (state: State): LayoutsState['layoutError'] =>
  state.browse.layouts.layoutError;

export const getIsLoading = (state: State): LayoutsState['loadingLayout'] => {
  return state.browse.layouts.loadingLayout;
};
