// https://github.com/substack/deep-freeze
export function deepFreeze<T extends Record<string, any>>(obj: T): Readonly<T> {
  Object.freeze(obj);
  if (obj === undefined) {
    return obj;
  }

  Object.getOwnPropertyNames(obj).forEach(function (prop) {
    if (obj[prop] !== null
    && (typeof obj[prop] === "object" || typeof obj[prop] === "function")
    && !Object.isFrozen(obj[prop])) {
      deepFreeze(obj[prop]);
    }
  });

  return obj;
};
