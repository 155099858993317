import React, { useEffect, useCallback } from 'react';
import { Search as SearchIcon } from 'react-feather';
import { color } from 'design-system/style';
import ResultItem from './ResultItem';
import SearchNullState from '../SearchNullState';
import {
  BrowsePageUrlGenerator,
  ProfilePageUrlGenerator,
  SearchData,
  SearchItem,
  SearchPageUrlGenerator,
} from './types';

import { Name, Image, Wrapper, Query, Items, EmptyState } from './Styled';

type Props = {
  data: SearchData;
  generateBrowsePageUrl: BrowsePageUrlGenerator;
  generateProfilePageUrl: ProfilePageUrlGenerator;
  generateSearchPageUrl: SearchPageUrlGenerator;
  highlightedItemIndex: number;
  isEmpty: boolean;
  isErrored: boolean;
  isLoading: boolean;
  isOpen: boolean;
  minQueryLength: number;
  onItemClick: (item: SearchItem, listIndex: number, itemType: string) => void;
  onMouseEnter: (index: number) => void;
  onMouseLeave: () => void;
  onQueryClick: (query: string, listIndex: number) => void;
  closeSearchResultsDropdown?: () => void;
  query: string;
};

export const SearchResults = ({
  data = [],
  generateBrowsePageUrl,
  generateProfilePageUrl,
  generateSearchPageUrl,
  highlightedItemIndex,
  isEmpty,
  isErrored,
  isLoading,
  isOpen,
  minQueryLength = 3,
  onItemClick,
  onMouseEnter,
  onMouseLeave,
  onQueryClick,
  query,
  closeSearchResultsDropdown,
}: Props) => {
  const resultsToRender = data.filter((item) => item?.data?.name);
  const shouldDisplayErrorMessage = isErrored;
  const shouldDisplayNoResults =
    !isErrored && !isLoading && isEmpty && query.length >= minQueryLength;
  const shouldDisplayShortQuery = isEmpty && query.length > 0;
  const isFirstItemTitle =
    !shouldDisplayErrorMessage &&
    !shouldDisplayNoResults &&
    !shouldDisplayShortQuery &&
    resultsToRender &&
    resultsToRender.length &&
    resultsToRender[0].type === 'title';

  // Add additional bottom padding based on the y position of the SearchResult list
  // to fix issue of browser UI overlay on top of last search result on mobile viewports.
  // Dynamically determined the y position to counter additional the promotional banner above header.
  // See https://cameofameo.atlassian.net/browse/DISCO-2313.
  const itemsListEl = React.useRef(null);
  const [itemsListYPosition, setItemsListYPosition] = React.useState(0);
  const paddingBottomFallback = 185;

  useEffect(() => {
    if (itemsListEl) {
      setItemsListYPosition(
        itemsListEl?.current?.getBoundingClientRect()?.y ??
          paddingBottomFallback
      );
    }
  }, []);

  const items = resultsToRender.map((item: SearchItem, index: number) => {
    return (
      <ResultItem
        generateProfilePageUrl={generateProfilePageUrl}
        generateBrowsePageUrl={generateBrowsePageUrl}
        highlighted={highlightedItemIndex === index + 1}
        index={index}
        item={item}
        key={`${item.group}_${item._id}`}
        onItemClick={onItemClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        query={query}
      />
    );
  });

  const onClick = useCallback(() => {
    onQueryClick(query, items?.length ?? 0);
  }, [items]);

  const hasQuery = query.length > 0;

  return (
    <Wrapper isOpen={isOpen} data-testid="SearchResults">
      <Items
        ref={itemsListEl}
        yposition={itemsListYPosition}
        isFirstItemTitle={isFirstItemTitle}
      >
        {shouldDisplayErrorMessage && (
          <EmptyState>
            {/* eslint-disable-next-line react/jsx-no-literals */}
            <Name>Something went wrong. Try again</Name>
          </EmptyState>
        )}
        {shouldDisplayNoResults && (
          <EmptyState>
            {/* eslint-disable-next-line react/jsx-no-literals */}
            <Name>No search results found.</Name>
          </EmptyState>
        )}
        {shouldDisplayShortQuery && (
          <Query data-testid="SearchQuery" disabled>
            <Image as="div">
              <SearchIcon color={color.solids.MUTED} size={16} />
            </Image>
            <Name>{`"${query}"`}</Name>
          </Query>
        )}
        <SearchNullState
          isVisible={isOpen && !hasQuery}
          closeSearchResultsDropdown={closeSearchResultsDropdown}
        />
        {items}
        {!isEmpty && hasQuery && (
          <Query
            data-testid="SearchQuery"
            to={generateSearchPageUrl(query)}
            onClick={onClick}
          >
            <Image as="div">
              <SearchIcon color={color.solids.MUTED} size={16} />
            </Image>
            <Name>{`"${query}"`}</Name>
          </Query>
        )}
      </Items>
    </Wrapper>
  );
};

export default SearchResults;
