export const LOAD_RELATED_USERS = 'cameo/users/loadRelatedUsers/start';
export const LOAD_RELATED_USERS_SUCCESS =
  'cameo/users/loadRelatedUsers/success';
export const LOAD_RELATED_USERS_FAIL = 'cameo/users/loadRelatedUsers/fail';

export const reducer = {
  [LOAD_RELATED_USERS]: (state) => {
    return state;
  },
  [LOAD_RELATED_USERS_SUCCESS]: (state, action) => {
    const returnedUsers = {};
    const relatedUsers = [];
    action.result.forEach((user) => {
      returnedUsers[user._id] = user;
      relatedUsers.push(user._id);
    });
    return {
      ...state,
      relatedUsers,
      users: {
        ...state.users,
        ...returnedUsers,
      },
    };
  },
  [LOAD_RELATED_USERS_FAIL]: (state, action) => ({
    ...state,
    relatedUsers: [],
    // Fixes hangups on profile page 404's
    loadingUser: false,
    loadingUserError: action.error.message || action.error,
    //
  }),
};

export function actionCreator(userId) {
  return {
    types: [
      LOAD_RELATED_USERS,
      LOAD_RELATED_USERS_SUCCESS,
      LOAD_RELATED_USERS_FAIL,
    ],
    promise: (client) => client.get(`/user/${userId}/related`),
  };
}
