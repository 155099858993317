import analytics from 'analytics';

export const trackItemClick = (page: string, path: string, label: string) => {
  analytics.trackAction({
    eventName: 'Engage',
    eventDtl: {
      eventType: 'Click',
    },
    targetDtl: {
      targetType: page,
      targetPath: path,
    },
    objectDtl: {
      objectName: 'UserMenu',
      objectLabel: label,
    },
  });
};

export const trackCategoryClick = (path: string, label: string) => {
  analytics.trackAction({
    eventName: 'Engage',
    eventDtl: {
      eventType: 'Click',
    },
    targetDtl: {
      targetType: 'Category-Browse',
      targetPath: path,
    },
    objectDtl: {
      objectName: 'UserMenu',
      objectLabel: label,
      objectType: 'Dropdown',
      containerType: 'List',
    },
  });
};

export const trackItemHover = (label: string) => {
  analytics.trackAction({
    eventName: 'Engage',
    eventDtl: {
      eventType: 'Hover',
    },
    objectDtl: {
      objectName: 'UserMenu',
      objectType: 'Button',
      objectLabel: label,
    },
  });
};

// Track click interaction without a target navigation destination (i.e. nav drawer)
export const trackItemClickWithoutTargetDtl = (label: string) => {
  analytics.trackAction({
    eventName: 'Engage',
    eventDtl: {
      eventType: 'Click',
    },
    objectDtl: {
      objectName: 'UserMenu',
      objectType: 'Button',
      objectLabel: label,
    },
  });
};

export const trackCameoCallsCalendarClick = () => {
  analytics.trackAction({
    eventName: 'Engage',
    eventDtl: {
      eventType: 'Click',
    },
    objectDtl: {
      objectName: 'CameoCallsCalendar',
      objectType: 'Button',
    },
    sourceDtl: {
      sourceType: 'Home',
      sourceKey: 'Screen',
    },
  });
};

export const trackSavedCTAClick = () => {
  analytics.trackAction({
    eventName: 'Engage',
    eventDtl: {
      eventType: 'Click',
    },
    objectDtl: {
      objectName: 'SavedPageCTA',
      objectType: 'Button',
    },
    sourceDtl: {
      sourceType: 'Home',
      sourceKey: 'Screen',
    },
  });
};

export const trackLogInClick = () => {
  analytics.trackAction({
    eventName: 'Engage',
    eventDtl: {
      eventType: 'Click',
    },
    objectDtl: {
      objectType: 'Button',
      objectName: 'LogIn',
      containerType: 'Navigation',
    },
  });
};
