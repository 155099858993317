/* eslint-disable id-length */
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import * as ModalBase from '@radix-ui/react-dialog';
import { rgba } from 'polished';

import { ThemeInterface } from 'domains/web/theme/types';
import { variant } from 'styled-system';
import { X } from 'react-feather';
import { Box, BoxProps } from '../Box';
import { DialogProps } from './types';
import { Text } from '../Text';
import * as Slots from './Slots';
import { Button } from '../Button';

const Core = ({
  title,
  children,
  onCancel,
  cancelLabel,
  onConfirm,
  confirmLabel,
  size = 'medium',
  ...rest
}: Pick<
  DialogProps,
  | 'cancelLabel'
  | 'confirmLabel'
  | 'onCancel'
  | 'onConfirm'
  | 'title'
  | 'children'
> &
  BoxProps) => {
  const hasBuiltInFooter = Boolean(cancelLabel || confirmLabel);

  return (
    <ModalMainBox
      backgroundColor="background.level2"
      borderRadius={3}
      boxShadow={3}
      maxWidth="100%"
      mx="auto"
      position="relative"
      {...rest}
      pb={{ _: 5, sm: 6 }}
      size={size}
    >
      <Box as="header" display="flex" position="relative" pt={7} px={0} pb={0}>
        {typeof title === 'string' ? (
          <Text variant="title_m" color="foreground.default" mb={1}>
            {title}
          </Text>
        ) : (
          title
        )}
      </Box>
      <Box
        color="foreground.subtle"
        hideScrollbar
        maxHeight="75vh"
        overflow="auto"
        position="relative"
      >
        {children}
        <Slots.Footer
          display={!hasBuiltInFooter ? 'none' : 'flex'}
          layout={
            cancelLabel && confirmLabel
              ? { _: 'column-reverse', sm: 'row' }
              : 'column'
          }
        >
          {cancelLabel ? (
            <ModalBase.Close asChild>
              <Button
                type="button"
                variant="secondary"
                mr={2}
                onClick={onCancel}
              >
                {cancelLabel}
              </Button>
            </ModalBase.Close>
          ) : null}
          {confirmLabel ? (
            <ModalBase.Close asChild>
              <Button type="button" variant="primary" onClick={onConfirm}>
                {confirmLabel}
              </Button>
            </ModalBase.Close>
          ) : null}
        </Slots.Footer>
      </Box>
      <ModalBase.Close asChild>
        <Box position="absolute" top={2} right={2}>
          <Button
            ml="auto"
            modifier="icon-only"
            size="small"
            variant="minimal"
            color="foreground.default"
          >
            <X color="currentColor" />
          </Button>
        </Box>
      </ModalBase.Close>
    </ModalMainBox>
  );
};

export const Modal = ({
  title,
  children,
  onCancel,
  cancelLabel,
  onConfirm,
  confirmLabel,
  defaultOpen,
  onOpenChange,
  open,
  ...rest
}: DialogProps &
  Pick<ModalBase.DialogProps, 'defaultOpen' | 'onOpenChange' | 'open'>) => {
  return (
    <ModalBase.Root
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      open={open}
    >
      <ModalBase.Portal>
        <Overlay>
          <Content>
            <Core
              title={title}
              onCancel={onCancel}
              onConfirm={onConfirm}
              confirmLabel={confirmLabel}
              cancelLabel={cancelLabel}
              {...rest}
            >
              {children}
            </Core>
          </Content>
        </Overlay>
      </ModalBase.Portal>
    </ModalBase.Root>
  );
};

const ModalMainBox = styled(Box)`
  ${variant({
    prop: 'size',
    variants: {
      medium: {
        width: '48rem',
        px: 7,
      },
      large: {
        width: '64rem',
        px: 9,
      },
      'x-large': {
        width: '80rem',
        px: 9,
      },
    },
  })};
`;

const AnimateContent = keyframes`
  from {
    opacity: 0;
    transform: translate(0%, 3%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(0%, 0%) scale(1);
  }
  `;

/**
 * https://www.radix-ui.com/docs/primitives/components/alert-dialog#content
 */
const Content = styled(ModalBase.Content)`
  ${() => {
    // const theme = t as unknown as ThemeInterface;
    return css``;
  }}
  animation: ${AnimateContent} 300ms cubic-bezier(0.16, 1, 0.3, 1);
`;

/**
 * This element is not visible, but it is used to capture the mouse events
 * https://www.radix-ui.com/docs/primitives/components/alert-dialog#overlay
 */
const Overlay = styled(ModalBase.Overlay)`
  ${({ theme: t }) => {
    const theme = t as unknown as ThemeInterface;

    return css`
      align-items: center;
      background-color: ${rgba(theme.colors.background.level1, 0.5)};
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 9999;
    `;
  }}
`;
