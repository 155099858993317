/**
 * This is the entry point on the client (browser) for the React app.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import UniversalCookie from 'universal-cookie';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory as createHistory } from 'history';
import analytics from 'analytics';
import App from 'containers/App/App';
import { config } from 'cameoConfig';
import { HelmetProvider } from 'react-helmet-async';
import { UserAgentData } from 'shared-utils/userAgent';
import { CAMEO_SESSION_IDENTIFIER_COOKIE_NAME } from 'shared-types/CameoSessionIdentifier';
import { loadableReady } from '@loadable/component';
import { ThemeProvider } from 'domains/web/theme/Provider';
import { selectors as authSelectors } from 'state/modules/auth';
import { I18nextProvider } from 'react-i18next';
import { disableReactDevTools } from 'utils/disableReactDevTools';
import * as trackingClients from 'analytics/clients';
import { dpOptimizelyIdManager } from 'analytics/utils/dpOptimizelyIdManager';
import { Paths as C4BRouteProps } from 'domains/c4b/pages/pages';

import i18n from 'i18next';
import { updateConversionRateForCurrency } from 'state/modules/clientInfo/i18n/actions';
import { dpLanguageManager } from 'analytics/utils/dpLanguageManager';

import { PlaybackProvider } from 'domains/consumer/components/VideoPlayer/PlaybackOrchestrator';

import algoliaAnalytics from 'analytics/clients/algoliaAnalytics';
import EppoRandomizationProvider from 'helpers/EppoRandomizationProvider';
import { ExperimentUserDataProvider } from 'helpers/ExperimentUserDataProvider';
import BrowserLanguageDetector from 'i18next-browser-languagedetector';
import { QueryClientProvider, Hydrate } from '@tanstack/react-query';
import { queryClient } from './api/utils/queryClient';
import { createStore } from './state/create';
import ApiClient from './helpers/ApiClient';
import routes, { arrayOfRoutes } from './routes';

import { initI18n } from './utils/i18n';
import { initEppo } from './utils/eppo';
import {
  CAMEO_CURRENCY_COOKIE,
  CAMEO_LANGUAGE_COOKIE,
  CAMEO_REGION_COOKIE,
} from './utils/i18n/constants';

const cookies = new UniversalCookie();

if (config.disableReactDevTools) {
  disableReactDevTools();
}

const client = new ApiClient();
const history = createHistory();
const dest = document.getElementById('content');

const savedCurrency = cookies.get(CAMEO_CURRENCY_COOKIE);
const savedRegion = cookies.get(CAMEO_REGION_COOKIE);
const savedLanguage = cookies.get(CAMEO_LANGUAGE_COOKIE);
const i18nClientInfoState = {
  clientInfo: {
    i18n: {
      regionPreferences: {
        ...window.__data?.clientInfo?.i18n?.regionPreferences,
        countryCode:
          savedRegion ||
          window.__data?.clientInfo?.i18n?.regionPreferences?.countryCode,
        currencyCode:
          savedCurrency ||
          window.__data?.clientInfo?.i18n?.regionPreferences?.currencyCode,
        languageCode:
          savedLanguage ||
          window.__data?.clientInfo?.i18n?.regionPreferences?.languageCode,
      },
    },
  },
};

// react-query Hydrate data
const reactQueryDehydratedState = window.__REACT_QUERY_STATE__;

// window.__data is injected by SSR and it's the final state object after all the intial data has been fetched
const store = createStore(history, client, {
  ...window.__data,
  ...i18nClientInfoState,
});

store.dispatch(
  updateConversionRateForCurrency(
    i18nClientInfoState.clientInfo.i18n.regionPreferences.currencyCode
  )
);

analytics.setRoutes([...Object.values(C4BRouteProps), ...arrayOfRoutes]);

// Universal cookie automatically parses JSON cookies.
const sessionIdentifier =
  cookies.get(CAMEO_SESSION_IDENTIFIER_COOKIE_NAME) || null;

const countryCode = window.__countryCode;

const languageCode = window.__languageCode;

/**
 * Get UserAgent data
 * Used by OptimizelyProvider
 */
const userAgentData = new UserAgentData().getClientSideUserAgentAttributes();

const userAttributes = {
  ...userAgentData,
  countryCode,
  languageCode,
};

dpOptimizelyIdManager.setOptimizelyId(
  sessionIdentifier ? sessionIdentifier.id : '[anonymous]'
);

const loggedInUser = authSelectors.getLoggedInUser(store.getState());
analytics.init(loggedInUser, trackingClients);
algoliaAnalytics.init();

i18n.on('languageChanged', (lng) => {
  dpLanguageManager.setLanguageCode(lng);
});

if (config.environment === 'development') {
  analytics.setStore(store);
}

function handleStoreInjection() {
  if (loggedInUser?.role === 'admin') {
    analytics.setStore(store);
  } else {
    analytics.setStore(null);
  }
}

handleStoreInjection();

const component = (
  <I18nextProvider i18n={i18n}>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={reactQueryDehydratedState}>
          <EppoRandomizationProvider>
            <ConnectedRouter history={history}>
              <ThemeProvider>
                <PlaybackProvider>
                  <ExperimentUserDataProvider
                    loggedInUser={loggedInUser}
                    sessionIdentifier={sessionIdentifier}
                    userAttributes={userAttributes}
                  >
                    <App>{routes}</App>
                  </ExperimentUserDataProvider>
                </PlaybackProvider>
              </ThemeProvider>
            </ConnectedRouter>
          </EppoRandomizationProvider>
        </Hydrate>
      </QueryClientProvider>
    </HelmetProvider>
  </I18nextProvider>
);

/**
 * Initialize i18n and Eppo. Hydrate the app once both are ready.
 */
Promise.allSettled([initI18n(BrowserLanguageDetector), initEppo()]).then(
  ([_, eppoResult]) => {
    if (eppoResult.status === 'rejected')
      console.log('Eppo error', eppoResult.reason);

    loadableReady(() => {
      ReactDOM.hydrate(
        <Provider store={store} key="provider">
          {component}
        </Provider>,
        dest
      );
    });
  }
);
