import { TalentMetadata } from 'types/src/api/endpoints/explore/endpoints/layouts';

export const useKidsTalent = ({ layoutData }) => {
  const talentMetadata = layoutData?.pageMetadata as TalentMetadata;
  return (
    talentMetadata?.breadcrumb?.findIndex(
      (value) => value.slug === 'animated-characters'
    ) > -1
  );
};
