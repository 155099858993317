/* eslint-disable no-restricted-syntax */
import * as FingerprintPRO from '@fingerprintjs/fingerprintjs-pro';
import { config } from 'cameoConfig';

// ANY means any action is allowed
// DELETE and DEL are the same, but we allow both for flexibility since ApiClient states DEL so want to be safe
type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'DEL' | 'HEAD' | 'OPTIONS' | 'ANY';

// list of regex to match the url against and the actions that are allowed for that url
const whitelistUrlsAndActions: [RegExp, Method[]][] = [
  // e.g. any url that starts with /api/cart/* except /api/cart/preview for any action
  [/api\/cart\/(?!preview)/, ['ANY']],
  // e.g. /api/chat/channel/:channelId/message for POST actions only
  [/api\/chat\/channel\/.*\/message/, ['POST']],
];

const EXPIRATION_IN_MINUTES = 30;

export async function getFingerprint({ url, method }: { url: string, method: Method }) {
  const useProVersion = config?.fingerprintPro?.enabled;
  let data;
  let provider;

  try {
    // If the url and method are not in the whitelist, return null. ANY means any action is allowed.
    if (!whitelistUrlsAndActions.find(([whitelistUrl, whitelistActions]) => whitelistUrl.test(url) && (whitelistActions.includes('ANY') ||  whitelistActions.includes(method) ))) {
      return null;
    }

    const fingerprintData = JSON.parse(
      localStorage.getItem('fingerprint-device-data')
    );

    if (fingerprintData && new Date(fingerprintData.expiresAt) > new Date()) {
      return fingerprintData;
    }

    if (useProVersion) {
      data = await getFingerprintProData();
      provider = 'fingerprintPro';
    } else {
      return null;
    }

    localStorage.setItem(
      'fingerprint-device-data',
      JSON.stringify({
        data,
        deviceId: data.visitorId,
        requestId: data.requestId,
        provider,
        expiresAt: new Date(Date.now() + EXPIRATION_IN_MINUTES * 60000),
      })
    );

    return JSON.parse(localStorage.getItem('fingerprint-device-data'));
  } catch (error) {
    return null;
  }
}

async function getFingerprintProData() {
  const fp = await FingerprintPRO.load({
    apiKey: config.fingerprintPro.publicKey,
    endpoint: config.fingerprintPro.endpoint,
    scriptUrlPattern: [
      // This endpoint will be used primarily
      config.fingerprintPro.scriptUrlPattern,
      // The default endpoint will be used if the primary fails
      FingerprintPRO.defaultScriptUrlPattern,
    ],
  });
  return await fp.get({ timeout: 20000 });
}
