import { createGlobalStyle } from 'styled-components';
import { theme } from '@cameo/theme';

export const AppGlobalStyle = createGlobalStyle`
  :root {
    color-scheme: dark;
  }

  @font-face {
    font-family: "Geist";
    font-weight: 400;
    font-display: optional;
    src: url("https://cdn.cameo.com/static/assets/geist-regular.woff2") format("woff2"), url("https://cdn.cameo.com/static/assets/geist-regular.otf") format("opentype");
  }

  @font-face {
    font-family: "Geist";
    font-weight: 500;
    font-display: optional;
    src: url("https://cdn.cameo.com/static/assets/geist-medium.woff2") format("woff2"), url("https://cdn.cameo.com/static/assets/geist-medium.otf") format("opentype");
  }

  @font-face {
    font-family: "Geist";
    font-weight: 700;
    font-display: optional;
    src: url("https://cdn.cameo.com/static/assets/geist-bold.woff2") format("woff2"), url("https://cdn.cameo.com/static/assets/geist-bold.otf") format("opentype");
  }

  @font-face {
    font-family: "Geist";
    font-weight: 800;
    font-display: optional;
    src: url("https://cdn.cameo.com/static/assets/geist-black.woff2") format("woff2"), url("https://cdn.cameo.com/static/assets/geist-black.otf") format("opentype");
  }

  html,
  body {
    background-color: ${theme.colors.background.level0};
    font-size: 10px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
    font-family: Geist, Helvetica, sans-serif;
    margin: 0;
  }

  html,
  body,
  #content {
    height: 100%;
  }

  a {
    text-decoration: none;
  }

  #agsdk_modal {
    iframe {
      color-scheme: normal
    }
  }
`;
