import { config } from 'cameoConfig';

export const resizeImage = (width: number, height: number, image: string) => {
  return `${config.imageResizerRoot}width=${width},height=${height}/${config.imageServerUrl}/${image}`;
};

export const resizeImageFromFullCameoUrl = (
  width: number,
  height: number,
  urlString: string
) => {
  if (!urlString) return '';

  return `${config.imageResizerRoot}width=${width},height=${height}/${urlString}`;
};

export const formatMalformedImageUrl = (
  width: number,
  height: number,
  url: string
) => {
  // NOTE: we still have legacy urls in our DB that are passed in here
  // url examples that are passed in:
  // https://cdn.cameo.com/resizer/NOT_REAL.png
  // https://starboard-media.s3.amazonaws.com/static/user/NOT_REAL.jpg
  // https://d31wcbk3iidrjq.cloudfront.net/NOT_REAL.jpg
  // https://d3el26csp1xekx.cloudfront.net/static/icon.png
  // https://cdn.cameo.com/resizer/static/icon.png
  // https://www.cameo.com/cdn-cgi/image/fit=cover,format=auto,width=300,height=300/https://cdn.cameo.com/resizer/NOT_REAL.jpg

  // step 1: replace all the old urls that are no longer valid for the resizer. if something is passing this in, it shouldn't.
  let updatedUrl = url.replace(/^.+cloudfront.net/, config.imageServerUrl);
  updatedUrl = updatedUrl.replace(
    /^.+starboard-media.s3.amazonaws.com/,
    config.imageServerUrl
  );

  // step 2: if the url includes cloudflare pathing, assuming it is already resized - so skip
  return updatedUrl?.includes('/cdn-cgi/image')
    ? updatedUrl
    : resizeImageFromFullCameoUrl(width * 2, height * 2, updatedUrl);
};
