import { AuthState } from '../types';

export type ResetPasswordTokenIsValidParams = {
  token: string;
};

export type ResetPasswordTokenIsValidState = Partial<AuthState>;

export const INITIAL_STATE: ResetPasswordTokenIsValidState = Object.freeze({
  resetPasswordError: null,
});
