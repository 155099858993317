// Google Analytics Tracking Client
import ga from 'react-ga4';

let loggedInUserId;
let initialized = false;
const preInitEventQueue = [];

// *** Analytics Tracking Action Handlers *** //
const handlePageview = ({ data }) => {
  // Upgraded GA requires this check since we SSR
  // This triggers 'SSR discarded on home page' warning.
  // TODO: discuss strategy since window object is undefined on SERVER
  // AKA: https://www.npmjs.com/package/window-or-global
  if (typeof window === 'object') {
    ga.set({ page: window.location.pathname });
    // This next line doesn't seem to get hit while browsing, but may be necessary for SSR - dtown
    ga.send({ hitType: 'pageview', page: data.url });
  }
};

const handleEvent = ({ data, eventName, category }) => {
  ga.event({
    category,
    action: eventName,
    label: JSON.stringify({ ...data, loggedInUserId }),
    // value is an optional of type number. If the caller sends a value, send it with the event, otherwise exclude it from the event data
    value: (data && data.value) || undefined,
  });
};

const typeToHandler = {
  pageview: handlePageview,
  event: handleEvent,
};

function track({ type, data, eventName, category }) {
  if (type in typeToHandler) {
    if (initialized) {
      // Call handler function
      typeToHandler[type]({ data, eventName, category });
    } else {
      preInitEventQueue.push({ type, data, eventName, category });
    }
  } else {
    // Unable to find analytics action of 'type' specified.
    console.log('Warning: Unable to find GA client action of specified type.');
  }
}

// *** Public Methods *** //
async function init(configs) {
  if (!window.ga) {
    console.log('Initialized GA Client');
    ga.initialize(configs.analytics.id);

    initialized = true;

    preInitEventQueue.forEach(track);
    preInitEventQueue.length = 0;
  }
}

function setUser({ user }) {
  if (user) {
    loggedInUserId = user._id;
  }
}

export default {
  track,
  setUser,
  init,
};
