import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { startAuthFlow, closeAuthFlow } from './actions';
import { AuthFlowState, INITIAL_STATE } from './types';

const reducer = reducerWithInitialState<AuthFlowState>(INITIAL_STATE)
  .case(startAuthFlow, (state) => ({
    ...state,
    authFlowVisible: true,
  }))
  .case(closeAuthFlow, (state) => ({
    ...state,
    authFlowVisible: false,
  }));

export default reducer.build();
