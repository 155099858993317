import React, { createContext, useMemo } from 'react';
import { CameoSessionIdentifier } from 'shared-types/CameoSessionIdentifier';
import {
  UserAgentAttributes,
  UserAgentDeviceType,
} from 'shared-utils/userAgent';
import { config } from 'cameoConfig';

import { PublicUser } from 'types/src/api/models/user';

interface ExperimentUserDataProviderProps {
  children: React.ReactNode;
  loggedInUser: Partial<
    Pick<
      PublicUser,
      '_id' | 'role' | 'featureFlags' | 'cameoForBusinessActivatedAt'
    >
  >;
  sessionIdentifier: CameoSessionIdentifier | null;
  userAttributes?: UserAgentAttributes;
  isServerSide?: boolean;
}

export type ExperimentUserDataContextValue = {
  id: string;
  attributes: {
    device: UserAgentDeviceType;
    deviceModel: string;
    deviceVendor: string;
    os: string;
    osVersion: string;
    browser: string;
    browserVersion: string;
    environment: string;
    isGuest: boolean;
    isIdentified: boolean;
    userId: string;
    role: string;
    isC4BManagedCustomer: boolean;
  };
};

const ExperimentUserDataContext =
  createContext<ExperimentUserDataContextValue | null>(null);

// TODO: Combine with EppoRandomizationProvider into generic ExperimentationProvider after initial launch
const ExperimentUserDataProvider = (props: ExperimentUserDataProviderProps) => {
  const { loggedInUser, sessionIdentifier, userAttributes, children } = props;

  const userProperties = useMemo(() => {
    const isLoggedIn = Boolean(loggedInUser);
    const isIdentified = !!(sessionIdentifier && sessionIdentifier.id);

    const attributes = {
      ...userAttributes,
      environment: config.environment,
      isGuest: !isLoggedIn,
      isIdentified,
      userId: isLoggedIn ? loggedInUser._id : '',
      role: isLoggedIn ? loggedInUser.role : '',
      isC4BManagedCustomer: Boolean(loggedInUser?.cameoForBusinessActivatedAt),
    };

    return {
      id: sessionIdentifier ? sessionIdentifier.id : '[anonymous]',
      attributes,
    };
  }, [loggedInUser, sessionIdentifier, userAttributes]);

  return (
    <ExperimentUserDataContext.Provider value={{ ...userProperties }}>
      {children}
    </ExperimentUserDataContext.Provider>
  );
};

export { ExperimentUserDataProvider, ExperimentUserDataContext };
