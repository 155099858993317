import styled from 'styled-components';
import { sizing } from 'design-system/style';

export const Letters = styled.img`
  height: ${sizing(20)};
  width: ${sizing(80)};
`;

export const KidsLogo = styled.img`
  height: ${sizing(40)};
  max-width: ${sizing(130)};
  margin-top: -10px;
`;
