import * as React from 'react';
import type { CustomIconProps } from './types';

function BirthdayCakeIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M2.0144 8.66669C2.0144 6.87176 3.46948 5.41669 5.2644 5.41669H9.90916C11.7041 5.41669 13.1592 6.87176 13.1592 8.66669V12C13.1592 12.6904 12.5995 13.25 11.9092 13.25H3.26441C2.57405 13.25 2.0144 12.6904 2.0144 12V8.66669Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M2.52881 8.66669C3.80086 8.66669 3.80086 10 5.0729 10C6.34495 10 6.34495 8.66669 7.55642 8.66669C8.82847 8.66669 8.82847 10 10.1005 10C11.3726 10 11.3726 8.66669 12.6446 8.66669"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.85122 2.66667C8.85122 3.40305 8.28509 4 7.58674 4C6.88839 4 6.32227 3.40305 6.32227 2.66667C6.32227 2.02978 6.55873 0.998501 7.23618 0.300142C7.37153 0.16062 7.58911 0.269309 7.61322 0.462191C7.79501 1.91645 8.85122 1.54328 8.85122 2.66667Z"
        fill="white"
      />
    </svg>
  );
}

export default BirthdayCakeIcon;
