import { QueryClient } from '@tanstack/react-query';

/**
 * QueryClient instance to be used in the client-side app.
 * This allows us to use QueryClient outside React.
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 2 * 1000,
    },
  },
});
