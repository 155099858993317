import * as React from 'react';
import type { CustomIconProps } from './types';

function HalloweenIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M8.89944 3.50002C9.29944 3.40002 9.59944 3.50002 9.99944 3.60002C10.1994 3.60002 10.3994 3.70002 10.6994 3.70002C10.9994 3.60002 11.3994 3.50002 11.7994 3.60002C13.5994 3.80002 15.3994 5.70002 15.0994 9.40002C14.8994 12.8 13.4994 15.2 11.6994 15.2C11.3994 15.2 11.0994 15.1 10.7994 15C10.4994 15.1 10.1994 15.2 9.89944 15.2C9.59944 15.2 9.19944 15.1 8.89944 14.9C8.59944 15.1 8.19944 15.2 7.89944 15.2C7.59944 15.2 7.19944 15.1 6.89944 14.9C6.59944 15.1 6.19944 15.2 5.89944 15.2C5.59944 15.2 5.29944 15.1 5.09944 15C4.79944 15.1 4.49944 15.2 4.19944 15.2C2.39944 15.2 0.899441 12.1 0.799441 9.40002C0.699441 7.40002 1.19944 5.00002 2.59944 4.00002C3.09944 3.70002 3.59944 3.50002 4.19944 3.60002C4.39944 3.60002 4.59944 3.70002 4.79944 3.70002C5.09944 3.60002 5.39944 3.50002 5.69944 3.50002C5.69944 3.50002 6.69944 3.10002 6.99944 3.40002C7.79944 4.20002 8.79944 3.70002 8.89944 3.50002Z"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M8 1V3.4"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default HalloweenIcon;
