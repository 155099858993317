import * as React from 'react';
import type { CustomIconProps } from './types';

function ToothbrushIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M6.19961 0.799988L1.49961 5.49999C1.29961 5.69999 1.29961 5.89999 1.49961 6.09999L10.1996 14.8C10.8996 15.5 11.6996 15.1 12.0996 14.6C12.5996 14.1 12.5996 13.3 12.0996 12.9L6.69961 7.39999C6.49961 7.19999 6.49961 6.99999 6.69961 6.79999L9.39961 4.09999"
        stroke="#F2F2F2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.70039 2.5L4.90039 5.3"
        stroke="#F2F2F2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.09961 3.70001L7.29961 7.90001"
        stroke="#F2F2F2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default ToothbrushIcon;
