import {
  DEVICE_PLATFORM_ID_KEY,
  VISITOR_ID_KEY,
} from 'analytics/utils/constants';
import { v4 as uuidv4 } from 'uuid';
import { dpCookieManager } from 'analytics/utils/dpCookieManager';

class DpDevicePlatformManager {
  private devicePlatformId: string;

  private isInitialized = false;

  private initialize() {
    if (this.isInitialized) {
      return;
    }

    const storedDevicePlatformId = window.localStorage.getItem(
      DEVICE_PLATFORM_ID_KEY
    );

    const storedVisitorId = window.localStorage.getItem(VISITOR_ID_KEY);
    const visitorCookie = dpCookieManager.getVisitorIdCookie();

    this.devicePlatformId =
      storedDevicePlatformId || storedVisitorId || visitorCookie || null;

    window.addEventListener('storage', (event) => {
      if (event.key === DEVICE_PLATFORM_ID_KEY) {
        this.devicePlatformId = window.localStorage.getItem(
          DEVICE_PLATFORM_ID_KEY
        );
      }
    });
    this.isInitialized = true;
  }

  public getDevicePlatformId(): string {
    this.initialize();
    const devicePlatformIdExists = Boolean(this.devicePlatformId);
    if (!devicePlatformIdExists) {
      this.getNewDevicePlatformId();
    }
    return this.devicePlatformId;
  }

  public flushDevicePlatformIdToDisk() {
    if (this.isInitialized) {
      window.localStorage.setItem(
        DEVICE_PLATFORM_ID_KEY,
        this.devicePlatformId
      );
    }
  }

  private getNewDevicePlatformId() {
    // initialization process takes care of situation where its stored in
    // localstorage or cookie - so this just generates new one
    this.devicePlatformId = uuidv4();
    this.flushDevicePlatformIdToDisk();
  }

  // for testing only
  reset() {
    this.devicePlatformId = undefined;
    this.isInitialized = false;
  }
}

export const dpDevicePlatformManager = new DpDevicePlatformManager();
