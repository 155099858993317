import actionCreatorFactory from 'typescript-fsa';
import { POST } from 'types/src/api/endpoints/order/createGift';
import { PUT } from 'types/src/api/endpoints/order/openLastGift';
import { GiftWrapperType } from 'types/src/utils/enums/gift';

const actionCreator = actionCreatorFactory('GIFTS');

export type CreateGiftParams = {
  fromName: POST.RequestBody['fromName'];
  message?: POST.RequestBody['message'];
  orderId: POST.RequestBody['orderId'];
  recipientName: POST.RequestBody['recipientName'];
  wrapper?: GiftWrapperType;
};

export type PreviewGiftParams = {
  fromName: POST.RequestBody['fromName'];
  message?: POST.RequestBody['message'];
  recipientName: POST.RequestBody['recipientName'];
  wrapper: POST.RequestBody['wrapper'];
};

type CreateGiftResult = {
  giftId: string;
};

type CreateGiftError = {
  message: string;
  status: number;
};

type GetGiftByIdParams = {
  giftId: string;
};

type GetGiftByIdResult = {
  gift: Cameo.Models.Order.Gift;
  order: Cameo.Models.Order.PublicOrder;
};

type GetUserOrderGiftParams = {
  giftId: string;
  orderId: string;
};

type GetUserOrderGiftResult = Cameo.Models.Order.Gift;

type GetUserOrderGiftError = {
  message: string;
  status: number;
};

type OpenGiftParams = {
  giftId: string;
  orderId: string;
};

type OpenLastGiftParams = PUT.Params;

type OpenLastGiftResult = PUT.Response;

type OpenGiftError = {
  message: string;
  statusCode: number;
};

type AttributeGiftParams = {
  orderId: string;
  userId: any;
  giftId?: string;
};

type RemoveGiftParams = {
  orderId: string;
  userId: any;
  giftId: string;
};

export const CreateGift = actionCreator.async<
  CreateGiftParams,
  CreateGiftResult,
  CreateGiftError
>('cameo/createGift/CREATE_GIFT');

// TODO: type error
export const GetGiftById = actionCreator.async<
  GetGiftByIdParams,
  GetGiftByIdResult,
  any
>('cameo/getGiftById/GET_GIFT_BY_ID');

export const GetUserOrderGift = actionCreator.async<
  GetUserOrderGiftParams,
  GetUserOrderGiftResult,
  GetUserOrderGiftError
>('cameo/getUserOrderGift/GET_USER_ORDER_GIFT');

export const OpenGift = actionCreator.async<
  OpenGiftParams,
  void,
  OpenGiftError
>('cameo/openGift/OPEN_GIFT');

export const OpenLastGift = actionCreator.async<
  OpenLastGiftParams,
  OpenLastGiftResult,
  OpenGiftError
>('cameo/openLastGift/OPEN_LAST_GIFT');

export const AttributeGift = actionCreator.async<
  AttributeGiftParams,
  void,
  any
>('cameo/attributeGift/ATTRIBUTE_GIFT');

export const RemoveGift = actionCreator.async<
  RemoveGiftParams,
  Cameo.Endpoints.Order.RemoveGift.DELETE.ResponseBody,
  any
>('cameo/removeGift/REMOVE_GIFT');

export const SetGiftWrap = actionCreator<GiftWrapperType>(
  'cameo/setGiftWrap/SET_GIFT_WRAP'
);

export const SetGiftWrapFormData = actionCreator<{
  fromName: POST.RequestBody['fromName'];
  message?: POST.RequestBody['message'];
  recipientName: POST.RequestBody['recipientName'];
}>('cameo/setGiftWrapFormData/SET_GIFT_WRAP_FORM_DATA');

export const SetIsGiftPreview = actionCreator<boolean>(
  'cameo/setIsGiftPreview/SET_IS_GIFT_PREVIEW'
);

export const SetLatestCreatedGift = actionCreator<{ giftId: string }>(
  'cameo/setLatestCreatedGift/SET_LATEST_CREATED_GIFT'
);

export const ResetGiftWrapping = actionCreator<void>(
  'cameo/resetGiftWrapping/RESET_GIFT_WRAPPING'
);

export const ResetGiftWrapFormData = actionCreator<void>(
  'cameo/resetGiftWrappingFormData/RESET_GIFT_WRAPPING_FORM_DATA'
);

export function createGift(gift: CreateGiftParams) {
  return {
    types: [
      CreateGift.started.type,
      CreateGift.done.type,
      CreateGift.failed.type,
    ],
    promise: (client) => {
      return client.post('/order/gift/create', {
        data: {
          ...gift,
        },
      });
    },
  };
}

export function getGiftById(giftId: string) {
  return {
    types: [
      GetGiftById.started.type,
      GetGiftById.done.type,
      GetGiftById.failed.type,
    ],
    promise: (client) => {
      return client.get(`/order/gift/${giftId}`);
    },
  };
}

export function getUserOrderGift(orderId: string, giftId: string) {
  return {
    types: [
      GetUserOrderGift.started.type,
      GetUserOrderGift.done.type,
      GetUserOrderGift.failed.type,
    ],
    promise: (client) => {
      return client.get(`/order/${orderId}/gift/${giftId}`);
    },
  };
}

export function openGift(orderId: string, giftId: string) {
  return {
    types: [OpenGift.started.type, OpenGift.done.type, OpenGift.failed.type],
    promise: (client) => {
      return client.put(`/order/${orderId}/gift/${giftId}/open`);
    },
  };
}

export function openLastGift(orderId: string) {
  return {
    types: [
      OpenLastGift.started.type,
      OpenLastGift.done.type,
      OpenLastGift.failed.type,
    ],
    promise: (client) => {
      return client.put(`/order/${orderId}/gift/open-last`);
    },
  };
}

export function attributeGift(
  orderId: string,
  userId: string,
  giftId?: string
) {
  return {
    types: [
      AttributeGift.started.type,
      AttributeGift.done.type,
      AttributeGift.failed.type,
    ],
    promise: (client) => {
      return client.post(`/order/${orderId}/attributeGift`, {
        data: {
          ...(giftId && { giftId }),
        },
      });
    },
  };
}

/**
 * NOTE: We need the userId for later usage on the reducer
 */
export function removeGift(orderId: string, userId: string, giftId: string) {
  return {
    types: [
      RemoveGift.started.type,
      RemoveGift.done.type,
      RemoveGift.failed.type,
    ],
    promise: (client) => {
      return client.del(`/order/${orderId}/removeGift`, {
        data: {
          giftId,
        },
      });
    },
  };
}
