import { Link } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';

import * as routerSelectors from 'state/modules/router/selectors';

import { useKidsExperience } from 'utils/hooks/useKidsExperience';
import { config } from '../../../config';
import { KidsLogo, Letters } from './Styled';

export const WHITE_LOGO_URL = `${config.cloudfrontBaseUrl}/staticDir/logo_white.svg`;
export const KIDS_LOGO_URL = `${config.cloudfrontBaseUrl}/static/assets/cameo-kids-logo.svg`;

export const Logo = () => {
  const isCheckoutPage = useSelector(routerSelectors.isCheckoutPage);
  const shouldUseKidsLogo = useKidsExperience();

  if (isCheckoutPage) {
    return <Letters src={WHITE_LOGO_URL} alt="Cameo Logo" data-testid="logo" />;
  }

  if (shouldUseKidsLogo) {
    return (
      <Link to="/kids">
        <KidsLogo
          src={KIDS_LOGO_URL}
          alt="Cameo Kids Logo"
          data-testid="kids-logo"
        />
      </Link>
    );
  }

  return (
    <Link to="/">
      <Letters src={WHITE_LOGO_URL} alt="Cameo Logo" data-testid="logo" />
    </Link>
  );
};
