import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { loginWithFacebook as loginWithFacebookService } from 'services';
import authActionCreatorFactory from '../authActionCreatorFactory';
import { ActionError } from '../types';
import {
  ContinueWithFacebookParams,
  ContinueWithFacebookResponse,
} from './types';

const asyncActionCreator =
  authActionCreatorFactory<ContinueWithFacebookResponse>();

const loginWithFacebook = async (params: ContinueWithFacebookParams) => {
  return {
    user: await loginWithFacebookService(params),
  };
};

const continueWithFacebook = asyncActionCreator<
  ContinueWithFacebookParams,
  ContinueWithFacebookResponse,
  ActionError
>('CONTINUE_WITH_FACEBOOK', loginWithFacebook);

export const service = (token: string) =>
  thunkToAction(continueWithFacebook)({ token });

export default continueWithFacebook;
