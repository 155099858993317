import { theme as cameoTheme } from '@cameo/theme';

interface SpaceArray extends Array<string> {
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  xxl?: string;
}

// within cameo 1rem = 10px
const remBase = 10;

export const space: SpaceArray = [
  `${cameoTheme.space.$0 / remBase}rem`, // [0] 0
  `${cameoTheme.space['$0.5'] / remBase}rem`, // [1] 4
  `${cameoTheme.space.$1 / remBase}rem`, // [2] 8
  `${cameoTheme.space['$1.5'] / remBase}rem`, // [3] 12
  `${cameoTheme.space.$2 / remBase}rem`, // [4] 16
  `${cameoTheme.space['$2.5'] / remBase}rem`, // [5] 20
  `${cameoTheme.space.$3 / remBase}rem`, // [6] 24
  `${cameoTheme.space.$4 / remBase}rem`, // [7] 32
  `${cameoTheme.space.$5 / remBase}rem`, // [8] 40
  `${cameoTheme.space.$6 / remBase}rem`, // [9] 48
  `${cameoTheme.space.$8 / remBase}rem`, // [10] 64
  `${cameoTheme.space.$10 / remBase}rem`, // [11] 80
  `${cameoTheme.space.$12 / remBase}rem`, // [12] 96
  `${cameoTheme.space.$24 / remBase}rem`, // [13] 192
];

space.xs = space['1']; // 4px
space.sm = space['2']; // 8px
space.md = space['3']; // 12px
space.lg = space['4']; // 16px
space.xl = space['5']; // 20px
space.xxl = space['6']; // 24px
