import actionCreatorFactory from 'typescript-fsa';

interface State {
  isLoading: boolean;
  unreadChannelCount: number;
}

const actionCreator = actionCreatorFactory('GET_USER_UNREAD_MESSAGE_COUNT');
export const GetUserUnreadChannelCount = actionCreator.async<string, any, any>(
  'cameo/users/GET_USER_UNREAD_MESSAGE_COUNT'
);

export const reducer = {
  [GetUserUnreadChannelCount.started.type]: (state: State) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [GetUserUnreadChannelCount.done.type]: (state: State, action) => {
    return {
      ...state,
      isLoading: false,
      unreadChannelCount: action.payload.result,
    };
  },
  [GetUserUnreadChannelCount.failed.type]: (state: State) => {
    return {
      ...state,
      isLoading: false,
    };
  },
};

export function getUserUnreadChannelCount() {
  const endpoint = '/user/getUserUnreadChannelCount?filterType=nonCrm';
  return {
    types: [
      GetUserUnreadChannelCount.started,
      GetUserUnreadChannelCount.done,
      GetUserUnreadChannelCount.failed,
    ],
    promise: (client) => client.get(endpoint),
  };
}
