import styled from 'styled-components';

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
`;

export const Background = styled.div`
  background: linear-gradient(
    180.25deg,
    rgba(0, 0, 0, 0) 21.97%,
    rgba(29, 29, 29, 0.64) 99.76%
  );
  height: 100%;
  opacity: 0.8;
  position: absolute;
  transform: matrix(1, 0, 0, -1, 0, 0);
  width: 100%;
`;
