import { reducerWithInitialState } from 'typescript-fsa-reducers';
import omit from 'lodash/omit';
import Cameo from 'types';
import { FanClubsState, Error } from './types';
import INITIAL_STATE from './initialState';
import { actionCreator } from './utils';

export const ToggleFanClubMember = actionCreator.async<
  Cameo.Endpoints.FanClub.ToggleFanClubMembers.PATCH.Params &
    Cameo.Endpoints.FanClub.ToggleFanClubMembers.PATCH.RequestBody,
  Cameo.Endpoints.FanClub.ToggleFanClubMembers.PATCH.Response,
  Error
>('TOGGLE_FAN_CLUB_MEMBER');

export function toggleFanClubMember(
  fanClubId: Cameo.Endpoints.FanClub.ToggleFanClubMembers.PATCH.Params['fanClubId'],
  {
    initialJoinSource,
  }: Cameo.Endpoints.FanClub.ToggleFanClubMembers.PATCH.RequestBody = {}
) {
  return {
    types: [
      ToggleFanClubMember.started.type,
      ToggleFanClubMember.done.type,
      ToggleFanClubMember.failed.type,
    ],
    promise: (client) =>
      client.patch(`/fanClub/${fanClubId}/toggleFanClubMember`, {
        data: { initialJoinSource },
      }),
  };
}

export const reducer = reducerWithInitialState<FanClubsState>(INITIAL_STATE)
  .case(ToggleFanClubMember.started, (state) => ({
    ...state,
    togglingFanClubMember: true,
    toggleFanClubMemberError: null,
    hasJustJoinedFanClub: null,
    hasJustLeftFanClub: null,
  }))
  .case(ToggleFanClubMember.done, (state, payload) => {
    const {
      result: { isUserMember, ...fanClub },
    } = payload;
    const membershipIDList = isUserMember
      ? [...state.membershipIDList, fanClub._id]
      : state.membershipIDList.filter(
          (membershipID) => membershipID !== fanClub._id
        );

    const updatedActiveUserSubscriptions = isUserMember
      ? state.activeUserSubscriptions
      : omit(state.activeUserSubscriptions, fanClub._id);

    return {
      ...state,
      subscriptionPaymentIntent: isUserMember
        ? state.subscriptionPaymentIntent
        : null,
      subscriptionStatus: isUserMember ? state.subscriptionStatus : null,
      togglingFanClubMember: false,
      toggleFanClubMemberError: null,
      hasJustJoinedFanClub: isUserMember,
      hasJustLeftFanClub: !isUserMember,
      membershipIDList,
      activeUserSubscriptions: updatedActiveUserSubscriptions || {},
      userSubscriptionType: null,
      fanClubs: {
        ...state.fanClubs,
        [fanClub._id]: fanClub,
      },
    };
  })
  .case(ToggleFanClubMember.failed, (state, action) => ({
    ...state,
    togglingFanClubMember: false,
    toggleFanClubMemberError: action.error,
  }))
  .build();
