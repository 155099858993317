import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import React, { Fragment, useEffect, useState } from 'react';
import analytics from 'analytics';

import { useBookingDrafts } from 'domains/booking/BookingDraft/useBookingDrafts';
import { getUserByUsername } from 'state/modules/users/selectors';
import { actions as userActions } from 'state/modules/users';
import { Box, Button, Dialog, Text } from 'domains/web/components';
import { useSelector } from 'react-redux';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';

export const BookingDraft = () => {
  const [isOpen, toggleOpen] = useState(false);
  const { t } = useTranslation('BookingDraft');
  const { bookingDrafts, deleteBookingDraft } = useBookingDrafts();
  const history = useHistory();

  // We only display one draft at a time.
  const username = Object.keys(bookingDrafts)[0];
  const talent = useSelector(getUserByUsername(username));

  useEffect(() => {
    if (username && !talent) {
      userActions.loadUser(username);
    }
  }, [talent, username]);

  // Early return, since there's no need to show the Dialog or call-to-action when there's no draft.
  if (Object.keys(bookingDrafts).length === 0) {
    return null;
  }

  const close = () => {
    analytics.trackAction({
      eventName: 'Engage',
      eventDtl: {
        eventType: 'Click',
      },
      objectDtl: {
        objectType: 'Module',
        containerType: 'Modal',
        objectLabel: 'Close',
      },
    });
    toggleOpen(false);
  };

  const open = () => {
    analytics.trackAction({
      eventName: 'Engage',
      eventDtl: {
        eventType: 'Click',
      },
      objectDtl: {
        objectType: 'Button',
        objectName: 'ContinueDraftOrder',
        objectLabel: 'CartIcon',
        containerType: 'Navigation',
      },
    });
    toggleOpen(true);
  };

  const removeOrder = () => {
    analytics.trackAction({
      eventName: 'Engage',
      eventDtl: {
        eventType: 'Click',
      },
      objectDtl: {
        objectType: 'Module',
        containerType: 'Modal',
      },
      targetDtl: {
        targetPath: window.location.href,
        targetKey: 'User',
        targetValue: username,
        targetType: 'Cart',
      },
    });
    deleteBookingDraft(username);
    toggleOpen(false);
  };

  const continueWithOrder = () => {
    analytics.trackNavigation({
      eventName: 'Page',
      sourceDtl: {
        sourcePath: window.location.href,
      },
      targetDtl: {
        targetPath: `/${username}/book`,
        targetKey: 'Booking',
        targetValue: username,
      },
    });
    toggleOpen(false);
    history.push(`/${username}/book`);
  };

  return (
    <Fragment>
      <Box
        as="button"
        aria-label={'Continue with request'}
        onClick={open}
        alignItems="center"
        display="flex"
      >
        <Box position="relative" display="inline-flex">
          <ShoppingCartIcon height={24} width={24} />
          <Box
            backgroundColor="accent.default"
            size={16}
            borderRadius="50%"
            position="absolute"
            top={-4}
            right={-8}
            textAlign="center"
          >
            <Text aria-hidden variant="base_xxs">
              {'1'}
            </Text>
          </Box>
        </Box>
      </Box>
      <Dialog.Root
        open={isOpen}
        title={t('title', 'Your cart')}
        onClose={close}
      >
        <Text>
          {t(
            'description',
            'You have an uncompleted request for {{talent}}. Do you want to complete your request?',
            { talent: talent?.name || username }
          )}
        </Text>
        <Dialog.Footer flexDirection={{ _: 'column-reverse', sm: 'row' }}>
          <Button variant="secondary" onClick={removeOrder}>
            {t('actions.remove.label', 'Remove request')}
          </Button>
          <Button variant="primary" onClick={continueWithOrder}>
            {t('actions.continue.label', 'Continue with request')}
          </Button>
        </Dialog.Footer>
      </Dialog.Root>
    </Fragment>
  );
};
