import { AdminUser } from 'types/src/api/models/user';
import { AuthState } from '../types';

export type SignupParams = {
  dob?: Date | string;
  email: string;
  name: string;
  password?: string;
  briefcaseCustomer?: boolean;
  username?: string;
  prospected?: boolean;
  passwordless?: boolean;
  preferences?: {
    optInMarketingEmails?: boolean;
  };
};

export type AuthSignupParams = SignupParams;

export type SignupResponse = AdminUser;

export type SignupState = Partial<AuthState>;

export const INITIAL_STATE: SignupState = Object.freeze({
  loggedInUser: {} as AdminUser,
  signupError: null,
  signingUp: false,
});
