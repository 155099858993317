import React, { useCallback } from 'react';
import {
  CategoryItem,
  ContentThumbnail,
} from 'types/src/api/endpoints/explore/categories';
import { resizeImage } from 'utils/getResizedImageUrl';
import { Title } from 'design-system/Components/SearchNullState/Styled';
import { useSearchNullStateOverrides } from 'utils/c4b/consumerOverrides/SearchNullStateOverridesProvider/SearchNullStateOverridesProvider';
import { Box, Image, Text } from 'domains/web/components';
import { Link } from 'react-router-dom';
import { trackCategoryTileClickEvent } from './analytics';
import { CategoriesContainer } from './Styled';

type CategoryListItemProps = {
  category: CategoryItem;
  index: number;
  categorySlug?: string;
  closeSearchResultsDropdown?: () => void;
};

type CategoryListProps = {
  categories: CategoryItem[];
  title: string;
  closeSearchResultsDropdown?: () => void;
};

const parseImageUrl = (thumbnail: ContentThumbnail | undefined) => {
  if (thumbnail) {
    return resizeImage(100, 100, thumbnail.image.url);
  }

  return undefined;
};

const CategoryListItem = ({
  category,
  index,
  categorySlug,
  closeSearchResultsDropdown,
}: CategoryListItemProps) => {
  const { generateBrowsePageUrl } = useSearchNullStateOverrides();

  const [avatarCenter, avatarLeft, avatarRight] =
    category.contentThumbnails || [];

  const onPress = useCallback(() => {
    trackCategoryTileClickEvent(category, index);
  }, [category, index]);

  // Temporary override to send traffic to the curated HP and Survivor landing pages
  const getBrowsePageUrlOverrides = (cat) => {
    if (cat.slug === 'harry-potter') {
      return '/harry-potter';
    }
    if (cat.slug === 'survivor') {
      return '/survivor';
    }
    return `/browse/${cat.materializedPath}`;
  };

  // eslint-disable-next-line no-nested-ternary
  const linkUrl = generateBrowsePageUrl
    ? generateBrowsePageUrl(
        category.materializedPath ?? categorySlug,
        'category'
      )
    : getBrowsePageUrlOverrides(category);

  return (
    <Box
      onClick={() => {
        if (closeSearchResultsDropdown) {
          closeSearchResultsDropdown();
        }
        onPress();
      }}
      as={Link}
      to={linkUrl}
      width={138}
      height={100}
      borderColor="border.default"
      backgroundColor="background.level3"
      borderRadius={2}
      py={2}
      borderStyle="solid"
      borderWidth={1}
      alignItems="center"
      flexDirection="column"
      display="flex"
      justifyContent="center"
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        {avatarLeft && (
          <Box>
            <Image
              alt="avatar-left"
              borderRadius="50%"
              height={32}
              width={32}
              src={parseImageUrl(avatarLeft)}
            />
          </Box>
        )}
        {avatarCenter && (
          <Box ml={-3} mb={2}>
            <Image
              alt="avatar-center"
              borderRadius="50%"
              height={48}
              width={48}
              src={parseImageUrl(avatarCenter)}
            />
          </Box>
        )}
        {avatarRight && (
          <Box ml={-3}>
            <Image
              alt="avatar-right"
              borderRadius="50%"
              height={32}
              width={32}
              src={parseImageUrl(avatarRight)}
            />
          </Box>
        )}
      </Box>
      <Text>{category.name}</Text>
    </Box>
  );
};

const CategoryList = ({
  categories = [],
  title,
  closeSearchResultsDropdown,
}: CategoryListProps) => {
  if (categories.length === 0) {
    return null;
  }

  return (
    <>
      <Title>{title}</Title>
      <CategoriesContainer>
        {categories.map((category, index) => {
          return (
            <CategoryListItem
              categorySlug={category.slug}
              category={category}
              index={index}
              key={category.slug}
              closeSearchResultsDropdown={closeSearchResultsDropdown}
            />
          );
        })}
      </CategoriesContainer>
    </>
  );
};

export default CategoryList;
