export const getEnrolled = (state) => state.auth.enrolled;
export const getEnrolling = (state) => state.auth.enrolling;
export const getEnrollError = (state) => state.auth.enrollError;

export const getEnrolledPartner = (state) => state.auth.enrolledPartner;
export const getEnrollingPartner = (state) => state.auth.enrollingPartner;
export const getEnrollPartnerError = (state) => state.auth.enrollPartnerError;

export const getLoginError = (state) => state.auth.loginError;
export const getLoggingIn = (state) => state.auth.loggingIn;

export const getLinkMessengerError = (state) => state.auth.linkMessengerError;
export const getLinkMessengerRedirect = (state) =>
  state.auth.linkMessengerRedirect;
export const getLinkMessengerSuccess = (state) =>
  state.auth.linkMessengerSuccess;

export const getRequestPasswordResetInfo = (state) =>
  state.auth.requestPasswordResetInfo;
export const getRequestPasswordResetError = (state) =>
  state.auth.requestPasswordResetError;

export const getResetPasswordError = (state) => state.auth.resetPasswordError;
export const getResetPasswordInfo = (state) => state.auth.resetPasswordInfo;
export const getResettingPassword = (state) => state.auth.resettingPassword;

export const getSignupError = (state) => state.auth.signupError;
export const getSigningUp = (state) => state.auth.signingUp;

export const getLoggedInUser = (state) => {
  const { loggedInUser } = state.auth;

  if (!loggedInUser) {
    return null;
  }

  return loggedInUser;
};

export const getLoggedInUserId = (state) => {
  const loggedInUser = getLoggedInUser(state);

  if (!loggedInUser) return null;

  return loggedInUser?._id;
};

export const getIsLoggedInUserAdmin = (state) => {
  const loggedInUser = getLoggedInUser(state);
  const isAdmin = loggedInUser && loggedInUser.role === 'admin';
  return !!isAdmin;
};

export const getShowAuthView = (state) => state.auth.showAuthView;
export const getShowAuthFlowData = (state) => state.auth.showAuthFlowData;

export const getIsLoggedIn = (state) =>
  !!(state.auth && state.auth.loggedInUser);
export const isLoaded = (state) => !!(state.auth && state.auth.loaded);
export const getIsReferralAmountEnabled = (state) =>
  !!state.auth?.loggedInUser?.featureFlags?.useReferralAmount10;

export const isAuthFlowVisible = (state) => state.auth.authFlowVisible;
