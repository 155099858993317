import React from 'react';
import styled from 'styled-components';
import { color, fonts, sizing } from '../../style';

export enum BadgeTheme {
  light = 'light',
  dark = 'dark',
}

export type Props = {
  theme?: BadgeTheme;
  backgroundColor: string;
  title: string;
};

export const Wrapper = styled.span<{ bgColor: string; textColor: string }>`
  ${fonts.mediumText['100']};
  background-color: ${({ bgColor }) => bgColor};
  border-radius: ${sizing(4)};
  color: ${({ textColor }) => textColor};
  display: inline-block;
  padding: ${sizing(2)} ${sizing(4)};
  pointer-events: none;
`;

const Theme = {
  light: color.solids.WHITE,
  dark: color.solids.DARK,
};

export const Badge = ({
  theme = BadgeTheme.light,
  backgroundColor,
  title,
}: Props) => (
  <Wrapper bgColor={backgroundColor} textColor={Theme[theme]}>
    {title}
  </Wrapper>
);
