export enum MenuItems {
  About = 'about-nav-btn',
  Admin = 'item-admin',
  AppOrderHistory = 'item-app-order-history',
  Business = 'business-nav-btn',
  businessOrders = 'item-business-orders',
  Categories = 'categories-nav-btn',
  Dashboard = 'item-dashboard',
  DrawerMenuBack = 'item-navdrawer-menu-back-btn',
  DrawerMenuClose = 'item-navdrawer-menu-close-btn',
  DrawerMenuOpen = 'item-navdrawer-menu-open-btn',
  Events = 'events-nav-btn',
  Following = 'item-following',
  GiftCards = 'item-giftcards',
  InviteFriends = 'item-invite-friends',
  Logout = 'logout-nav-btn',
  Merch = 'merch-nav-btn',
  MyEvents = 'item-my-events',
  Orders = 'item-orders',
  Inbox = 'item-inbox',
  Kids = 'kids-nav-btn',
  Likes = 'likes-nav-btn',
  Enroll = 'enroll-as-talent',
}

export type Notification = MenuItems;
