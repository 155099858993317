import { ChevronDown, ChevronUp } from 'react-feather';

import React, { useState } from 'react';
import ReactDOM from 'react-dom';

const interval = 500;
const maxRetry = 5;

interface ExpandButtonElement extends HTMLButtonElement {
  ariaExpanded: string;
}

export async function OneTrustInit() {
  let retry = 0;
  const oneTrustLoaded = setInterval(() => {
    retry += 1;
    if (document.getElementById('ot-pc-content')) {
      init();
      clearInterval(oneTrustLoaded);
    } else if (retry === maxRetry) {
      clearInterval(oneTrustLoaded);
    }
  }, interval);
}

const init = function () {
  setBottomSectionExpanded(false);

  const otContent = document.getElementById('ot-pc-content');
  if (!document.getElementById('ot-pc-content-hr')) {
    const hr = document.createElement('HR');
    hr.id = 'ot-pc-content-hr';
    document
      .getElementById('accept-recommended-btn-handler')
      .insertAdjacentElement('afterend', hr);
  }
  const catItems = otContent.getElementsByClassName('ot-cat-item');
  Array.from(catItems).map((catItem) => {
    const button = catItem.querySelector('button') as ExpandButtonElement;
    button.ariaExpanded = 'true';
  });

  const title = document.getElementById('ot-category-title');
  const titleWrapper = document.createElement('div');
  titleWrapper.id = 'ot-category-title-container';
  titleWrapper.appendChild(title);

  if (!document.getElementById('ot-category-title-container-button')) {
    const expandButton = document.createElement('div');
    expandButton.id = 'ot-category-title-container-button';
    titleWrapper.appendChild(expandButton);
  }

  document
    .getElementById('ot-pc-content-hr')
    .insertAdjacentElement('afterend', titleWrapper);

  if (document.getElementById('ot-category-title-container-button')) {
    renderChevronButton(
      'ot-category-title-container-button',
      setBottomSectionExpanded
    );
  }

  removeAccordionOnClick();
};

function removeAccordionOnClick() {
  document
    .querySelectorAll("[ot-accordion='true']")
    .forEach((otHeader: HTMLButtonElement) => {
      otHeader.remove();
    });
}

const renderChevronButton = (
  id: string,
  onClick: (expand: boolean) => void
) => {
  ReactDOM.render(
    <ChevronButton onClick={onClick} />,
    document.getElementById(id)
  );
};

function setBottomSectionExpanded(expanded: boolean) {
  const bottomSection = document.getElementsByClassName(
    'ot-sdk-row ot-cat-grp'
  )[0];
  const confirmButton = document.getElementsByClassName(
    'save-preference-btn-handler onetrust-close-btn-handler'
  )[0];
  if (expanded) {
    bottomSection.classList.remove('ot-pc-hidden');
    confirmButton.classList.remove('ot-pc-hidden');
  } else {
    bottomSection.classList.add('ot-pc-hidden');
    confirmButton.classList.add('ot-pc-hidden');
  }
}

function ChevronButton(props) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      onClick={() => {
        const tempIsExpanded = !isExpanded;
        setIsExpanded(tempIsExpanded);
        props.onClick(tempIsExpanded);
      }}
    >
      {isExpanded ? <ChevronDown /> : <ChevronUp />}
    </div>
  );
}
