import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectors as authSelectors } from 'state/modules/auth';

import { Box, Text } from 'domains/web/components';
import { Footer } from 'components';
import { OTPForm } from 'components/OTPVerification/components/OTPForm';

type LocationState = {
  email: string;
  last2PhoneNumberDigits?: string;
  redirect?: string;
};

export const VerifyCodePage = () => {
  const location = useLocation<LocationState>();
  const { email, last2PhoneNumberDigits, redirect } = location.state ?? {};
  const { t } = useTranslation('LoginPage');

  const [verifiedRedirect, setVerifiedRedirect] = useState(redirect);

  const loggedInUser = useSelector(authSelectors.getLoggedInUser);
  const history = useHistory();

  useEffect(() => {
    if (loggedInUser) {
      history.push('/dashboard');
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (email && !last2PhoneNumberDigits) {
      setVerifiedRedirect(`/save-phone-number?redirect=${redirect}`);
    }
  }, [email, last2PhoneNumberDigits]);

  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
    >
      <Helmet title="Verify Code" />
      <Box m="auto" width={480} maxWidth="100%" px={5} py={{ _: 5, md: 0 }}>
        <Text variant="title_l">
          {t(
            'verifyCode.enterYourVerificationCode',
            'Enter your verification code'
          )}
        </Text>
        <Trans
          context={last2PhoneNumberDigits ? 'with-last2PhoneNumberDigits' : ''}
          i18nKey={'LoginPage:verifyCode.enterCodeSubtitle'}
          values={{ email, last2PhoneNumberDigits }}
        >
          <Text>
            {'Click the link or enter the code sent to '}
            <strong>{{ email }}</strong>
            {last2PhoneNumberDigits ? (
              <>
                <span>{` or `}</span>
                <strong>{`(***)***-**${last2PhoneNumberDigits}`}</strong>
              </>
            ) : (
              ''
            )}
            {' in order to proceed with your Cameo request.'}
          </Text>
        </Trans>
        <Box mt={6}>
          <OTPForm email={email} redirect={verifiedRedirect} />
        </Box>
      </Box>
      <Box backgroundColor="background.level2" mt="auto">
        <Footer />
      </Box>
    </Box>
  );
};
