import React, { PureComponent } from 'react';
import handleViewport, { InjectedViewportProps } from 'react-in-viewport';
import styled from 'styled-components';
import { resizeImage } from 'utils/getResizedImageUrl';

type Props = {
  alt: string;
  className?: string;
  height?: number;
  imageKey?: string;
  imageSrc?: string;
  quality?: number;
  width?: number;
  preloadStarcardImage?: boolean;
};

const LazyImageStyle = styled.img<{
  enterCount: number;
  loaded: boolean;
}>`
  @keyframes load-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  object-fit: cover;
  opacity: 0;
  animation: ${({ enterCount, loaded }) =>
    enterCount > 0 && loaded
      ? `load-fade-in .5s ease-in-out forwards`
      : `none`};
  opacity: ${({ enterCount, loaded }) => (enterCount > 0 && loaded ? `0` : 1)};
`;

const ImmediateImageStyle = styled.img`
  object-fit: cover;
  opacity: 1 !important;
`;

class LazyImage extends PureComponent<Props & InjectedViewportProps> {
  static defaultProps = { alt: '' };

  state = {
    loaded: false,
  };

  onLoad = () => {
    this.setState({ loaded: true });
  };

  render() {
    const {
      alt,
      enterCount,
      height,
      imageKey,
      imageSrc,
      width,
      className,
      preloadStarcardImage,
    } = this.props;

    if (!imageKey && !imageSrc) {
      return null;
    }

    const displayURL = imageKey
      ? resizeImage(width, height, imageKey)
      : imageSrc;

    let imgSrc = null;
    // if the server overrides LazyImage loading, render the image with the url the first time
    if (preloadStarcardImage || enterCount > 0) {
      imgSrc = displayURL;
    }

    let ImageRenderer = LazyImageStyle;
    if (preloadStarcardImage) {
      ImageRenderer = ImmediateImageStyle;
    }

    return (
      <ImageRenderer
        src={imgSrc}
        alt={alt}
        onLoad={this.onLoad}
        enterCount={enterCount}
        loaded={this.state.loaded}
        className={className}
        data-testid="LazyImage"
        width={width}
        height={height}
      />
    );
  }
}

export default handleViewport(
  LazyImage,
  {
    rootMargin: '10%',
  },
  { disconnectOnLeave: true }
);
