import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BookingFields } from '../types';

export const useLocalDraftStorage = () => {
  const isBrowser = typeof window !== 'undefined';

  const saveDraft = useCallback(
    (formValues: BookingFields): string | undefined => {
      const draftId: string = uuidv4();

      if (isBrowser) {
        window.localStorage.setItem(
          `draft_${draftId}`,
          JSON.stringify(formValues)
        );

        return draftId;
      }

      return undefined;
    },
    [isBrowser]
  );

  const loadDraft = useCallback(
    (draftId: string): string | undefined => {
      if (isBrowser) {
        return window.localStorage.getItem(`draft_${draftId}`);
      }

      return undefined;
    },
    [isBrowser]
  );

  const removeDraft = useCallback(
    (draftId: string) => {
      if (isBrowser) {
        window.localStorage.removeItem(`draft_${draftId}`);
      }
    },
    [isBrowser]
  );

  return { saveDraft, loadDraft, removeDraft };
};
