import * as React from 'react';
import type { CustomIconProps } from './types';

function EasterIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M7.00039 0.799988C3.90039 0.799988 0.900391 5.49999 0.900391 9.09999C0.900391 12.7 3.30039 15.2 7.00039 15.2C10.7004 15.2 13.1004 12.8 13.1004 9.09999C13.1004 5.59999 10.1004 0.799988 7.00039 0.799988Z"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M1.20117 8.99996C1.20117 8.99996 3.50117 10.8 6.10117 10.5C8.70117 10.2 11.4012 7.79996 12.5012 7.39996"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default EasterIcon;
