import * as React from 'react';
import type { CustomIconProps } from './types';

function VideoIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 36 26" fill="none">
      <path
        d="M5.5 0.5C2.73858 0.5 0.5 2.73858 0.5 5.5L0.5 20.5C0.5 23.2614 2.73858 25.5 5.5 25.5L19.25 25.5C22.0114 25.5 24.25 23.2614 24.25 20.5L24.25 5.5C24.25 2.73858 22.0114 0.5 19.25 0.5L5.5 0.5Z"
        fill="#F2F1F3"
      />
      <path
        d="M31.2322 24.2499L26.75 19.7677L26.75 6.23217L31.2322 1.74994C32.8072 0.175024 35.5 1.29045 35.5 3.51771V22.4822C35.5 24.7094 32.8072 25.8249 31.2322 24.2499Z"
        fill="#F2F1F3"
      />
    </svg>
  );
}

export default VideoIcon;
