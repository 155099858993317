import { useCallback } from 'react';
import { client as apiClient } from 'helpers/ApiClient';
import { AdminUser } from 'types/src/api/models/user';
import { POST } from 'types/src/api/endpoints/auth/generateOtp';

type CreateUserOpts = {
  email: string;
  dob: string;
  phoneNumber?: string;
  addUserToAttentive?: boolean;
  preferences: {
    optInMarketingEmails?: boolean;
  };
};

export type GenerateOtpProps = {
  email: string;
  redirect: string;
  includeSMS?: boolean;
  isC4B?: boolean;
  c4bSkipAccountSetup?: boolean;
};

interface OTPServices {
  createUser: (data: CreateUserOpts) => Promise<AdminUser>;
  verifyOtp: (email: string, otp: string) => Promise<AdminUser>;
  generateOtp: (
    GenerateOtpProps: GenerateOtpProps
  ) => Promise<POST.ResponseBody>;
}

export const usePasswordlessServices = (): OTPServices => {
  const createUser = useCallback(async (user: CreateUserOpts) => {
    return await apiClient.post('/user/create', {
      data: {
        ...user,
        passwordless: true,
      },
    });
  }, []);

  const generateOtp = useCallback(
    async ({
      email,
      redirect,
      includeSMS = false,
      isC4B = false,
      c4bSkipAccountSetup = false,
    }: GenerateOtpProps) => {
      return await apiClient.post('/auth/generate-otp', {
        data: { redirect, email, includeSMS, isC4B, c4bSkipAccountSetup },
      });
    },
    []
  );

  const verifyOtp = useCallback(async (email: string, otp: string) => {
    return await apiClient.post('/auth/verify-otp', {
      data: { otp, email },
    });
  }, []);

  return {
    createUser,
    verifyOtp,
    generateOtp,
  };
};
