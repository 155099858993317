import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';

export const actionCreator = actionCreatorFactory('cameo/auth');

function asyncActionCreatorFactory<Type>() {
  return asyncFactory<Type>(actionCreator);
}

export default asyncActionCreatorFactory;
