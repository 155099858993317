import * as React from 'react';
import type { CustomIconProps } from './types';

function BirthdayCakeIconV2({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M5.40009 0.783447L4.69299 1.49056C4.30247 1.88109 4.30247 2.51425 4.69299 2.90478C5.08351 3.2953 5.71668 3.2953 6.1072 2.90478C6.49773 2.51425 6.49773 1.88109 6.1072 1.49056L5.40009 0.783447Z"
        fill="#F2F1F3"
      />
      <path
        d="M10.6001 0.783447L9.89299 1.49056C9.50247 1.88109 9.50247 2.51425 9.89299 2.90478C10.2835 3.2953 10.9167 3.2953 11.3072 2.90478C11.6977 2.51425 11.6977 1.88109 11.3072 1.49056L10.6001 0.783447Z"
        fill="#F2F1F3"
      />
      <path
        d="M8.00009 0.783447L8.7072 1.49056C9.09773 1.88109 9.09773 2.51425 8.7072 2.90478C8.31668 3.2953 7.68351 3.2953 7.29299 2.90478C6.90247 2.51425 6.90247 1.88109 7.29299 1.49056L8.00009 0.783447Z"
        fill="#F2F1F3"
      />
      <path
        d="M6.0001 4.59998C6.0001 4.2686 5.73147 3.99998 5.4001 3.99998C5.06873 3.99998 4.8001 4.2686 4.8001 4.59998V4.97101C3.85707 5.21475 3.2001 6.08343 3.2001 7.06853V7.14415C3.28038 7.12766 3.36193 7.11381 3.44468 7.10272C4.93558 6.90299 6.45628 6.79998 8.0001 6.79998C9.54392 6.79998 11.0646 6.90299 12.5555 7.10272C12.6383 7.11381 12.7198 7.12766 12.8001 7.14415V7.06853C12.8001 6.08343 12.1431 5.21475 11.2001 4.97101V4.59998C11.2001 4.2686 10.9315 3.99998 10.6001 3.99998C10.2687 3.99998 10.0001 4.2686 10.0001 4.59998V4.85461C9.53569 4.82917 9.06897 4.81254 8.6001 4.80488V4.59998C8.6001 4.2686 8.33147 3.99998 8.0001 3.99998C7.66873 3.99998 7.4001 4.2686 7.4001 4.59998V4.80488C6.93122 4.81254 6.4645 4.82917 6.0001 4.85461V4.59998Z"
        fill="#F2F1F3"
      />
      <path
        d="M3.60403 8.2921C5.04225 8.09942 6.50972 7.99998 8.0001 7.99998C9.49048 7.99998 10.9579 8.09942 12.3962 8.2921C13.5734 8.44981 14.4001 9.47025 14.4001 10.6231V11.1777C13.9438 11.1777 13.4876 11.0729 13.0684 10.8633C11.8923 10.2752 10.5079 10.2752 9.33177 10.8633C8.49346 11.2825 7.50673 11.2825 6.66843 10.8633C5.49229 10.2752 4.10791 10.2752 2.93177 10.8633C2.51262 11.0729 2.05636 11.1777 1.6001 11.1777V10.6231C1.6001 9.47026 2.4268 8.44981 3.60403 8.2921Z"
        fill="#F2F1F3"
      />
      <path
        d="M12.5318 11.9366C13.1198 12.2307 13.76 12.3777 14.4001 12.3777V13.2C14.4001 13.8627 13.8628 14.4 13.2001 14.4H2.8001C2.13736 14.4 1.6001 13.8627 1.6001 13.2V12.3777C2.24023 12.3777 2.88036 12.2307 3.46843 11.9366C4.30673 11.5175 5.29346 11.5175 6.13177 11.9366C7.30791 12.5247 8.69228 12.5247 9.86843 11.9366C10.7067 11.5175 11.6935 11.5175 12.5318 11.9366Z"
        fill="#F2F1F3"
      />
    </svg>
  );
}

export default BirthdayCakeIconV2;
