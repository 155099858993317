import { createSelector } from 'reselect';

import { State } from 'state/modules/reducer';
import { getCurrentPage } from 'state/modules/router/selectors';
import { CategoryNavItem } from 'types/src/api/endpoints/explore/categories';

/**
 * Few reasons why this file is overly complicated:
 * (i) we can't nest the navbar component inside of routes because of a global spinner
 * (i) we can't render the navbar as a top-level route because we don't support SSR multi-route matching
 * (i) we don't want to make an api call just yet to get categories on every page (performance issues)
 */

function getNavbarState(state: State) {
  return state.navbar;
}

const getIsPreviousPageStatic = createSelector(
  getNavbarState,
  (navbarState) => {
    return (
      navbarState.previousPathnames.length > 1 &&
      !navbarState.previousPathnames[0]?.startsWith('/browse') &&
      !navbarState.previousPathnames[0]?.startsWith('/categories')
    );
  }
);

const getIsCurrentPageStatic = createSelector(getNavbarState, (navbarState) => {
  return (
    !navbarState.previousPathnames.slice(-1)[0]?.startsWith('/browse') &&
    !navbarState.previousPathnames.slice(-1)[0]?.startsWith('/categories')
  );
});

/**
 * @description Sliding array of max length two, whose contents are the buttons to show
 * on the left-most side of the CategoryNav
 */
export const getNavLeftOptions = createSelector(
  [
    getNavbarState,
    getCurrentPage,
    getIsPreviousPageStatic,
    getIsCurrentPageStatic,
  ],
  (
    navbarState,
    currentPage,
    isPreviousPageStatic,
    isCurrentPageStatic
  ): CategoryNavItem[] => {
    if (!isCurrentPageStatic) {
      if (isPreviousPageStatic && navbarState.isLoading) {
        return [];
      }
      const { navLeftOptions } = navbarState;
      return navLeftOptions?.slice(-2);
    }

    return [];
  }
);

export function isLoading(state: State) {
  return state.navbar.isLoading;
}
type DEFAULT_NAV_FLAG = 'default-nav-options';
export const getNavRightOptions = createSelector(
  [
    getNavbarState,
    getCurrentPage,
    getIsPreviousPageStatic,
    getIsCurrentPageStatic,
  ],
  (
    navbarState,
    currentPage,
    isPreviousPageStatic,
    isCurrentPageStatic
  ): CategoryNavItem[] | DEFAULT_NAV_FLAG => {
    if (!isCurrentPageStatic) {
      // no good logic tree to get around the duplicate on 117 and the default return of the function
      if (isPreviousPageStatic && navbarState.isLoading) {
        return 'default-nav-options';
      }
      // when going from see all to dynamic, the right side affects the See All before the left + right options are ready
      return navbarState.navRightOptions;
    }

    return 'default-nav-options';
  }
);

export const getNotifications = createSelector(
  getNavbarState,
  (navbarState) => {
    return navbarState.notifications;
  }
);

export const getUnreadChannelCount = createSelector(
  getNavbarState,
  (navbarState) => navbarState.unreadChannelCount
);

export const getIsNavbarHidden = createSelector(
  getNavbarState,
  (navbarState) => navbarState.isNavbarHidden
);

export const getNavbarCategories = createSelector(
  getNavbarState,
  (navbarState) => navbarState.navbarCategories
);
