import * as React from 'react';
import type { CustomIconProps } from './types';

function EyeIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 22 18" fill="none">
      <path
        d="M1.03556 9.3224C0.966481 9.11513 0.966415 8.89073 1.03538 8.68343C2.42374 4.50972 6.3608 1.5 11.0008 1.5C15.6387 1.5 19.5742 4.50692 20.9643 8.6776C21.0334 8.88487 21.0335 9.10927 20.9645 9.31658C19.5762 13.4903 15.6391 16.5 10.9991 16.5C6.36121 16.5 2.42565 13.4931 1.03556 9.3224Z"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 9C14 10.6569 12.6569 12 11 12C9.34316 12 8.00001 10.6569 8.00001 9C8.00001 7.34315 9.34316 6 11 6C12.6569 6 14 7.34315 14 9Z"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EyeIcon;
