import * as React from 'react';
import type { CustomIconProps } from './types';

function FlameIcon({ height, width }: CustomIconProps) {
  return (
    <svg height={height} width={width} viewBox="0 0 12 13" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57 3.656C7.352 2.168 6.222 1.562 6.175 1.537a.325.325 0 00-.348.03.288.288 0 00-.102.317c.724 2.254.26 3.604-.416 4.115-.335.254-.73.31-1.03.144-.621-.342-.321-1.337-.319-1.347a.29.29 0 00-.123-.33.324.324 0 00-.368.011c-.779.58-1.625 1.88-1.446 3.635.202 1.963 1.884 3.388 4 3.388 2.185 0 3.893-1.621 3.972-3.77.053-1.415-.467-2.9-1.427-4.074z"
        fill="#FF0761"
      />
    </svg>
  );
}

export default FlameIcon;
