import React from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { theme } from '.';

export interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return (
    // @ts-expect-error: because the type declarations on types.d.ts are adding c4b theme
    <StyledComponentsThemeProvider theme={theme.DEFAULT}>
      {children}
    </StyledComponentsThemeProvider>
  );
};
