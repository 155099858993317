import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { setHttpReferrer } from './actions';

interface InitialState {
  referrer: string;
}

const INITIAL_STATE: InitialState = {
  referrer: '',
};

const httpHeadersReducer = reducerWithInitialState(INITIAL_STATE)
  .case(setHttpReferrer, (state, action) => {
    return {
      ...state,
      referrer: action,
    };
  })
  .build();

export default httpHeadersReducer;
