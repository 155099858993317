import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import browse from './browse';
import gifts from './gifts';
import { reducer as auth } from './auth';
// eslint-disable-next-line import/no-cycle
import orders from './orders';
import { reducer as payments } from './payments';
import reviews from './reviews';
import { reducer as users } from './users';
import { reducer as promotions } from './promotions';
import client from './client/reducer';
import asyncConnect from './asyncConnect';
import clientInfo from './clientInfo';
import navbar from './navbar/reducer';
import fanClubs from './fanClubs';
import contentFeed from './contentFeed/reducer';
import internalEvents from './internalEvents/reducer';
import ordersPage from './ordersPage';
import { blockUserReducer } from './blockUser';
import { businessVideoReducer } from './businessVideos/reducer';
import collage from './collage';
import { likesReducer } from './likes';

const createReducer = (history) => {
  return combineReducers({
    router: connectRouter(history),
    asyncConnect,
    auth,
    browse,
    businessVideos: businessVideoReducer,
    client,
    clientInfo,
    collage,
    contentFeed,
    fanClubs,
    form,
    gifts,
    internalEvents,
    navbar,
    orders,
    ordersPage,
    payments,
    promotions,
    reviews,
    users,
    blockUser: blockUserReducer,
    likes: likesReducer,
  });
};

// Represents the State of our redux store
export type State = ReturnType<ReturnType<typeof createReducer>>;

export default createReducer;
