export const types = {
  start: 'auth/LOAD',
  success: 'auth/LOAD_SUCCESS',
  fail: 'auth/LOAD_FAIL',
};

export const start = (state) => ({
  ...state,
  loading: true,
});

export const success = (state, action) => ({
  ...state,
  loading: false,
  loggedInUser: action.result,
  loaded: true,
});

export const fail = (state, action) => ({
  ...state,
  loading: false,
  loaded: false,
  error: action.error,
});

export const service = () => ({
  types: [types.start, types.success, types.fail],
  promise: (client) => client.get('/loadAuth'),
});
