import { LOCATION_CHANGE } from 'connected-react-router';
import analytics from 'analytics';
import qs from 'qs';

export default function analyticsMiddleware() {
  return ({ dispatch, getState }) => {
    return (next) => (action) => {
      const result = next(action);

      try {
        const data = {
          eventName: 'Page',
          targetDtl: {},
          sourceDtl: {},
        };
        switch (action.type) {
          case LOCATION_CHANGE:
            // Custom state property on navigation to allow replacement of query params without triggering additional navigation events
            if (action?.payload?.location?.state?.skipLocationChangeTracking) {
              break;
            }
            // this can be expanded to switch based on action.payload.location.pathname & fire specific events
            data.targetDtl.targetPath = action.payload.location.pathname;
            // if there is a search string present - add it to data & break it out into attributes (ex. utm codes)
            if (action.payload.location.search) {
              data.targetDtl.targetParams = action.payload.location.search;
              data.sourceDtl.sourcePathType =
                action.payload.location.pathname === '/results'
                  ? 'Quiz'
                  : 'Search';

              const verifyOtpPath = /\/otp\/[A-Z,0-9]{6}/g;
              if (verifyOtpPath.test(action.payload.location.pathname)) {
                data.sourceDtl.sourcePathType = 'Authentication';
              }

              data.sourceDtl.sourceParams = qs.parse(
                action.payload.location.search.split('?')[1]
              );
            }
            // if there is hash property in location, add it to navigation data
            if (action.payload.location.hash) {
              data.targetDtl.targetHash = action.payload.location.hash;
            }
            analytics.setPageId();
            analytics.trackNavigation(data);
            analytics.logPageView(
              analytics.getPageType(window.location.pathname),
              window.location.pathname,
              window.location.search.slice(1)
            );
            break;
          default:
        }
      } catch (error) {
        console.error('Problem capturing analytics event', { error });
      }

      return result;
    };
  };
}
