import { AppleUser } from '../../api/models/user';

/**
 * Supported login methods; each method requires a unique set of Credentials, defined below
 */
export enum LoginMethod {
  APPLE = 'apple',
  BIOMETRICS = 'biometrics',
  DATABASE = 'email',
  FACEBOOK = 'facebook',
  MAGIC_LINK_OPEN = 'open_magic_link',
  MAGIC_LINK_SEND = 'send_magic_link',
  AUTHORIZATION_CODE = 'passwordless',
  SOCIAL_TOKEN = 'social',
}

/**
 * Any time we store or reference the provider from an Auth0 tenant
 */
export enum Auth0Provider {
  APPLE = 'apple',
  DATABASE = 'auth0', // email/password
  FACEBOOK = 'facebook',
  PASSWORDLESS_EMAIL = 'email',
  PASSWORDLESS_SMS = 'sms',
}

/**
 * Any time we store or reference the provider from our own database
 */
export enum CameoProvider {
  APPLE = 'apple',
  DATABASE = 'local', // email/password
  FACEBOOK = 'facebook',
  PASSWORDLESS_EMAIL = 'passwordless_email',
  PASSWORDLESS_SMS = 'passwordless_sms',
}

/**
 * Required credentials for various login methods
 * as defined by clients, endpoints, or services
 */
export type Credentials = {
  [LoginMethod.APPLE]: {
    authorizationCode: string;
    appleUser: AppleUser;
  };

  [LoginMethod.AUTHORIZATION_CODE]: {
    email?: string;
    sms?: string;
    otp: string;
  };

  [LoginMethod.DATABASE]: {
    email: string;
    password: string;
    briefcaseCustomer?: boolean;
    signature?: string;
    currentTime?: number;
  };

  [LoginMethod.FACEBOOK]: {
    token: string;
  };

  [LoginMethod.BIOMETRICS]: {
    keychain: {
      username: string;
      password: string;
    };
  };

  [LoginMethod.MAGIC_LINK_OPEN]: {
    url?: string; // client-side
    access_token?: string; // api-side
  };

  [LoginMethod.MAGIC_LINK_SEND]: {
    email?: string;
    sms?: string;
  };

  [LoginMethod.SOCIAL_TOKEN]: {
    provider: Auth0Provider.APPLE | Auth0Provider.FACEBOOK;
    socialToken: string;
  };
};

/**
 * Describes all the steps/screens in our auth flow.
 */
export type AuthFlowStep =
  | 'OmniAuth'
  | 'EnterPassword'
  | 'SendPasswordResetLink'
  | 'LinkSentConfirmationLoginMagicLink'
  | 'LinkSentConfirmationCreateAccountMagicLink'
  | 'LinkSentConfirmationPasswordReset'
  | 'CompleteAccountSocial'
  | 'CompleteAccountEmail';

export enum GenerateOTPResponse {
  CompleteSignup = '1',
  VerifyOTP = '2',
  EnrollTalent = '3',
}
