import { AuthState } from '../types';

export type RequestPasswordResetParams = {
  email: string;
  briefcaseCustomer?: boolean;
  urlSearchParams?: string;
};

export type RequestPasswordResetState = Partial<AuthState>;

export const INITIAL_STATE: RequestPasswordResetState = Object.freeze({
  requestingPasswordReset: false,
  requestPasswordResetError: null,
  requestPasswordResetInfo: null,
});
