import * as React from 'react';
import type { CustomIconProps } from './types';

function SnowflakeIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M4.72579 6.25092C4.49417 6.38501 4.35156 6.63236 4.35156 6.89999V9.09999C4.35156 9.36762 4.49417 9.61497 4.72579 9.74906L6.62579 10.8491C6.85823 10.9836 7.14489 10.9836 7.37734 10.8491L9.27734 9.74906C9.50895 9.61497 9.65156 9.36762 9.65156 9.09999V6.89999C9.65156 6.63236 9.50895 6.38501 9.27734 6.25092L7.37734 5.15092C7.14489 5.01634 6.85823 5.01634 6.62579 5.15092L4.72579 6.25092Z"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 6.5L0.800781 4.39996"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.60156 5.39999L2.10156 3.79999"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.60039 5.39996L0.900391 5.89996"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 9.5L0.800781 11.6"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.60039 10.6L0.900391 10.1"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.60156 10.6L2.10156 12.2"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 11L7 15"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00078 13.1L5.80078 14.3"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 13.1L8.2 14.3"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 9.5L13.2004 11.6"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4004 10.6L11.9004 12.2"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4004 10.6L13.1004 10.1"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 6.5L13.2004 4.39996"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4004 5.39996L13.1004 5.89996"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4004 5.39999L11.9004 3.79999"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 5L7 1"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 2.90001L8.2 1.70001"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00078 2.90001L5.80078 1.70001"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SnowflakeIcon;
