type Breakpoints = {
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  xxl?: string;
};

/* eslint-disable prefer-destructuring */
export interface BreakpointsArray extends Array<string>, Breakpoints {}

export type ResponsiveVariant<T> =
  | T
  | Partial<Record<keyof Breakpoints | '_', T>>;

export const breakpoints: BreakpointsArray = [
  '36rem', // 576px - sm
  '48rem', // 768px - md
  '62rem', // 992px - lg
  '75rem', // 1200px - xl
  '90rem', // 1440px - xxl
];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];
breakpoints.xxl = breakpoints[4];
