import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { getGlobalVariable } from 'utils/getGlobalVariable';
import { CAMEO_LANGUAGE_COOKIE } from './constants';

// A union of all short locale names ('en'|'es'|...)
export type LocaleName = 'en' | 'fr' | 'es';

// A runtime interface containing information about a supported locale
export type LocaleMetadata<L extends LocaleName = LocaleName> = {
  // The full name of the locale (e.g. en, fr, ...)
  localeName: L;
  // Just the language code (e.g. en_US => en)
  languageCode: string;
  // The name of the language in itself (English, Español, etc.)
  nativeName: string;
  // The flag emoji to use for this language in the language picker
  flagEmoji: string;
};

// A map holding runtime information on all supported locales.
// Flag emojis are encoded as Unicode escapes to avoid issues with complex
// emojis in some terminals and editors.
export const LOCALES: { [L in LocaleName]?: LocaleMetadata<L> } = {
  en: {
    localeName: 'en',
    languageCode: 'en',
    nativeName: 'English',
    flagEmoji: '\u{1F1FA}\u{1F1F8}',
  },
  es: {
    localeName: 'es',
    languageCode: 'es',
    nativeName: 'Español',
    flagEmoji: '\u{1F1EA}\u{1F1F8}',
  },
  fr: {
    localeName: 'fr',
    languageCode: 'fr',
    nativeName: 'Français',
    flagEmoji: '\u{1F1EB}\u{1F1F7}',
  },
};

function getResourcePath(language) {
  return language;
}

export function getAllI18nNamespaces() {
  return import('../../i18n/locales/en').then(({ default: resources }) => {
    return [...Object.keys(resources), 'ReactNativeWebDesignSystem'];
  });
}

type LanguageCode = 'en' | 'es' | 'en-GB' | 'fr';

export function getLocaleCodeForCountryCode(
  countryCode?: string
): LanguageCode {
  if (!countryCode) return null;

  const countryCodeToLanguage = {
    us: 'en',
    es: 'es',
    gb: 'en-GB',
    fr: 'fr',
  };

  return countryCodeToLanguage[countryCode.toLowerCase()];
}

// All possible values: https://github.com/i18next/i18next-browser-languageDetector#detector-options
const BROWSER_DETECTION_ORDER = ['querystring', 'cookie', 'navigator'];

// All possible values: https://github.com/i18next/i18next-http-middleware#detector-options
const SSR_DETECTION_ORDER = ['querystring', 'cookie', 'header'];

const inBrowser = Boolean(
  getGlobalVariable('__WEBPACK_IS_CLIENT__') ||
    getGlobalVariable('__IS_CLIENT__')
);

/**
 * nonExplicitSupportedLngs and supportedLngs options work together to determine which language resource to load.
 *
 * Setting nonExplicitSupportedLngs to FALSE (default) means that region specific languages will fallback to their
 * base language if it is not found in the supportedLngs list.
 *
 * For example, en-GB will only load the en language resources.
 */

export const options: InitOptions = {
  // debug: true,
  supportedLngs: ['en', 'es', 'fr'],
  load: 'currentOnly',
  fallbackLng: {
    default: ['en'],
  },
  interpolation: { escapeValue: false },
  lowerCaseLng: true,
  detection: {
    order: inBrowser ? BROWSER_DETECTION_ORDER : SSR_DETECTION_ORDER,
    caches: ['cookie'],
    lookupQuerystring: 'lng',
    lookupCookie: CAMEO_LANGUAGE_COOKIE,
  },
  react: {
    wait: true,
    useSuspense: false,
  },
};

function lazyLoadResources(language, namespace) {
  const languageCache = inBrowser && getGlobalVariable('__languageData');
  if (
    languageCache &&
    languageCache[language] &&
    languageCache[language][namespace]
  ) {
    return Promise.resolve({ default: languageCache[language] });
  }

  // fix import path for project hermes
  if (getGlobalVariable('__IS_EXTERNAL_PROJECT__')) {
    return import(`../../i18n/locales/${getResourcePath(language)}/index.ts`);
  }

  /**
   * Using webpackExclude to prevent webpack from splitting out each namespace file
   * This causes an overloading of the watch resources when running locally.
   *
   * This should be re-visited in the future when optimizing language loading
   * at a namespace level.
   */
  return import(
    /* webpackExclude: /json$/ */
    /* webpackChunkName: "i18n/[request]" */
    `../../i18n/locales/${getResourcePath(language)}`
  );
}

export function initI18n(languageDetector) {
  if (!i18n.isInitialized) {
    return i18n
      .use(
        resourcesToBackend((language, namespace, callback) => {
          lazyLoadResources(language, namespace)
            .then(({ default: resources }) => {
              callback(null, resources[namespace]);
            })
            .catch((error) => {
              callback(error, null);
            });
        })
      )
      .use(languageDetector)
      .use(initReactI18next)

      .init(options)
      .then(() => i18n);
  }

  return Promise.resolve(i18n);
}
