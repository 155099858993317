import * as React from 'react';
import { Link as RRDLink, LinkProps } from 'react-router-dom';
import qs from 'qs';
import { useGetQueryParams } from 'utils/hooks/useGetQueryParams';

export interface Props extends Omit<LinkProps, 'to'> {
  to?: LinkProps['to'];
  href?: string;
}

export function Link({ to, children, href, ...rest }: Props) {
  const getQueryParams = useGetQueryParams();

  if (!href && typeof to !== 'string') {
    return (
      <RRDLink to={to} {...rest}>
        {children}
      </RRDLink>
    );
  }

  const [toPath, toQueryParamsString] = href
    ? href?.split('?')
    : to?.toString()?.split('?') || ['', ''];
  const toQueryParams = toQueryParamsString?.length
    ? qs.parse(toQueryParamsString)
    : {};

  const queryParams = getQueryParams(toQueryParams);

  if (href) {
    return (
      <a href={`${toPath}${queryParams}`} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <RRDLink
      {...rest}
      to={{
        pathname: toPath,
        search: queryParams,
      }}
    >
      {children}
    </RRDLink>
  );
}

Link.defaultProps = {
  href: undefined,
  to: undefined,
};
