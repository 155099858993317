import analytics from 'analytics';
import { config } from 'cameoConfig';
import { init } from '@eppo/js-client-sdk';

export const initEppo = () => {
  return init({
    apiKey: config.eppoSDK,
    requestTimeoutMs: 1500,
    assignmentLogger: {
      logAssignment(assignment) {
        const onDecision = ({ type, userId, attributes, decisionInfo }) => {
          const decisionEvent = {
            optimizelyId: userId,
            isIdentified: attributes.isIdentified,
            isGuest: attributes.isGuest,
            userId: attributes.userId,
            userRole: attributes.role,
          };
          if (type === 'feature') {
            analytics.logEvent({
              eventName: 'FeatureDecision',
              featureKey: decisionInfo.experimentKey,
              featureEnabled: decisionInfo.featureEnabled,
              ...decisionEvent,
            });
          } else if (type === 'ab-test') {
            analytics.logEvent({
              eventName: 'ExperimentDecision',
              experimentKey: decisionInfo.experimentKey,
              experimentVariationKey: decisionInfo.variationKey,
              isForcedVariation: false,
              ...decisionEvent,
            });
          }
        };
        onDecision({
          type: assignment.featureFlag.includes('experiment')
            ? 'ab-test'
            : 'feature',
          userId: assignment.subject,
          attributes: assignment.subjectAttributes,
          decisionInfo: {
            experimentKey: assignment.featureFlag,
            variationKey: assignment.variation,
            featureEnabled: assignment.variation === 'experiment',
          },
        });
      },
    },
  });
};
