import { thunkToAction } from 'typescript-fsa-redux-thunk';
import unconnectedSignup from 'services/auth/signup';
import authActionCreatorFactory from '../authActionCreatorFactory';
import { ActionError } from '../types';
import { AuthSignupParams, SignupResponse } from './types';

const asyncActionCreator = authActionCreatorFactory<SignupResponse>();

export const action = asyncActionCreator<
  AuthSignupParams,
  SignupResponse,
  ActionError
>('SIGNUP', unconnectedSignup);

export const service = thunkToAction(action);

export default action;
