import React from 'react';
import { NavColorContext } from 'containers/App/NavColorContext';
import { NavBar } from 'components';
import { Wrapper, Background } from './Styled';

interface OwnProps {
  shouldShowGradientBackground?: boolean;
  setAppNavHeightCallback?: (number) => void;
  isLean?: boolean;
  isFullWidth?: boolean;
  renderLoginButton?: () => React.ReactNode;
}
type Props = OwnProps;

export const AppNav = ({
  shouldShowGradientBackground = false,
  setAppNavHeightCallback,
  isFullWidth = false,
  renderLoginButton,
}: Props) => {
  const appNavRef = React.useRef(null);

  React.useEffect(() => {
    if (
      appNavRef &&
      appNavRef.current &&
      typeof setAppNavHeightCallback === 'function'
    ) {
      setAppNavHeightCallback(appNavRef.current.clientHeight);
    }
  }, [appNavRef, setAppNavHeightCallback]);

  return (
    <Wrapper ref={appNavRef}>
      <Background />
      <NavColorContext.Provider
        value={{ gradientActive: shouldShowGradientBackground }}
      >
        <NavBar
          isFullWidth={isFullWidth}
          renderLoginButton={renderLoginButton}
        />
      </NavColorContext.Provider>
    </Wrapper>
  );
};
