import aa from 'search-insights';
import { config } from 'cameoConfig';
import { SearchItem } from 'design-system/Components/SearchResults/types';
import { StarCardUserFields } from 'types/src/api/models/user';
import { CategoryItem } from 'types/src/api/endpoints/explore/categories';
import { dpOptimizelyIdManager } from 'analytics/utils/dpOptimizelyIdManager';

const ALGOLIA_APP_ID = config.algolia.appId;
const ALGOLIA_API_KEY = config.algolia.apiKey;

class AlgoliaAnalytics {
  private static instance;

  private inSearchExperiment = false;

  constructor() {
    try {
      aa('init', {
        appId: ALGOLIA_APP_ID,
        apiKey: ALGOLIA_API_KEY,
      });
    } catch (e) {
      console.error('error instantiating algolia analytics');
    }
  }

  public static getInstance(): AlgoliaAnalytics {
    if (!AlgoliaAnalytics.instance) {
      AlgoliaAnalytics.instance = new AlgoliaAnalytics();
    }

    return AlgoliaAnalytics.instance;
  }

  private getSessionId(): string {
    // This is the cameo session id value from the cookie and will match the backend value
    return dpOptimizelyIdManager.getOptimizelyId();
  }

  private trackClickedObjectAfterSearch({
    eventName,
    index,
    queryId,
    objectId,
    position,
  }: {
    eventName: string;
    index: string;
    queryId?: string;
    objectId?: string;
    position?: number;
  }) {
    const sessionId = this.getSessionId();
    // Algolia requires non-zero indexed positions
    const adjustedPosition = position === 0 || position ? position + 1 : null;
    aa('clickedObjectIDsAfterSearch', {
      userToken: sessionId,
      eventName,
      index,
      queryID: queryId,
      objectIDs: [objectId],
      positions: adjustedPosition ? [adjustedPosition] : null,
    });
  }

  private trackClickedObject({
    eventName,
    index,
    objectId,
  }: {
    eventName: string;
    index: string;
    objectId: string;
  }) {
    const sessionId = this.getSessionId();
    aa('clickedObjectIDs', {
      userToken: sessionId,
      eventName,
      index,
      objectIDs: [objectId],
    });
  }

  private trackConvertedObject({
    index,
    objectId,
  }: {
    index: string;
    objectId: string;
  }) {
    const sessionId = this.getSessionId();
    aa('convertedObjectIDs', {
      userToken: sessionId,
      eventName: 'ViewBookingFormNotAfterSearch',
      index,
      objectIDs: [objectId],
    });
  }

  private trackConvertedObjectAfterSearch({
    index,
    objectId,
    queryId,
  }: {
    index: string;
    objectId: string;
    queryId: string;
  }) {
    const sessionId = this.getSessionId();
    aa('convertedObjectIDsAfterSearch', {
      userToken: sessionId,
      eventName: 'ViewBookingForm',
      index,
      queryID: queryId,
      objectIDs: [objectId],
    });
  }

  private shouldTrack(): boolean {
    return this.inSearchExperiment;
  }

  private shouldTrackC4B(): boolean {
    return this.inSearchExperiment;
  }

  private getIndexName(type: 'user' | 'category' | 'tag' | 'c4b_user'): string {
    const ALGOLIA_ENV = config.environment !== 'production' ? 'dev' : 'prod';
    if (type === 'category' || type === 'tag') {
      return [ALGOLIA_ENV, 'cameo', 'categories', 'v0'].join('_');
    }

    if (type === 'user') {
      return [ALGOLIA_ENV, 'cameo', 'talent', 'v0'].join('_');
    }

    if (type === 'c4b_user') {
      return [ALGOLIA_ENV, 'cameo', 'business_talent', 'v0'].join('_');
    }

    return null;
  }

  public trackSearchResultClick({
    item,
    itemType,
    listIndex,
  }: {
    item: SearchItem;
    listIndex: number;
    itemType: string;
  }) {
    try {
      if (!this.shouldTrack() || !item._id) {
        return;
      }

      switch (itemType) {
        case 'User': {
          this.trackClickedObjectAfterSearch({
            index: item.aaIndex,
            objectId: item._id,
            position: listIndex,
            queryId: item.aaQueryId,
            eventName: 'SearchSuggestionTalentClick',
          });
          break;
        }
        case 'Category': {
          this.trackClickedObjectAfterSearch({
            index: item.aaIndex,
            objectId: item._id,
            position: listIndex,
            queryId: item.aaQueryId,
            eventName: 'SearchSuggestionCategoryClick',
          });
          break;
        }
        case 'Tag': {
          this.trackClickedObjectAfterSearch({
            index: item.aaIndex,
            objectId: item._id,
            position: listIndex,
            queryId: item.aaQueryId,
            eventName: 'SearchSuggestionTagClick',
          });
          break;
        }
        default:
          break;
      }
    } catch (e) {
      // Swallow errors and ensure no impact from analytics errors
    }
  }

  public trackC4BSearchResultClick({
    item,
    itemType,
    listIndex,
  }: {
    item: SearchItem;
    listIndex: number;
    itemType: string;
  }) {
    try {
      if (!this.shouldTrackC4B() || !item._id) {
        return;
      }

      switch (itemType) {
        case 'User': {
          this.trackClickedObjectAfterSearch({
            index: item.aaIndex,
            objectId: item._id,
            position: listIndex,
            queryId: item.aaQueryId,
            eventName: 'SearchSuggestionTalentClick',
          });
          break;
        }
        case 'Category': {
          this.trackClickedObjectAfterSearch({
            index: item.aaIndex,
            objectId: item._id,
            position: listIndex,
            queryId: item.aaQueryId,
            eventName: 'SearchSuggestionCategoryClick',
          });
          break;
        }
        case 'Tag': {
          this.trackClickedObjectAfterSearch({
            index: item.aaIndex,
            objectId: item._id,
            position: listIndex,
            queryId: item.aaQueryId,
            eventName: 'SearchSuggestionTagClick',
          });
          break;
        }
        default:
          break;
      }
    } catch (e) {
      // Swallow errors and ensure no impact from analytics errors
    }
  }

  public trackCategoryClick({ item }: { item: CategoryItem }) {
    try {
      if (!this.shouldTrack()) {
        return;
      }

      this.trackClickedObject({
        index: this.getIndexName('category'),
        objectId: item._id,
        eventName: 'CategoryTileClick',
      });
    } catch (e) {
      // Swallow errors and ensure no impact from analytics errors
    }
  }

  public trackStarCardClick({
    user,
    index,
    slug,
  }: {
    user: StarCardUserFields;
    index: number;
    slug?: string;
  }) {
    try {
      if (!this.shouldTrack() && slug !== 'business') {
        return;
      }
      if (slug === 'business' && !this.shouldTrackC4B()) {
        return;
      }

      if (user?.aaIndex && user?.aaQueryId) {
        this.trackClickedObjectAfterSearch({
          index: user?.aaIndex,
          objectId: user?._id,
          position: index,
          queryId: user?.aaQueryId,
          eventName: 'TalentStarCardClick',
        });
      } else {
        this.trackClickedObject({
          index: this.getIndexName(slug === 'business' ? 'c4b_user' : 'user'),
          objectId: user._id,
          eventName: 'TalentStarCardClickNotAfterSearch',
        });
      }
    } catch (e) {
      // Swallow errors and ensure no impact from analytics errors
    }
  }

  public trackConversion({
    talentId,
    queryId,
  }: {
    talentId: string;
    queryId?: string;
  }) {
    try {
      if (!this.shouldTrack()) {
        return;
      }

      const eventDtl = {
        objectId: talentId,
        index: this.getIndexName('user'),
      };

      if (queryId) {
        this.trackConvertedObjectAfterSearch({ queryId, ...eventDtl });
      } else {
        this.trackConvertedObject(eventDtl);
      }
    } catch (e) {
      // Swallow errors and ensure no impact from analytics errors
    }
  }

  public trackC4BConversion({
    talentId,
    queryId,
  }: {
    talentId: string;
    queryId?: string;
  }) {
    try {
      if (!this.shouldTrackC4B()) {
        return;
      }

      const eventDtl = {
        objectId: talentId,
        index: this.getIndexName('c4b_user'),
      };

      if (queryId) {
        this.trackConvertedObjectAfterSearch({ queryId, ...eventDtl });
      } else {
        this.trackConvertedObject(eventDtl);
      }
    } catch (e) {
      // Swallow errors and ensure no impact from analytics errors
    }
  }

  public init() {
    this.inSearchExperiment = config.envSpecificFeatureOverride.algolia;
  }
}

export default AlgoliaAnalytics.getInstance();
