import styled, { StyledComponent } from 'styled-components';
import { BorderProps, LayoutProps } from 'styled-system';
import omit from 'lodash/omit';
import React from 'react';

import { getBorderProps, getLayoutProps } from 'domains/web/utils/filterProps';
import { getSourceUrl } from 'domains/web/utils/getSourceUrl';
import { ThemeInterface } from 'domains/web/theme/types';
import { ResponsiveVariant } from 'domains/web/theme/breakpoints';
import { Text } from '../Text';
import { Box } from '../Box';

type BaseProps = {
  as?: React.ElementType | keyof JSX.IntrinsicElements;
  height?: number;
  onClick?: (event) => void;
  size?: number | ResponsiveVariant<number>;
  username: string;
  width?: number;
} & (
  | {
      imageUrlKey?: never;
      src: string;
    }
  | {
      imageUrlKey: string;
      src?: never;
    }
);

const getBiggerSizeFromObject = (
  size: number | ResponsiveVariant<number>
): number => {
  if (typeof size === 'number') {
    return size;
  }

  const values = Object.values(size);

  return Math.max(...values);
};

export type AvatarProps = LayoutProps &
  BorderProps &
  BaseProps &
  React.ImgHTMLAttributes<HTMLImageElement>;

// @ts-expect-error - read at the end of the file
export const Avatar: StyledComponentAvatarTypeProp = ({
  as = 'div',
  imageUrlKey,
  onClick,
  size = 40,
  src,
  username,
  ...rest
}: AvatarProps) => {
  const firstTwoLetters = username
    ? username.slice(0, 2).toUpperCase()
    : 'User';
  const imageResolution = {
    height: rest?.height ?? getBiggerSizeFromObject(size),
    width: rest?.width ?? getBiggerSizeFromObject(size),
  };

  const sourceUrl = getSourceUrl({
    src,
    imageUrlKey,
    /**
     * TODO: It would be great to being able to get the source url every time a new breakpoint is reached.
     * for instance, size={{ _: 64, md: 80, lg: 120 }} will result on a different source url for each breakpoint,
     * currently, the source url is only calculated once, when the component is mounted, using the biggest size.
     */
    height: imageResolution.height,
    width: imageResolution.width,
  });

  const layoutProps = getLayoutProps(rest);
  const borderProps = getBorderProps(rest);
  const imageProps = omit(rest, Object.keys(layoutProps), 'onClick');

  /**
   * Dealing with cases in which a dev can set as="a" and href="https://cameo.com/username"
   * and the avatar will be rendered as an anchor tag. Or as={Link} and to="/username" and
   * the avatar will be rendered as a Link component.
   *
   * It would be ideal to be able to have getImageProps(), but the list of props is too long
   */
  const containerProps = as !== 'div' ? { ...rest } : {};

  return (
    <Box
      as={as}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      width={size}
      height={size}
      borderRadius="50%"
      overflow="hidden"
      backgroundColor={sourceUrl ? 'transparent' : 'background.level3'}
      onClick={onClick}
      flexShrink={0}
      {...layoutProps}
      {...borderProps}
      {...containerProps}
    >
      {sourceUrl ? (
        <Img
          src={sourceUrl}
          alt={username}
          loading="lazy"
          height={imageResolution.height}
          width={imageResolution.width}
          {...imageProps}
        />
      ) : (
        <Text
          as="span"
          fontSize={`${Math.round(imageResolution.height / 2)}px`}
          fontWeight="bold"
        >
          {firstTwoLetters}
        </Text>
      )}
    </Box>
  );
};

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.15s ease-in-out;

  // Always display the alt text of the image in the middle of the container
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * The declaration of the StyledComponent does not seem to align with the definition of Avatar.
 * While removing the declaration would eliminate the issue, it would lead to a suboptimal developer experience
 * by breaking the prop validation check and potentially causing issues with the usage of <Avatar as={Link} />.
 * Ideally, the component would automatically detect the need for certain props, such as to for links and href for anchor tags,
 * to improve usability and prevent errors.
 */
type StyledComponentAvatarTypeProp = StyledComponent<
  'div',
  ThemeInterface,
  AvatarProps,
  never
>;
