import React, {
  useState,
  useEffect,
  useRef,
  SyntheticEvent,
  useCallback,
  KeyboardEvent,
  useContext,
} from 'react';
import { useSelector } from 'react-redux';
import { selectors as routerSelectors } from 'state/modules/router';
import { NavColorContext } from 'containers/App/NavColorContext';
import { Search as SearchIcon, X as XIcon } from 'react-feather';
import { useKidsExperience } from 'utils/hooks/useKidsExperience';
import { useIsProminentSearchBar } from 'utils/hooks/useIsProminentSearchBar';
import { color } from '../../style';

import { ClearButton, Input, SearchButton, Wrapper } from './Styled';

const MAX_QUERY_CHAR_COUNT = 60;
const SEARCH_ROTATION_IMTERVAL = 3000;

const searchQueries = [
  'The Office',
  'Harry Potter',
  'WWE',
  'Love is Blind',
  'Breaking Bad',
  'country music',
  'James Buckley',
  'Dallas Cowboys',
  'Real Housewives',
  'stand up',
  'Dean Norris',
];

type SearchBarProps = {
  isDesktop?: boolean;
  isOpen?: boolean;
  onChange: (value: string) => void;
  onClear?: () => void;
  onClick?: (e: React.MouseEvent | MouseEvent) => void;
  onFocusOrBlur?: () => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  value?: string;
};

const SearchBar = ({
  isDesktop,
  isOpen,
  onChange,
  onClear,
  onClick,
  onKeyDown,
  value,
  onFocusOrBlur,
}: SearchBarProps) => {
  const inputRef = useRef(null);
  const hasValue = value.length > 0;

  const isBusinessPage = useSelector(routerSelectors.isBusinessPage);
  const isKidsPage = useKidsExperience();
  const shouldUseProminentSearchBar = useIsProminentSearchBar();

  // NOTE: adding isBusinessPage logic here so its in one place
  const isProminentSearchBarExperimentEnabled =
    !isBusinessPage && shouldUseProminentSearchBar && !isKidsPage;

  const [selectedSeachTermIndex, setSelectedSeachTermIndex] = useState(0);

  useEffect(() => {
    if (isOpen) {
      inputRef.current.focus({ preventScroll: true });
    } else {
      inputRef.current.blur();
    }
  }, [isOpen]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => onKeyDown?.(event),
    [onKeyDown]
  );

  const handleInputChange = useCallback(
    ({ target: { value: newValue } }) => onChange?.(newValue),
    [onChange]
  );

  const handleClearButtonClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      onChange?.('');
      onClear?.();
    },
    [onChange, onClear]
  );

  const navColor = useContext(NavColorContext);
  const hasDarkBackground = !isProminentSearchBarExperimentEnabled;
  const iconColor = hasDarkBackground ? color.solids.WHITE : color.solids.BLACK;

  useEffect(() => {
    const intervalId = isProminentSearchBarExperimentEnabled
      ? setInterval(() => {
          setSelectedSeachTermIndex((prev) =>
            prev === searchQueries.length - 1 ? 0 : prev + 1
          );
        }, SEARCH_ROTATION_IMTERVAL)
      : null;

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Wrapper
      data-testid="SearchBar"
      isOpen={isOpen}
      onClick={onClick}
      $isTransparent={
        !isProminentSearchBarExperimentEnabled &&
        Boolean(navColor.gradientActive)
      }
      $hasDarkBackground={hasDarkBackground}
    >
      <form role="search">
        <Input
          aria-label="Search terms"
          onKeyDown={handleKeyDown}
          data-testid="SearchInput"
          ref={inputRef}
          autoFocus={isOpen}
          placeholder={
            isProminentSearchBarExperimentEnabled
              ? `Search for ${searchQueries[selectedSeachTermIndex]}...`
              : 'Search...'
          }
          value={value}
          onChange={handleInputChange}
          maxLength={MAX_QUERY_CHAR_COUNT}
          onFocus={onFocusOrBlur}
          onBlur={onFocusOrBlur}
        />
        <SearchButton
          type="button"
          data-testid="SearchButton"
          aria-label="Run search"
        >
          <SearchIcon color={iconColor} size={18} />
        </SearchButton>
        <ClearButton
          aria-label="Clear search"
          data-testid="SearchClear"
          role="button"
          type="button"
          isVisible={(isDesktop && hasValue) || (isOpen && hasValue)}
          onClick={handleClearButtonClick}
        >
          <XIcon color={iconColor} size={18} />
        </ClearButton>
      </form>
    </Wrapper>
  );
};

export default SearchBar;
