import { reducerWithInitialState } from 'typescript-fsa-reducers';
import Cameo from 'types';

import { actionCreator } from './utils';
import INITIAL_STATE from './initialState';
import { FanClubsState, Error } from './types';

type Params = { fanClubId: string };
type Metadata = {
  metadata: {
    fanClubId: string;
  };
};

export const LoadRelated = actionCreator.async<
  Params & Metadata,
  Cameo.Endpoints.FanClub.Related.GET.Response,
  Error
>('LOAD_RELATED');

export const loadRelated = ({ fanClubId }: Params) => ({
  types: [
    LoadRelated.started.type,
    LoadRelated.done.type,
    LoadRelated.failed.type,
  ],
  metadata: { fanClubId },
  promise: (client) => {
    return client.get(`/fanClub/${fanClubId}/related`);
  },
});

export const reducer = reducerWithInitialState<FanClubsState>(INITIAL_STATE)
  .case(LoadRelated.started, (state) => ({
    ...state,
    relatedLoading: true,
    relatedError: null,
  }))
  .case(LoadRelated.done, (state, { params, result }) => ({
    ...state,
    relatedLoading: false,
    related: {
      ...state.related,
      [params.metadata.fanClubId]: result,
    },
  }))
  .case(LoadRelated.failed, (state, { error }) => ({
    ...state,
    relatedLoading: false,
    relatedError: error.message,
  }))
  .build();
