export const hideScrollbars = `
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important;
  }
`;

export const utils = {
  hideScrollbars,
};
