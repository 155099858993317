import { v4 as uuidv4 } from 'uuid';
import { VISITOR_ID_KEY } from 'analytics/utils/constants';
import { dpCookieManager } from 'analytics/utils/dpCookieManager';

class DpVisitorManager {
  private visitorId: string;

  private isInitialized = false;

  private initialize() {
    if (this.isInitialized) {
      return;
    }

    const storedVisitorId = window.localStorage.getItem(VISITOR_ID_KEY);
    this.visitorId = storedVisitorId || null;

    window.addEventListener('storage', (event) => {
      if (event.key === VISITOR_ID_KEY) {
        this.visitorId = window.localStorage.getItem(VISITOR_ID_KEY);
      }
    });

    dpCookieManager.setVisitorIdCookie(this.visitorId);
    this.isInitialized = true;
  }

  public getVisitorId(): string {
    this.initialize();

    const visitorExists = Boolean(this.visitorId);
    if (!visitorExists) {
      this.startNewVisitor();
    }

    return this.visitorId;
  }

  public flushVisitorIdToDisk() {
    window.localStorage.setItem(VISITOR_ID_KEY, this.visitorId);
  }

  private startNewVisitor() {
    this.visitorId = uuidv4();
    this.flushVisitorIdToDisk();

    dpCookieManager.setVisitorIdCookie(this.visitorId);
  }

  // for test purpose only
  reset() {
    this.visitorId = undefined;
    this.isInitialized = false;
  }
}

export const dpVisitorManager = new DpVisitorManager();
