export const types = {
  start: 'cameo/payments/balance/get/start',
  success: 'cameo/payments/balance/get/success',
  fail: 'cameo/payments/balance/get/fail',
};

export const success = (state, action) => ({
  ...state,
  [action.result.currency]: {
    balance: action.result.balance,
  },
});

export const service = (userId, currency) => ({
  types: [types.start, types.success, types.fail],
  // TODO: Remove userId from path ('/payments/balance')
  promise: (client) =>
    client.get(
      `/payments/balance/${userId}${currency ? `?currency=${currency}` : ''}`
    ),
});
