/* eslint-disable react/jsx-no-literals */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LOCALES } from 'utils/i18n';
import { getLoggedInUserId } from 'state/modules/auth/selectors';
import { ChevronDown, ChevronUp } from 'react-feather';
import { getIsMobile } from 'state/modules/client/selectors';
import { CameoEventsExplorer } from './CameoEventsExplorer';

import {
  BannerText,
  Card,
  DesktopOnlyText,
  DevelopmentBanner,
  DevelopmentDashboard,
  Root,
} from './Styled';

type Props = {
  environment: string;
};

export const CameoDevTools = ({ environment }: Props) => {
  const { i18n } = useTranslation();
  const currentLocale = LOCALES[i18n.language] ?? LOCALES.en;
  const userId = useSelector(getLoggedInUserId);
  const [isOpen, setIsOpen] = useState(false);
  const [hasBeenOpen, setHasBeenOpen] = useState(false);
  const isMobile: boolean = useSelector(getIsMobile);

  const handleBannerClick = useCallback(() => {
    setIsOpen((open) => {
      if (!open) setHasBeenOpen(true);
      return !open;
    });
  }, [setIsOpen]);

  if (isMobile) {
    return null;
  }

  return (
    <Root sticky={isOpen}>
      <DevelopmentBanner onClick={handleBannerClick}>
        <BannerText>
          {currentLocale.flagEmoji}{' '}
          <DesktopOnlyText>{currentLocale.nativeName}</DesktopOnlyText>
        </BannerText>
        <BannerText>
          {environment}
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </BannerText>
        <BannerText>
          <DesktopOnlyText>userId: {userId}</DesktopOnlyText>
        </BannerText>
      </DevelopmentBanner>
      {hasBeenOpen && environment === 'development' && (
        <DevelopmentDashboard open={isOpen}>
          <Card>
            <CameoEventsExplorer />
          </Card>
        </DevelopmentDashboard>
      )}
      {hasBeenOpen && environment === 'production' && (
        <DevelopmentDashboard open={isOpen}>
          <Card>
            <CameoEventsExplorer />
          </Card>
        </DevelopmentDashboard>
      )}
    </Root>
  );
};
