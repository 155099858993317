import Cookies from 'universal-cookie';
import {
  SESSION_ID_COOKIE,
  VISITOR_ID_COOKIE,
} from 'analytics/utils/constants';
import { CAMEO_LANGUAGE_COOKIE } from 'utils/i18n/constants';
import { CAMEO_SESSION_IDENTIFIER_COOKIE_NAME } from 'shared-types/CameoSessionIdentifier';

class DpCookieManager {
  private cookies: Cookies;

  private isInitialized = false;

  private initialize() {
    if (this.isInitialized) {
      return;
    }
    this.cookies = new Cookies();
    this.isInitialized = true;
  }

  public getVisitorIdCookie(): string {
    this.initialize();

    return this.cookies.get(VISITOR_ID_COOKIE) as unknown as string;
  }

  /* current logic 8/9/2021: we are to set cookie in the following cases:
   * 1. create initial or new visitorId (startNewVisitor)
   * 2. create or update new session id (startNewSession)
   *
   * if the cookie gets cleared in the middle of a session, we will not try to set the cookie
   * however, when the user later closes the session and start a new session, the initialization will occur
   * and cookie will get set again.
   */
  public setVisitorIdCookie(value: string): boolean {
    this.initialize();

    // exclude undefined, null, empty visitor id
    if (typeof value === 'undefined' || !value || !value.trim()) {
      return false;
    }

    if (value === this.getVisitorIdCookie()) {
      return true;
    }

    try {
      this.cookies.set(VISITOR_ID_COOKIE, value);
      return true;
    } catch (_) {
      return false;
    }
  }

  public getSessionIdCookie(): string {
    this.initialize();

    return this.cookies.get(SESSION_ID_COOKIE) as unknown as string;
  }

  public setSessionIdCookie(value: string): boolean {
    this.initialize();

    // exclude undefined, null, empty session id
    if (typeof value === 'undefined' || !value || !value.trim()) {
      return false;
    }

    if (value === this.getSessionIdCookie()) {
      return true;
    }

    try {
      this.cookies.set(SESSION_ID_COOKIE, value);
      return true;
    } catch (_) {
      return false;
    }
  }

  public getLanguageCookie(): string {
    this.initialize();

    return this.cookies?.get(CAMEO_LANGUAGE_COOKIE) ?? 'en';
  }

  // cameoSessionCookie is where the value for _optimizelyId is being stored
  public getCameoSessionIdentifierCookie(): string {
    this.initialize();

    const cookieValue = this.cookies?.get(CAMEO_SESSION_IDENTIFIER_COOKIE_NAME);

    // @ts-ignore
    const cookieId = cookieValue.id;
    if (cookieId) {
      return cookieId;
    }
    return null;
  }
}

export const dpCookieManager = new DpCookieManager();
