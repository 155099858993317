import { getAge } from 'utils/validation';
import {
  AuthSignupParams,
  SignupResponse,
} from 'state/modules/auth/signup/types';
import { SignatureHeader } from 'services/auth/utils/types';
import { client as ApiClient } from '../../helpers/ApiClient';
import handleError from './utils/handleError';

/**
 * Signup
 *
 * @example
 *  import { AuthService } from 'services';
 *  AuthService.signup(data);
 */
export default function (data: AuthSignupParams): Promise<SignupResponse> {
  getAge(data.dob, 13);
  const body: {
    data: AuthSignupParams;
    headers?: SignatureHeader;
  } = {
    data,
  };
  const path = '/user/create'; // legacy

  return ApiClient.post(path, body).catch(handleError('signup'));
}
