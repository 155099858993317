import actionCreatorFactory from 'typescript-fsa';

export const actionCreator = actionCreatorFactory(
  'cameo/clientInfo/httpHeaders'
);

/**
 * Set the HTTP referrer.
 * This is useful to be used during server-side rendering.
 * In the client you can do window.document.referrer.
 */
export const setHttpReferrer = actionCreator<string>(
  'cameo/clientInfo/httpHeaders/setHttpReferrer'
);
