import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import qs from 'qs';

import { selectors as routerSelectors } from 'state/modules/router';

interface GetQueryParamsOptions {
  keepExisting?: boolean;
}

/**
 * Returns a callback that will generate a query param string based on the
 * current Cameo page and global store.
 *
 * @example
 * // current page: https://www.cameo.com/tags/business?test=true
 * const getQueryParams = useGetQueryParams();
 * const queryParams = getQueryParams({new: true}, {keepingExisting: true});
 * // queryParams: '?test=true&business=true&new=true'
 */
export function useGetQueryParams() {
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isBusinessPage = useSelector(routerSelectors.isBusinessPage);

  const getQueryParams = (
    moreQueryParams: { [key: string]: any } = {},
    options: GetQueryParamsOptions = {
      keepExisting: false,
    }
  ) => {
    const preservedParams = options.keepExisting ? queryParams : {};
    return qs.stringify(
      {
        ...preservedParams,
        ...moreQueryParams,
      },
      { addQueryPrefix: true }
    );
  };

  return React.useCallback(getQueryParams, [queryParams, isBusinessPage]);
}
