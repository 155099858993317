import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actionCreator } from './utils';
import INITIAL_STATE from './initialState';
import { FanClubsState, Error } from './types';

export const Unsubscribe = actionCreator.async<
  { fanClubId: string },
  any,
  Error
>('UNSUBSCRIBE');

export const unsubscribe = (fanClubId: string) => {
  return {
    types: [
      Unsubscribe.started.type,
      Unsubscribe.done.type,
      Unsubscribe.failed.type,
    ],
    promise: (client) => {
      return client.patch(`/fanClub/${fanClubId}/cancel`);
    },
  };
};

export const reducer = reducerWithInitialState<FanClubsState>(INITIAL_STATE)
  .case(Unsubscribe.started, (state) => {
    return {
      ...state,
      unsubscribeLoading: true,
      unsubscribeError: null,
    };
  })
  .case(Unsubscribe.done, (state, { result, params }) => {
    return {
      ...state,
      unsubscribeLoading: false,
      selectedFanClubUserDetails: {
        ...state.selectedFanClubUserDetails,
        userSubscriptionExpiresAt: result.expiresAt,
        userPendingCancellation: true,
      },
    };
  })
  .case(Unsubscribe.failed, (state, { error }) => {
    return {
      ...state,
      unsubscribeLoading: false,
      unsubscribeError: error,
    };
  })
  .build();
