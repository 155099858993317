// TODO the business rules in these helpers methods should be shared with the API
import { differenceInDays, intervalToDuration, parseISO } from 'date-fns';

/**
 * Given a user with a list of user promotions, we should determine which
 * promotion we should show in the UI. The backend will do the final validation
 * as to whether or not the promotion may be applied. The rules are as follows:
 * - Sort by most recently started
 * - Sort by soonest expiring
 * - Sort by mongo id
 * @param user A standard user object from the Cameo API.
 * @param {Boolean} includeWithoutUsesRemaining - Flag used to define if promotions
 *  without uses should be considered active or not. False as default.
 * @returns null | userPromotion object.
 * @todo Want to move this to the backend and run the filter there, but we have
 *       too many endpoints right now that makes that effort pretty difficult
 */

export function getActiveUserPromotion(user) {
  if (!user) return null;

  const { userPromotions } = user;

  if (!userPromotions?.length) return null;

  const activePromotions = userPromotions
    .filter((userPromotion) => {
      const currentISODate = new Date().toISOString();
      const isActive =
        userPromotion.startsAt <= currentISODate &&
        userPromotion.expiresAt >= currentISODate;
      return isActive;
    })
    .sort((userPromotionA, userPromotionB) => {
      if (userPromotionA.startsAt > userPromotionB.startsAt) return -1;
      if (userPromotionA.startsAt < userPromotionB.startsAt) return 1;
      if (userPromotionA.expiresAt < userPromotionB.expiresAt) return -1;
      if (userPromotionA.expiresAt > userPromotionB.expiresAt) return 1;
      // A larger object id essentially means recently created.
      if (userPromotionA._id > userPromotionB._id) return -1;
      return 0;
    });

  return activePromotions[0] || null;
}

/**
 * Calculates a new, discounted price for a given price and UserPromotion.
 * @param price talent price used to calculate the right discount.
 * @param promotion a standard userPromotion object from the Cameo API.
 * @returns a number that represents the lower price between the given price and the calculated discount price.
 */
export const getUserPromotionDiscountPrice = (
  price: number,
  promotion
): number => {
  let updatedPrice = 0;
  if (promotion.discountPrice) {
    updatedPrice = promotion.discountPrice;
  } else if (promotion.discountAmount || promotion.discountAmount === 0) {
    updatedPrice = price - promotion.discountAmount;
  } else if (promotion.discountPercent || promotion.discountPercent === 0) {
    updatedPrice = Math.round(price * (1 - promotion.discountPercent / 100));
  }

  if (updatedPrice <= 0) {
    updatedPrice = 0;
  }
  return Math.min(updatedPrice, price);
};

/**
 * Returns a boolean if the promotion is an unlimited-use promotion
 * @param promotion a standard userPromotion object from the Cameo API.
 * @returns boolean if the promotion is unlimited use
 */
export const isUnlimitedUserPromotion = (promotion) => {
  if (!promotion) return false;
  return promotion.maxUses === 0;
};

/**
 * Gets the current active promotion from a user and returns
 * true if there's a limited quantity, false otherwise if it's unlimited.
 * @param user
 * @returns
 */
export const isLimitedQuantityPromotion = (
  user: Cameo.Models.User.PublicUser
) => {
  const activeUserPromotion = getActiveUserPromotion(user);

  const isLimitedQuantityPromo =
    isUsableUserPromotion(activeUserPromotion) &&
    activeUserPromotion?.maxUses !== 0;

  return isLimitedQuantityPromo;
};

/**
 * Returns a boolean if the promotion is usable (has uses remaining)
 * @param promotion a standard userPromotion object from the Cameo API.
 * @returns boolean if the promotion is usable by the
 */
export const isUsableUserPromotion = (promotion) => {
  if (!promotion) return false;
  const currentISODate = new Date().toISOString();
  const isActive =
    promotion.startsAt <= currentISODate &&
    promotion.expiresAt >= currentISODate;
  return (
    isActive &&
    (isUnlimitedUserPromotion(promotion) || promotion.usesRemaining > 0)
  );
};

/**
 * Get the time left of the given promotion.
 * @param promotion a standard userPromotion object from the Cameo API.
 * @returns object containing the days, hours, minutes and seconds until userPromotion ends.
 */
export const getActiveUserPromotionTimeLeft = (promotion) => {
  const today = new Date();
  const endDate = parseISO(promotion.expiresAt);

  if (endDate < today) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  const duration = intervalToDuration({
    start: today,
    end: endDate,
  });

  return {
    days: duration.days,
    hours: duration.hours,
    minutes: duration.minutes,
    seconds: duration.seconds,
  };
};

export const getActiveUserPromotionDaysDuration = (promotion) => {
  /**
   * Get the active UserPromotion for a given user.
   * @param promotion a standard userPromotion object from the Cameo API.
   * @returns number of day between a promotion starts and ends.
   */

  const startDate = parseISO(promotion.startsAt);
  const endDate = parseISO(promotion.expiresAt);

  return differenceInDays(endDate, startDate);
};

export function getHasActiveButNotUsablePromotion(user) {
  const activeUserPromotion = getActiveUserPromotion(user);
  return Boolean(
    activeUserPromotion && !isUsableUserPromotion(activeUserPromotion)
  );
}
