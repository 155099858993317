import { useCallback, useState } from 'react';

const DEFAULT_CALLBACK_DELAY = 300;

interface Params {
  delay?: number;
  /*
   * Delay in ms for callback function to be called (how long does the user need
   * to hover on the component in order for the event to be called)
   */
}

export const useCallbackWithDelay = ({
  delay = DEFAULT_CALLBACK_DELAY,
}: Params) => {
  const [delayHandler, setDelayHandler] = useState(null);

  const delayCallback = useCallback(
    (callback: () => void) => {
      setDelayHandler(
        setTimeout(() => {
          callback();
        }, delay)
      );
    },
    [delay]
  );

  const cancelDelayedCallback = useCallback(() => {
    clearTimeout(delayHandler);
  }, [delayHandler]);

  return { delayCallback, cancelDelayedCallback };
};
