import styled from 'styled-components';
import { Link as RRDLink } from 'react-router-dom';
import { theme } from 'domains/web/theme/og';
import { animations, color, fonts, sizing, spacing } from '../../style';
import { Wrapper as Badge } from '../Badge';

export const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
`;

export const DropDownIndicator = styled.div<{ isExpanded: boolean }>`
  display: flex;
  justify-content: center;
  margin-left: ${spacing.margin.xsmall};
  transition: transform ${animations.transition.FAST}ms ease-in-out;
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export const Button = styled.button.attrs({ type: 'button', role: 'button' })`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  &:focus {
    outline: none;
  }
`;

export const List = styled.div.attrs({ role: 'list' })<{
  isExpanded: boolean;
  align: 'right' | 'left';
}>`
  transition: opacity ${animations.transition.FAST}ms linear,
    transform ${animations.transition.FAST}ms ease-in-out;
  display: inline-flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background.level2};
  border-radius: ${sizing(8)};
  overflow: hidden;
  position: absolute;
  pointer-events: ${({ isExpanded }) => (isExpanded ? 'inherit' : 'none')};
  opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
  transform: ${({ isExpanded }) =>
    isExpanded ? `translate3d(0, ${sizing(50)}, 0)` : `translate3d(0, 10%, 0)`};
  top: 0px;
  ${({ isExpanded }) => (isExpanded ? '' : 'height: 0px;')}
  ${({ align }) => `${align}: 0px;`};
  min-width: ${sizing(165)};
  z-index: 3;
`;

export const Item = styled(RRDLink).attrs({ role: 'listitem' })`
  ${fonts.regularText['400']};
  display: flex;
  align-items: center;
  color: ${color.solids.WHITE};
  padding: ${spacing.padding.medium} ${spacing.padding.large};
  cursor: pointer;
  min-height: ${sizing(50)};
  text-decoration: none;
  justify-content: start;

  &:hover {
    background-color: ${theme.colors.foreground.subtle};
    color: ${color.solids.WHITE};
    text-decoration: none;
  }

  ${Badge} {
    margin-top: ${spacing.margin.xxsmall};
  }
`;

export const ItemContent = styled.span`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

export const ItemIcon = styled.span`
  margin-left: 5px;
  margin-bottom: -3px;
`;
