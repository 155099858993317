import { Action } from 'typescript-fsa';
import { authFlow, authFlowReducer } from './authFlow';
import { clearAuthErrors, clearAuthErrorsReducer } from './clearAuthErrors';
import {
  continueWithApple,
  continueWithAppleReducer,
  continueWithAppleService,
} from './continueWithApple';
import {
  continueWithFacebook,
  continueWithFacebookReducer,
  continueWithFacebookService,
} from './continueWithFacebook';
import * as load from './load';
import { loginAction, loginService, loginReducer } from './login';
import {
  loginWithAction,
  loginWithService,
  loginWithReducer,
} from './loginWith';
import * as linkMessenger from './linkMessenger';
import {
  requestPasswordReset,
  requestPasswordResetReducer,
  requestPasswordResetService,
} from './requestPasswordReset';
import {
  resetPassword,
  resetPasswordReducer,
  resetPasswordService,
} from './resetPassword';
import { signup, signupReducer, signupService } from './signup';
import {
  resetPasswordTokenIsValid,
  resetPasswordTokenIsValidReducer,
  resetPasswordTokenIsValidService,
} from './resetPasswordTokenIsValid';
import * as enroll from './enroll';
import * as enrollPartner from './enrollPartner';
import { logout, logoutReducer, logoutService } from './logout';
import * as setAuthView from './setAuthView';
import * as constants from './constants';
import * as selectors from './selectors';
import { INITIAL_STATE } from './types';

interface AuthReducerAsyncAction extends Action<any> {
  result: Action<any>['payload'];
  userId?: string;
}

type AuthReducerAction = AuthReducerAsyncAction;

export function reducer(state = INITIAL_STATE, action: AuthReducerAction) {
  const asyncAction = action as AuthReducerAsyncAction;

  switch (asyncAction.type) {
    // handle all authFlow action types by passing state and action to its reducer
    case authFlow.startAuthFlow.type:
    case authFlow.closeAuthFlow.type:
      return authFlowReducer(state, action);
    // handle clearAuthErrors action type by passing state and action to its reducer
    case clearAuthErrors.type:
      return clearAuthErrorsReducer(state, action);
    // handle all continueWithApple action types by passing state and action to its reducer
    case continueWithApple.async.started.type:
    case continueWithApple.async.done.type:
    case continueWithApple.async.failed.type:
      return continueWithAppleReducer(state, action);
    // handle all ContinueWithFacebook action types by passing state and action to its reducer
    case continueWithFacebook.async.started.type:
    case continueWithFacebook.async.done.type:
    case continueWithFacebook.async.failed.type:
      return continueWithFacebookReducer(state, action);
    case setAuthView.SET_AUTH_VIEW:
      return setAuthView.setAuthView(state, action);

    case load.types.start:
      return load.start(state, action);
    case load.types.success:
      return load.success(state, action);
    case load.types.fail:
      return load.fail(state, action);

    // handle all login action types by passing state and action to its reducer
    case loginAction.async.started.type:
    case loginAction.async.done.type:
    case loginAction.async.failed.type:
      return loginReducer(state, action);

    case loginWithAction.async.started.type:
    case loginWithAction.async.done.type:
    case loginWithAction.async.failed.type:
      return loginWithReducer(state, action);

    case linkMessenger.types.start:
      return linkMessenger.start(state, action);
    case linkMessenger.types.success:
      return linkMessenger.success(state, action);
    case linkMessenger.types.fail:
      return linkMessenger.fail(state, action);

    // handle all requestPasswordReset action types by passing state and action to its reducer
    case requestPasswordReset.async.started.type:
    case requestPasswordReset.async.done.type:
    case requestPasswordReset.async.failed.type:
      return requestPasswordResetReducer(state, action);

    // handle all resetPassword action types by passing state and action to its reducer
    case resetPassword.async.started.type:
    case resetPassword.async.done.type:
    case resetPassword.async.failed.type:
      return resetPasswordReducer(state, action);

    // handle all signup action types by passing state and action to its reducer
    case signup.async.started.type:
    case signup.async.done.type:
    case signup.async.failed.type:
      return signupReducer(state, action);

    // handle all resetPasswordTokenIsValid action types by passing state and action to its reducer
    case resetPasswordTokenIsValid.async.started.type:
    case resetPasswordTokenIsValid.async.done.type:
    case resetPasswordTokenIsValid.async.failed.type:
      return resetPasswordTokenIsValidReducer(state, action);

    case enroll.types.start:
      return enroll.start(state, action);
    case enroll.types.success:
      return enroll.success(state, action);
    case enroll.types.fail:
      return enroll.fail(state, action);

    case enrollPartner.types.start:
      return enrollPartner.start(state);
    case enrollPartner.types.success:
      return enrollPartner.success(state);
    case enrollPartner.types.fail:
      return enrollPartner.fail(state, action);

    // handle all logout action types by passing state and action to its reducer
    case logout.async.started.type:
    case logout.async.done.type:
    case logout.async.failed.type:
      return logoutReducer(state, action);

    default:
      return state;
  }
}

const enrollService = enroll.service;
const linkMessengerService = linkMessenger.service;
const enrollPartnerService = enrollPartner.service;
const loadService = load.service;
const setAuthViewService = setAuthView.service;

const actions = {
  authFlow,
  clearAuthErrors,
  continueWithApple: continueWithAppleService,
  continueWithFacebook: continueWithFacebookService,
  enroll: enrollService,
  linkMessenger: linkMessengerService,
  load: loadService,
  login: loginService,
  loginWith: loginWithService,
  logout: logoutService,
  requestPasswordReset: requestPasswordResetService,
  resetPassword: resetPasswordService,
  setAuthView: setAuthViewService,
  signup: signupService,
  resetPasswordTokenIsValid: resetPasswordTokenIsValidService,
  enrollPartner: enrollPartnerService,
};

export { actions, constants, selectors };
