import React from 'react';
import { ThemeInterface } from 'domains/web/theme/types';
import styled, { css, CSSProp, StyledComponent } from 'styled-components';
import {
  compose,
  margin,
  MarginProps,
  ResponsiveValue,
  variant,
  width,
  WidthProps,
} from 'styled-system';
import { lighten, rgba } from 'polished';
import { Loading } from './Loading';
import { Box } from '../Box';

type BaseProps = {
  variant?:
    | 'primary'
    | 'minimal'
    | 'secondary'
    | 'tertiary'
    | 'transparent'
    | 'danger'
    | 'landing';
  size?: ResponsiveValue<'large' | 'medium' | 'small'>;
  modifier?: 'icon-only';
  loading?: boolean;
  /**
   * @warning - this prop is intended to be used only for branded buttons. For
   * example, the "Affirm" payment button. Do not use this prop for other buttons.
   */
  css?: CSSProp;
};

export type ButtonProps = React.PropsWithChildren<
  BaseProps &
    React.ButtonHTMLAttributes<HTMLButtonElement> &
    WidthProps &
    MarginProps
>;

const CoreButton: StyledComponentButtonTypeProp = styled.button.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => {
    return defaultValidatorFn(prop) && !['loading'].includes(prop);
  },
})<ButtonProps>`
  ${({ theme: t, modifier, disabled, loading, size }) => {
    const theme = t as unknown as ThemeInterface;
    const iconOnly = Boolean(modifier === 'icon-only');
    const gridGap = size === 'small' ? '0.6rem' : theme.space[2];

    return css`
      align-items: center;
      appearance: none;
      background-color: transparent;
      border-radius: ${iconOnly ? '50%' : '10rem'};
      border: 0.1rem solid transparent;
      cursor: ${disabled || loading ? 'not-allowed' : 'pointer'};
      display: grid;
      grid-auto-flow: column;
      grid-gap: ${gridGap};
      justify-content: center;
      margin: 0;
      opacity: ${disabled || loading ? 0.4 : 1};
      pointer-events: ${disabled || loading ? 'none' : 'auto'};
      position: relative;
      text-align: center;
      text-decoration: none;
      transition: ${theme.transitions.default};

      &,
      &[href],
      &:hover,
      &:active,
      &:focus {
        color: ${loading ? 'transparent' : theme.colors.foreground.default};
        text-decoration: none;
      }

      &:active {
        transform: scale(0.95);
      }

      &:focus-visible {
        outline: ${theme.colors.foreground.default} solid 0.2rem;
        outline-offset: -0.2rem;
      }

      .dsg-btn-icon,
      .dsg-btn-text {
        display: inline-flex;
      }

      .dsg-btn-icon + .dsg-btn-text {
        margin-right: calc(${gridGap} / 2);
      }

      .dsg-btn-text[data-position^='0'] {
        margin-left: calc(${gridGap} / 2);
      }

      ${ButtonTextCSS};
      ${ButtonIconCSS};

      ${variant({
        variants: {
          primary: {
            backgroundColor: theme.colors.accent.default,
            '&:hover': {
              backgroundColor: lighten(0.08, theme.colors.accent.default),
            },
          },
          minimal: {
            '&:hover': {
              backgroundColor: rgba(theme.colors.foreground.default, 0.08),
              textDecoration: 'underline',
            },
          },
          secondary: {
            borderColor: rgba(theme.colors.foreground.default, 0.6),
            '&:hover': {
              backgroundColor: rgba(theme.colors.foreground.default, 0.08),
            },
          },
          tertiary: {
            backgroundColor: theme.colors.background.level3,
            '&:hover': {
              backgroundColor: lighten(0.08, theme.colors.background.level3),
            },
          },
          transparent: {
            backgroundColor: rgba(theme.colors.background.level1, 0.4),
            '&:hover': {
              backgroundColor: rgba(theme.colors.background.level1, 0.48),
            },
          },
          danger: {
            backgroundColor: 'transparent',
            '&': {
              color: theme.colors.danger.emphasis,
              textDecoration: 'underline',
            },
            '&:hover': {
              color: theme.colors.danger.emphasis,
              textDecoration: 'underline',
            },
            '&:active': {
              color: theme.colors.danger.emphasis,
              textDecoration: 'underline',
            },
            '&:focus': {
              color: theme.colors.danger.emphasis,
              textDecoration: 'underline',
            },
          },
          landing: {
            backgroundColor: theme.colors.pink[50],
            '&:hover': {
              backgroundColor: theme.colors.pink[60],
            },
          },
        },
      })}

      ${variant({
        prop: 'size',
        variants: {
          large: {
            padding: iconOnly ? '1.4rem' : '1.6rem 3.2rem',
            width: iconOnly ? '5.2rem' : 'unset',
            height: iconOnly ? '5.2rem' : 'unset',
          },
          medium: {
            padding: iconOnly ? '1rem' : '1.2rem 2.4rem',
            width: iconOnly ? '4.4rem' : 'unset',
            height: iconOnly ? '4.4rem' : 'unset',
          },
          small: {
            padding: iconOnly ? '0.8rem' : '0.8rem 1.6rem',
            width: iconOnly ? '3.6rem' : 'unset',
            height: iconOnly ? '3.6rem' : 'unset',
          },
        },
      })}

      ${compose(width, margin)}
    `;
  }}
`;

CoreButton.defaultProps = {
  variant: 'minimal',
  size: 'medium',
};

const ButtonTextCSS = css`
  ${({ theme: t, size }: ButtonProps & { theme: ThemeInterface }) => {
    const theme = t as unknown as ThemeInterface;

    return css`
      text-decoration: none;
      font-family: ${theme.fonts.fontFamily.body};
      font-weight: ${theme.fonts.fontWeights.medium};
      font-size: ${size === 'small'
        ? theme.fonts.fontSizes.base_s
        : theme.fonts.fontSizes.base_m};
      line-height: ${size === 'small'
        ? theme.fonts.lineHeights.base_s
        : theme.fonts.lineHeights.base_m};
    `;
  }}
`;

/**
 * TODO: odd behavior on storybook Safari, the icon is not centered horizontally
 * when render in Canvas, but it is when render in Docs
 */
const ButtonIconCSS = css`
  ${({ theme, size, modifier }: ButtonProps & { theme: ThemeInterface }) =>
    modifier === 'icon-only'
      ? css`
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0;

          svg:not(.omit-button-icon) {
            height: 100%;
          }
        `
      : css`
          svg:not(.omit-button-icon) {
            height: ${size === 'small'
              ? theme.fonts.fontSizes.base_s
              : theme.fonts.fontSizes.base_m};
          }
        `}
`;

// @ts-expect-error - read at the end of the file
export const Button: StyledComponentButtonTypeProp = React.forwardRef(
  (props: StyledComponentButtonTypeProp, ref) => {
    /**
     * Manipulate children to allow target the text and icon
     * separately with CSS
     */
    const { children } = props;
    /**
     * Filter children is important to avoid to create empty spans that will produce
     * unexpected layout.
     */
    const nonNullishChildren = React.Children.toArray(children).filter(
      (child) =>
        child !== null &&
        child !== undefined &&
        typeof child === 'string' &&
        child.trim() !== ''
    );

    return (
      // @ts-expect-error - ts(2698)
      <CoreButton {...props} ref={ref}>
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          opacity={props.loading ? 1 : 0}
          style={{ pointerEvents: 'none' }}
          role={props.loading ? 'alert' : undefined}
          aria-busy={props.loading}
        >
          <Loading />
        </Box>
        {nonNullishChildren.length > 1
          ? nonNullishChildren.map((child, index) => (
              <span
                key={index}
                className={
                  typeof child === 'string' ? 'dsg-btn-text' : 'dsg-btn-icon'
                }
                data-position={`${index}/${nonNullishChildren.length}`}
              >
                {child}
              </span>
            ))
          : children}
      </CoreButton>
    );
  }
);

type StyledComponentButtonTypeProp = StyledComponent<
  'button',
  ThemeInterface,
  ButtonProps,
  never
>;
