import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { client } from '../../../../helpers/ApiClient';
import authActionCreatorFactory from '../authActionCreatorFactory';
import { ActionError } from '../types';
import { ResetPasswordTokenIsValidParams } from './types';

const asyncActionCreator =
  authActionCreatorFactory<ResetPasswordTokenIsValidParams>();

async function unconnectedResetPasswordTokenIsValid({
  token,
}: ResetPasswordTokenIsValidParams) {
  try {
    client.get(`/auth/resetPasswordTokenIsValid/${token}`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      'cameoweb.state.auth.resetPasswordTokenIsValid.ResetPasswordTokenIsValidError',
      error
    );
    throw new Error('Unable to test reset password token validity.');
  }
}

const resetPasswordTokenIsValid = asyncActionCreator<
  ResetPasswordTokenIsValidParams,
  void,
  ActionError
>('RESET_PASSWORD_TOKEN_IS_VALID', unconnectedResetPasswordTokenIsValid);

export const service = (token: string) =>
  thunkToAction(resetPasswordTokenIsValid)({ token });

export default resetPasswordTokenIsValid;
