import {
  PageHash,
  PageKeys,
  PageParams,
  PageQuery,
} from 'domains/c4b/pages/pages';

/**
 * Makes all fields in T optional if all fields in R are.
 */
type MaybeOptional<T, R> = Partial<T> extends T ? Partial<R> : R;
/**
 * Makes the given fields in T required.
 */
type RequireFields<T, K extends keyof T> = T &
  Required<Pick<T, Extract<K, keyof T>>>;

/**
 * Define properties that will be combined together to create
 * path descriptors for pages.
 */
/** The name of the page */
type Path<Page extends PageKeys> = { page: Page };
/** Any path params available to the page */
type Params<Page extends PageKeys> = { params: PageParams<Page> };
/** Any query params available to the page */
type Query<Page extends PageKeys> = { query: PageQuery<Page> };
/** Any hash available to the page */
type Hash<Page extends PageKeys> = { hash: PageHash<Page> };

/**
 * Generic Type utility to determine if a page has
 * url parameters.
 *
 * This will provide type safety for Briefcase path
 * generators. i.e. if you navigate to a page that expects
 * url params, you will get an error if you generate a path
 * without passing the appropriate URL params.
 */
export type PathDescriptor<Page extends PageKeys> = Path<Page> &
  // Makes 'params' field optional if there are no required path params
  MaybeOptional<PageParams<Page>, Params<Page>> &
  // Makes 'query' field optional if there are no required query params
  MaybeOptional<PageQuery<Page>, Query<Page>> &
  // Makes 'query' field optional if there are no required query params
  MaybeOptional<PageHash<Page>, Hash<Page>>;

/**
 * Type narrowing utility.
 * Will determine if a path has params and limit
 * the type of the passed in param to use the
 * correct page parameters
 * @param path
 */
export const hasParams = <Page extends PageKeys>(
  path: PathDescriptor<Page>
): path is RequireFields<PathDescriptor<Page>, 'params'> =>
  'params' in path && !!path.params && Object.keys(path.params).length > 0;

/**
 * Type narrowing utility.
 * Will determine if a path has query params and limit
 * the type of the passed in param to use the
 * correct page query params
 * @param path
 */
export const hasQuery = <Page extends PageKeys>(
  path: PathDescriptor<Page>
): path is RequireFields<PathDescriptor<Page>, 'query'> =>
  'query' in path && !!path.query && Object.keys(path.query).length > 0;

/**
 * Type narrowing utility.
 * Will determine if a path has a hash and limit
 * the type of the passed in param to use the
 * correct page hash
 * @param path
 */
export const hasHash = <Page extends PageKeys>(
  path: PathDescriptor<Page>
): path is RequireFields<PathDescriptor<Page>, 'hash'> =>
  'hash' in path && !!path.hash;
