const DEFAULT_HASH_ALGO = 'SHA-1';

/*
 * Private methods
 */

// Formats the hashed buffer as a hex string
function formatBufferAsHexString(hashBuffer: ArrayBuffer): string {
  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray
    .map((b) => `00${b.toString(16)}`.slice(-2))
    .join('');

  return hashHex;
}

// Formats a string as a buffer
function stringToBuffer(str: string) {
  const buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
  const bufView = new Uint16Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i += 1) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

/**
 * hashes the provided string into a collision resistant, short-length value;
 * @param {toHash} - string to hash
 * @returns - promise returning hash hex string
 */
export function generateSha1Hash(toHash: string): PromiseLike<string> {
  const utf8Buffer = stringToBuffer(toHash);
  return crypto.subtle
    .digest(DEFAULT_HASH_ALGO, utf8Buffer)
    .then((hashBuffer) => {
      return formatBufferAsHexString(hashBuffer);
    });
}
