interface InputUtilParams {
  inputValue: string;
  maxLength: number;
}

export const isOverMaxLength = ({ inputValue, maxLength }: InputUtilParams) =>
  inputValue?.length > maxLength;

export const getTextInputCount = ({ inputValue, maxLength }: InputUtilParams) =>
  isOverMaxLength({ inputValue, maxLength })
    ? `-${inputValue?.length - maxLength}`
    : `${maxLength - inputValue?.length}`;

export const getMaxLengthAndInputCount = ({
  inputValue,
  maxLength,
}: InputUtilParams) => ({
  isOverMaxLength: isOverMaxLength({ inputValue, maxLength }),
  getTextInputCount: getTextInputCount({ inputValue, maxLength }),
});
