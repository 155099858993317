/* eslint import/no-unresolved: 0 */
import superagent from 'superagent';
import { config } from 'cameoConfig';
import amplitude from 'analytics/clients/amplitude';
import { Headers } from 'types/src/utils/enums';
import { isServer } from './isServer';
import { getFingerprint } from '../services/auth/utils/fingerprint';

const METHODS = Object.freeze(['get', 'post', 'put', 'patch', 'del']);
export const REQUEST_HEADERS_TO_FORWARD = Object.freeze([
  'x-forwarded-for',
  'accept-language',
  'cf-ipcountry',
  'cf-ray',
]);

// This method prepends our API's URL, port, etc. to a relative path
export function formatAPIUrl(relativePath) {
  const adjustedPath =
    relativePath[0] !== '/' ? `/${relativePath}` : relativePath;
  if (isServer) {
    // Prepend host and port of the API server to the relative path.
    return `http://${config.apiHost}:${config.apiPort}${adjustedPath}`;
  }
  // Prepend `/api` to relative URL, to proxy to API server.
  return `/api${adjustedPath}`;
}

// HACK: CSRF is disabled for Algolia due to SSR issues, so skip requesting the token for these requests.
function isAlgoliaSearchRequest(requestUrl) {
  return (
    requestUrl.includes('v3/search/users') || requestUrl.includes('v3/search')
  );
}

// This returns an API request method that can make API calls
// from both client-side and server-side
function ApiClientMethod({ method }) {
  function makeApiCall(path, { params, data, headers } = {}) {
    return new Promise(async (resolve, reject) => {
      // We use superagent (basically a fetch API polyfill) for our AJAX calls.
      // See https://visionmedia.github.io/superagent/ for documentation
      const requestUrl = formatAPIUrl(path);
      const outboundHttpRequest = superagent[method](requestUrl);

      // Append query to GET request if one is present.
      // Input variable is called "params", but that's a misnomer
      if (params) {
        outboundHttpRequest.query(params);
      }

      if (headers) {
        Object.keys(headers).forEach((key) =>
          outboundHttpRequest.set(key, headers[key])
        );
      }

      // set Client capability headers
      outboundHttpRequest.set('X-CAMEO-TAG-Capabilities', 'dynamicShelves');

      // set amplitude sessionId
      if (amplitude.getSessionId()) {
        outboundHttpRequest.set(
          Headers.CAMEO_AMPLITUDE_SESSIONID,
          amplitude.getSessionId()
        );
      }

      const fingerprintData = await getFingerprint({
        url: requestUrl,
        method: method?.toUpperCase(),
      });

      if (
        !['GET', 'HEAD', 'OPTIONS'].includes(method?.toUpperCase()) &&
        !isAlgoliaSearchRequest(requestUrl)
      ) {
        const { token } = await this.get('/csrf/token');
        outboundHttpRequest.set('x-csrf-token', token);
      }

      if (fingerprintData) {
        outboundHttpRequest.set(
          'x-cameo-fingerprint-device-id',
          fingerprintData.deviceId
        );
        outboundHttpRequest.set(
          'x-cameo-fingerprint-request-id',
          fingerprintData.requestId
        );
        outboundHttpRequest.set(
          'x-cameo-fingerprint-provider',
          fingerprintData.provider
        );
      }

      // Server-side: Use AsyncLocalStorage to access request-specific variables
      // requestCookie, requestId, and requestHeaders for each server-side request.
      if (isServer) {
        const asyncLocalStorage = (await import('../storage')).default;
        const store = asyncLocalStorage.getStore();

        if (store) {
          if (store.get('requestCookie')) {
            const cookie = store.get('requestCookie');
            outboundHttpRequest.set('cookie', cookie);
          }

          // mirrors: REQUEST_ID_HEADER_NAME
          if (store.get('requestId')) {
            const requestId = store.get('requestId');
            outboundHttpRequest.set('x-request-id', requestId);
          }

          if (store.get('requestHeaders')) {
            const requestHeaders = store.get('requestHeaders');
            for (const [header, value] of Object.entries(requestHeaders)) {
              outboundHttpRequest.set(header, value);
            }
          }
        }
      }

      // If request has `data` object attached
      if (data) {
        // Clear all cached requests to be extra safe,
        // Since PUT/POST calls alter records
        // Append data to request body calls
        outboundHttpRequest.send(data);
      }

      // Sends AJAX call as a promise
      outboundHttpRequest.end((err, { body } = {}) =>
        err ? reject(body || err) : resolve(body)
      );
    });
  }
  return makeApiCall;
}

// On the client, this class is instantiated on page load.
// On the server, this class is re-instantiated for every page load as well.
export default class ApiClient {
  constructor() {
    METHODS.forEach((method) => {
      this[method] = new ApiClientMethod({ method });
    });
  }

  /*
   * There's a V8 bug where, when using Babel, exporting classes with only
   * constructors sometimes fails. Until it's patched, this is a solution to
   * "ApiClient is not defined" from issue #14.
   * https://github.com/erikras/react-redux-universal-hot-example/issues/14
   *
   * Relevant Babel bug (but they claim it's V8): https://phabricator.babeljs.io/T2455
   *
   * Remove it at your own risk.
   */
  /* eslint-disable class-methods-use-this */
  empty() {
    return null;
  }
  /* eslint-enable class-methods-use-this */
}

// pre-built client for use in services
export const client = new ApiClient();
