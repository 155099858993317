import { AuthState } from '../types';

export type ResetPasswordParams = {
  token: string;
  password: string;
  briefcaseResetPassword?: boolean;
};

export type ResetPasswordState = Partial<AuthState>;

export const INITIAL_STATE: ResetPasswordState = Object.freeze({
  resettingPassword: false,
  resetPasswordError: null,
  resetPasswordInfo: null,
});
