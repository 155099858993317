import { PublicLike } from 'types/src/api/models/likes';
import { LikeTargets } from 'types/src/utils/enums';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { AddLike, GetLikes, RemoveLike } from './actions';

export type State = {
  error: string | null;
  loading: boolean;
  likes: Array<
    Required<Pick<PublicLike, 'target' | 'targetId'>> & Partial<PublicLike>
  >;
};

const LIKES_INITIAL_STATE = Object.freeze<State>({
  error: null,
  loading: false,
  likes: [],
});

const likesReducer = reducerWithInitialState(LIKES_INITIAL_STATE);

likesReducer
  .cases([AddLike.started], (state, payload) => ({
    ...state,
    error: null,
    likes: [
      ...state.likes,
      { target: payload.target, targetId: payload.targetId },
    ],
  }))
  .cases([AddLike.done], (state, payload) => ({
    ...state,
    error: null,
    likes: [...state.likes.filter(({ _id }) => Boolean(_id)), payload.result],
  }))
  .cases([AddLike.failed], (state, payload) => ({
    ...state,
    error: payload.error,
    likes: [...state.likes.filter(({ _id }) => Boolean(_id))],
  }));

const removeLike = (
  state: Readonly<State>,
  { target: targetType, targetId }: { target: LikeTargets; targetId: string }
) => {
  const differentTypeLikes = state.likes.filter(
    (like) => like.target !== targetType
  );
  const sameTypeLikes = state.likes.filter(
    (like) => like.target === targetType
  );
  const newSameTypeLikes = sameTypeLikes.filter(
    (like) => like.targetId !== targetId
  );

  return [...differentTypeLikes, ...newSameTypeLikes];
};

likesReducer
  .cases([RemoveLike.started], (state, payload) => ({
    ...state,
    likes: removeLike(state, payload),
    error: null,
  }))
  .cases([RemoveLike.done], (state, payload) => {
    const likes = removeLike(state, {
      target: payload.result.target,
      targetId: payload.result.targetId,
    });

    return {
      ...state,
      error: null,
      likes,
    };
  })
  .cases([RemoveLike.failed], (state, payload) => ({
    ...state,
    likes: [
      ...state.likes,
      { target: payload.params.target, targetId: payload.params.targetId },
    ],
    error: payload.error,
  }));

likesReducer
  .cases([GetLikes.started], (state) => ({
    ...state,
    error: null,
    loading: true,
  }))
  .cases([GetLikes.done], (state, payload) => ({
    ...state,
    error: null,
    loading: false,
    likes: payload.result.map((data) => ({
      ...data,
      targetData: data.target,
      targetId: data.target._id,
      target: LikeTargets.ORDER,
    })),
  }));

export default likesReducer.build();
