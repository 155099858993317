import React from 'react';

type Props = {
  copy?: string;
  queryToMatch?: string;
};

const NameWithHighlight = ({ copy = '', queryToMatch = '' }: Props) => {
  if (
    copy?.length === 0 ||
    queryToMatch.length === 0 ||
    queryToMatch.length > copy?.length
  ) {
    return <>{copy}</>;
  }

  const parts = copy.split(
    new RegExp(`(${queryToMatch.match(/^[a-zA-Z]*$/)})`, 'gi')
  );

  return (
    <>
      {parts.map((str, index) => {
        const match = str.toLowerCase() === queryToMatch.toLowerCase();
        return match ? (
          // eslint-disable-next-line react/no-array-index-key
          <strong key={index}>{str}</strong>
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          <span key={index}>{str}</span>
        );
      })}
    </>
  );
};

export default NameWithHighlight;
