import { theme as cameoTheme } from '@cameo/theme';

const semantic = {
  ...cameoTheme.semanticColors,
};

const palette = {
  cobalt: {
    '20': cameoTheme.globalColors['cobalt-20'],
    '40': cameoTheme.globalColors['cobalt-40'],
    '70': cameoTheme.globalColors['cobalt-70'],
  },
  cyan: {
    '20': cameoTheme.globalColors['cyan-20'],
    '40': cameoTheme.globalColors['cyan-40'],
    '70': cameoTheme.globalColors['cyan-70'],
  },
  green: {
    '20': cameoTheme.globalColors['green-20'],
    '60': cameoTheme.globalColors['green-60'],
    '70': cameoTheme.globalColors['green-70'],
  },
  grey: {
    '07': cameoTheme.globalColors['grey-07'],
    '10': cameoTheme.globalColors['grey-10'],
    '20': cameoTheme.globalColors['grey-20'],
    '25': cameoTheme.globalColors['grey-25'],
    '30': cameoTheme.globalColors['grey-30'],
    '40': cameoTheme.globalColors['grey-40'],
    '50': cameoTheme.globalColors['grey-50'],
    '70': cameoTheme.globalColors['grey-70'],
  },
  pink: {
    '20': cameoTheme.globalColors['pink-20'],
    '50': cameoTheme.globalColors['pink-50'],
    '60': cameoTheme.globalColors['pink-60'],
  },
  purple: {
    '20': cameoTheme.globalColors['purple-20'],
    '50': cameoTheme.globalColors['purple-50'],
    '70': cameoTheme.globalColors['purple-70'],
  },
  red: {
    '20': cameoTheme.globalColors['red-20'],
    '40': cameoTheme.globalColors['red-40'],
    '70': cameoTheme.globalColors['red-70'],
  },
  yellow: {
    '20': cameoTheme.globalColors['yellow-20'],
    '60': cameoTheme.globalColors['yellow-60'],
    '70': cameoTheme.globalColors['yellow-70'],
  },
};

// TODO: SPOT CHECK ALL ICON COLORS / CONVERT

export const colors = {
  ...semantic,
  ...palette,
};
