export enum SignupPromo {
  RecipientValueProps = 'recipientValueProps',
}

export enum SignupPromoCreditAmount {
  RecipientValueProps = 2000,
}

export enum BriefcaseBlockReason {
  BAN = 'ban',
  POLICY_REJECTION = 'policyRejection',
}
