import { reducerWithInitialState } from 'typescript-fsa-reducers';
import setAnalyticsUser from '../utils/setAnalyticsUser';
import signup from './actions';
import { SignupState, INITIAL_STATE } from './types';

const reducer = reducerWithInitialState<SignupState>(INITIAL_STATE)
  .case(signup.async.started, (state) => ({
    ...state,
    signingUp: true,
    signupError: null,
  }))
  .case(signup.async.done, (state, payload) => {
    const user = payload.result;
    setAnalyticsUser(user);
    return {
      ...state,
      signingUp: false,
      loggedInUser: user,
    };
  })
  .case(signup.async.failed, (state, payload) => ({
    ...state,
    signingUp: false,
    signupError: payload.error.message ?? 'Something went wrong.',
  }));

export default reducer.build();
