// External dependency outside the domain
import { resizeImage } from 'utils/getResizedImageUrl';

import { getParsedSizeForResolution } from './getParsedSizeForResolution';

export type GetSourceUrlArgs = {
  width: number | string;
  height: number | string;
  resizerWidth?: number | string;
  resizerHeight?: number | string;
  resolution?: number;
} & (
  | {
      src: string;
      imageUrlKey?: never;
    }
  | {
      src?: never;
      imageUrlKey: string;
    }
  | {
      src?: string;
      imageUrlKey?: string;
    }
);

/**
 * It will prioritize the `src` when available, otherwise it will use the `imageUrlKey`
 * to get a properly resized "retina display" ready image using the resizer.
 */
export const getSourceUrl = ({
  src,
  imageUrlKey,
  width,
  height,
  resizerWidth,
  resizerHeight,
  resolution = 1.5,
}: GetSourceUrlArgs) => {
  if (src) {
    return src;
  }

  if (imageUrlKey) {
    return resizeImage(
      getParsedSizeForResolution(resizerWidth || width, resolution),
      getParsedSizeForResolution(resizerHeight || height, resolution),
      imageUrlKey
    );
  }

  // TODO: Should we throw an error with some guidance in this scenario?
  return undefined;
};
