import { combineReducers } from 'redux';
import { businessVideoDetailsReducer } from './getBusinessVideoById/reducer';
import { orderDetailsReducer } from './getOrderDetails/reducer';

const businessVideoReducer = combineReducers({
  videoDetails: businessVideoDetailsReducer,
  orderDetails: orderDetailsReducer,
});

export type State = ReturnType<typeof businessVideoReducer>;

export { businessVideoReducer };
