import { dpCookieManager } from 'analytics/utils/dpCookieManager';

class DataPlatformOptimizelyIdManager {
  private optimizelyId = null;

  private initialized = false;

  /**
   * This class is designed to help coordinate _optimizelyId
   * with the id that is being used by the optimizely client to bucket users.
   *
   * This grabs it from the cookie by default to find the value
   * unless or until the client has a manual setting of a new id from a user change.
   *
   * setOptimizelyId is being called as a callback on the client in client.js
   */
  private initialize() {
    this.initialized = true;
    this.optimizelyId = dpCookieManager.getCameoSessionIdentifierCookie();
  }

  public getOptimizelyId() {
    if (!this.initialized) {
      this.initialize();
    }
    return this.optimizelyId;
  }

  public setOptimizelyId(optimizelyId) {
    this.initialized = true;
    this.optimizelyId = optimizelyId;
  }

  public reset() {
    this.optimizelyId = null;
    this.initialized = false;
  }
}

export const dpOptimizelyIdManager = new DataPlatformOptimizelyIdManager();
