import { C4BRouteProps } from 'domains/c4b/pages/App/components/C4BRoute/C4BRoute';
import { ContactSalesFormSource } from 'types/src/api/endpoints/businessContact/createSalesLead';

// eslint-disable-next-line import/no-cycle
export { _usePathGenerator as usePathGenerator } from 'domains/c4b/pages/App/hooks/usePathGenerator/usePathGenerator';
// eslint-disable-next-line import/no-cycle
export { _useInboundBriefcasePath as useInboundBriefcasePath } from 'domains/c4b/pages/App/hooks/usePathGenerator/useInboundBriefcasePath';
// eslint-disable-next-line import/no-cycle
export { _usePath as usePath } from 'domains/c4b/pages/App/hooks/usePathGenerator/usePath';

/**
 * Defines a unique key for each page in Briefcase.
 */
export type PageKeys =
  | 'BusinessLandingPage'
  | 'BusinessBrowsePage'
  | 'BusinessCustomerStories'
  | 'BusinessTerms'
  | 'BusinessTalentProfilePage'
  | 'BusinessBookingPage'
  | 'LicenseExtensionPage'
  | 'LicenseExtensionConfirmationPage'
  /**
   * This page is no longer in use. It is kept here for backwards compatibility,
   * but will redirect to the new page.
   */
  | 'ViewLicensePage'
  | 'OrderConfirmationPage'
  | 'BusinessVideoListPage'
  | 'BusinessVideoDetailPage'
  | 'EditOrderPage'
  | 'LoginPage'
  | 'SignUpPage'
  | 'ResetPasswordPage'
  | 'CheckEmailForPasswordReset'
  | 'BusinessSetupPage'
  | 'BusinessChooseAccountPage'
  | 'BusinessSettingsPage'
  | 'AccountSettingsPage'
  | 'MobileProfilePage'
  | 'InboxPage'
  | 'InboxThreadPage'
  | 'BusinessCreditsPage'
  | 'OTPCodeVerificationPage'
  | 'ContactConfirmationPage'
  | 'ContactSalesPage'
  | 'BusinessTalentListsDetailPage'
  | 'BusinessTalentListsPage'
  | 'BlogLandingPage'
  | 'BlogPostPage'
  | 'BlogAllPostsPage'
  | 'BlogCategoryPage';

/**
 * Defines the home page key
 * for C4B.
 */
export const C4BHomePageKey = 'BusinessBrowsePage' as const;

/**
 * Defines url path params for each of the C4B routes.
 */
export type PageParams<Page extends PageKeys> =
  Page extends 'BusinessBrowsePage'
    ? { pageType: 'browse' | 'search' | 'tags'; slug?: string }
    : Page extends 'BusinessCustomerStories'
    ? { slug?: string }
    : Page extends 'BusinessBookingPage'
    ? { username: string }
    : Page extends 'BusinessVideoDetailPage'
    ? { businessVideoId: string }
    : Page extends 'ViewLicensePage'
    ? { businessVideoId: string }
    : Page extends 'LicenseExtensionPage'
    ? { businessVideoId: string }
    : Page extends 'EditOrderPage'
    ? { orderId: string }
    : Page extends 'OrderConfirmationPage'
    ? { cartId: string }
    : Page extends 'LicenseExtensionConfirmationPage'
    ? { businessVideoId: string; cartId: string }
    : Page extends 'BusinessTalentProfilePage'
    ? { username: string }
    : Page extends 'InboxThreadPage'
    ? { channelId: string }
    : Page extends 'OTPCodeVerificationPage'
    ? { code?: string }
    : Page extends 'BusinessTalentListsDetailPage'
    ? { talentListId: string }
    : never;

/**
 * Defines profile page query params that are supported
 * because we're reusing the consumer page components.
 * This can be removed when we build our C4B profile page.
 */
type ConsumerProfilePageQueryParams = {
  business?: 'true' | 'false';
  nodeId?: string;
  nodeType?: string;
};

/**
 * Defines query params for each of the C4B routes.
 */
export type PageQuery<Page extends PageKeys> =
  Page extends 'BusinessBookingPage'
    ? { rebookId?: string; draftId?: string; bp?: string; fs?: string }
    : Page extends 'SignUpPage'
    ? { redir?: string }
    : Page extends 'LoginPage'
    ? { redir?: string; passwordLogin?: 'true' | 'false' }
    : Page extends 'CheckEmailForPasswordReset'
    ? { email: string }
    : Page extends 'ResetPasswordPage'
    ? { redir?: string }
    : Page extends 'BusinessSetupPage'
    ? { redir?: string }
    : Page extends 'BusinessChooseAccountPage'
    ? { redir?: string }
    : Page extends 'BusinessBrowsePage'
    ? {
        queryId?: string;
        categorySlug?: string;
        tagSlug?: string;
        q?: string;
        /**
         * If provided, causes the "create talent list" modal
         * to auto-open and add the given talent to the created list.
         */
        talentListTalentId?: string;
      }
    : Page extends 'BusinessVideoListPage'
    ? { status?: string; talentName?: string; businessVideoId?: string }
    : Page extends 'BusinessTalentProfilePage'
    ? {
        queryId?: string;
        aaQueryId?: string;
        /**
         * If provided, causes the "create talent list" modal
         * to auto-open and add the given talent to the created list.
         */
        talentListTalentId?: string;
      } & ConsumerProfilePageQueryParams
    : Page extends 'BusinessCreditsPage'
    ? { virtualTransactionId?: string }
    : Page extends 'LicenseExtensionPage'
    ? { cartId?: string; redir?: string }
    : Page extends 'OTPCodeVerificationPage'
    ? {
        email: string;
        source?: 'signup' | 'login';
        redir?: string;
        skipAccountSetup?: 'true' | 'false';
      }
    : Page extends 'ContactConfirmationPage'
    ? {
        // Short for 'high-budget' or 'low-budget', controls page variant.
        // Defaults to 'low-budget'.
        variant?: 'hb' | 'lb';
        orderId?: string;
      }
    : Page extends 'ContactSalesPage'
    ? {
        utm_medium?: string;
        utm_campaign?: string;
        utm_source?: string;
        /** Specifies where the lead is sourced from */
        sourceType?: ContactSalesFormSource;
        /** Any ID relevant to the lead's source. Should be talent._id when sourceType='GhostProfile' */
        sourceId?: string;
        /** The talent username associated with the lead. Only relevant when sourceType='GhostProfile' */
        talentUsername?: string;
        /** The talent name associated with the lead. Only relevant when sourceType='GhostProfile' */
        talentName?: string;
      }
    : Page extends 'BusinessTalentListsDetailPage'
    ? {
        /**
         * If provided, causes the "copy list" modal
         * to auto-open for the current talent list.
         */
        copyList?: 'true' | 'false';
        /**
         * If provided, causes the "create talent list" modal
         * to auto-open and add the given talent to the created list.
         */
        talentListTalentId?: string;
      }
    : never;

/**
 * Defines query params for each of the C4B routes.
 */
export type PageHash<Page extends PageKeys> = Page extends 'BusinessBookingPage'
  ? string // The active form page key, loosely typed to avoid any possible circular dependencies
  : never;

/**
 * Defines parameters passable via location.state
 */
export type PageLocationState<Page extends PageKeys> = Page extends 'SignUpPage'
  ? {
      skipAccountSetup?: boolean;
    }
  : Page extends 'OTPCodeVerificationPage'
  ? {
      skipAccountSetup?: boolean;
    }
  : Page extends 'BusinessBrowsePage'
  ? {
      shouldDisplayWelcomeModal?: boolean;
    }
  : Page extends 'BusinessTalentListsDetailPage'
  ? {
      shouldDisplayWelcomeModal?: boolean;
    }
  : Page extends 'BusinessTalentProfilePage'
  ? {
      shouldDisplayWelcomeModal?: boolean;
    }
  : never;

const BusinessIdRegex = `([0-9a-fA-F]{24})`;

const defaultRouteConfig = Object.freeze({
  pageType: 'Business',
  exact: true,
} as const);

// TODO - can we remove the optional businessId param and redirect /business/:businessId/* routes?
type PageRouteConfig = Partial<C4BRouteProps> & Pick<C4BRouteProps, 'path'>;
/**
 * All available Pages in the C4B App
 */
const pageRouteConfig: Record<PageKeys, PageRouteConfig> = {
  BusinessLandingPage: {
    path: '/business',
  },
  LoginPage: {
    path: '/business/login',
  },
  SignUpPage: {
    path: '/business/signup',
  },
  ResetPasswordPage: {
    path: '/business/auth/password/reset/:token',
  },
  CheckEmailForPasswordReset: {
    path: '/business/auth/password-reset-confirmation',
  },
  BusinessSetupPage: {
    path: '/business/account-setup',
    isProtected: true,
  },
  BusinessTerms: {
    path: '/business/terms',
  },
  BusinessBookingPage: {
    path: `/business/:businessId${BusinessIdRegex}?/talent/:username/book`,
  },
  BusinessCustomerStories: {
    path: '/business/customer-stories/:slug?',
  },
  /**
   * In Feb 2023, the C4B team decided to change the BusinessVideoListPage
   * and BusinessVideoDetailPage routes from `/videos` to `/orders` to better
   * align with the CSMs terminology at the time. It was decided not to update
   * existing component names and page keys from `BusinessVideo{...}` to `Order{...}`
   * due to the scope of the required changes, and because the migration to `order`
   * was done for end-user convenience only.
   *
   * The team should consider renaming these keys and components if a broader
   * refactor of the corresponding components occurs.
   */
  BusinessVideoListPage: {
    path: `/business/:businessId${BusinessIdRegex}?/orders`,
    isProtected: true,
  },
  BusinessVideoDetailPage: {
    path: `/business/:businessId${BusinessIdRegex}?/orders/:businessVideoId`,
    isProtected: true,
  },
  ViewLicensePage: {
    path: `/business/:businessId${BusinessIdRegex}?/videos/:businessVideoId/view-license`,
    isProtected: true,
  },
  LicenseExtensionPage: {
    path: `/business/:businessId${BusinessIdRegex}?/videos/:businessVideoId/license-upgrade`,
    isProtected: true,
  },
  EditOrderPage: {
    path: `/business/:businessId${BusinessIdRegex}?/order/:orderId/edit`,
    isProtected: true,
  },
  OrderConfirmationPage: {
    path: `/business/:businessId${BusinessIdRegex}?/checkout/:cartId/confirmation`,
    isProtected: true,
  },
  LicenseExtensionConfirmationPage: {
    path: `/business/:businessId${BusinessIdRegex}?/videos/:businessVideoId/license/checkout/:cartId`,
    isProtected: true,
  },
  BusinessBrowsePage: {
    path: `/business/:businessId${BusinessIdRegex}?/:pageType(browse|search|tags)/:slug*`,
  },
  BusinessCreditsPage: {
    path: `/business/:businessId${BusinessIdRegex}?/credits`,
    isProtected: true,
  },
  BusinessTalentListsPage: {
    path: `/business/:businessId${BusinessIdRegex}?/talent-lists`,
    isProtected: true,
  },
  BusinessTalentListsDetailPage: {
    path: `/business/:businessId${BusinessIdRegex}?/talent-lists/:talentListId`,
  },
  BusinessTalentProfilePage: {
    path: `/business/:businessId${BusinessIdRegex}?/talent/:username`,
  },
  BusinessChooseAccountPage: {
    path: '/business/choose-account',
    isProtected: true,
  },
  BusinessSettingsPage: {
    path: `/business/:businessId${BusinessIdRegex}?/settings/business`,
    isProtected: true,
  },
  AccountSettingsPage: {
    path: `/business/settings/account`,
    isProtected: true,
  },
  MobileProfilePage: {
    path: `/business/m/profile`,
    isProtected: true,
  },
  InboxPage: {
    path: `/business/:businessId${BusinessIdRegex}?/inbox`,
    isProtected: true,
  },
  InboxThreadPage: {
    path: `/business/:businessId${BusinessIdRegex}?/inbox/:channelId`,
    isProtected: true,
  },
  OTPCodeVerificationPage: {
    path: `/business/otp/verify/:code?`,
    key: 'OTPCodeVerificatoinPage',
  },
  ContactConfirmationPage: {
    path: `/business/contact/confirmation`,
  },
  ContactSalesPage: {
    path: `/business/:businessId${BusinessIdRegex}?/contact`,
  },

  /** Blog routes */
  BlogLandingPage: {
    path: '/business/blog',
  },
  BlogPostPage: {
    path: '/business/blog/post/:slug',
  },
  BlogAllPostsPage: {
    path: '/business/blog/all',
  },
  BlogCategoryPage: {
    path: '/business/blog/category/:category',
  },
};

export const Paths: Record<
  PageKeys,
  Omit<C4BRouteProps, 'component'>
> = Object.fromEntries(
  Object.entries(pageRouteConfig).map<
    [PageKeys, Omit<C4BRouteProps, 'component'>]
  >((entry) => {
    const key = entry[0] as keyof typeof pageRouteConfig;
    const routeConfig: Omit<C4BRouteProps, 'component'> = {
      ...defaultRouteConfig,
      key,
      ...entry[1],
    };

    return [key, routeConfig];
  })
) as Record<PageKeys, Omit<C4BRouteProps, 'component'>>;

// A subset of paths supported for legacy redirections.
export type LegacyPageKeys = Extract<
  PageKeys,
  'BusinessVideoListPage' | 'BusinessVideoDetailPage'
>;
