import React, { ChangeEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Check } from 'react-feather';

import { Box, Form, Input, Text } from 'domains/web/components';
import { AdminUser } from 'types/src/api/models/user';
import { useLocalDraftStorage } from 'containers/BookingForm/hooks/useLocalDraftStorage';
import useQuery from 'utils/hooks/useQuery';
import { usePasswordlessServices } from 'utils/hooks/usePasswordlessServices';
import { useAutoSubmit } from '../hooks/useAutoSubmit';
import { trackError, trackLogin, trackResendCode } from './analytics';

interface Props {
  email: string;
  onSuccess?: (user: AdminUser) => void;
  redirect?: string;
}

export type ResendState = 'new' | 'error' | 'default';

export const OTPForm = ({ email, onSuccess, redirect }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ redirect?: string }>();
  const qs = useQuery();
  const [digits, setDigits] = useState<string>();
  const [resendState, setResendState] = useState<ResendState>('default');
  const [error, setError] = useState('');
  const { generateOtp, verifyOtp } = usePasswordlessServices();
  const { removeDraft } = useLocalDraftStorage();

  const { t } = useTranslation('OTPForm');

  const handleSubmit = useCallback(
    async (code: string) => {
      try {
        const user = await verifyOtp(email, code.toUpperCase());

        dispatch({
          type: 'cameo/auth/LOGIN_DONE',
          payload: {
            result: user,
          },
        });

        trackLogin();

        removeDraft(qs.get('draftKey'));

        if (redirect) {
          history.push(redirect, { redirect: location?.state?.redirect });
        }

        if (onSuccess) {
          onSuccess(user);
        }
      } catch (err) {
        setResendState('error');
        setError(err.message);
        trackError(err.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email, removeDraft, verifyOtp, redirect]
  );

  useAutoSubmit({ digits, submit: handleSubmit });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDigits(event.target.value);
  };

  const handleResend = async () => {
    trackResendCode('Resend code');
    try {
      setDigits('');
      await generateOtp({
        email,
        redirect: `${window.location.pathname}${window.location.search}`,
        includeSMS: true,
      });
      setResendState('new');
    } catch (err) {
      setError(err.message);
      setResendState('error');
    }
  };

  return (
    <>
      <Form.Control hasError={Boolean(error)} mb={3}>
        <Input
          data-testid="digits"
          autoComplete="one-time-code"
          maxLength={6}
          name="digits"
          type="text"
          value={digits}
          onChange={handleChange}
        />
        {resendState === 'new' && (
          <Form.HelpText>
            <Box
              as="span"
              color="success.default"
              display="inline-block"
              position="relative"
              // Compensate for the icon's shape
              top="0.3em"
            >
              <Check size={20} />
            </Box>{' '}
            {t('newCode', 'A new code has been sent.')}
          </Form.HelpText>
        )}
        {resendState === 'error' && <Form.ErrorText>{error}</Form.ErrorText>}
      </Form.Control>
      <Box display="flex" flexDirection="row">
        <Text variant="base_s" color="foreground.muted">
          {t('didntRecieveCode', "Didn't receive the code?")}{' '}
          <Text
            as="span"
            role="button"
            color="foreground.default"
            variant="base_s"
            modifier="interactive"
            onClick={handleResend}
            textDecoration="underline"
          >
            {'Resend code'}
          </Text>
        </Text>
      </Box>
    </>
  );
};
