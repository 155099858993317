/* eslint-disable no-plusplus */
import { differenceInDays } from 'date-fns';
import { Values } from '../types';

export { Values } from '../types';
export class BookingDraftManager {
  private static readonly MAX_DAYS_TO_EXPIRE = 5;

  public static readonly BOOKING_DRAFT_KEY = '@cameo/bookingDrafts';

  /**
   * Reads the booking drafts from localStorage.
   * @returns {Record<string, Values>} Object with talent usernames as keys and booking drafts values.
   */
  public static read(): Record<string, Values> {
    let drafts = {};

    if (typeof window === 'undefined') return drafts;

    try {
      drafts = JSON.parse(localStorage.getItem(this.BOOKING_DRAFT_KEY) || '{}');
      this.cleanup(drafts);
    } catch (e) {
      localStorage.removeItem(this.BOOKING_DRAFT_KEY);
      window.dispatchEvent(new Event('storage'));
    }

    return drafts;
  }

  public static update(newValue: Record<string, any>): void {
    if (typeof window === 'undefined') return;
    localStorage.setItem(this.BOOKING_DRAFT_KEY, JSON.stringify(newValue));
    window.dispatchEvent(new Event('storage'));
  }

  public static remove(talentUsername: string): void {
    const drafts = this.read();
    delete drafts[talentUsername];
    this.update(drafts);
  }

  private static cleanup(drafts: Record<string, Values>): void {
    const talents = Object.keys(drafts);

    if (talents.length === 0) {
      return;
    }

    for (let i = 0; i < talents.length; i++) {
      if (
        differenceInDays(new Date(), new Date(drafts[talents[i]].updatedAt)) >
        this.MAX_DAYS_TO_EXPIRE
      ) {
        this.remove(talents[i]);
      }
    }
  }
}
