import React from 'react';
import {
  toast as toastifyToast,
  ToastContainer as ToastifyToastContainer,
  ToastContainerProps,
} from 'react-toastify';
import styled, { keyframes } from 'styled-components';
import { X } from 'react-feather';
import { theme } from '@cameo/theme';
import { color, fonts, media, sizing } from '../../style';

const CONTAINER_ID = 'CENTER_BOTTOM_CONTAINER';

const ToastifyBounceInUp = keyframes`
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, ${sizing(3000)}, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -${sizing(20)}, 0);
  }

  75% {
    transform: translate3d(0, ${sizing(10)}, 0);
  }

  90% {
    transform: translate3d(0, -${sizing(5)}, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

const ToastifyTrackProgress = keyframes`
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
`;

const ToastifyBounceOutDown = keyframes`
  20% {
    transform: translate3d(0, ${sizing(10)}, 0);
  }

  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -${sizing(20)}, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, ${sizing(2000)}, 0);
  }
`;

const GlyphWrapper = styled.div`
  display: flex;
  padding-right: ${sizing(12)};

  svg {
    width: ${sizing(18)};
    height: ${sizing(18)};

    @media screen and (min-width: ${media.queries.TABLET}) {
      width: ${sizing(20)};
      height: ${sizing(20)};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ActionWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Action = styled.button`
  ${fonts.boldText['200']};
  display: inline-block;
  margin-left: ${sizing(12)};
  color: ${color.solids.DARK};
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;

  @media screen and (min-width: ${media.queries.TABLET}) {
    ${fonts.boldText['300']};
  }
`;

const CloseButton = styled.div`
  padding-left: ${sizing(12)};
  display: flex;
`;

const Text = styled.p`
  ${fonts.regularText['200']};
  display: inline-block;
  margin: 0;
  color: ${theme.colors.foreground.default};
  @media screen and (min-width: ${media.queries.TABLET}) {
    ${fonts.regularText['300']};
  }
`;

const MessageWrapper = styled.span`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const ToastComponent = ({ Glyph, actions, message, onClose }) => {
  const handleButtonClickAndClose = (onButtonClick) => () => {
    onButtonClick();
    if (onClose) onClose();
  };

  return (
    <Wrapper>
      <MessageWrapper>
        {Glyph && (
          <GlyphWrapper>
            <Glyph />
          </GlyphWrapper>
        )}
        <Text>{message}</Text>
      </MessageWrapper>
      {actions && actions.length > 0 && (
        <ActionWrapper>
          {actions.map((action) => (
            <Action
              onClick={handleButtonClickAndClose(action.onClick)}
              key={action.text}
            >
              {action.text}
            </Action>
          ))}
        </ActionWrapper>
      )}
    </Wrapper>
  );
};

export const WrappedToastContainer = ({
  className,
  ...rest
}: ToastContainerProps & { className?: string }) => (
  <div className={className}>
    <ToastifyToastContainer
      {...rest}
      closeOnClick={false}
      position="bottom-center"
      hideProgressBar
      newestOnTop
      autoClose={false}
      draggable
      pauseOnHover
      closeButton={
        <CloseButton>
          <X color={theme.colors.foreground.default} size={20} />
        </CloseButton>
      }
      enableMultiContainer
      containerId={CONTAINER_ID}
    />
  </div>
);

export const ToastContainer = styled(WrappedToastContainer).attrs({
  // custom props
})`
  .Toastify__toast-container {
    z-index: 9999;
    -webkit-transform: translate3d(0, 0, ${sizing(9999)});
    position: fixed;
    padding: ${sizing(4)};
    max-width: ${sizing(500)};
    box-sizing: border-box;
    color: ${theme.colors['grey-30']};
  }

  .Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 50%;
    margin-left: -${sizing(250)};
    text-align: center;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  @media only screen and (max-width: 480px) {
    .Toastify__toast-container {
      width: 100vw;
      max-width: 100%;
      padding: 0 1.6rem;
      left: 0;
      margin: 0;
    }

    .Toastify__toast-container--bottom-center {
      bottom: 0;
    }
  }

  .Toastify__toast {
    position: relative;
    min-height: 1rem;
    box-sizing: border-box;
    margin: 0 auto 2rem;
    display: flex;
    justify-content: space-between;
    max-height: ${sizing(800)};
    cursor: pointer;
    direction: ltr;

    /* custom styles */
    background-color: ${theme.colors['grey-30']};
    box-shadow: 0 1rem 3.2rem rgba(0, 0, 0, 0.75);
    border-radius: ${sizing(10)};
    padding: 1.2rem 1.2rem;
    width: 100%;
  }

  @media only screen and (max-width: 480px) {
    .Toastify__toast {
      margin-bottom: 2rem;
    }
  }

  .Toastify__toast-body {
    margin: auto 0;
    width: 100%;
  }

  .Toastify__bounce-enter--bottom-center {
    animation-name: ${ToastifyBounceInUp};
  }

  .Toastify__bounce-exit--bottom-center {
    animation-name: ${ToastifyBounceOutDown};
  }

  .Toastify__progress-bar {
    animation: ${ToastifyTrackProgress} linear 1;
  }
`;

export const toast = ({
  message,
  actions,
  autoClose,
  Glyph,
  onClose,
  toastId,
  closeButton = true,
}) => {
  const hasActions = Array.isArray(actions);

  if (actions && !hasActions)
    throw new Error('Toast actions should be an array');
  return toastifyToast(
    <ToastComponent
      Glyph={Glyph}
      actions={actions}
      message={message}
      onClose={onClose}
    />,
    {
      closeButton: closeButton && !hasActions,
      autoClose,
      toastId,
      containerId: CONTAINER_ID,
    }
  );
};
