import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { toggleIsSoundOn } from './actions';

interface InitialState {
  isSoundOn: boolean;
}

const INITIAL_STATE: InitialState = {
  isSoundOn: false,
};

const soundStateReducer = reducerWithInitialState(INITIAL_STATE)
  .case(toggleIsSoundOn, (state, action) => {
    return {
      ...state,
      isSoundOn: action.isSoundOn,
    };
  })
  .build();

export default soundStateReducer;
