import { AdminUser } from 'types/src/api/models/user';
import { Credentials, LoginMethod } from 'types/src/utils/enums';
import { client as ApiClient } from '../../../helpers/ApiClient';
import { LoginHeaders } from './types';
import handleError from './handleError';

export default function (
  credentials: Credentials[LoginMethod.APPLE]
): Promise<AdminUser> {
  const body: {
    data: Credentials[LoginMethod.APPLE];
    headers?: LoginHeaders;
  } = {
    data: credentials,
  };

  return ApiClient.post('/auth/apple/callback', body).catch(
    handleError('loginWithApple')
  );
}
