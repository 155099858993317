import * as React from 'react';
import type { CustomIconProps } from './types';

function PlusIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M14.4008 5.59999H10.4008V1.59999C10.4008 1.19999 10.0008 0.799988 9.60078 0.799988H6.40078C6.00078 0.799988 5.60078 1.19999 5.60078 1.59999V5.59999H1.60078C1.20078 5.59999 0.800781 5.99999 0.800781 6.39999V9.59999C0.800781 9.99999 1.20078 10.4 1.60078 10.4H5.60078V14.4C5.60078 14.8 6.00078 15.2 6.40078 15.2H9.60078C10.0008 15.2 10.4008 14.8 10.4008 14.4V10.4H14.4008C14.8008 10.4 15.2008 9.99999 15.2008 9.59999V6.39999C15.2008 5.99999 14.8008 5.59999 14.4008 5.59999Z"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default PlusIcon;
