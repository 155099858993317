/* eslint-disable id-length */

import React, { PropsWithChildren } from 'react';

import { Box, BoxProps } from '../Box';

export const Footer = ({
  children,
  layout,
  ...boxProps
}: PropsWithChildren<
  BoxProps & { layout?: 'row' | 'column' | BoxProps['flexDirection'] }
>) => {
  return (
    <Box
      as="footer"
      backgroundColor="background.level2"
      bottom={0}
      display="flex"
      gap={2}
      flexDirection={layout || boxProps.flexDirection}
      justifyContent="flex-end"
      position="sticky"
      pt={{ _: 5, sm: 6 }}
      {...boxProps}
    >
      {children}
    </Box>
  );
};
