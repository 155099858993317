import React from 'react';
import { selectors as navbarSelectors } from 'state/modules/navbar';
import { useSelector } from 'react-redux';
import NavDrawerComponent from './NavDrawer';

type Props = {
  handleDrawerMenuToggle: () => void;
  handleLogin: () => void;
  isUserLoggedIn: boolean;
  isVisible: boolean;
  shouldDisplayMerchLink: boolean;
  shouldDisplayKidsLink: boolean;
};

export const NavDrawer = ({
  handleDrawerMenuToggle,
  handleLogin,
  isUserLoggedIn,
  isVisible,
  shouldDisplayMerchLink,
  shouldDisplayKidsLink,
}: Props) => {
  const navbarCategories = useSelector(navbarSelectors.getNavbarCategories);

  return (
    <NavDrawerComponent
      categories={navbarCategories}
      handleDrawerMenuToggle={handleDrawerMenuToggle}
      handleLogin={handleLogin}
      isUserLoggedIn={isUserLoggedIn}
      isVisible={isVisible}
      shouldDisplayMerchLink={shouldDisplayMerchLink}
      shouldDisplayKidsLink={shouldDisplayKidsLink}
    />
  );
};
