import React from 'react';
import { ThemeInterface } from 'domains/web/theme/types';
import styled, { css } from 'styled-components';
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  RequiredTheme,
  ResponsiveValue,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  system,
  textAlign,
  TextAlignProps,
  ThemeValue,
} from 'styled-system';
import { transitionProp, TransitionProps } from 'domains/web/theme/transitions';
import { ResponsiveVariant } from 'domains/web/theme/breakpoints';

export type BoxProps = ColorProps &
  DisplayProps &
  BackgroundProps &
  LayoutProps &
  FlexboxProps &
  GridProps &
  BorderProps &
  PositionProps &
  ShadowProps &
  SpaceProps &
  TransitionProps &
  TextAlignProps & {
    as?: React.ElementType | keyof JSX.IntrinsicElements;
    backdropFilter?: CSSStyleDeclaration['backdropFilter'];
    cursor?: CSSStyleDeclaration['cursor'];
    boxSizing?: CSSStyleDeclaration['boxSizing'];
    scrollSnapType?: CSSStyleDeclaration['scrollSnapType'];
    scrollSnapAlign?: CSSStyleDeclaration['scrollSnapAlign'];
    scrollPaddingLeft?: CSSStyleDeclaration['scrollPaddingLeft'] | number;
    scrollMargin?: CSSStyleDeclaration['scrollMargin'];
    hideScrollbar?: boolean;
    touchAction?: CSSStyleDeclaration['touchAction'];
    aspectRatio?: ResponsiveVariant<CSSStyleDeclaration['aspectRatio']>;
    isolation?: CSSStyleDeclaration['isolation'];
    pointerEvents?: CSSStyleDeclaration['pointerEvents'];
    transform?: CSSStyleDeclaration['transform'];
    outline?: CSSStyleDeclaration['outline'];
    outlineColor?: ThemeValue<'colors', RequiredTheme>;
    // A silly workaround to use inert until https://github.com/facebook/react/pull/24730 is merged.
    inert?: string | boolean;
    gap?:
      | CSSStyleDeclaration['gap']
      | ResponsiveValue<ThemeValue<'space', RequiredTheme>, RequiredTheme>
      | keyof ThemeInterface['space']
      | number;
    css?:
      | string
      | (({
          theme,
        }: {
          theme: ThemeInterface;
        }) => string | ReturnType<typeof css>);
    onMouseEnter?: (e: React.MouseEvent<React.ElementType>) => void;
    onMouseLeave?: (e: React.MouseEvent<React.ElementType>) => void;
  };

export const Box = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => {
    return defaultValidatorFn(prop);
  },
})<BoxProps>`
  ${({ theme: t, hideScrollbar }) => {
    const theme = t as unknown as ThemeInterface;

    return css`
      list-style: none;
      appearance: none;
      background-color: transparent;
      border: none;
      color: ${theme.colors.foreground.default};
      margin: 0;
      padding: 0;

      &:hover {
        text-decoration: none;
      }

      ${hideScrollbar && HideScrollbarCSS}

      ${system({
        backdropFilter: true,
        cursor: true,
        pointerEvents: true,
        outline: true,
        outlineColor: {
          property: 'outlineColor',
          scale: 'colors',
        },
        touchAction: {
          property: 'touchAction',
        },
        aspectRatio: {
          property: 'aspectRatio',
        },
        boxSizing: {
          property: 'boxSizing',
        },
        isolation: {
          property: 'isolation',
        },
        gap: {
          property: 'gap',
          scale: 'space',
        },
        transform: {
          property: 'transform',
        },
        scrollSnapType: {
          property: 'scrollSnapType',
        },
        scrollSnapAlign: {
          property: 'scrollSnapAlign',
        },
        scrollMargin: {
          property: 'scrollMargin',
          scale: 'space',
        },
        scrollPaddingLeft: {
          property: 'scrollPaddingLeft',
          scale: 'space',
        },
      })}

      ${transitionProp}

      ${compose(
        display,
        background,
        border,
        color,
        flexbox,
        grid,
        layout,
        position,
        shadow,
        space,
        textAlign
      )}
    `;
  }}
`;

export const HideScrollbarCSS = css`
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
`;
