/* eslint-disable no-restricted-syntax */
import { useEffect } from 'react';
import { getInstance } from '@eppo/js-client-sdk';
import { initEppo } from 'utils/eppo';

interface IEppoRandomizationProvider {
  waitForInitialization?: boolean;
  children: JSX.Element;
  loadingComponent?: JSX.Element;
}

export default function EppoRandomizationProvider({
  children,
}: IEppoRandomizationProvider): JSX.Element {
  /**
   * Eppo is initialized before hydration
   * We check in case we need to re-initialize it
   */
  useEffect(() => {
    try {
      const eppoClient = getInstance();
      if (!eppoClient.isInitialized()) initEppo();
    } catch (e) {
      console.error('cameoweb.eppoSdk.reinit', {
        message: 'Eppo initialization failed',
      });
    }
  }, []);

  return children;
}
