import React from 'react';
import styled from 'styled-components';
import { fonts, media, sizing } from 'design-system/style';
import { useQuery } from 'utils/hooks';
import { useIsomorphicLayoutEffect } from 'domains/web/hooks/useIsomorphicLayoutEffect';

export const Root = styled.div<{ sticky: boolean }>`
  ${fonts.regularText[400]};
  color: ${({ theme }) => theme.colors.foreground.default};
  background-color: #000;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  ${({ sticky }) =>
    sticky &&
    `
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    background-color: #000;
  `}
`;

export const DevelopmentBannerBox = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  left: 0;
  opacity: 1;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

const DEV_TOOL_STORAGE_KEY = 'cameo-dev-tools-closed';
const isDevToolBannerClosedByDefault = () => {
  if (typeof window === 'undefined') return false;

  if (window.innerWidth < 576) return true;

  return localStorage.getItem(DEV_TOOL_STORAGE_KEY) === 'true';
};

export const DevelopmentBanner = ({
  children,
  ...rest
}: {
  children: React.ReactNode;
  onClick: () => void;
}) => {
  const queryParams = useQuery();
  const [isClosed, setIsClosed] = React.useState(false);

  useIsomorphicLayoutEffect(() => {
    setIsClosed(isDevToolBannerClosedByDefault());
  }, []);

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.ctrlKey &&
        event.metaKey &&
        event.altKey &&
        event.code === 'KeyC'
      ) {
        setIsClosed((state) => {
          const newState = !state;
          localStorage.setItem(DEV_TOOL_STORAGE_KEY, String(newState));

          return newState;
        });
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Help in mobile devices when we want to open the dev tools
  React.useEffect(() => {
    if (queryParams.get('qa') === 'true') {
      setIsClosed(false);
    }
  }, [queryParams]);

  if (isClosed) return null;

  return <DevelopmentBannerBox {...rest}>{children}</DevelopmentBannerBox>;
};

export const BannerText = styled.span`
  display: inline-flex;
  padding: ${sizing(4)} ${sizing(8)};
`;

export const DesktopOnlyText = styled.span`
  display: none;

  @media screen and (min-width: ${media.queries.TABLET}) {
    display: inline-flex;
  }
`;

export const DevelopmentDashboard = styled.div<{ open: boolean }>`
  background-color: #000;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  display: grid;
  grid-template-columns: 1fr;
  height: ${({ open }) => (open ? 'auto' : '0')};
  overflow: hidden;
  padding: 0 ${sizing(16)};
  width: 100%;
`;

export const Card = styled.div`
  background-color: #000;
  border-radius: ${sizing(16)};
  display: flex;
  flex-direction: column;
  margin-bottom: ${sizing(16)};
  padding: ${sizing(24)};
  width: 100%;
`;

export const SectionTitle = styled.div`
  ${fonts.boldText[400]};
  padding: ${sizing(8)} ${sizing(8)} ${sizing(8)} 0;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
