import { createSelector } from 'reselect';
import { State } from 'state/modules/reducer';
import { InternalEventsState } from 'state/modules/internalEvents/types';

export const selectInternalEvents = (state: State): InternalEventsState =>
  state.internalEvents;

export const selectEvents = createSelector(
  selectInternalEvents,
  (internalEvents) => internalEvents.events.reverse()
);
