import { AdminUser } from 'types/src/api/models/user';
import { Credentials, LoginMethod } from 'types/src/utils/enums';
import { AuthState } from '../types';

export type ContinueWithAppleParams = Credentials[LoginMethod.APPLE];

export type ContinueWithAppleResponse = {
  user: AdminUser;
};

export type ContinueWithAppleState = Partial<AuthState>;

export const INITIAL_STATE: ContinueWithAppleState = Object.freeze({
  loggedInUser: {} as AdminUser,
  loginError: null,
  signupError: null,
  loggingIn: false,
});
