import analytics from 'analytics';
import { CategoryItem } from 'types/src/api/endpoints/explore/categories';

export const trackCategoryTileClickEvent = (
  category: CategoryItem,
  index: number
) => {
  const data = {
    eventName: 'Navigate',
    eventDtl: {
      eventType: 'Click',
    },
    objectDtl: {
      objectType: 'Card',
      objectName: 'CategoryTile',
      containerType: 'Search',
    },
    positionDtl: {
      col: index % 2,
      row: index / 2,
      rowLabelText: category.slug,
      rowLabelType: category.name,
    },
    sourceDtl: {
      sourceKey: 'Category',
      sourceValue: category._id,
    },
    targetDtl: {
      targetType: 'Category-Browse',
      targetPath: category.materializedPath,
      targetKey: 'Category',
      targetValue: category._id,
    },
  } as const;

  analytics.trackAction(data);
};
