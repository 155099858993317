export enum BookingPlatform {
  WEB = 'web',
  IOS = 'ios',
  ANDROID = 'android',
}

export enum PaymentProcessor {
  stripe = 'stripe',
  stripeMex = 'stripeMexico',
  stripeBra = 'stripeBrazil',
  paypal = 'paypal',
  affirm = 'affirm',
  appStore = 'appStore',
  pledge = 'pledge',
  cameoPaymentService = 'cameoPaymentService',
  androidIAP = 'androidIAP', // cart iap
  appleIAP = 'appleIAP', // cart iap
  cameo = 'cameo',
}

/**
 * The target collection of a payment operation.
 */
export enum PaymentTarget {
  message = 'message',
  order = 'order',
  liveEvent = 'liveEvent',
  liveEventCall = 'liveEventCall',
  liveEventTicket = 'liveEventTicket',
  fanClubUser = 'fanClubUser',
  giftCard = 'giftCard',
  transferRequest = 'transferRequest',
  broadcastEvent = 'broadcastEvent',
  interactionRequest = 'interactionRequest',
  cart = 'cart',
  tip = 'tip',
  licenseActivation = 'licenseActivation',
  postCard = 'postCard',
  digitalPostCard = 'digitalPostCard',
  thankYouNote = 'thankYouNote',
}

export enum ProductType {
  fanClubMembership = 'fanClubMembership',
  interactionRequest = 'interactionRequest',
  liveEvent = 'liveEvent',
  messageSticker = 'messageSticker',
  messageText = 'messageText',
  shoutoutClassic = 'shoutoutClassic',
  shoutoutPolitical = 'shoutoutPolitical',

  thankYouNote = 'thankYouNote',

  // business pricing
  shoutoutBusinessInternalComms = 'shoutoutBusinessInternalComms',
  shoutoutBusinessExternalEvents = 'shoutoutBusinessExternalEvents',
  shoutoutBusinessExternalMarketing = 'shoutoutBusinessExternalMarketing',

  // legacy business pricing
  shoutoutBusinessInternal = 'shoutoutBusinessInternal',
  shoutoutBusinessInternalEvents = 'shoutoutBusinessInternalEvents',
  shoutoutBusinessExternal = 'shoutoutBusinessExternal',

  broadcastEventTicket = 'broadcastEventTicket',
  callTicket = 'callTicket',
  tip = 'tip',
  giftCard = 'giftCard',

  // licensing
  licenseExtension = 'licenseExtension',

  // post cards
  postCard = 'postCard',
  digitalPostCard = 'digitalPostCard',
}

export enum CartEvent {
  CreateCart = 'CreateCart',
  DeleteCart = 'DeleteCart',
  AddItem = 'AddItem',
  UpdateItem = 'UpdateItem',
  RemoveItem = 'RemoveItem',
  ApplyPromoCode = 'ApplyPromoCode',
  RemovePromoCode = 'RemovePromoCode',
  AddPriceModifier = 'AddPriceModifier',
  RemovePriceModifier = 'RemovePriceModifier',
  ApplyCredits = 'ApplyCredits',
  RemoveCredits = 'RemoveCredits',
  PendingPayment = 'PendingPayment',
  ReactivateCart = 'ReactivateCart',
  CheckOutCart = 'CheckOutCart',
  CheckOutFailed = 'CheckOutFailed',
  FailedPayment = 'FailedPayment', // payment intent failed or was cancelled
  RefreshPriceModifierError = 'RefreshPriceModifierError',
  AbandonCart = 'AbandonCart',
  ValidateCart = 'ValidateCart',
  ReviewAdded = 'ReviewAdded',
}

export enum CartStatus {
  Deleted = 'Deleted',
  CheckedOut = 'CheckedOut',
  FailedCheckout = 'FailedCheckout',
  Active = 'Active',
  PendingPayment = 'PendingPayment',
  FailedPayment = 'FailedPayment',
  Abandoned = 'Abandoned',
}

export enum Currency {
  aed = 'aed',
  afn = 'afn',
  all = 'all',
  amd = 'amd',
  ang = 'ang',
  aoa = 'aoa',
  ars = 'ars',
  aud = 'aud',
  awg = 'awg',
  azn = 'azn',
  bam = 'bam',
  bbd = 'bbd',
  bdt = 'bdt',
  bgn = 'bgn',
  bhd = 'bhd',
  bif = 'bif',
  bmd = 'bmd',
  bnd = 'bnd',
  bob = 'bob',
  brl = 'brl',
  bsd = 'bsd',
  btn = 'btn',
  bwp = 'bwp',
  byn = 'byn',
  byr = 'byr',
  bzd = 'bzd',
  cad = 'cad',
  cdf = 'cdf',
  chf = 'chf',
  clp = 'clp',
  cny = 'cny',
  cop = 'cop',
  crc = 'crc',
  cup = 'cup',
  cve = 'cve',
  czk = 'czk',
  djf = 'djf',
  dkk = 'dkk',
  dop = 'dop',
  dzd = 'dzd',
  egp = 'egp',
  ern = 'ern',
  etb = 'etb',
  eur = 'eur',
  fjd = 'fjd',
  fkp = 'fkp',
  gbp = 'gbp',
  gel = 'gel',
  gip = 'gip',
  gmd = 'gmd',
  gnf = 'gnf',
  gtq = 'gtq',
  gyd = 'gyd',
  hkd = 'hkd',
  hnl = 'hnl',
  /* @deprecated - Croatia will be a part of the EU starting January, 2023 */
  hrk = 'hrk',
  htg = 'htg',
  huf = 'huf',
  idr = 'idr',
  ils = 'ils',
  inr = 'inr',
  iqd = 'iqd',
  irr = 'irr',
  isk = 'isk',
  jmd = 'jmd',
  jod = 'jod',
  jpy = 'jpy',
  kes = 'kes',
  kgs = 'kgs',
  khr = 'khr',
  kmf = 'kmf',
  kpw = 'kpw',
  krw = 'krw',
  kwd = 'kwd',
  kyd = 'kyd',
  kzt = 'kzt',
  lak = 'lak',
  lbp = 'lbp',
  lkr = 'lkr',
  lrd = 'lrd',
  lsl = 'lsl',
  ltl = 'ltl',
  lyd = 'lyd',
  mad = 'mad',
  mdl = 'mdl',
  mga = 'mga',
  mkd = 'mkd',
  mmk = 'mmk',
  mnt = 'mnt',
  mop = 'mop',
  mro = 'mro',
  mur = 'mur',
  mvr = 'mvr',
  mwk = 'mwk',
  mxn = 'mxn',
  myr = 'myr',
  mzn = 'mzn',
  nad = 'nad',
  ngn = 'ngn',
  nio = 'nio',
  nok = 'nok',
  npr = 'npr',
  nzd = 'nzd',
  pab = 'pab',
  pen = 'pen',
  pgk = 'pgk',
  php = 'php',
  pkr = 'pkr',
  pln = 'pln',
  pyg = 'pyg',
  qar = 'qar',
  ron = 'ron',
  rsd = 'rsd',
  rub = 'rub',
  rwf = 'rwf',
  sar = 'sar',
  sbd = 'sbd',
  scr = 'scr',
  sdg = 'sdg',
  sek = 'sek',
  sgd = 'sgd',
  shp = 'shp',
  sll = 'sll',
  sos = 'sos',
  srd = 'srd',
  ssp = 'ssp',
  std = 'std',
  syp = 'syp',
  szl = 'szl',
  thb = 'thb',
  tjs = 'tjs',
  tmt = 'tmt',
  top = 'top',
  try = 'try',
  ttd = 'ttd',
  twd = 'twd',
  tzs = 'tzs',
  uah = 'uah',
  ugx = 'ugx',
  usd = 'usd',
  uyu = 'uyu',
  uzs = 'uzs',
  vef = 'vef',
  vnd = 'vnd',
  vuv = 'vuv',
  wst = 'wst',
  xaf = 'xaf',
  xcd = 'xcd',
  xof = 'xof',
  xpf = 'xpf',
  yer = 'yer',
  zar = 'zar',
  zmw = 'zmw',
  zmk = 'zmk',
  zwl = 'zwl',
}
