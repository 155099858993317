import { useCallback, useEffect, useMemo } from 'react';
import noop from 'lodash/noop';
import omitBy from 'lodash/omitBy';
import { BookingDraftManager, Values } from './bookingDraftManager';

/**
 * Hook to manage booking drafts in local storage. Exposes methods to get and update booking drafts.
 *
 * @param {Function} onDraftUpdate - Optional callback function that will be called when a booking draft is updated in local storage.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
 *
 * @returns {Object} An object with the following properties:
 * - bookingDrafts: The current booking drafts in local storage.
 * - updateBookingDrafts: A function to update the booking drafts in local storage. (currently each draft replace the other)
 * - getBookingDraft: A function to get a specific booking draft by talent name.
 *
 * @example
 * const { bookingDrafts, updateBookingDrafts, getBookingDraft, deleteBookingDraft } = useBookingDrafts();
 * const bookingDraft = getBookingDraft('Liza Koshy');
 *
 */
export const useBookingDrafts = <T extends Record<string, any>>(
  onDraftUpdate?: (event: StorageEvent, newValue: Record<string, any>) => void
): {
  bookingDrafts: Values;
  deleteBookingDraft: (talentUsername: string) => void;
  getBookingDraft: (talentUsername: string) => Values;
  updateBookingDrafts: (talentUsername: string, newBookingDraft: T) => void;
} => {
  const bookingDrafts = BookingDraftManager.read();

  useEffect(() => {
    if (typeof window === 'undefined') return noop;

    const handleStorage = (event: StorageEvent) => {
      if (event.key === BookingDraftManager.BOOKING_DRAFT_KEY) {
        onDraftUpdate?.(event, JSON.parse(event.newValue));
      }
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [onDraftUpdate]);

  const updateBookingDrafts = useCallback(
    (talentUsername: string, newBookingDraft: T) => {
      const slimmerNewBookingDraft: Record<string, string | number | Date> = {
        ...omitBy(
          newBookingDraft,
          (value) =>
            typeof value === 'undefined' ||
            (typeof value === 'string' && value.trim() === '')
        ),
        updatedAt: new Date(),
      };

      // To support multiple talent booking drafts in the future, preserve the existing values
      const newValue = {
        [talentUsername]: slimmerNewBookingDraft,
      };

      BookingDraftManager.update(newValue);
    },
    []
  );

  const deleteBookingDraft = useCallback((talentUsername: string) => {
    BookingDraftManager.remove(talentUsername);
  }, []);

  const getBookingDraft = useCallback((talentUsername: string) => {
    const drafts = BookingDraftManager.read();
    const bookingDraft = drafts[talentUsername];

    return bookingDraft ?? null;
  }, []);

  return useMemo(
    () => ({
      bookingDrafts,
      deleteBookingDraft,
      getBookingDraft,
      updateBookingDrafts,
    }),
    [bookingDrafts, deleteBookingDraft, getBookingDraft, updateBookingDrafts]
  );
};
