// TODO: Move to the shared @cameo/types package.
export const CAMEO_SESSION_IDENTIFIER_COOKIE_NAME = "@cameo/sessionIdentifier";
/**
 * Cookie set from the session verified user in API
 */
export const CAMEO_USER_COOKIE_NAME = "@cameo/verifiedUserId";

export interface CameoSessionIdentifier {
  id: string;
}

export const CAMEO_VISITOR_IDENTIFIER_COOKIE_NAME = "visitorId";

