import {
  color,
  ColorProps,
  compose,
  fontWeight,
  layout,
  LayoutProps,
  lineHeight,
  margin,
  MarginProps,
  system,
  typography,
  TypographyProps,
  variant,
} from 'styled-system';
import styled, { css } from 'styled-components';

import { FontVariants } from 'domains/web/theme/fonts';
import { ThemeInterface } from 'domains/web/theme/types';
import { ResponsiveVariant } from 'domains/web/theme/breakpoints';

type BaseProps =
  | {
      modifier?: 'kicker';
      variant?: ResponsiveVariant<'base_xs' | 'base_s'>;
    }
  | {
      modifier?: 'interactive';
      variant?: ResponsiveVariant<'base_xs' | 'base_s' | 'base_m' | 'base_l'>;
    }
  | {
      modifier?: 'tall' | '';
      variant?: ResponsiveVariant<'base_m' | 'base_l'>;
    }
  | {
      modifier?: never;
      variant?: ResponsiveVariant<keyof FontVariants>;
    }
  | {
      modifier?: never;
      variant?: ResponsiveVariant<
        | 'headline_1'
        | 'headline_2'
        | 'headline_3'
        | 'headline_4'
        | 'body'
        | 'body_s'
      >;
    };

export type TextProps = {
  as?: React.ElementType | keyof JSX.IntrinsicElements;
  lines?: number;
  textDecoration?: CSSStyleDeclaration['textDecoration'];
  textOverflow?: CSSStyleDeclaration['textOverflow'];
  textTransform?: CSSStyleDeclaration['textTransform'];
  whiteSpace?: CSSStyleDeclaration['whiteSpace'];
  wordBreak?: CSSStyleDeclaration['wordBreak'];
  userSelect?: CSSStyleDeclaration['userSelect'];
  maxInlineSize?: CSSStyleDeclaration['maxInlineSize'];
  /**
   * This property is not available on `CSSStyleDeclaration`.
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/text-wrap
   */
  textWrap?: 'initial' | 'wrap' | 'nowrap' | 'balance' | 'pretty';
  css?:
    | string
    | (({
        theme,
      }: {
        theme: ThemeInterface;
      }) => string | ReturnType<typeof css>);
} & TypographyProps &
  ColorProps &
  LayoutProps &
  BaseProps &
  MarginProps;

export const Text = styled.p.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => {
    return (
      defaultValidatorFn(prop) &&
      ![
        'textDecoration',
        'textOverflow',
        'textTransform',
        'whiteSpace',
        'wordBreak',
      ].includes(prop)
    );
  },
})<TextProps>`
  ${({ theme: t, lines, modifier, textWrap = 'initial' }) => {
    const theme = t as unknown as ThemeInterface;

    const baseVariants = {
      base_xxs: {
        fontWeight: theme.fonts.fontWeights.regular,
        fontSize: theme.fonts.fontSizes.base_xxs,
        lineHeight: theme.fonts.lineHeights.base_xxs,
      },
      base_xs: {
        fontWeight: theme.fonts.fontWeights.regular,
        fontSize: theme.fonts.fontSizes.base_xs,
        lineHeight: theme.fonts.lineHeights.base_xs,
      },
      base_s: {
        fontWeight: theme.fonts.fontWeights.regular,
        fontSize: theme.fonts.fontSizes.base_s,
        lineHeight: theme.fonts.lineHeights.base_s,
      },
      base_m: {
        fontWeight: theme.fonts.fontWeights.regular,
        fontSize: theme.fonts.fontSizes.base_m,
        lineHeight:
          modifier === 'tall'
            ? theme.fonts.lineHeights[2]
            : theme.fonts.lineHeights.base_m,
      },
      base_l: {
        fontWeight: theme.fonts.fontWeights.regular,
        fontSize: theme.fonts.fontSizes.base_l,
        lineHeight:
          modifier === 'tall'
            ? theme.fonts.lineHeights[3]
            : theme.fonts.lineHeights.base_l,
      },
      title_s: {
        fontSize: theme.fonts.fontSizes.title_s,
        lineHeight: theme.fonts.lineHeights.title_s,
      },
      title_m: {
        fontSize: theme.fonts.fontSizes.title_m,
        lineHeight: theme.fonts.lineHeights.title_m,
      },
      title_l: {
        fontSize: theme.fonts.fontSizes.title_l,
        lineHeight: theme.fonts.lineHeights.title_l,
      },
      display_s: {
        fontSize: theme.fonts.fontSizes.display_s,
        lineHeight: theme.fonts.lineHeights.display_s,
      },
      display_m: {
        fontSize: theme.fonts.fontSizes.display_m,
        lineHeight: theme.fonts.lineHeights.display_m,
      },
      display_l: {
        fontSize: theme.fonts.fontSizes.display_l,
        lineHeight: theme.fonts.lineHeights.display_l,
      },
    };

    const semanticVariants = {
      headline_1: baseVariants.title_l,
      headline_2: baseVariants.title_m,
      headline_3: baseVariants.title_s,
      headline_4: baseVariants.base_m,
      body: {
        ...baseVariants.base_m,
        lineHeight: theme.fonts.lineHeights.base_m,
      },
      body_s: baseVariants.base_s,
    };

    const variants = {
      ...baseVariants,
      ...semanticVariants,
    };

    return css`
      margin: 0;
      text-decoration: none;
      font-family: ${theme.fonts.fontFamily.body};
      font-weight: ${theme.fonts.fontWeights.medium};
      text-wrap: ${textWrap};

      &,
      &:visited,
      &:active,
      &:hover {
        color: inherit;
        text-decoration: none;
        ${compose(color)}

        ${system({
          textDecoration: {
            property: 'textDecoration',
          },
          textOverflow: {
            property: 'textOverflow',
          },
          textTransform: {
            property: 'textTransform',
          },
          whiteSpace: {
            property: 'whiteSpace',
          },
          wordBreak: {
            property: 'wordBreak',
          },
          maxInlineSize: {
            property: 'maxInlineSize',
          },
        })}
      }

      & a,
      & a:visited,
      & a:active {
        color: ${theme.colors.foreground.default};
        font-weight: ${theme.fonts.fontWeights.medium};
      }

      ${variant({
        variants,
      })}

      ${variant({
        prop: 'modifier',
        variants: {
          interactive: {
            cursor: 'pointer',
            fontWeight: theme.fonts.fontWeights.medium,
          },
          kicker: {
            fontWeight: theme.fonts.fontWeights.bold,
            textTransform: 'uppercase',
            letterSpacing: '0.1ch',
          },
        },
      })}

      ${variant({
        prop: 'fontWeight',
        variants: {
          bold: {
            fontWeight: theme.fonts.fontWeights.bold,
          },
          medium: {
            fontWeight: theme.fonts.fontWeights.medium,
          },
          regular: {
            fontWeight: theme.fonts.fontWeights.regular,
          },
        },
      })}

      ${compose(fontWeight, lineHeight, typography, color, margin, layout)}

      ${lines && lines > 0 && ClampLinesCSS}
    `;
  }}
`;

Text.defaultProps = {
  variant: 'base_m',
  modifier: 'tall',
  color: 'inherit',
};

const ClampLinesCSS = css`
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines }: { lines: number }) => lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
