export const types = {
  start: 'auth/LINK_MESSENGER_LOGIN',
  success: 'auth/LINK_MESSENGER_LOGIN_SUCCESS',
  fail: 'auth/LINK_MESSENGER_LOGIN_FAIL',
};

export const start = (state) => ({
  ...state,
  linkMessengerError: null,
  linkMessengerSuccess: false,
});

export const success = (state, action) => ({
  ...state,
  linkMessengerRedirect: action.result.redirect,
  linkMessengerSuccess: true,
});

export const fail = (state, action) => ({
  ...state,
  linkMessengerError: action.error,
});

export const service = (data, queryString) => ({
  types: [types.start, types.success, types.fail],
  promise: (client) =>
    client.post(`/auth/authorizeMessenger${queryString}`, { data }),
});
