export const sizeMap = {
  xxxs: 8,
  xxs: 12,
  xs: 14,
  sm: 16,
  md: 18,
  lg: 20,
  xl: 22,
  xxl: 24,
  xxxl: 32,
  xxxxl: 48,
};
