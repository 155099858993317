import analytics from 'analytics';

const WUNDERKIND_CORNER_TAG_SELECTOR = '.bx-type-conversioncorner a.bx-button';

export const initWunderkindTracking = async () => {
  try {
    const interval = 500;
    const maxRetry = 5;
    let retry = 0;
    const wunderkindLoaded = setInterval(() => {
      retry += 1;
      if (document.querySelector('a.bx-button')) {
        trackWunderkindImpression();
        document
          .querySelector(WUNDERKIND_CORNER_TAG_SELECTOR)
          ?.addEventListener('click', trackWunderkindClick);
        clearInterval(wunderkindLoaded);
      } else if (retry === maxRetry) {
        clearInterval(wunderkindLoaded);
      }
    }, interval);
  } catch (e) {
    // swallow error. ensure this tracking can never cause page load failures
  }
};

const trackWunderkindClick = () => {
  analytics.trackAction({
    eventName: 'Engage',
    eventDtl: {
      eventType: 'Click',
    },
    objectDtl: {
      objectName: 'WunderkindOfferCornerTab',
      objectType: 'Button',
    },
  });
};

const trackWunderkindImpression = () => {
  analytics.trackSystem({
    eventName: 'View',
    objectDtl: {
      objectName: 'WunderkindOfferCornerTab',
      objectType: 'Button',
    },
  });
};

export const unregisterWunderkindTracking = () => {
  try {
    document
      .querySelector(WUNDERKIND_CORNER_TAG_SELECTOR)
      ?.removeEventListener('click', trackWunderkindClick);
  } catch (e) {
    // swallow error. ensure this tracking can never cause page load failures
  }
};
