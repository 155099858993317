import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Collage } from 'types/src/api/models/collage';
import {
  clearCollage,
  CreateCollage,
  GetById,
  PollById,
  Invite,
  Upload,
  Publish,
  DeleteContent,
  Share,
  GetByOrderId,
  UpdateCollage,
  UpdateContent,
  ReorderCollage,
} from './actions';

export type State = {
  error?: string;
  loading: boolean;
  collage?: Collage;
};

const COLLAGE_INITIAL_STATE = Object.freeze<State>({
  error: null,
  loading: false,
  collage: null,
});

const collageReducer = reducerWithInitialState(COLLAGE_INITIAL_STATE);

collageReducer.case(clearCollage, () => ({
  ...COLLAGE_INITIAL_STATE,
}));

collageReducer
  .cases(
    [
      CreateCollage.started,
      GetById.started,
      Upload.started,
      GetByOrderId.started,
      ReorderCollage.started,
    ],
    (state) => ({
      ...state,
      error: null,
      loading: true,
    })
  )
  .cases(
    [
      CreateCollage.done,
      GetById.done,
      Upload.done,
      GetByOrderId.done,
      ReorderCollage.done,
    ],
    (state, payload) => ({
      ...state,
      error: null,
      loading: false,
      collage: {
        ...payload.result,
        contributors: [],
      },
    })
  )
  .cases(
    [
      CreateCollage.failed,
      GetById.failed,
      Upload.failed,
      ReorderCollage.failed,
    ],
    (state, payload) => ({
      ...state,
      error: payload.error.message,
      loading: false,
    })
  )
  .cases([PollById.started, PollById.failed], (state) => state)
  .cases([PollById.done], (state, payload) => {
    if (state.collage.updatedAt === payload.result.updatedAt) {
      return state;
    }
    return {
      ...state,
      error: null,
      loading: false,
      collage: {
        ...payload.result,
        contributors: [],
      },
    };
  });

collageReducer
  .cases(
    [
      Invite.started,
      Publish.started,
      Share.started,
      DeleteContent.started,
      UpdateCollage.started,
      UpdateContent.started,
    ],
    (state) => ({
      ...state,
      error: null,
      loading: true,
    })
  )
  .cases(
    [
      Invite.done,
      Publish.done,
      Share.done,
      DeleteContent.done,
      UpdateCollage.done,
      UpdateContent.done,
    ],
    (state) => ({
      ...state,
      error: null,
      loading: false,
    })
  )
  .cases(
    [
      Invite.failed,
      Publish.failed,
      Share.failed,
      DeleteContent.failed,
      UpdateCollage.failed,
      UpdateContent.failed,
    ],
    (state, payload) => ({
      ...state,
      error: payload.error.message,
      loading: false,
    })
  );

collageReducer.cases([GetByOrderId.failed], (state) => ({
  ...state,
  loading: false,
}));

export default collageReducer.build();
