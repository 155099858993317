import { generateSha1Hash } from 'utils/hashEmail';

// GTM doesn't require any init or user setting
function init(configs) {} // eslint-disable-line

async function setUser({ user }) {
  window.gtmDataLayer = window.gtmDataLayer || [];
  if (user) {
    const userEmailHash = await generateSha1Hash(user.email);
    window.gtmDataLayer.push({
      userId: user._id,
      email: userEmailHash,
    });
  }
}

function track({
  // eslint-disable-next-line no-unused-vars
  type,
  data,
  eventName,
  category,
}) {
  window.gtmDataLayer = window.gtmDataLayer || [];
  if (eventName === 'var') {
    // Push variable to GTM data layer
    window.gtmDataLayer.push({
      ...data,
    });
  } else {
    // Prepend event name to variables to differentiate between GTM events
    const prependedData = {};
    Object.entries(data).forEach((entry) => {
      const [key, val] = entry;
      prependedData[`${eventName}_${key}`] = val;
    });
    // Push event to GTM data layer
    window.gtmDataLayer.push({
      event: eventName,
      ...prependedData,
    });
  }
}

export default {
  track,
  setUser,
  init,
};
