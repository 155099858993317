import React from 'react';
import { selectors as navbarSelectors } from 'state/modules/navbar';
import { useSelector } from 'react-redux';
import { CategoryFlyout as CategoryFlyoutComponent } from './CategoryFlyout';

type Props = {
  onCategoryClick?: () => void;
};
export const CategoryFlyout = ({
  onCategoryClick = () => undefined,
}: Props) => {
  const navbarCategories = useSelector(navbarSelectors.getNavbarCategories);

  return (
    <CategoryFlyoutComponent
      categories={navbarCategories}
      onCategoryClick={onCategoryClick}
    />
  );
};
