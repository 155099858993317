// @ts-nocheck
import { UAParser } from 'ua-parser-js';
import { IncomingMessage } from 'http';

export type UserAgentDeviceType = 'mobile' | 'tablet' | 'desktop';

export interface UserAgentAttributes {
  device: UserAgentDeviceType;
  deviceModel: string;
  deviceVendor: string;
  os: string;
  osVersion: string;
  browser: string;
  browserVersion: string;
}

export class UserAgentData {
  private uaParser: UAParser;

  constructor() {
    this.uaParser = new UAParser();
  }

  /**
   * Returns the device type of the user agent.
   * Limits ua-parser possible values to mobile, tablet, and desktop
   * @param deviceType
   */
  private getDeviceType = (type: string): UserAgentDeviceType => {
    switch (type) {
      case 'mobile':
        return 'mobile';
      case 'tablet':
        return 'tablet';
      default:
        return 'desktop';
    }
  };

  private getUserAgentAttributes(userAgent: string): UserAgentAttributes {
    const parser = this.uaParser.setUA(userAgent);
    const { model, type, vendor } = parser.getDevice();
    const { name: browserName, version: browserVersion } = parser.getBrowser();
    const { name: osName, version: osVersion } = parser.getOS();

    return {
      device: this.getDeviceType(type),
      deviceModel: model || '',
      deviceVendor: vendor || '',
      os: osName || '',
      osVersion: osVersion || '',
      browser: browserName || '',
      browserVersion: browserVersion || '',
    };
  }

  getClientSideUserAgentAttributes(): UserAgentAttributes {
    return this.getUserAgentAttributes(window.navigator.userAgent);
  }

  getServerSideUserAgentAttributes(req: IncomingMessage): UserAgentAttributes {
    return this.getUserAgentAttributes(req.headers['user-agent']);
  }
}
