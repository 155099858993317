import { ORDER } from '../orders';
import { INITIAL_STATE } from './types';

// This reducer checks for the ORDER.CANCEL_SUCCESS action from the orders module
// and updates the ordersPage.myCameos state with the cancelled order.
export default function reducer(state = INITIAL_STATE, action: any = {}) {
  if (action.type === ORDER.CANCEL_SUCCESS) {
    const cancelledOrder = action.result.order;
    return {
      ...state,
      myCameos: {
        ...state.myCameos,
        orders: state.myCameos.orders.map((order) =>
          order._id === cancelledOrder._id ? cancelledOrder : order
        ),
      },
    };
  }

  return state;
}
