import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions as userActions } from 'state/modules/users';
import { Icon, IconName } from 'components/Icon';
import {
  Button,
  DropDownIndicator,
  Item,
  List,
  Wrapper,
  ItemContent,
  ItemIcon,
} from './Styled';
import { Badge, Props as BadgeProps } from '../Badge';
import { NotificationIndicatorProps } from '../NotificationIndicator';

/** An option in the DropDown. */
export type Link = {
  badge?: BadgeProps;
  name: string;
  id?: string;
  url?: string;
  icon?: IconName;
  onClick?: (event: React.UIEvent) => void;
  notificationIndicator?: React.ReactElement<NotificationIndicatorProps>;
};

/** Props to pass to DropDown for the default appearance/behavior. */
interface DropDownDefaultProps {
  links: Link[];
  shouldDisplayIndicator?: boolean;
  toggler: ReactElement;
  align?: 'right' | 'left';
}

/** Props that will be passed to the custom button component.  */
export interface CustomButtonProps {
  indicator: ReactElement<any>;
  isExpanded: boolean;
  toggleExpanded: () => void;
}

/** Props to pass to DropDown when you want to customize the button's
 * appearance or behavior. */
interface DropDownCustomProps {
  links: Link[];
  button: (props: CustomButtonProps) => ReactElement<CustomButtonProps>;
  align?: 'right' | 'left';
}

type DropDownProps = DropDownDefaultProps | DropDownCustomProps;

/** Type guard to check if this is intended as a 'custom' or default dropdown. */
function isDefaultProps(props: DropDownProps): props is DropDownDefaultProps {
  return (props as DropDownDefaultProps).toggler != null;
}

export const DropDown = (props: DropDownProps) => {
  const dispatch = useDispatch();

  const isDirectMessagesOnWebEnabled = true;

  const { links, align } = { align: 'right' as 'right', ...props };
  const [isExpanded, setIsExpanded] = useState(false);
  const container = useRef(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  let button: ReactElement<any>;
  if (isDefaultProps(props)) {
    button = (
      <Button onClick={toggleExpanded}>
        {props.toggler}
        {props.shouldDisplayIndicator && (
          <>
            {' '}
            <DropDownIndicator isExpanded={isExpanded}>
              <Icon name="chevron-down" size="lg" />
            </DropDownIndicator>
          </>
        )}
      </Button>
    );
  } else {
    button = React.createElement(props.button, {
      toggleExpanded,
      isExpanded,
      indicator: (
        <DropDownIndicator isExpanded={isExpanded}>
          <Icon name="chevron-down" size="lg" />
        </DropDownIndicator>
      ),
    });
  }

  function toggleExpanded() {
    if (!isExpanded && isDirectMessagesOnWebEnabled) {
      dispatch(userActions.getUserUnreadChannelCount());
    }
    setIsExpanded(!isExpanded);
  }

  function handleClickOutside(
    event: React.MouseEvent | KeyboardEvent | MouseEvent
  ) {
    if (container.current && !container.current.contains(event.target)) {
      setIsExpanded(false);
    }
  }

  function handleItemClick(externalHandler?: Function) {
    return () => {
      toggleExpanded();
      if (externalHandler) externalHandler();
    };
  }

  return (
    <Wrapper ref={container}>
      {button}
      <List isExpanded={isExpanded} align={align}>
        {links.map((link) => (
          <Item
            id={link.id}
            key={`${link.name}-${link.url}`}
            to={link.url}
            onClick={handleItemClick(link.onClick)}
          >
            <ItemContent>
              {link.name}
              {link.notificationIndicator}
              {link.badge && <Badge {...link.badge} />}
            </ItemContent>
            {link.icon && (
              <ItemIcon>
                <Icon name={link.icon} size="md" />
              </ItemIcon>
            )}
          </Item>
        ))}
      </List>
    </Wrapper>
  );
};
