import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { actionCreator, INITIAL_STATE } from './config';
import { BlockUserState } from './types';

export const LoadBlockedFromTalentIds = actionCreator.async<
  void,
  Cameo.Endpoints.BlockUser.getBlockedByTalent.GET.Response
>('LOAD_BLOCKED_BY_TALENT_IDS');

export function loadBlockedFromTalentIds(userId?: string) {
  const endpoint = `/block-user/blockedByTalent${userId ? `/${userId}` : ''}`;

  return {
    types: [
      LoadBlockedFromTalentIds.started.type,
      LoadBlockedFromTalentIds.done.type,
      LoadBlockedFromTalentIds.failed.type,
    ],
    promise: (client) => client.get(endpoint),
  };
}

export const reducer = reducerWithInitialState<BlockUserState>(INITIAL_STATE)
  .case(LoadBlockedFromTalentIds.started, (state) => ({
    ...state,
    ...INITIAL_STATE,
    loading: true,
  }))
  .case(LoadBlockedFromTalentIds.done, (state, { result }) => ({
    ...state,
    blockedFromTalentIds: result.talentIds || [],
    loading: false,
  }))
  .case(LoadBlockedFromTalentIds.failed, (state) => ({
    ...state,
    loading: false,
  }));
