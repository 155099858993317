import { generatePath } from 'domains/c4b/pages/App/hooks/usePathGenerator/utils/generatePath';
import React, { useCallback } from 'react';

const cleanSlug = (slug: string) => {
  return slug && slug[0] === '/' ? slug.slice(1) : slug;
};

export const useSearchUrlGenerators = (
  queryId: React.MutableRefObject<string>
) => {
  const generateBrowsePageUrl = useCallback(
    (slug: string, type: 'category' | 'tag') => {
      const safeSlug = cleanSlug(slug);

      if (type === 'category') {
        return generatePath({
          desc: {
            page: 'BusinessBrowsePage',
            params: {
              pageType: 'browse',
              slug: safeSlug,
            },
            query: {
              queryId: queryId.current,
              // categorySlug: safeSlug,
            },
          },
        });
      }

      return generatePath({
        desc: {
          page: 'BusinessBrowsePage',
          params: {
            pageType: 'tags',
            slug: safeSlug,
          },
          query: {
            queryId: queryId.current,
            // tagSlug: safeSlug,
          },
        },
      });
    },
    []
  );

  const generateProfilePageUrl = useCallback(
    (username: string, id?: string, aaQueryId?: string) => {
      return generatePath({
        desc: {
          page: 'BusinessTalentProfilePage',
          params: {
            username,
          },
          query: {
            queryId: queryId.current,
            ...(aaQueryId && { aaQueryId }),
          },
        },
      });
    },
    []
  );

  const generateSearchPageUrl = useCallback((queryStr: string) => {
    return generatePath({
      desc: {
        page: 'BusinessBrowsePage',
        params: {
          pageType: queryStr ? 'search' : 'browse',
        },
        query: queryStr ? { q: queryStr, queryId: queryId.current } : {},
      },
    });
  }, []);

  return {
    generateBrowsePageUrl,
    generateProfilePageUrl,
    generateSearchPageUrl,
  };
};
