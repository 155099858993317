import * as React from 'react';
import type { CustomIconProps } from './types';

function KwanzaaIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M7.0991 15.1C6.8991 15.1 6.7991 15.1 6.7991 15.1C6.7991 15.1 6.7991 15.1 6.7991 15.1V15.1C6.63341 15.1 6.4991 14.9657 6.4991 14.8V14.8C6.4991 14.7 6.2991 12 7.7991 10.2C8.54062 9.27312 11.2582 7.91663 11.7312 7.72329C11.7761 7.70491 11.8217 7.68872 11.8651 7.667V7.667C12.0908 7.55416 12.3504 7.74123 12.3147 7.991L12.3027 8.0749C12.3003 8.09163 12.299 8.10711 12.2972 8.12391C12.2665 8.4072 11.871 10.8682 11.1991 12.5C10.3991 14.9 7.9991 15.1 7.0991 15.1Z"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M6.49961 15.1C5.49961 15 3.59961 14.6 2.79961 12.5C2.22365 10.8681 1.73984 8.40694 1.70199 8.12387C1.69975 8.10711 1.69841 8.09163 1.69602 8.0749L1.68704 8.01206C1.65009 7.75338 1.91887 7.55966 2.15259 7.67652V7.67652C2.18373 7.69209 2.21202 7.71054 2.2422 7.72789C2.63244 7.95225 5.44345 9.25482 6.19961 10.2C6.39961 10.4 6.79961 10.9 6.89961 11.1"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M3.79883 8.10002C4.19883 4.80002 4.99883 0.900024 6.99883 0.900024C8.59883 0.900024 9.59883 3.50002 10.1988 8.20002"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default KwanzaaIcon;
