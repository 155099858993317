import Cameo from 'types';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actionCreator } from './utils';
import INITIAL_STATE from './initialState';
import { FanClubsState, Error } from './types';

type Params = { fanClubId: string; postId: string };

export const LoadSelectedFollowPost = actionCreator.async<
  Cameo.Endpoints.Follow.Post.GET.QueryParams,
  Cameo.Endpoints.Follow.Post.GET.Response,
  Error
>('LOAD_SELECTED_FOLLOW_POST');

export const loadSelectedFollowPost = ({ fanClubId, postId }: Params) => ({
  types: [
    LoadSelectedFollowPost.started.type,
    LoadSelectedFollowPost.done.type,
    LoadSelectedFollowPost.failed.type,
  ],
  promise: (client) => {
    return client.get(`/follow/${fanClubId}/post/${postId}`);
  },
});

export const reducer = reducerWithInitialState<FanClubsState>(INITIAL_STATE)
  .case(LoadSelectedFollowPost.started, (state) => ({
    ...state,
    selectedFollowPost: {
      ...state.selectedFollowPost,
      loading: true,
      error: null,
    },
  }))
  .case(LoadSelectedFollowPost.done, (state, { result }) => ({
    ...state,
    selectedFollowPost: {
      ...state.selectedFollowPost,
      loading: false,
      post: result,
    },
  }))
  .case(LoadSelectedFollowPost.failed, (state, { error }) => ({
    ...state,
    selectedFollowPost: {
      ...state.selectedFollowPost,
      loading: false,
      error: error.message,
    },
  }))
  .build();
