import { space } from './space';
import { colors } from './colors';
import { radii } from './border';
import { shadows } from './shadows';
import { breakpoints } from './breakpoints';
import { fontWeights, fontSizes, fontFamily, lineHeights } from './fonts';
import { sizes } from './sizes';
import { transitions } from './transitions';

export const theme = {
  DEFAULT: {
    breakpoints,
    colors,
    radii,
    space,
    shadows,
    sizes,
    transitions,
    fonts: {
      fontWeights,
      fontSizes,
      fontFamily,
      lineHeights,
    },
  },
};
