import React from 'react';

export function OneTrustButton() {
  // Child text will be replaced by One Trust script onload.
  return (
    <span id="ot-sdk-btn-container">
      <button id="ot-sdk-btn" className="ot-sdk-show-settings">
        {'Your Privacy Choices'}
      </button>
      <img
        loading={'lazy'}
        src="https://d3el26csp1xekx.cloudfront.net/static/assets/privacyoptions123x59.png"
        alt="California Consumer Privacy Act (CCPA) Opt-Out Icon"
        height={20}
        width={32}
        style={{ paddingLeft: '4px', paddingBottom: '6px' }}
      />
    </span>
  );
}
