import * as React from 'react';
import type { CustomIconProps } from './types';

function UserIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M7.9999 6.39998C9.32539 6.39998 10.3999 5.32546 10.3999 3.99998C10.3999 2.67449 9.32539 1.59998 7.9999 1.59998C6.67442 1.59998 5.5999 2.67449 5.5999 3.99998C5.5999 5.32546 6.67442 6.39998 7.9999 6.39998Z"
        fill="#F2F1F3"
      />
      <path
        d="M2.77204 11.5948C2.61613 12.0012 2.75538 12.4568 3.09926 12.7236C4.45315 13.7744 6.15352 14.4 7.99999 14.4C9.84836 14.4 11.5503 13.7731 12.9049 12.7204C13.2486 12.4533 13.3875 11.9977 13.2314 11.5914C12.424 9.49081 10.3873 7.99998 8.00236 7.99998C5.61616 7.99998 3.57854 9.49243 2.77204 11.5948Z"
        fill="#F2F1F3"
      />
    </svg>
  );
}

export default UserIcon;
