import { stringify } from 'qs';
import Cameo from 'types';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actionCreator } from './utils';
import INITIAL_STATE from './initialState';
import { FanClubsState, Error } from './types';

export const LoadContentPreview = actionCreator.async<
  Cameo.Endpoints.FanClub.ContentPreview.GET.QueryParams,
  Cameo.Endpoints.FanClub.ContentPreview.GET.Response,
  Error
>('LOAD_CONTENT_PREVIEW');

export const loadContentPreview = (
  params: Cameo.Endpoints.FanClub.ContentPreview.GET.QueryParams
) => ({
  types: [
    LoadContentPreview.started.type,
    LoadContentPreview.done.type,
    LoadContentPreview.failed.type,
  ],
  promise: (client) => {
    return client.get(`/fanClub/contentPreview?${stringify(params)}`);
  },
});

export const reducer = reducerWithInitialState<FanClubsState>(INITIAL_STATE)
  .case(LoadContentPreview.started, (state) => ({
    ...state,
    contentPreviewLoading: true,
    contentPreviewError: null,
  }))
  .case(LoadContentPreview.done, (state, { result }) => ({
    ...state,
    contentPreviewLoading: false,
    contentPreviews: {
      ...state.contentPreviews,
      [result.fanClubContentPreview.fanClubId]: result.fanClubContentPreview,
    },
  }))
  .case(LoadContentPreview.failed, (state, { error }) => ({
    ...state,
    contentPreviewLoading: false,
    contentPreviewError: error.message,
  }))
  .build();
