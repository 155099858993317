import qs from 'qs';

export type QueryParams = { [key: string]: string };

export function getQueryParamsFromString<T extends QueryParams = QueryParams>(
  queryString: string
): T {
  try {
    const queryParams = qs.parse(queryString, { ignoreQueryPrefix: true });
    return queryParams as T;
  } catch (error) {
    return {} as T;
  }
}
