import * as SelectPrimitive from '@radix-ui/react-select';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import { Check, ChevronDown } from 'react-feather';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ThemeInterface } from 'domains/web/theme/types';
import { rgba } from 'polished';

export type SelectProps = {
  hasError?: boolean;
  size?: 'medium' | 'large';
};

const animateIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const animateOut = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const StyledSelectIcon = styled(SelectPrimitive.Icon)`
  box-sizing: border-box;
  transition: transform 200ms cubic-bezier(0.04, 0.62, 0.23, 0.98);
`;

const StyledSelectTrigger = styled(SelectPrimitive.Trigger)`
  ${({
    theme: t,
    size,
    hasError,
  }: {
    theme: ThemeInterface;
  } & SelectProps) => {
    const theme = t as unknown as ThemeInterface;
    return css`
      align-items: center;
      appearance: none;
      background-color: ${theme.colors.background.level2};
      border-radius: ${theme.radii[1]};
      border: 0.15rem solid ${theme.colors.border.default};
      box-sizing: border-box;
      color: ${theme.colors.foreground.default};
      display: flex;
      font-family: ${theme.fonts.fontFamily.body};
      font-size: ${theme.fonts.fontSizes.base_m};
      font-weight: ${theme.fonts.fontWeights.regular};
      height: ${size === 'medium' ? '4.4rem' : '5.2rem'};
      justify-content: space-between;
      line-height: ${theme.fonts.lineHeights.base_m};
      outline-offset: -0.2rem;
      outline: transparent solid 0.2rem;
      padding: 0 ${theme.space.lg};
      transition: ${theme.transitions.default};
      width: 100%;

      &:focus,
      &:focus-within,
      &:focus-visible {
        outline-color: ${theme.colors.border.focus};
      }

      &:disabled {
        opacity: 0.4;
        pointer-events: none;
      }

      &[data-state='open'] ${StyledSelectIcon} {
        transform: rotate(-180deg);
      }

      ${hasError &&
      css`
        border-color: ${theme.colors.danger.emphasis};
      `}
    `;
  }}
`;

const StyledSelectPrimitivePortal = styled(SelectPrimitive.Portal)`
  box-sizing: border-box;
`;
const StyledSelectPrimitiveContent = styled(SelectPrimitive.Content)`
  ${({ theme: t }) => {
    const theme = t as unknown as ThemeInterface;
    return css`
      box-sizing: border-box;
      background-color: ${theme.colors.background.level2};
      border-radius: ${theme.radii[1]};
      border: 0.15rem solid ${theme.colors.border.default};
      z-index: 99999999;
      margin-top: ${theme.space.xs};
      box-shadow: 0 3px 10px 6px ${rgba(theme.colors.background.level1, 0.75)};

      &[data-state='open'] {
        animation: ${animateIn} 200ms cubic-bezier(0.04, 0.62, 0.23, 0.98);
      }
      &[data-state='closed'] {
        animation: ${animateOut} 200ms cubic-bezier(0.04, 0.62, 0.23, 0.98);
      }
      &[data-side='left'] {
      }
      &[data-side='top'] {
      }
      &[data-side='right'] {
      }
      &[data-side='bottom'] {
      }
    `;
  }}
`;
const StyledSelectPrimitiveViewport = styled(SelectPrimitive.Viewport)`
  box-sizing: border-box;
  padding: 0;
  min-width: calc(var(--radix-select-trigger-width) - 2px);
  height: var(--radix-select-trigger-height);
  width: 100%;
`;
const StyledSelectPrimitiveLabel = styled(SelectPrimitive.Label)`
  ${({ theme: t }) => {
    const theme = t as unknown as ThemeInterface;
    return css`
      box-sizing: border-box;
      font-family: ${theme.fonts.fontFamily.body};
      font-size: ${theme.fonts.fontSizes.base_m};
      font-weight: ${theme.fonts.fontWeights.regular};
      line-height: ${theme.fonts.lineHeights.base_m};
    `;
  }}
`;
const StyledSelectPrimitiveItemIndicator = styled(
  SelectPrimitive.ItemIndicator
)`
  ${({ theme: t }) => {
    const theme = t as unknown as ThemeInterface;
    return css`
      box-sizing: border-box;
      padding: ${theme.space.xs};
      stroke-width: 2;
    `;
  }}
`;
const StyledSelectPrimitiveItem = styled(SelectPrimitive.Item)`
  ${({ theme: t }) => {
    const theme = t as unknown as ThemeInterface;
    return css`
      box-sizing: border-box;
      appearance: none;
      font-family: ${theme.fonts.fontFamily.body};
      font-size: ${theme.fonts.fontSizes.base_m};
      font-weight: ${theme.fonts.fontWeights.regular};
      line-height: ${theme.fonts.lineHeights.base_m};
      padding: ${theme.space.sm} ${theme.space.lg};
      outline-offset: -0.2rem;
      outline: transparent solid 0.2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:focus,
      &:focus-within,
      &:focus-visible {
        outline-color: ${theme.colors.border.focus};
      }

      &[data-highlighted] {
        background-color: ${theme.colors.background.level3};
      }
    `;
  }}
`;
const StyledSelectPrimitiveItemText = styled(SelectPrimitive.ItemText)`
  ${({ theme: t }) => {
    const theme = t as unknown as ThemeInterface;
    return css`
      font-family: ${theme.fonts.fontFamily.body};
      font-size: ${theme.fonts.fontSizes.base_m};
      font-weight: ${theme.fonts.fontWeights.regular};
      line-height: ${theme.fonts.lineHeights.base_m};
    `;
  }}
`;
const StyledSelectPrimitiveSeparator = styled(SelectPrimitive.Separator)`
  ${({ theme: t }) => {
    // @ts-ignore
    const theme = t as unknown as ThemeInterface;
    return css`
      box-sizing: border-box;
    `;
  }}
`;
const StyledSelectPrimitiveScrollUpButton = styled(
  SelectPrimitive.ScrollUpButton
)`
  ${({ theme: t }) => {
    // @ts-ignore
    const theme = t as unknown as ThemeInterface;
    return css`
      box-sizing: border-box;
      padding: ${theme.space.xs};
      display: flex;
      justify-content: center;
      align-items: center;
    `;
  }}
`;
const StyledSelectPrimitiveScrollDownButton = styled(
  SelectPrimitive.ScrollDownButton
)`
  ${({ theme: t }) => {
    // @ts-ignore
    const theme = t as unknown as ThemeInterface;
    return css`
      box-sizing: border-box;
      padding: ${theme.space.xs};
      display: flex;
      justify-content: center;
      align-items: center;
    `;
  }}
`;
const StyledScrollAreaRoot = styled(ScrollAreaPrimitive.Root)`
  ${({ theme: t }) => {
    // @ts-ignore
    const theme = t as unknown as ThemeInterface;
    return css`
      max-height: var(--radix-select-content-available-height);
      width: 100%;
      height: 100%;
    `;
  }}
`;
const StyledScrollAreaViewport = styled(ScrollAreaPrimitive.Viewport)`
  ${({ theme: t }) => {
    // @ts-ignore
    const theme = t as unknown as ThemeInterface;
    return css`
      max-height: var(--radix-select-content-available-height);
      width: 100%;
      height: 100%;
    `;
  }}
`;
const StyledScrollAreaScrollbar = styled(ScrollAreaPrimitive.Scrollbar)`
  ${({ theme: t }) => {
    // @ts-ignore
    const theme = t as unknown as ThemeInterface;
    return css`
      width: ${theme.space.sm};
      padding: ${theme.space.sm} 0;
    `;
  }}
`;
const StyledScrollAreaThumb = styled(ScrollAreaPrimitive.Thumb)`
  ${({ theme: t }) => {
    // @ts-ignore
    const theme = t as unknown as ThemeInterface;
    return css`
      background-color: ${theme.colors.foreground.default};
      height: 1rem;
      margin-right: 2px;
      border-radius: ${theme.radii[2]};
    `;
  }}
`;

const SelectRoot = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger> & SelectProps,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & SelectProps
>(({ children, className, ...props }, ref) => (
  <StyledSelectTrigger
    className={`form-input ${className}`}
    ref={ref}
    {...props}
  >
    {children}
    <StyledSelectIcon asChild>
      <ChevronDown />
    </StyledSelectIcon>
  </StyledSelectTrigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ children, position = 'popper', ...props }, ref) => (
  <StyledSelectPrimitivePortal>
    <StyledSelectPrimitiveContent ref={ref} position={position} {...props}>
      <StyledScrollAreaRoot>
        <StyledSelectPrimitiveViewport asChild>
          {/* https://github.com/radix-ui/primitives/issues/2059#issuecomment-1492071891 */}
          <StyledScrollAreaViewport style={{ overflowY: undefined }}>
            {children}
          </StyledScrollAreaViewport>
        </StyledSelectPrimitiveViewport>
        <StyledScrollAreaScrollbar orientation="vertical">
          <StyledScrollAreaThumb />
        </StyledScrollAreaScrollbar>
      </StyledScrollAreaRoot>
    </StyledSelectPrimitiveContent>
  </StyledSelectPrimitivePortal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ ...props }, ref) => <StyledSelectPrimitiveLabel ref={ref} {...props} />);
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ children, ...props }, ref) => (
  <StyledSelectPrimitiveItem ref={ref} {...props}>
    <StyledSelectPrimitiveItemText>{children}</StyledSelectPrimitiveItemText>
    <StyledSelectPrimitiveItemIndicator asChild>
      <Check />
    </StyledSelectPrimitiveItemIndicator>
  </StyledSelectPrimitiveItem>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ ...props }, ref) => (
  <StyledSelectPrimitiveSeparator ref={ref} {...props} />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ ...props }, ref) => (
  <StyledSelectPrimitiveScrollUpButton ref={ref} {...props} />
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ ...props }, ref) => (
  <StyledSelectPrimitiveScrollDownButton ref={ref} {...props} />
));
SelectScrollDownButton.displayName =
  SelectPrimitive.ScrollDownButton.displayName;

/**
 * @example
 * <Select onValueChange={(val) => setPreferredRegion(val)} defaultValue={preferredRegion}>
 *   <SelectTrigger hasError={hasError} disabled={disabled}>
 *     <SelectValue placeholder="Theme" />
 *   </SelectTrigger>
 *   <SelectContent>
 *     <SelectItem value="light">{'Light'}</SelectItem>
 *     <SelectItem value="dark">{'Dark'}</SelectItem>
 *     <SelectSeparator />
 *     <SelectItem value="system">{'System'}</SelectItem>
 *   </SelectContent>
 * </Select>
 */
export const Select = {
  Root: SelectRoot,
  Group: SelectGroup,
  Value: SelectValue,
  Trigger: SelectTrigger,
  Content: SelectContent,
  Label: SelectLabel,
  Item: SelectItem,
  Separator: SelectSeparator,
  ScrollUpButton: SelectScrollUpButton,
  ScrollDownButton: SelectScrollDownButton,
};
