import { combineReducers } from 'redux';
import i18nReducer from './i18n/reducer';
import scrollStateReducer from './scrollState/reducer';
import httpHeadersReducer from './httpHeaders/reducer';
import soundStateReducer from './soundState/reducer';
import eppoStateReducer from './eppoState/reducer';

export default combineReducers({
  httpHeaders: httpHeadersReducer,
  i18n: i18nReducer,
  scrollState: scrollStateReducer,
  soundState: soundStateReducer,
  eppoState: eppoStateReducer,
});
