import styled from 'styled-components';
import { sizing } from 'design-system/style';
import { theme } from 'domains/web/theme/og';
import { Link } from 'components/CameoLink/Link';

export const Wrapper = styled.div`
  background-clip: padding-box;
  background-color: ${theme.colors.background.level2};
  border-radius: ${sizing(12)};
  border: ${sizing(4)} solid transparent;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: start;
  left: 0;
  padding: ${sizing(24)};
  position: absolute;
  top: 100%;
  z-index: 1000;
`;

export const CategoryList = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${sizing(8)} ${sizing(32)};
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 1fr);
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const L1Column = styled(CategoryList)`
  border-right: ${sizing(1)} solid rgba(255, 255, 255, 0.1);
  padding-right: ${sizing(16)};
  width: ${sizing(160)};
`;

export const L2Column = styled(CategoryList)`
  grid-template-columns: repeat(2, 200px);
  padding-left: ${sizing(16)};
`;

export const CategoryListItemLink = styled(Link)`
  align-items: center;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  padding: ${sizing(6)} ${sizing(8)};
  width: 100%;

  &:active,
  &:focus,
  &:hover,
  &:visited {
    color: inherit;
    text-decoration: none;
  }

  // Typography
  & > div {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.2s;
    white-space: nowrap;
  }

  &:hover {
    & > div {
      color: ${theme.colors.foreground.default};
    }
  }
`;

const categoryListItemTextColorWhite = `
  ${CategoryListItemLink} > div {
    color: ${theme.colors.foreground.default};
  }
`;
export const CategoryListItem = styled.li<{ active?: boolean }>`
  align-items: center;
  background-color: ${({ active }) =>
    active ? theme.colors.background.level3 : 'transparent'};
  border-radius: ${sizing(4)};
  display: flex;
  transition: all 0.2s;

  ${({ active }) =>
    active
      ? `
    ${categoryListItemTextColorWhite}
  `
      : ''};

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${theme.colors.background.level3};

    ${categoryListItemTextColorWhite}
  }
`;

export const ViewAllLink = styled(CategoryListItemLink)`
  align-items: center;
  color: ${theme.colors.foreground.default};
  display: flex;
  padding: ${sizing(5)} ${sizing(8)};

  div {
    display: inline-block;
    margin-right: ${sizing(12)};
  }
`;
