import styled, { createGlobalStyle } from 'styled-components';
import { color, sizing, media } from 'design-system/style';
import { Link } from 'components/CameoLink/Link';

export const DisableBodyScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const DarkenOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 150;
  top: 0;
  background: ${color.blacks.BLACKTRANSPARENT40};
  display: block;
`;

export const Wrapper = styled.div<{
  isVisible: boolean;
}>`
  color: ${color.solids.WHITE};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${color.background.LEVEL0};
  position: fixed;
  top: 0;
  z-index: 1000;
  overflow-x: hidden;
  transition: transform 200ms ease-in-out;
  transform: ${({ isVisible }) =>
    isVisible ? `translateX(0)` : `translateX(-100%)`};

  @media screen and (min-width: ${media.queries.TABLET}) {
    width: 375px;
  }

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    display: none;
  }
`;

const NAV_DRAWER_FOOTER_HEIGHT = 94;

export const Container = styled.nav<{
  isMainMenuActive: boolean;
  shouldRenderFooter: boolean;
}>`
  height: ${({ shouldRenderFooter }) =>
    shouldRenderFooter
      ? `calc(100% - ${sizing(NAV_DRAWER_FOOTER_HEIGHT)})`
      : '100%'};
  left: ${({ isMainMenuActive }) => (isMainMenuActive ? '0' : '-100%')};
  position: relative;
  transition: left 200ms ease-in-out;
`;

export const Menu = styled.div<{
  isActive: boolean;
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 ${sizing(14)} 0 ${sizing(24)};
  opacity: ${({ isActive }) => (isActive ? `1` : `0`)};
  pointer-events: ${({ isActive }) => (isActive ? `auto` : `none`)};
  transition: opacity 200ms ease-in-out;
`;

export const Header = styled.div`
  display: flex;
  padding: ${sizing(30)} 0;
  justify-content: space-between;
  align-items: center;
`;

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${sizing(16)} 0;

  & > *:last-child {
    line-height: normal;
  }
`;

export const List = styled.ul`
  list-style-type: none;
  margin-bottom: 0;
  padding-inline-start: ${sizing(22)};
`;

export const SubList = styled.ul`
  list-style-type: none;
  margin-bottom: 0;
  padding-inline-start: 0;
`;

export const ListItem = styled.li`
  padding: ${sizing(16)} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const ViewAllItem = styled.div`
  display: flex;
  align-items: center;
`;

export const ArrowIconWrapper = styled.div`
  display: flex;
  margin-left: ${sizing(17)};
`;

export const SubMenu = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 100%;
  pointer-events: none;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
`;

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  margin-left: -10px;

  & > svg:first-child {
    margin-right: ${sizing(15)};
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  overflow-y: auto;
  padding-bottom: ${sizing(100)};
  // little hack to make the scroll bar look like it belongs to the outer container
  padding-right: 15px;
  margin-right: -15px;
`;

export const Footer = styled.div`
  background-color: ${color.background.LEVEL0};
  width: 100%;
  padding: ${sizing(14)} ${sizing(14)} ${sizing(40)} ${sizing(14)};
`;

export const CloseButton = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const LogoContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const NavigationLink = styled(Link)`
  color: ${color.solids.WHITE};

  &:hover {
    color: ${color.solids.WHITE};
    text-decoration: none;
  }
`;
