/**
 * https://styled-system.com/theme-specification#key-reference
 * width, height, min-width, max-width, min-height, max-height
 */

interface SizesArray extends Array<string> {
  content?: string;
  narrow?: string;
}

export const sizes: SizesArray = [];

/**
 * Width of the content container, developers needs to exclude padding from this value.
 * @example
 * <Box boxSizing="content-box" width="content" mx="auto" px={5}>
 *
 * @see https://www.figma.com/file/eX4SjNvtxUKMVRKZAElaq5/Cameo-Design-System?type=design&node-id=5970%3A161&mode=design&t=5uNXERELcbG9BUK9-1
 */
sizes.content = '128rem'; // 1280px

sizes.narrow = '64rem'; // 640px
