import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  addInternalEvent,
  clearInternalEvents,
} from 'state/modules/internalEvents/actions';
import { InternalEventsState } from './types';

export const INTERNAL_EVENTS_INITIAL_STATE: InternalEventsState = Object.freeze(
  {
    events: [],
  }
);

const internalEventsReducer = reducerWithInitialState<InternalEventsState>(
  INTERNAL_EVENTS_INITIAL_STATE
);

internalEventsReducer.case(addInternalEvent, (state, event) => {
  const newEvents = [...state.events, event];
  if (newEvents.length > 100) {
    newEvents.shift();
  }
  return {
    ...state,
    events: newEvents,
  };
});

internalEventsReducer.case(clearInternalEvents, (state) => {
  return {
    ...state,
    events: [],
  };
});

export default internalEventsReducer.build();
