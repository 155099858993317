import * as React from 'react';
import type { CustomIconProps } from './types';

export const TikTokIcon = ({ height, width }: CustomIconProps) => (
  <svg width={width} height={height} viewBox="0 0 19 23" fill="none">
    <path
      d="M18.823 9.59416C18.6432 9.61163 18.4627 9.62079 18.2821 9.6216C17.3069 9.62173 16.347 9.37902 15.4891 8.91537C14.6312 8.45172 13.9023 7.78172 13.3681 6.96586V16.0093C13.3681 17.3313 12.9761 18.6236 12.2416 19.7228C11.5072 20.822 10.4632 21.6787 9.2419 22.1846C8.02055 22.6905 6.67662 22.8228 5.38004 22.5649C4.08347 22.307 2.89249 21.6704 1.95771 20.7356C1.02293 19.8009 0.38634 18.6099 0.128435 17.3133C-0.12947 16.0167 0.00289553 14.6728 0.508794 13.4515C1.01469 12.2301 1.8714 11.1862 2.97058 10.4518C4.06977 9.71731 5.36206 9.3253 6.68403 9.3253C6.82356 9.3253 6.95995 9.33784 7.09713 9.34646V12.6403C6.95995 12.6238 6.82513 12.5987 6.68403 12.5987C5.77928 12.5987 4.91158 12.9581 4.27182 13.5979C3.63206 14.2377 3.27264 15.1054 3.27264 16.0101C3.27264 16.9149 3.63206 17.7826 4.27182 18.4223C4.91158 19.0621 5.77928 19.4215 6.68403 19.4215C8.56845 19.4215 10.2326 17.9369 10.2326 16.0524L10.2655 0.693359H13.4167C13.5606 2.06253 14.1813 3.33753 15.1701 4.29542C16.159 5.25331 17.4531 5.83313 18.8261 5.93351V9.59416"
      fill="#FFFFFF"
    />
  </svg>
);
