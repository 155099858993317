import actionCreatorFactory from 'typescript-fsa';
import { GetNavBarCategoriesResponse } from 'types/src/api/endpoints/explore/categories';
import { Notification } from './types';

const actionCreator = actionCreatorFactory('cameo/navbar');

export const removeNotifications = actionCreator<Notification[]>(
  'REMOVE_NOTIFICATIONS'
);

export const addNotifications =
  actionCreator<Notification[]>('ADD_NOTIFICATIONS');

export const toggleNavbar = actionCreator<boolean>('TOGGLE_NAVBAR');

export const GetNavbarCategories = actionCreator.async<
  void,
  GetNavBarCategoriesResponse,
  any
>('GET_NAVBAR_CATEGORIES');

export function getNavbarCategories() {
  return {
    types: [
      GetNavbarCategories.started.type,
      GetNavbarCategories.done.type,
      GetNavbarCategories.failed.type,
    ],
    promise: (client) => {
      return client.get(`/categories/navbar`);
    },
  };
}
