import { DataQualitySnapshot } from '@cameo/events';
import { TIMEOUT_RESPONSE_STATUS } from 'analytics/utils/constants';

const DISK_KEY = 'DISK-FLUSH-FAILURE-COUNT';
const UPLOAD_5XX_KEY = 'UPLOAD-5XX-ERROR-KEY';
const UPLOAD_4XX_KEY = 'UPLOAD-4XX-ERROR-KEY';
const BUFFER_OVERFLOW_KEY = 'BUFFER-OVERFLOW-EVENT-KEY';
const TIMEOUT_KEY = 'TIMEOUT-ERROR-KEY';
const WINDOW_START_TS_KEY = 'WINDOW-START-TS-KEY';

class QualityMetrics {
  private _diskFlushFailureCount: number;

  private _upload5xxErrorCount: number;

  private _upload4xxErrorCount: number;

  private _bufferOverflowEventCount: number;

  private _timeoutErrorCount: number;

  private _windowStartTs: number;

  private _isInitialized: boolean;

  constructor() {
    this.clear();
    this._isInitialized = false;
  }

  public incrementDiskFlushFailureCount() {
    this.initialize();
    this._diskFlushFailureCount += 1;
  }

  public incrementBufferOverflowEventCount() {
    this.initialize();
    this._bufferOverflowEventCount += 1;
  }

  private initialize() {
    if (this._isInitialized) {
      return;
    }
    const storedDiskFlushFailureCount = window.sessionStorage.getItem(DISK_KEY);
    const storedUpload5xxErrorCount =
      window.sessionStorage.getItem(UPLOAD_5XX_KEY);
    const storedUpload4xxErrorCount =
      window.sessionStorage.getItem(UPLOAD_4XX_KEY);
    const storedBufferOverflowEventCount =
      window.sessionStorage.getItem(BUFFER_OVERFLOW_KEY);
    const storedTimeoutCount = window.sessionStorage.getItem(TIMEOUT_KEY);
    const storedWindowStartTs =
      window.sessionStorage.getItem(WINDOW_START_TS_KEY);

    if (storedDiskFlushFailureCount) {
      this._diskFlushFailureCount = Number(storedDiskFlushFailureCount);
    }
    if (storedUpload5xxErrorCount) {
      this._upload5xxErrorCount = Number(storedUpload5xxErrorCount);
    }
    if (storedUpload4xxErrorCount) {
      this._upload4xxErrorCount = Number(storedUpload4xxErrorCount);
    }
    if (storedBufferOverflowEventCount) {
      this._bufferOverflowEventCount = Number(storedBufferOverflowEventCount);
    }
    if (storedTimeoutCount) {
      this._timeoutErrorCount = Number(storedTimeoutCount);
    }
    if (storedWindowStartTs) {
      this._windowStartTs = Number(storedWindowStartTs);
    }
    this._isInitialized = true;
  }

  public createDataQualitySnapshot(batchSize: number, bufferSize: number) {
    const event: DataQualitySnapshot = {
      eventName: 'DataQualitySnapshot',
      uploadBatchSize: batchSize,
      bufferSizeAtUpload: bufferSize,
      diskFlushFailureCount: this._diskFlushFailureCount,
      upload5xxErrorCount: this._upload5xxErrorCount,
      upload4xxErrorCount: this._upload4xxErrorCount,
      bufferOverflowEventCount: this._bufferOverflowEventCount,
      timeoutErrorCount: this._timeoutErrorCount,
      windowStartTs: this._windowStartTs,
    };
    return event;
  }

  public testReset() {
    this.clear();
    this._isInitialized = false;
  }

  public clear() {
    this._diskFlushFailureCount = 0;
    this._upload5xxErrorCount = 0;
    this._upload4xxErrorCount = 0;
    this._bufferOverflowEventCount = 0;
    this._timeoutErrorCount = 0;
    this._windowStartTs = new Date().getTime();
  }

  public flushToSessionStorage() {
    window.sessionStorage.setItem(
      DISK_KEY,
      String(this._diskFlushFailureCount)
    );
    window.sessionStorage.setItem(
      UPLOAD_5XX_KEY,
      String(this._upload5xxErrorCount)
    );
    window.sessionStorage.setItem(
      UPLOAD_4XX_KEY,
      String(this._upload4xxErrorCount)
    );
    window.sessionStorage.setItem(
      BUFFER_OVERFLOW_KEY,
      String(this._bufferOverflowEventCount)
    );
    window.sessionStorage.setItem(TIMEOUT_KEY, String(this._timeoutErrorCount));
    window.sessionStorage.setItem(
      WINDOW_START_TS_KEY,
      String(this._windowStartTs)
    );
  }

  public incrementResponseStatusCounters(status: number) {
    if (!this._isInitialized) {
      this.initialize();
    }
    if (status >= 400 && status < 500) {
      this._upload4xxErrorCount += 1;
    } else if (status >= 500 && status < 600) {
      this._upload5xxErrorCount += 1;
    } else if (status === TIMEOUT_RESPONSE_STATUS) {
      this._timeoutErrorCount += 1;
    }
  }
}

export const qualityMetrics = new QualityMetrics();
