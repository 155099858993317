import { thunkToAction } from 'typescript-fsa-redux-thunk';
import unconnectedRequestPasswordReset from 'services/auth/requestPasswordReset';
import authActionCreatorFactory from '../authActionCreatorFactory';
import { ActionError } from '../types';
import { RequestPasswordResetParams } from './types';

const asyncActionCreator =
  authActionCreatorFactory<RequestPasswordResetParams>();

const requestPasswordReset = asyncActionCreator<
  RequestPasswordResetParams,
  void,
  ActionError
>('REQUEST_PASSWORD_RESET', unconnectedRequestPasswordReset);

export const service = (
  email: string,
  briefcaseCustomer = false,
  urlSearchParams = undefined
) =>
  thunkToAction(requestPasswordReset)({
    email,
    briefcaseCustomer,
    urlSearchParams,
  });

export default requestPasswordReset;
