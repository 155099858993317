import { AdminUser } from 'types/src/api/models/user';
import { Credentials, LoginMethod } from 'types/src/utils/enums';
import { client as ApiClient } from '../../../helpers/ApiClient';
import handleError from './handleError';

/** @deprecated */
function legacyLoginWithPassword({
  email,
  password,
  briefcaseCustomer,
}: Credentials[LoginMethod.DATABASE]): Promise<AdminUser> {
  const currentTime = Date.now();
  const signature = btoa(
    `web:${currentTime}${currentTime}${email}${currentTime}`
  );
  return ApiClient.post('/auth/login', {
    data: {
      email,
      password,
      currentTime,
      signature,
      briefcaseCustomer,
    },
  }).catch(handleError('legacyLoginWithPassword'));
}

export default function ({
  email,
  password,
  briefcaseCustomer,
}: Credentials[LoginMethod.DATABASE]): Promise<AdminUser> {
  const formattedEmail = email.trim().toLowerCase();

  return legacyLoginWithPassword({
    email: formattedEmail,
    password,
    briefcaseCustomer,
  });
}
