import { ProductType } from '../../api/models/cart/enums';

export enum PriceApplicationMethod {
  fixed = 'fixed',
  flat = 'flat',
  percent = 'percent',
}

/*
 * Product: the tangible item a user is purchasing, such as a classic or
 *  business Cameo.
 * Discount: anything to reduce price, such as promo codes or promotions
 * Fee: anything to increase price, such as a service fee
 */
export enum LineItemType {
  product = 'product',
  discount = 'discount',
  fee = 'fee',
}

export enum AdjustmentFeeType {
  iap = 'In App Purchase',
  paymentProcessorMinCharge = 'Payment Processor Minimum Charge',
}

// Adjustment fees can be related to either IAP or
// payment processor minimum charges. This enum will be helpful
// in identifying different adjustment fees applied to line items.
export enum AdjustmentFeeSubtype {
  stripeMinimumCharge = 'stripeMinimumCharge',
}

/*
 * @param entity: the specific instance of a LineItem
 * @param priceApplicationMethod: enum value that tells pricing calculator how
 *  to apply the amount to the final price
 * @param amount: amount to modify final price by (ex: 5 for 5% off promo code)
 * @param amountModified: computed by price calculator, total change in price
 *  from this line item (ex: 2.5 for 5% off a $50 order)
 */
export interface LineItem {
  name: string;
  type: LineItemType;
  entity: ProductPriceModifiers | ProductType;
  priceApplicationMethod: PriceApplicationMethod;
  amount: number;
  amountModified?: number;
}

/*
 * Encapsulate anything that can affect a product's price prior to taxes and service fees
 * If you add a new field here, make sure to update subTotalPriceModifiersEligiblity
 */
export enum ProductPriceModifiers {
  adjustmentFee = 'adjustmentFee',
  characterCountUpsell = 'characterCountUpsell',
  expedited = 'expedited',
  expeditedCameo = 'expeditedCameo',
  licensing = 'licensing',
  promoCode = 'promoCode',
  removeWatermark = 'removeWatermark',
  salesTax = 'salesTax',
  serviceFee = 'serviceFee',
  userPromotion = 'userPromotion',
  videoEditing = 'videoEditing',
}

/*
 * ProductPriceModifiers that are applied to a line item's subtotal
 * this affects the taxable amount
 */
export const subTotalPriceModifiersEligibility = {
  [ProductPriceModifiers.characterCountUpsell]: true,
  [ProductPriceModifiers.expedited]: true,
  [ProductPriceModifiers.expeditedCameo]: true,
  [ProductPriceModifiers.licensing]: true,
  [ProductPriceModifiers.promoCode]: true,
  [ProductPriceModifiers.removeWatermark]: true,
  [ProductPriceModifiers.videoEditing]: true,
  [ProductPriceModifiers.userPromotion]: true,

  [ProductPriceModifiers.adjustmentFee]: false,
  [ProductPriceModifiers.salesTax]: false,
  [ProductPriceModifiers.serviceFee]: false, // treated as a separate taxable line item
};

export const AllC4BVideoProduct = Object.freeze([
  ProductType.shoutoutBusinessInternalComms,
  ProductType.shoutoutBusinessExternalEvents,
  ProductType.shoutoutBusinessExternalMarketing,
  ProductType.shoutoutBusinessInternal,
  ProductType.shoutoutBusinessInternalEvents,
  ProductType.shoutoutBusinessExternal,
] as const);

export const C4BProducts = Object.freeze([
  ProductType.shoutoutBusinessInternalComms,
  ProductType.shoutoutBusinessExternalEvents,
  ProductType.shoutoutBusinessExternalMarketing,
  ProductType.licenseExtension,
] as const);

export const PostCardsProducts = Object.freeze([ProductType.postCard] as const);

/**
  Backwards compatible enum for all app versions — do not remove a value that has been added
  unless all possible version of the app that reference it are no longer in use.

  Values must be pulled from `ProductType` enum.
 */
export enum OccasionBasedProducts {
  c4b_external_marketing = ProductType.shoutoutBusinessExternalMarketing,

  /** Deprecated SKUs */
  c4b_internal_comms = ProductType.shoutoutBusinessInternalComms,
  c4b_external_events = ProductType.shoutoutBusinessExternalEvents,
  c4b_internal = ProductType.shoutoutBusinessInternal,
  c4b_internal_events = ProductType.shoutoutBusinessInternalEvents,
  c4b_external = ProductType.shoutoutBusinessExternal,
}

export const ActiveProducts: ProductType[] = [
  ProductType.shoutoutBusinessExternalMarketing,
];

interface AltPrices {
  price: number;
  [altTreatmentKey: string]: number;
}

export type AltPriceSet = {
  [altPriceKey: 'mp-min' | 'mp-max' | 'mp-price' | string]: AltPrices;
};

export interface Product {
  name: string;
  price: number;
  iosPrice?: number;
  iosPriceExpedited?: number;
  sku: string;
  type: ProductType;
  eligiblePriceModifiers: ProductPriceModifiers[];
  prices?: AltPriceSet;
}

export interface ProductLookupResult {
  product: Product;
  quantityAvailable: number;
  modifications: any; // TODO: Add type for LineItemModifier
  targetInfo: any;
}

export const GRANDFATHERED_TAKE_RATE_PERCENTAGE = 0.75;
export const NEW_TAKE_RATE_PERCENTAGE = 0.7;
