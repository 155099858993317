import { PageKeys, Paths } from 'domains/c4b/pages/pages';
import { generatePath as generateDomPath } from 'react-router-dom';
import { hasHash, hasParams, hasQuery, PathDescriptor } from '../types';

export const generatePath = <Page extends PageKeys>({
  desc,
  activeBusiness,
}: {
  desc: PathDescriptor<Page>;
  activeBusiness?: { _id: string } | null;
}): string => {
  const params = {
    ...(activeBusiness && { businessId: activeBusiness._id }),
    ...(hasParams(desc) && desc.params),
  };

  const { path } = Paths[desc.page];

  try {
    let slugs = [] as string[];
    if ('slug' in params) {
      [params.slug, ...slugs] = (params.slug || '').split('/');
    }

    // DEV note: react-router-dom's generatePath escapes /'s in the path,
    // so we're splitting and rejoining later as a temporary workaround
    let formattedPath = generateDomPath(path, params);
    formattedPath = [formattedPath, ...slugs].join('/');

    if (hasQuery(desc)) {
      const query = new URLSearchParams(
        Object.entries(desc.query).map(([key, value]) => [
          key,
          value.toString(),
        ])
      );
      formattedPath = `${formattedPath}?${query}`;
    }

    if (hasHash(desc)) {
      formattedPath = `${formattedPath}#${desc.hash}`;
    }
    return formattedPath;
  } catch (error) {
    return '/404';
  }
};
