import styled from 'styled-components';
import { color, sizing, fonts, utils } from '../../../style';

export const RecentlyViewedUserContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: ${sizing(16)};
  max-width: ${sizing(72)};

  &:last-child {
    margin-right: 0;
  }
`;

export const AvatarsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  overflow-x: scroll;

  ${utils.hideScrollbars}
`;

export const Name = styled.span`
  ${fonts.boldText['100']};
  color: ${color.solids.WHITE};
  margin: ${sizing(5)} 0 0;
  text-align: center;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;
