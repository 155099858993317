import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { AdminUser } from 'types/src/api/models/user';
import { client } from '../../../../helpers/ApiClient';
import authActionCreatorFactory from '../authActionCreatorFactory';
import { ActionError } from '../types';
import { ResetPasswordParams } from './types';

const asyncActionCreator = authActionCreatorFactory<ResetPasswordParams>();

async function unconnectedResetPassword({
  token,
  password,
  briefcaseResetPassword,
}: ResetPasswordParams) {
  try {
    return client.post('/auth/resetPassword', {
      data: {
        token,
        password,
        briefcaseResetPassword,
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      'cameoweb.state.auth.resetPassword.ResetPasswordError',
      error
    );
    throw new Error('Unable to reset password.');
  }
}

const resetPassword = asyncActionCreator<
  ResetPasswordParams,
  { user: AdminUser; isLoggedIn?: boolean },
  ActionError
>('RESET_PASSWORD', unconnectedResetPassword);

export const service = (
  token: string,
  password: string,
  briefcaseResetPassword = false
) =>
  thunkToAction(resetPassword)({
    token,
    password,
    briefcaseResetPassword,
  });

export default resetPassword;
