import React from 'react';
import styled from 'styled-components';
import { getUnixTime, parseISO } from 'date-fns';

import analytics from 'analytics';
import { Text } from 'domains/web/components';
import { theme } from '@cameo/theme';
import { media, sizing, color as styleColor } from '../../style';
import { CountdownClock } from '../CountdownClock';

const isHex = (str: string): str is Hex => /^#[0-9a-f]{3,6}$/i.test(str);
const getColorFromString = (bgColor: BGColors) => {
  const trimmed = bgColor?.trim?.()?.toLowerCase() ?? '';

  if (isHex(trimmed)) {
    return trimmed;
  }

  const foundColor = bgColors.find((item) => item.text === trimmed);
  return foundColor?.value || theme.colors['grey-20'];
};

export const bgColors = [
  {
    // default value
    text: 'gray',
    value: theme.colors['grey-20'],
  },
  {
    text: 'yellow',
    value: theme.colors['yellow-20'],
  },
  {
    text: 'blue',
    value: theme.colors['cobalt-20'],
  },
  {
    text: 'purple',
    value: theme.colors['purple-20'],
  },
  {
    text: 'green',
    value: theme.colors['green-20'],
  },
  {
    text: 'brown',
    value: '#41290D',
  },
];

type Hex = string; // Convert to template literal type when TS updated to ^4.1.0: `#${string}`;
type BGColors = 'gray' | 'yellow' | 'blue' | 'purple' | 'green' | 'brown' | Hex;

const Wrapper = styled.div`
  display: block;
  position: relative;
  top: 0;
  cursor: pointer;
  background-color: ${({ color }) => color};
  padding: ${sizing(8)};
`;

const URLText = styled.span`
  display: inline;
  text-decoration: underline;
  color: ${styleColor.solids.WHITE};
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80%;

  @media only screen and (min-width: ${media.queries.TABLET}) {
    width: 100%;
  }
`;

const BannerURL = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
  width: 100%;

  &:focus,
  &:visited,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

export interface PromoBannerProps {
  title?: string;
  name: string;
  linkUrl: string;
  linkText: string;
  startsAt: string;
  expiresAt: string;
  color?: BGColors;
  fontColor?: string;
  iconUrl?: string;
  hideCountdown?: boolean;
  hideBanner?: boolean;
}

export const PromoBanner = ({
  title,
  linkUrl,
  linkText,
  name,
  startsAt,
  expiresAt,
  color,
  fontColor,
  iconUrl,
  hideCountdown,
  hideBanner,
}: PromoBannerProps) => {
  const validImagePath = iconUrl?.endsWith('.svg') || iconUrl?.endsWith('.png');
  let imagePathResized = iconUrl;
  const getTimeStamp = (date = '') => {
    const time =
      date.length === 0 ? getUnixTime(new Date()) : getUnixTime(parseISO(date));
    return time;
  };

  if (validImagePath) {
    // set URL search params: s3 resizes the image for us
    imagePathResized = iconUrl.concat('?w=24&h=24');
  }

  // If the promotion has ended, has not started, or has no name, or is meant to be hidden, do not render
  if (
    !name ||
    !startsAt ||
    !expiresAt ||
    getTimeStamp(expiresAt) < getTimeStamp() ||
    getTimeStamp(startsAt) > getTimeStamp() ||
    hideBanner
  ) {
    return null;
  }

  const handleLinkClick = () => {
    const data = {
      eventName: 'Navigate',
      eventDtl: {
        eventType: 'Click',
      },
      objectDtl: {
        objectType: 'Text',
        containerType: 'Basic-Shelf',
      },
      targetDtl: {
        targetType: 'Home',
        targetPath: linkUrl,
        targetValue: name,
      },
    } as const;

    analytics.trackAction(data);
  };

  return (
    <Wrapper
      data-testid="promo-banner"
      color={getColorFromString(color)}
      onClick={handleLinkClick}
    >
      <BannerURL href={linkUrl} aria-label={name}>
        <LeftContent style={{ color: fontColor || styleColor.solids.WHITE }}>
          {validImagePath && (
            <img
              style={{ marginRight: sizing(5) }}
              src={imagePathResized}
              alt=""
              aria-hidden="true"
            />
          )}
          <Text whiteSpace="break-spaces">
            {title && <b>{title} </b>}
            {name}{' '}
            {!hideCountdown && (
              <>
                <CountdownClock expiresAt={expiresAt} />{' '}
              </>
            )}
            {linkText && (
              <URLText style={{ color: fontColor || styleColor.solids.WHITE }}>
                <b>{linkText}</b>
              </URLText>
            )}
          </Text>
        </LeftContent>
      </BannerURL>
    </Wrapper>
  );
};
