/**
 * Use custom prop to bake a composable transition prop
 * that can be used on any component.
 * https://styled-system.com/api#system
 */

import { system } from 'styled-system';

interface TransitionsArray extends Array<string> {
  default?: string;
}

export const transitions: TransitionsArray = [];
transitions.default = 'all 0.15s ease-in-out';

export type TransitionProps = {
  transition?: 'default' | CSSStyleDeclaration['transition'];
  transitionProperty?: CSSStyleDeclaration['transitionProperty'];
};

export const transitionProp = system({
  transition: {
    property: 'transition',
    scale: 'transitions',
  },
  transitionProperty: {
    property: 'transitionProperty',
  },
});
