import { reducerWithInitialState } from 'typescript-fsa-reducers';
import Cameo from 'types';
import { GiftWrapperType } from 'types/src/utils/enums/gift';
import {
  CreateGift,
  GetGiftById,
  GetUserOrderGift,
  OpenGift,
  OpenLastGift,
  AttributeGift,
  ResetGiftWrapping,
  ResetGiftWrapFormData,
  SetGiftWrap,
  SetGiftWrapFormData,
  SetIsGiftPreview,
  SetLatestCreatedGift,
  RemoveGift,
} from './actions';

export type State = {
  loading: boolean;
  error: any;
  giftWrapping: {
    formData: {
      fromName: string;
      message?: string;
      recipientName: string;
    };
    isPreview: boolean;
    latestCreatedGift: string;
    wrapper?: GiftWrapperType;
  };
  gifts: {
    [key: string]: Cameo.Models.Order.Gift;
  };
};

const GIFTS_INITIAL_STATE = Object.freeze<State>({
  loading: false,
  error: null,
  giftWrapping: {
    formData: {
      fromName: '',
      recipientName: '',
    },
    isPreview: false,
    latestCreatedGift: null,
    wrapper: null,
  },
  gifts: {},
});

const giftsReducer = reducerWithInitialState(GIFTS_INITIAL_STATE);

giftsReducer
  .case(CreateGift.started, (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  })
  .case(CreateGift.done, (state) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  })
  .case(CreateGift.failed, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  })
  .case(GetGiftById.started, (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  })
  .case(GetGiftById.done, (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
      gifts: {
        ...state.gifts,
        [action.result.gift._id]: {
          ...action.result.gift,
          orderId: action.result.order._id,
        },
      },
    };
  })
  .case(GetGiftById.failed, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  })
  .case(GetUserOrderGift.started, (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  })
  .case(GetUserOrderGift.done, (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
      gifts: {
        ...state.gifts,
        [action.result._id]: action.result,
      },
    };
  })
  .case(GetUserOrderGift.failed, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  })
  .case(OpenGift.started, (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  })
  .case(OpenGift.done, (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
      gifts: {
        ...state.gifts,
        [action.params.giftId]: {
          ...state.gifts[action.params.giftId],
          giftOpened: true,
        },
      },
    };
  })
  .case(OpenGift.failed, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  })
  .case(OpenLastGift.started, (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  })
  .case(OpenLastGift.done, (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
      gifts: {
        ...state.gifts,
        [action.result._id]: action.result,
      },
    };
  })
  .case(OpenLastGift.failed, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  })
  .case(AttributeGift.started, (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  })
  .case(AttributeGift.done, (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
      gifts: {
        ...state.gifts,
        [action.params.giftId]: {
          ...state.gifts[action.params.giftId],
          recipients: [action.params.userId],
        },
      },
    };
  })
  .case(AttributeGift.failed, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  })
  .case(RemoveGift.started, (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  })
  .case(RemoveGift.done, (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
      gifts: {
        ...state.gifts,
        [action.result.giftId]: {
          ...state.gifts[action.result.giftId],
          recipients: state.gifts[action.result.giftId]?.recipients.filter(
            (recipient) => String(recipient) !== action.result.userId
          ),
        },
      },
    };
  })
  .case(RemoveGift.failed, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  })
  .case(SetGiftWrap, (state, action) => {
    return {
      ...state,
      giftWrapping: {
        ...state.giftWrapping,
        wrapper: action,
      },
    };
  })
  .case(SetGiftWrapFormData, (state, action) => {
    return {
      ...state,
      giftWrapping: {
        ...state.giftWrapping,
        formData: action,
      },
    };
  })
  .case(SetLatestCreatedGift, (state, action) => {
    return {
      ...state,
      giftWrapping: {
        ...state.giftWrapping,
        latestCreatedGift: action.giftId,
      },
    };
  })
  .case(SetIsGiftPreview, (state, action) => {
    return {
      ...state,
      giftWrapping: {
        ...state.giftWrapping,
        isPreview: action,
      },
    };
  })
  .case(ResetGiftWrapping, (state) => {
    return {
      ...state,
      giftWrapping: GIFTS_INITIAL_STATE.giftWrapping,
    };
  })
  .case(ResetGiftWrapFormData, (state) => {
    return {
      ...state,
      giftWrapping: {
        ...state.giftWrapping,
        formData: GIFTS_INITIAL_STATE.giftWrapping.formData,
      },
    };
  });

export default giftsReducer.build();
