import { FanClubsState } from './types';

const INITIAL_STATE = Object.freeze<FanClubsState>({
  togglingFanClubMember: false,
  toggleFanClubMemberError: null,
  hasJustJoinedFanClub: null,
  hasJustLeftFanClub: null,
  fanClubs: {},
  loadingFanClub: false,
  loadingFanClubError: null,
  membershipIDList: [],
  ownershipIDList: [],
  activeUserSubscriptions: {},
  creatingSubscription: false,
  createSubscriptionError: null,
  subscriptionStatus: null,
  subscriptionPaymentIntent: null,
  related: {},
  relatedLoading: false,
  relatedError: null,
  unsubscribeLoading: false,
  unsubscribeError: null,
  selectedFanClubUserDetails: {
    userSubscriptionExpiresAt: null,
    userPendingCancellation: false,
    userStripeSubscriptionId: null,
    userSubscriptionType: null,
    subscriptionId: null,
    userPricePaid: null,
  },
  contentPreviews: {},
  contentPreviewLoading: false,
  contentPreviewError: null,
  following: [],
  followingLoading: false,
  followingError: null,
  selectedFollowPost: {
    post: null,
    loading: false,
    error: null,
  },
});

export default INITIAL_STATE;
