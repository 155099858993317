import analytics from 'analytics';
// eslint-disable-next-line import/no-cycle
import { State } from 'state/modules/reducer';
import { stringify } from 'qs'; // bundle-size concern?
import { OrderStatus, OrderHistoryEventEnum } from 'types/src/utils/enums';
import { AdminOrder, FanOrder, PublicOrder } from 'types/src/api/models/order';
import { createSelector } from 'reselect';
import { CartFields } from 'containers/BookingForm/types';
import { GetLikes } from 'state/modules/likes';

export const ORDER = {
  DETAIL: 'orders/DETAIL',
  DETAIL_SUCCESS: 'orders/DETAIL_SUCCESS',
  DETAIL_FAIL: 'orders/DETAIL_FAIL',

  COMPLETE: 'orders/COMPLETE',
  COMPLETE_SUCCESS: 'orders/COMPLETE_SUCCESS',
  COMPLETE_FAIL: 'orders/COMPLETE_FAIL', // COMPLETE_FAIL story of my life

  CREATE_HISTORY_EVENT: 'orders/CREATE_HISTORY_EVENT',
  CREATE_HISTORY_EVENT_SUCCESS: 'orders/CREATE_HISTORY_EVENT_SUCCESS',
  CREATE_HISTORY_EVENT_FAIL: 'orders/CREATE_HISTORY_EVENT_FAIL',

  LOAD_ORDER: 'orders/LOAD_ORDER',
  LOAD_ORDER_SUCCESS: 'orders/LOAD_ORDER_SUCCESS',
  LOAD_ORDER_FAIL: 'orders/LOAD_ORDER_FAIL',

  UPDATE_ORDER: 'sb/orders/UPDATE',
  UPDATE_ORDER_SUCCESS: 'sb/orders/UPDATE_SUCCESS',
  UPDATE_ORDER_FAIL: 'sb/orders/UPDATE_FAIL',

  CREATE: 'orders/CREATE',
  CREATE_SUCCESS: 'orders/CREATE_SUCCESS',
  CREATE_FAIL: 'orders/CREATE_FAIL',

  DECLINE: 'orders/DECLINE',
  DECLINE_SUCCESS: 'orders/DECLINE_SUCCESS',
  DECLINE_FAIL: 'orders/DECLINE_FAIL',

  DECLINESET_REVIEWED: 'orders/SET_REVIEWED',
  SET_REVIEWED_SUCCESS: 'orders/SET_REVIEWED_SUCCESS',
  SET_REVIEWED_FAIL: 'orders/SET_REVIEWED_FAIL',

  EXPIRE: 'orders/EXPIRE',
  EXPIRE_SUCCESS: 'orders/EXPIRE_SUCCESS',
  EXPIRE_FAIL: 'orders/EXPIRE_FAIL',

  LIKE: 'orders/LIKE',
  LIKE_SUCCESS: 'orders/LIKE_SUCCESS',
  LIKE_FAIL: 'orders/LIKE_FAIL',

  UNLIKE: 'orders/UNLIKE',
  UNLIKE_SUCCESS: 'orders/UNLIKE_SUCCESS',
  UNLIKE_FAIL: 'orders/UNLIKE_FAIL',

  LOAD: 'orders/LOAD',
  LOAD_SUCCESS: 'orders/LOAD_SUCCESS',
  LOAD_FAIL: 'orders/LOAD_FAIL',

  PLAY_VIDEO: 'orders/PLAY_VIDEO',

  CANCEL: 'orders/CANCEL',
  CANCEL_SUCCESS: 'orders/CANCEL_SUCCESS',
  CANCEL_FAIL: 'orders/CANCEL_FAIL',

  EMAIL_OPT_IN: 'orders/EMAIL_OPT_IN',
  EMAIL_OPT_IN_SUCCESS: 'orders/EMAIL_OPT_IN_SUCCESS',
  EMAIL_OPT_IN_FAIL: 'orders/EMAIL_OPT_IN_FAIL',

  REFUND: 'orders/REFUND',
  REFUND_SUCCESS: 'orders/REFUND_SUCCESS',
  REFUND_FAIL: 'orders/REFUND_FAIL',

  LOAD_ORDER_HISTORY: 'orders/LOAD_ORDER_HISTORY',
  LOAD_ORDER_HISTORY_SUCCESS: 'orders/LOAD_ORDER_HISTORY_SUCCESS',
  LOAD_ORDER_HISTORY_FAIL: 'orders/LOAD_ORDER_HISTORY_FAIL',

  LOAD_USER_ORDERS_HISTORY: 'orders/LOAD_USER_ORDERS_HISTORY',
  LOAD_USER_ORDERS_HISTORY_SUCCESS: 'orders/LOAD_USER_ORDERS_HISTORY_SUCCESS',
  LOAD_USER_ORDERS_HISTORY_FAIL: 'orders/LOAD_USER_ORDERS_HISTORY_FAIL',

  LOAD_FEATURED: 'orders/LOAD_FEATURED',
  LOAD_FEATURED_SUCCESS: 'orders/LOAD_FEATURED_SUCCESS',
  LOAD_FEATURED_FAIL: 'orders/LOAD_FEATURED_FAIL',

  UPVOTE_ORDER: 'orders/UPVOTE_ORDER',
  UPVOTE_ORDER_SUCCESS: 'orders/UPVOTE_ORDER_SUCCESS',
  UPVOTE_ORDER_FAIL: 'orders/UPVOTE_ORDER_FAIL',

  SELECT_ORDER: 'orders/SELECT_ORDER',

  SET_APPLE_PAY_AVAILABLE: 'orders/SET_APPLE_PAY_AVAILABLE',

  LOAD_ORDERS_FOR_USER: 'orders/LOAD_ORDERS_FOR_USER',
  LOAD_ORDERS_FOR_USER_SUCCESS: 'orders/LOAD_ORDERS_FOR_USER_SUCCESS',
  LOAD_ORDERS_FOR_USER_FAIL: 'orders/LOAD_ORDERS_FOR_USER_FAIL',

  LOAD_ORDERS_FOR_FEED: 'orders/LOAD_ORDERS_FOR_FEED',
  LOAD_ORDERS_FOR_FEED_SUCCESS: 'orders/LOAD_ORDERS_FOR_FEED_SUCCESS',
  LOAD_ORDERS_FOR_FEED_FAIL: 'orders/LOAD_ORDERS_FOR_FEED_FAIL',

  CREATE_ORDER_LOADING_STATE: 'orders/CREATE_ORDER_LOADING_STATE',

  BAN_CUSTOMER: 'orders/BAN_CUSTOMER',
  BAN_CUSTOMER_SUCCESS: 'orders/BAN_CUSTOMER_SUCCESS',
  BAN_CUSTOMER_FAIL: 'orders/BAN_CUSTOMER_FAIL',

  SET_EXPEDITED_CAMEO_DELIVERY: 'orders/SET_EXPEDITED_CAMEO_DELIVERY',

  LOAD_MY_ORDER_BY_ID: 'orders/LOAD_MY_ORDER_BY_ID',
  LOAD_MY_ORDER_BY_ID_SUCCESS: 'orders/LOAD_MY_ORDER_BY_ID_SUCCESS',
  LOAD_MY_ORDER_BY_ID_FAIL: 'orders/LOAD_MY_ORDER_BY_ID_FAIL',

  LOAD_BUSINESS: 'orders/LOAD_BUSINESS',
  LOAD_BUSINESS_SUCCESS: 'orders/LOAD_BUSINESS_SUCCESS',
  LOAD_BUSINESS_FAIL: 'orders/LOAD_BUSINESS_FAIL',

  LOAD_CART: 'orders/LOAD_CART',
  LOAD_CART_SUCCESS: 'orders/LOAD_CART_SUCCESS',
  LOAD_CART_FAIL: 'orders/LOAD_CART_FAIL',

  CLEAN_SELECTED_ORDER_ID: 'orders/CLEAN_SELECTED_ORDER_ID',
};

export const initialState = {
  /*
   * Short term implementation.
   * Added here to avoid fetching data for a business
   * on the client.
   * If businesses use redux long term this should be
   * moved to it's own substore.
   */
  business: null,

  orders: {},
  orderIds: {},
  selectedOrderId: null,
  dataBeaverScore: null,
  numPurchases: null,
  loadOrderError: null,
  completeOrderError: null,

  createOrder: null,
  creatingOrder: false,
  createOrderError: null,

  creatingOrderHistoryEvent: false,
  createOrderHistoryEventError: null,

  cart: null,

  loadingOrder: false,
  loading: false,

  updateOrderError: null,
  updatingOrder: false,

  playingVideoUrl: null,

  cancellingOrder: false,
  cancelOrderError: null,

  applePayAvailable: false,

  selectedOrderIds: [],
  orderFormDisabled: false,

  featuredLoaded: false,

  sharedOrderEmailOptingIn: null,
  sharedOrderEmailOptInError: null,

  userOrderHistory: null,
  loadingOrderHistory: null,

  isFeaturedLoaded: null,

  expeditedCameo: null,

  loadingUserOrdersHistory: false,
  ordersHistory: [],
};

export default function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case ORDER.PLAY_VIDEO:
      return {
        ...state,
        playingVideoUrl: action.videoUrl,
      };

    case ORDER.LOAD_BUSINESS_SUCCESS:
      return {
        ...state,
        business: action?.result?.business,
      };
    case ORDER.LOAD_MY_ORDER_BY_ID_SUCCESS:
      const rebookOrder = action.result || {}; // eslint-disable-line no-case-declarations
      return {
        ...state,
        loadingOrder: false,
        orders: {
          ...state.orders,
          [rebookOrder._id]: rebookOrder,
        },
        orderIds: {
          ...state.orderIds,
          [rebookOrder.id]: rebookOrder._id,
        },
        selectedOrderId: rebookOrder._id,
        loadOrderError: null,
      };
    case ORDER.LOAD_MY_ORDER_BY_ID:
    case ORDER.LOAD_ORDER:
      return {
        ...state,
        loadingOrder: true,
      };
    case ORDER.LOAD_ORDER_SUCCESS:
      return {
        ...state,
        loadingOrder: false,
        orders: {
          ...state.orders,
          [action.result.order._id]: {
            ...action.result.order,
            review: action.result.review,
          },
        },
        orderIds: {
          ...state.orderIds,
          [action.result.order.id]: action.result.order._id,
        },
        selectedOrderId: action.result.order._id,
        loadOrderError: null,
      };
    case ORDER.LOAD_MY_ORDER_BY_ID_FAIL:
    case ORDER.LOAD_ORDER_FAIL:
      return {
        ...state,
        loadingOrder: false,
        loadOrderError: action.error.message || action.error,
      };
    case ORDER.COMPLETE:
      return {
        ...state,
        completeOrderError: null,
      };
    case ORDER.COMPLETE_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.result.order._id]: {
            ...state.orders[action.result.order._id],
            ...action.result.order,
          },
        },
      };
    case ORDER.COMPLETE_FAIL:
      return {
        ...state,
        completeOrderError: action.error,
      };
    case ORDER.LOAD:
      return {
        ...state,
        loading: true,
      };
    case ORDER.LOAD_SUCCESS:
    case ORDER.LOAD_ORDERS_FOR_USER_SUCCESS:
    case ORDER.LOAD_ORDERS_FOR_FEED_SUCCESS: {
      if (!action.result.orders || !action.result.orders.length) {
        return state;
      }
      const ordersCopyLoad = { ...state.orders };
      const orderIdsCopy = { ...state.orderIds };
      const actionOrdersCopy = [...action.result.orders];
      actionOrdersCopy.forEach((order) => {
        if (order.ownerId && order.ownerId._id) {
          order.ownerId = order.ownerId._id;
        }
        ordersCopyLoad[order._id] = order;
        orderIdsCopy[order.id] = order._id;
      });
      if (action.result && action.result.introOrder) {
        ordersCopyLoad[action.result.introOrder._id] = action.result.introOrder;
        orderIdsCopy[action.result.introOrder.id] =
          action.result.introOrder._id;
      }
      return {
        ...state,
        numberOfCompletedCameos: action.result.numberOfCompletedCameos,
        numberOfReviews: action.result.numberOfReviews,
        orders: ordersCopyLoad,
        orderIds: orderIdsCopy,
        loading: false,
      };
    }
    /*
     *
     * LOAD ORDER HISTORY
     */
    case ORDER.LOAD_ORDER_HISTORY:
      return {
        ...state,
        loadingOrderHistory: true,
      };
    case ORDER.LOAD_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        loadingOrderHistory: false,
        userOrderHistory: action.result.orderHistory,
      };
    case ORDER.LOAD_ORDER_HISTORY_FAIL:
      return {
        ...state,
        loadingOrderHistory: false,
      };
    /*
     *
     * LOAD USER ORDERS HISTORY
     */
    case ORDER.LOAD_USER_ORDERS_HISTORY:
      return {
        ...state,
        loadingUserOrdersHistory: true,
      };
    case ORDER.LOAD_USER_ORDERS_HISTORY_SUCCESS:
      return {
        ...state,
        loadingUserOrdersHistory: false,
        ordersHistory: action.result.results,
      };
    case ORDER.LOAD_USER_ORDERS_HISTORY_FAIL:
      return {
        ...state,
        loadingUserOrdersHistory: false,
      };
    /*
     *
     * Ban customer
     */
    case ORDER.BAN_CUSTOMER:
      return {
        ...state,
        banningCustomer: true,
      };
    case ORDER.BAN_CUSTOMER_SUCCESS:
      return {
        ...state,
        banningCustomer: false,
      };
    case ORDER.BAN_CUSTOMER_FAIL:
      return {
        ...state,
        banningCustomer: false,
      };
    /*
     *
     * LIKE ORDER
     */
    case ORDER.LIKE:
      return {
        ...state,
        likingOrder: action.orderId,
        likeOrderError: null,
      };
    case ORDER.LIKE_SUCCESS:
      return {
        ...state,
        likingOrder: null,
        orders: {
          ...state.orders,
          [action.result._id]: action.result,
        },
      };
    case ORDER.LIKE_FAIL:
      return {
        ...state,
        likingOrder: null,
        likeOrderError: action.error.message,
      };
    /*
     *
     * UNLIKE ORDER
     */
    case ORDER.UNLIKE:
      return {
        ...state,
        unlikingOrder: action.orderId,
        unlikeOrderError: null,
      };
    case ORDER.UNLIKE_SUCCESS:
      return {
        ...state,
        unlikingOrder: null,
        orders: {
          ...state.orders,
          [action.result._id]: action.result,
        },
      };
    case ORDER.UNLIKE_FAIL:
      return {
        ...state,
        unlikingOrder: null,
        unlikeOrderError: action.error.message,
      };
    /*
     * UPVOTE ORDER
     */
    case ORDER.UPVOTE_ORDER: {
      const upvoteOrdersCopy = { ...state.orders };
      const upvotedOrder = upvoteOrdersCopy[action.orderId];
      upvotedOrder.points += 1;
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.orderId]: upvotedOrder,
        },
      };
    }

    /*
     *
     */
    case ORDER.SELECT_ORDER:
      return {
        ...state,
        selectedOrderId: action.orderId,
      };

    /*
     *
     */
    case ORDER.SET_APPLE_PAY_AVAILABLE:
      return {
        ...state,
        applePayAvailable: action.isAvailable,
      };

    case ORDER.DECLINE_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.result.order._id]: action.result.order,
        },
      };

    case ORDER.SET_REVIEWED_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.result.order._id]: action.result.order,
        },
      };

    case ORDER.EXPIRE_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.result.order._id]: action.result.order,
        },
      };

    case ORDER.CANCEL:
      return {
        ...state,
        cancellingOrder: true,
        cancelOrderError: null,
      };
    case ORDER.CANCEL_SUCCESS:
      return {
        ...state,
        cancellingOrder: false,
        orders: {
          ...state.orders,
          [action.result.order._id]: action.result.order,
        },
      };
    case ORDER.CANCEL_FAIL:
      return {
        ...state,
        cancellingOrder: false,
        cancelOrderError: action.error,
      };

    case ORDER.EMAIL_OPT_IN:
      return {
        ...state,
        sharedOrderEmailOptingIn: true,
        sharedOrderEmailOptInError: null,
      };
    case ORDER.EMAIL_OPT_IN_SUCCESS:
      return {
        ...state,
        sharedOrderEmailOptingIn: false,
        sharedOrderEmailOptInError: null,
      };
    case ORDER.EMAIL_OPT_IN_FAIL:
      return {
        ...state,
        sharedOrderEmailOptingIn: false,
        sharedOrderEmailOptInError: action.error.message,
      };

    case ORDER.REFUND:
      return {
        ...state,
        refundingOrder: true,
        refundingOrderError: null,
      };
    case ORDER.REFUND_SUCCESS:
      return {
        ...state,
        refundingOrder: false,
        orders: {
          ...state.orders,
          [action.result._id]: action.result,
        },
      };
    case ORDER.REFUND_FAIL:
      return {
        ...state,
        refundingOrder: false,
        refundOrderError: action.error,
      };

    case ORDER.LOAD_FEATURED_SUCCESS: {
      const ordersObj2 = { ...state.orders };
      for (const order of action.result.orders) {
        ordersObj2[order._id] = order;
      }
      return {
        ...state,
        orders: ordersObj2,
        isFeaturedLoaded: true,
      };
    }

    case ORDER.CREATE:
      return {
        ...state,
        creatingOrder: true,
        createOrderError: null,
        updateOrderError: null,
      };

    case ORDER.CREATE_SUCCESS:
      analytics.setUser({
        email: action.result.order.purchaserEmail,
        user: {
          _id: action.result.order.bookedByUserId,
          email: action.result.order.purchaserEmail,
        },
      });
      if (action.completion && action.ApplePaySession) {
        action.completion(action.ApplePaySession.STATUS_SUCCESS);
      }
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.result.order._id]: action.result.order,
        },
        orderFormDisabled: false,
        creatingOrder: false,
        selectedOrderId: action.result.order._id,
        dataBeaverScore: action.result.dataBeaverScore,
        numPurchases: action.result.numPurchases,
      };
    case ORDER.CREATE_FAIL:
      if (action.completion && action.ApplePaySession) {
        action.completion(action.ApplePaySession.STATUS_FAILURE);
      }
      return {
        ...state,
        orderFormDisabled: false,
        creatingOrder: false,
        createOrderError: action.error.message || action.error,
      };
    case ORDER.CREATE_ORDER_LOADING_STATE:
      return {
        ...state,
        orderFormDisabled: action.isLoading,
      };
    case ORDER.DETAIL:
      return {
        ...state,
        updateOrderError: null,
        loading: true,
      };
    case ORDER.DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedOrderId: action.result.order._id,
        orders: {
          ...state.orders,
          [action.result.order._id]: action.result.order,
        },
      };
    case ORDER.DETAIL_FAIL:
      return {
        ...state,
        loading: false,
      };
    case ORDER.SET_EXPEDITED_CAMEO_DELIVERY: {
      return {
        ...state,
        expeditedCameo: action.expeditedCameo,
      };
    }
    case ORDER.LOAD_CART: {
      return {
        ...state,
        loading: true,
      };
    }
    case ORDER.LOAD_CART_SUCCESS: {
      return {
        ...state,
        loading: false,
        cart: [action.result.cart][0],
      };
    }
    case ORDER.LOAD_CART_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    /*
     * Order history events
     */
    case ORDER.CREATE_HISTORY_EVENT: {
      return {
        ...state,
        creatingOrderHistoryEvent: true,
        createOrderHistoryEventError: null,
      };
    }
    case ORDER.CREATE_HISTORY_EVENT_SUCCESS: {
      return {
        ...state,
        creatingOrderHistoryEvent: false,
      };
    }
    case ORDER.CREATE_HISTORY_EVENT_FAIL: {
      return {
        ...state,
        creatingOrderHistoryEvent: false,
        createOrderHistoryEventError: action.error.message || action.error,
      };
    }
    case ORDER.CLEAN_SELECTED_ORDER_ID: {
      return {
        ...state,
        selectedOrderId: null,
      };
    }

    case GetLikes.done.type: {
      // NOTE: We are assuming all likes are of type order, but we should filter by type
      const likesOfTypeOrder = action.payload.result.filter(() => true);
      const ordersFromLikes = likesOfTypeOrder.reduce((acc, like) => {
        const order = state.orders[like.target._id] || {};
        return {
          ...acc,
          [like.target._id]: {
            ...like.target,
            ...order,
          },
        };
      }, {});

      return {
        ...state,
        orders: {
          ...state.orders,
          ...ordersFromLikes,
        },
      };
    }

    default:
      return state;
  }
}

function isOrderLoaded(globalState, orderId) {
  return globalState.orders && globalState.orders.orders[orderId];
}

function isLoaded(globalState, orderId) {
  return globalState.orders && globalState.orders.orders[orderId];
}

function playVideo(videoUrl) {
  return {
    type: ORDER.PLAY_VIDEO,
    videoUrl,
  };
}

function loadOrder({ orderId }: { orderId: string }) {
  const url = `/order/show/${orderId}`;

  return {
    types: [ORDER.LOAD_ORDER, ORDER.LOAD_ORDER_SUCCESS, ORDER.LOAD_ORDER_FAIL],
    promise: (client) => client.get(url),
  };
}

function loadActiveOrders() {
  return {
    types: [ORDER.LOAD, ORDER.LOAD_SUCCESS, ORDER.LOAD_FAIL],
    promise: (client) => client.get('/order/active/0/1200'),
  };
}
function loadOrdersForUser(userId) {
  return {
    types: [
      ORDER.LOAD_ORDERS_FOR_USER,
      ORDER.LOAD_ORDERS_FOR_USER_SUCCESS,
      ORDER.LOAD_ORDERS_FOR_USER_FAIL,
    ],
    promise: (client) => client.get(`/order/loadForUser/${userId}`),
  };
}
function loadOrdersForFeed({ userId, start = 0, stop = 50 }) {
  return {
    types: [
      ORDER.LOAD_ORDERS_FOR_FEED,
      ORDER.LOAD_ORDERS_FOR_FEED_SUCCESS,
      ORDER.LOAD_ORDERS_FOR_FEED_FAIL,
    ],
    promise: (client) =>
      client.get(`/order/loadForFeed/${userId}/${start}/${stop}`),
  };
}
function loadOrdersForLoggedInUser() {
  return {
    types: [
      ORDER.LOAD_ORDERS_FOR_USER,
      ORDER.LOAD_ORDERS_FOR_USER_SUCCESS,
      ORDER.LOAD_ORDERS_FOR_USER_FAIL,
    ],
    promise: (client) => client.get('/order/loadForLoggedInUser'),
  };
}

function likeOrder(orderId) {
  return {
    types: [ORDER.LIKE, ORDER.LIKE_SUCCESS, ORDER.LIKE_FAIL],
    orderId,
    promise: (client) => client.get(`/order/like/${orderId}`),
  };
}

function unlikeOrder(orderId) {
  return {
    types: [ORDER.UNLIKE, ORDER.UNLIKE_SUCCESS, ORDER.UNLIKE_FAIL],
    orderId,
    promise: (client) => client.get(`/order/unlike/${orderId}`),
  };
}

function loadOrderType(type, offset, limit, sortIndex) {
  return {
    types: [ORDER.LOAD, ORDER.LOAD_SUCCESS, ORDER.LOAD_FAIL],
    promise: (client) =>
      client.get(`/order/${type}/${offset}/${limit}/${sortIndex}`),
  }; // /order/type/offset/limit/sortType limit is how many pages, offset is which page on*limit, sortIndex should be how the page is sorted
}

function selectOrder(orderId) {
  return { type: ORDER.SELECT_ORDER, orderId };
}

function setApplePayAvailable(bool) {
  return {
    type: ORDER.SET_APPLE_PAY_AVAILABLE,
    isAvailable: bool,
  };
}

function loadFeaturedOrders(limit = 6) {
  return {
    types: [
      ORDER.LOAD_FEATURED,
      ORDER.LOAD_FEATURED_SUCCESS,
      ORDER.LOAD_FEATURED_FAIL,
    ],
    promise: (client) => client.get(`/order/loadFeatured?limit=${limit}`),
  };
}
function loadOrderHistory(userId?: string) {
  return {
    types: [
      ORDER.LOAD_ORDER_HISTORY,
      ORDER.LOAD_ORDER_HISTORY_SUCCESS,
      ORDER.LOAD_ORDER_HISTORY_FAIL,
    ],
    promise: (client) =>
      client.get(`/order/loadOrderHistory${userId ? `/${userId}` : ''}`),
  };
}
function loadUserOrdersHistory({ talentView }) {
  const query = stringify({
    status: OrderStatus.ACTIVE,
    talentView,
    limit: 50,
    next: undefined,
    previous: undefined,
    filters: {
      createdAt: undefined,
    },
  });

  return {
    types: [
      ORDER.LOAD_USER_ORDERS_HISTORY,
      ORDER.LOAD_USER_ORDERS_HISTORY_SUCCESS,
      ORDER.LOAD_USER_ORDERS_HISTORY_FAIL,
    ],
    promise: (client) => client.get(`/order/history?${query}`),
  };
}
function upvoteOrder(orderId) {
  return {
    types: [
      ORDER.UPVOTE_ORDER,
      ORDER.UPVOTE_ORDER_SUCCESS,
      ORDER.UPVOTE_ORDER_FAIL,
    ],
    orderId,
    promise: (client) => client.get(`/order/upvote/${orderId}`),
  };
}
function declineOrder(orderId, reason) {
  return {
    types: [ORDER.DECLINE, ORDER.DECLINE_SUCCESS, ORDER.DECLINE_FAIL],
    promise: (client) =>
      client.put(`/order/decline/${orderId}`, {
        data: { reason },
      }),
  };
}
function cancelOrder({
  orderId,
  email,
  reason,
  authToken,
}: {
  orderId: any;
  email?: string;
  reason: string;
  authToken: string;
}) {
  return {
    types: [ORDER.CANCEL, ORDER.CANCEL_SUCCESS, ORDER.CANCEL_FAIL],
    promise: (client) =>
      client.post(`/order/cancel/${orderId}`, {
        data: {
          email,
          authToken,
          reason,
        },
      }),
  };
}
function refundOrder({ orderId, reason }) {
  return {
    types: [ORDER.REFUND, ORDER.REFUND_SUCCESS, ORDER.REFUND_FAIL],
    promise: (client) =>
      client.put(`/order/refund/${orderId}`, {
        data: {
          reason,
        },
      }),
  };
}
function expireOrder(orderId) {
  return {
    types: [ORDER.EXPIRE, ORDER.EXPIRE_SUCCESS, ORDER.EXPIRE_FAIL],
    promise: (client) => client.del(`/order/expire/${orderId}`),
  };
}
export function loadMyOrderById(orderId) {
  return {
    types: [
      ORDER.LOAD_MY_ORDER_BY_ID,
      ORDER.LOAD_MY_ORDER_BY_ID_SUCCESS,
      ORDER.LOAD_MY_ORDER_BY_ID_FAIL,
    ],
    promise: (client): FanOrder => client.get(`/order/myCameo/${orderId}`),
  };
}

export function createOrder(data, isNewCart = false) {
  if (isNewCart) {
    return {
      types: [ORDER.CREATE, ORDER.CREATE_SUCCESS, ORDER.CREATE_FAIL],
      completion: data.completion,
      ApplePaySession: data.ApplePaySession,
      promise: () => Promise.resolve(data),
    };
  }

  return {
    types: [ORDER.CREATE, ORDER.CREATE_SUCCESS, ORDER.CREATE_FAIL],
    completion: data.completion,
    ApplePaySession: data.ApplePaySession,
    promise: (client) =>
      client.post('/order/create', {
        data,
      }),
  };
}
function updateOrder(changes, orderId, authToken?) {
  let url = `/order/update/${orderId}`;
  if (authToken) {
    url += `/${authToken}`;
  }
  return {
    types: [
      ORDER.UPDATE_ORDER,
      ORDER.UPDATE_ORDER_SUCCESS,
      ORDER.UPDATE_ORDER_FAIL,
    ],
    promise: (client) =>
      client.post(url, {
        data: changes,
      }),
  };
}

function setCreateOrderLoadingState(bool) {
  return {
    type: ORDER.CREATE_ORDER_LOADING_STATE,
    isLoading: bool,
  };
}

function getOrderDetails(orderId) {
  return {
    types: [ORDER.DETAIL, ORDER.DETAIL_SUCCESS, ORDER.DETAIL_FAIL],
    promise: ({ get }): PublicOrder => get(`/order/detail/${orderId}`),
  };
}

function removeVideo(orderId) {
  return {
    types: [
      ORDER.UPDATE_ORDER,
      ORDER.UPDATE_ORDER_SUCCESS,
      ORDER.UPDATE_ORDER_FAIL,
    ],
    promise: (client) => client.del(`/order/removeVideo/${orderId}`),
  };
}

function setExpeditedCameo(expeditedCameo: boolean) {
  return {
    type: ORDER.SET_EXPEDITED_CAMEO_DELIVERY,
    expeditedCameo,
  };
}

function reconfirmSuccessForCurrentOrder(order: AdminOrder) {
  return {
    type: ORDER.CREATE_SUCCESS,
    result: { order },
  };
}

function loadCart(cartId: string) {
  return {
    types: [ORDER.LOAD_CART, ORDER.LOAD_CART_SUCCESS, ORDER.LOAD_CART_FAIL],
    promise: (client) => client.get(`cart/${cartId}`),
  };
}

type CreateOrderHistoryEventParams = {
  orderId: string;
  eventType:
    | OrderHistoryEventEnum.ORDER_VIEWED_PURCHASER
    | OrderHistoryEventEnum.ORDER_SHARED
    | OrderHistoryEventEnum.ORDER_REACTION_VIEWED;
};

function createOrderHistoryEvent({
  orderId,
  eventType,
}: CreateOrderHistoryEventParams) {
  return {
    types: [
      ORDER.CREATE_HISTORY_EVENT,
      ORDER.CREATE_HISTORY_EVENT_SUCCESS,
      ORDER.CREATE_HISTORY_EVENT_FAIL,
    ],
    promise: (client) =>
      client.post(`/order/createOrderHistoryEvent`, {
        data: { orderId, eventType },
      }),
  };
}

function cleanSelectedOrderId() {
  return { type: ORDER.CLEAN_SELECTED_ORDER_ID };
}

const actions = {
  cleanSelectedOrderId,
  cancelOrder,
  createOrder,
  createOrderHistoryEvent,
  declineOrder,
  expireOrder,
  getOrderDetails,
  isLoaded,
  isOrderLoaded,
  likeOrder,
  loadActiveOrders,
  loadFeaturedOrders,
  loadOrder,
  loadOrderHistory,
  loadUserOrdersHistory,
  loadOrdersForFeed,
  loadOrdersForLoggedInUser,
  loadOrdersForUser,
  loadOrderType,
  loadMyOrderById,
  loadCart,
  playVideo,
  refundOrder,
  removeVideo,
  selectOrder,
  setApplePayAvailable,
  setCreateOrderLoadingState,
  unlikeOrder,
  updateOrder,
  upvoteOrder,
  setExpeditedCameo,
  reconfirmSuccessForCurrentOrder,
};

// Selector functions

const getCancellingOrder = (state: State) => state.orders.cancellingOrder;
const getCompleteOrderError = (state: State) => state.orders.completeOrderError;
const getCreateOrder = (state: State) => state.orders.createOrder;
export const getCreatingOrder = (state: State) => state.orders.creatingOrder;
export const getCreateOrderError = (state: State) =>
  state.orders.createOrderError;
const getIsFeaturedLoaded = (state: State) => state.orders.isFeaturedLoaded;
const getLastTranscription = (orderId: string) => {
  return (state: State) => {
    const order = getOrderById(orderId)(state);

    if (order && order.transcriptions && order.transcriptions.length > 0) {
      return order.transcriptions[order.transcriptions.length - 1];
    }

    return null;
  };
};
const getLoading = (state: State) => state.orders.loading;
const getLoadingOrder = (state: State) => state.orders.loadingOrder;
const getLoadingOrderError = (state: State) => state.orders.loadOrderError;
const getLoadingOrderHistory = (state: State) =>
  state.orders.loadingOrderHistory;
export const getOrderById = (orderId: string) => {
  return (state: State) => state.orders.orders[orderId];
};
const getOrderFormDisabled = (state: State) => state.orders.orderFormDisabled;
const getOrders = (state: State) => ({ ...state.orders.orders });
const getOrdersIds = (state: State) => state.orders.orderIds;
const getPlayingVideoUrl = (state: State) => state.orders.playingVideoUrl;
export const getSelectedOrder = (state: State) =>
  state.orders.orders[state.orders.selectedOrderId];
export const getSelectedOrderId = (state: State) =>
  state.orders.selectedOrderId;
export const getSelectedOrderAuthToken = createSelector(
  getSelectedOrder,
  (order) => order?.authToken || null
);
const getSharedOrderEmailOptingIn = (state: State) =>
  state.orders.sharedOrderEmailOptingIn;
const getSharedOrderEmailOptInError = (state: State) =>
  state.orders.sharedOrderEmailOptInError;
const getDataBeaverScore = (state: State) => state.orders.dataBeaverScore;
const getNumPurchases = (state: State) => state.orders.numPurchases;
const getUpdatingOrder = (state: State) => state.orders.updatingOrder;
const getUpdatingOrderError = (state: State) => state.orders.updateOrderError;
const getUserOrderHistory = (state: State) => ({
  ...state.orders.userOrderHistory,
});

const getExpeditedCameo = (state: State) => state.orders.expeditedCameo;
const getCart = (state: State): CartFields => state.orders.cart;

const selectors = {
  getCancellingOrder,
  getCart,
  getCompleteOrderError,
  getCreateOrder,
  getCreatingOrder,
  getCreateOrderError,
  getIsFeaturedLoaded,
  getLastTranscription,
  getLoading,
  getLoadingOrder,
  getLoadingOrderError,
  getLoadingOrderHistory,
  getOrderById,
  getOrderFormDisabled,
  getOrders,
  getOrdersIds,
  getPlayingVideoUrl,
  getSelectedOrder,
  getSelectedOrderAuthToken,
  getSelectedOrderId,
  getSharedOrderEmailOptInError,
  getSharedOrderEmailOptingIn,
  getUpdatingOrder,
  getUpdatingOrderError,
  getUserOrderHistory,
  getDataBeaverScore,
  getNumPurchases,
  getExpeditedCameo,
};

export { actions, selectors };
