import { ThemeInterface } from 'domains/web/theme/types';
import { createGlobalStyle, css } from 'styled-components';

const fontSize = '0.9em';
const fontFamily = 'Cerebri Sans';

const confirmButtonFontColor = '#413D40';
const confirmButtonBorderColor = 'rgba(0, 0, 0, 0.2)';

const alwaysActiveFontColor = '#141114';

const borderRadius = '8px';
const buttonBorderWidth = '2px';

const marginSpacingSmall = '10px';
const marginSpacingMedium = '20px';
const marginSpacingLarge = '33px';

const hrSpacing = '15px';

const opacity = '95%';

export const OneTrustGlobalStyle = createGlobalStyle`
    @media screen and (max-width: 800px) {
        #onetrust-consent-sdk {
            font-family: '${fontFamily}';
            #onetrust-banner-sdk {
                .banner-close-btn-container button {
                    margin-right: 0;
                }
            }

            #onetrust-banner-sdk .ot-sdk-row {
                margin-bottom: ${marginSpacingSmall};
            }
        }

        #onetrust-pc-sdk[role=alertdialog] {
            height: auto !important;
            min-width: 0 !important;
            width: calc(100% - ${marginSpacingMedium}) !important;
            margin-top: ${marginSpacingSmall} !important;
            border-radius: ${borderRadius} !important;
            padding-bottom: 15px !important;
            opacity: ${opacity};

            #ot-pc-content {
                position: static !important;
                margin-left: 20px;
                #accept-recommended-btn-handler {
                    border-radius: ${borderRadius};
                }
            }

            .ot-always-active {
                padding-top: 8px;
                color: ${alwaysActiveFontColor} !important;
            }

            #ot-pc-content-hr {
                margin-top: -${marginSpacingSmall};
                margin-bottom: ${hrSpacing};
            }

            .ot-accordion-layout.ot-cat-item {
                border: none !important;
            }

            .ot-acc-hdr{
                padding-left: 0 !important;
                padding-right: 40px !important;
                padding-bottom: 0 !important;
            }

            .ot-cat-header {
                padding: 0;
            }

            .ot-acc-grpdesc.ot-category-desc {
                padding: 0;
            }

            .ot-plus-minus {
                display: none;
            }

            .ot-pc-footer {
                position: static !important;
                border-top: none !important;
            }

            #ot-category-title-container {
                display: flex;
                margin-bottom: -${marginSpacingSmall};
                #ot-category-title {
                    flex-grow: 1;
                }
            }

            .save-preference-btn-handler {
                background-color: white !important;
                color: ${confirmButtonFontColor} !important;
                border-color: ${confirmButtonBorderColor} !important;
                border-radius: ${borderRadius} !important;
                border-width: ${buttonBorderWidth} !important;
                margin-right: ${marginSpacingSmall};
                margin-left: 7px;
                font-size: ${fontSize};
            }

            .ot-pc-footer {
                margin-left: ${marginSpacingSmall};
                .ot-btn-container {
                    margin-right: ${marginSpacingLarge};
                }
            }
            
        }

        button#onetrust-pc-btn-handler {
            border: 0 !important;
            margin-bottom: 0 !important;
        }

        #onetrust-banner-sdk { 
            .ot-sdk-container {
                border-radius: ${borderRadius} !important;
                margin: ${marginSpacingMedium} !important;
                width: auto !important;
                opacity: ${opacity};
            }
        }

        .ot-pc-hidden {
            display: none !important;
        }
    } 

    #ot-sdk-btn-container#ot-sdk-btn-container {
        display: flex;
        align-items: center;
        gap: 0.2rem;

        img {
            padding: 0.2rem !important;
        }
    }

    #ot-sdk-btn#ot-sdk-btn.ot-sdk-show-settings {
        background: none !important;
        border: none !important;
        padding: 0!important;
        cursor: pointer;

        ${({ theme: t }) => {
          const theme = t as unknown as ThemeInterface;

          return css`
            color: ${theme.colors.foreground.muted} !important;
            font-family: ${theme.fonts.fontFamily.body} !important;
            font-size: ${theme.fonts.fontSizes.base_s} !important;
            font-weight: ${theme.fonts.fontWeights.medium} !important;
            line-height: ${theme.fonts.lineHeights.base_s} !important;
            transition: ${theme.transitions.default} !important;

            &:hover {
              color: ${theme.colors.foreground.default} !important;
            }
          `;
        }}
    }
`;
