import { MediaSizes } from 'design-system/style/media';
import { getGlobalVariable } from 'utils/getGlobalVariable';

const type = 'cameo/web/client/getScreenSize';

const DESKTOP_THRESHOLD = MediaSizes.LARGE_DESKTOP;
const MOBILE_THRESHOLD = MediaSizes.TABLET;
const TABLET_THRESHOLD = MediaSizes.DESKTOP;

// TODO: Drop large-desktop, isn't possible to know the difference between large-desktop and desktop during SSR
function getBreakPoint(screenWidth) {
  if (screenWidth < MOBILE_THRESHOLD) {
    return 'mobile';
  }
  if (screenWidth < TABLET_THRESHOLD) {
    return 'tablet';
  }
  if (screenWidth < DESKTOP_THRESHOLD) {
    return 'desktop';
  }
  return 'large-desktop';
}

function getScreenData(payload = {}) {
  if (getGlobalVariable('innerWidth')) {
    return {
      screenWidth: getGlobalVariable('innerWidth'),
      screenHeight: getGlobalVariable('innerHeight'),
      screenSizeKnown: true,
      breakpoint: getBreakPoint(getGlobalVariable('innerWidth')),
    };
  }

  return payload;
}

export const reducer = {
  [type]: (state, action) => {
    return {
      ...state,
      ...getScreenData(action.payload),
    };
  },
};

export const actionCreator = (payload = {}) => ({ type, payload });
