import { config } from 'cameoConfig';
import { EventBase, EventBaseFull, appAndWebEventNames } from '@cameo/events';
import { DpUploader } from 'analytics/utils/dpUploader';
import { dpSessionManager } from 'analytics/utils/dpSessionManager';
import { qualityMetrics } from 'analytics/utils/quality-metrics';
import { v4 as uuidv4 } from 'uuid';
import { dpDevicePlatformManager } from 'analytics/utils/dpDevicePlatformManager';
import { dpOptimizelyIdManager } from 'analytics/utils/dpOptimizelyIdManager';
import { dpLanguageManager } from 'analytics/utils/dpLanguageManager';
import { addInternalEvent } from 'state/modules/internalEvents/actions';
import { dpVisitorManager } from 'analytics/utils/dpVisitorManager';

const BUFFER_KEY = 'DP-LOGGER-EVENTS';
const SEQ_ID_KEY = 'DP-LOGGER-SEQID';
const DISPATCH_KEY = 'DP-LOGGER-DISPATCH-ATTEMPT';
const TAB_ID_KEY = 'DP-LOGGER-TABID';
const APP_TYPE: 'SERVER' | 'WEB' | 'MOBILE-APP' = 'WEB';

class DpLogger {
  private eventBuffer: EventBaseFull[];

  private sequenceId: number; // (note: this represents the next sequence ID that will be applied)

  private userId: string;

  private queuedUpload: NodeJS.Timeout | number; // note: if this var is undefined, we assume that no upload is queued up

  private isInitialized: boolean;

  private dispatchAttempt: number;

  private tabId: string;

  private canFlush: boolean; // lightweight lock to ensure background flushes only occur once

  private store; // for use with event explorer

  private appAndWebEventNames: string[] = appAndWebEventNames; // to fix eslint issue

  private pageId: string;

  constructor() {
    this.eventBuffer = [];
    this.sequenceId = 0;
    this.userId = null;
    this.tabId = uuidv4();
    this.queuedUpload = undefined;
    this.isInitialized = false;
    this.dispatchAttempt = 0;
    this.canFlush = true;
    this.store = null;
    this.pageId = uuidv4();
  }

  private initialize() {
    if (typeof window === 'undefined') return;

    this.getStoredValuesFromSessionStorage();

    // we need to listen to both visibilitychange and pagehide in order to support backgrounding behavior on all major
    // browsers (safari - and only safari - doesn't use visibilitychange on tab close/refresh/etc)
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.onBackground();
      } else if (document.visibilityState === 'visible') {
        this.onForeground();
      }
    });
    window.addEventListener('pagehide', this.onBackground.bind(this));
    window.addEventListener('pageshow', this.onForeground.bind(this));

    this.isInitialized = true;
  }

  private getStoredValuesFromSessionStorage() {
    const storedBuffer = window.sessionStorage.getItem(BUFFER_KEY);
    const storedSeqId = window.sessionStorage.getItem(SEQ_ID_KEY);
    const storedDispatchAttempt = window.sessionStorage.getItem(DISPATCH_KEY);
    const storedTabId = window.sessionStorage.getItem(TAB_ID_KEY);

    if (storedBuffer) {
      this.eventBuffer = JSON.parse(storedBuffer);
    }
    if (storedSeqId) {
      this.sequenceId = Number(storedSeqId);
    }
    if (storedDispatchAttempt) {
      this.dispatchAttempt = Number(storedDispatchAttempt);
    }
    if (storedTabId) {
      this.tabId = storedTabId;
    }
  }

  private onBackground() {
    try {
      if (this.canFlush && this.eventBuffer.length) {
        this.canFlush = false;
        // preempt any scheduled uploads, if background upload is forced
        clearTimeout(this.queuedUpload);
        this.queuedUpload = undefined;
        const bufferCopy = this.createBufferCopyWithDataQuality();
        this.dispatchAttempt += 1;
        DpUploader.uploadEventsOnUnload(bufferCopy, this.dispatchAttempt);
        // assume success - otherwise we will incur massive duplication (e.g. on tab switching)
        this.onUploadSuccess(this.sequenceId - 1);
        this.flushToDisk();
      }
    } catch (error) {
      console.error('dplogger background flush error:', error);
    }
  }

  private onForeground() {
    this.canFlush = true;
  }

  public async logEvent(event: EventBase) {
    try {
      if (!this.isInitialized) {
        this.initialize();
      }
      if (!this.isInitialized) return;

      const data = { ...event };
      const eventFullDtl = this.applyCommonProps(data);
      if (this.store) {
        // eslint-disable-next-line no-unused-expressions
        this.store.dispatch?.(addInternalEvent(eventFullDtl));
      }
      this.eventBuffer.push(eventFullDtl);
      if (this.eventBuffer.length > config.eventCollector.maxBufferSize) {
        qualityMetrics.incrementBufferOverflowEventCount();
        this.eventBuffer = this.eventBuffer.slice(1);
      }
      if (!this.queuedUpload) {
        this.queuedUpload = setTimeout(async () => {
          await this.uploadBuffer();
        }, config.eventCollector.flushDelayMs);
      }
    } catch (error) {
      console.error('dplogger event log error:', error);
    }
  }

  private async uploadBuffer() {
    try {
      this.flushToDisk();
      const maxUploadedSeqId = this.eventBuffer.slice(-1)[0]._sequenceId; // (slicing gives us last event)
      const bufferCopy = this.createBufferCopyWithDataQuality();
      this.dispatchAttempt += 1;
      const response = await DpUploader.uploadEvents(
        bufferCopy,
        this.dispatchAttempt
      );
      qualityMetrics.incrementResponseStatusCounters(response);
      if (response === 200) {
        this.onUploadSuccess(maxUploadedSeqId);
      }
      this.flushToDisk();
    } catch (error) {
      console.error('dplogger uploadBuffer error:', error);
    } finally {
      this.queuedUpload = undefined;
    }
  }

  private flushToDisk() {
    try {
      this.flushToSessionStorage();
      qualityMetrics.flushToSessionStorage();
      dpSessionManager.flushAllToDisk();
      dpDevicePlatformManager.flushDevicePlatformIdToDisk();
    } catch (error) {
      console.error('dplogger flushToDisk error:', error);
      qualityMetrics.incrementDiskFlushFailureCount();
    }
  }

  private flushToSessionStorage() {
    window.sessionStorage.setItem(BUFFER_KEY, JSON.stringify(this.eventBuffer));
    window.sessionStorage.setItem(SEQ_ID_KEY, String(this.sequenceId));
    window.sessionStorage.setItem(DISPATCH_KEY, String(this.dispatchAttempt));
    window.sessionStorage.setItem(TAB_ID_KEY, this.tabId);
  }

  private onUploadSuccess(maxUploadSeqId: number) {
    this.eventBuffer = this.eventBuffer.filter(
      (event) => event._sequenceId > maxUploadSeqId
    );
    this.dispatchAttempt = 0;
    qualityMetrics.clear();
  }

  public setUser(user) {
    this.userId = user._id;
    dpSessionManager.setUserId(user._id);
  }

  public setPageId() {
    this.pageId = uuidv4();
  }

  // public method to align with the analytics/clients interface expected by ../index.js
  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
  public track() {}

  // public method to align with the analytics/clients interface expected by ../index.js
  public init() {
    if (!this.isInitialized) {
      this.initialize();
    }
  }

  public setStore(store) {
    this.store = store;
  }

  private applyCommonProps(event: EventBase, applySeqId = true): EventBaseFull {
    const commonProps = this.getCommonProps();
    if (applySeqId) {
      commonProps._sequenceId = this.sequenceId;
      this.sequenceId += 1;
    }
    return {
      ...event,
      ...commonProps,
      ...this.getPlatformProps(event.eventName),
    };
  }

  private getCommonProps() {
    return {
      _userTs: new Date().getTime(),
      _userId: this.userId,
      _sequenceId: undefined,
      _appType: APP_TYPE,
      _sessionId: dpSessionManager.getSessionId(),
      _devicePlatformId: dpDevicePlatformManager.getDevicePlatformId(),
      _referrer: document.referrer,
      _tabId: this.tabId,
      _eventUUID: DpLogger.createEventUUID(),
      _optimizelyId: dpOptimizelyIdManager.getOptimizelyId(),
      _userSelectedLang: dpLanguageManager.getLanguageCode(),
      _visitorId: dpVisitorManager.getVisitorId(),
      _userUploadTs: undefined,
      _version: undefined,
      _optimizelyExperiments: undefined,
      _optimizelyFeatureFlags: undefined,
      _nativeDeviceId: undefined,
      _connection_type: undefined,
      _pageId: this.pageId,
    };
  }

  private getPlatformProps(eventName: string) {
    if (eventName in this.appAndWebEventNames) {
      // if we had web specific platform fields, we would always return those and optionally return
      //   nulled out app fields if the event is shared between app and web. But we have none!
      return {
        _appBuildNumber: null,
        _appBinaryVersion: null,
        _appDeviceCarrier: null,
        _appBundleId: null,
        _appDeviceId: null,
      };
    }
    return {};
  }

  private createBufferCopyWithDataQuality() {
    const bufferCopy = [...this.eventBuffer];
    const qualityEvent = qualityMetrics.createDataQualitySnapshot(
      bufferCopy.length,
      this.eventBuffer.length
    );
    const fullDtl = this.applyCommonProps(qualityEvent, false);
    bufferCopy.push(fullDtl);
    return bufferCopy;
  }

  private static createEventUUID() {
    /* Using timestamp + uuidv4 generated uuid to create event uuid
     * because of a higher than normal amount of collision in uuid generation
     * from the uuidv4 library in other parts of code. Want to ensure smaller
     * amount of collision likelihood.
     * */
    const timestamp = new Date().getTime();
    const UUID = uuidv4();
    return `${timestamp}-${UUID}`;
  }

  public reset() {
    this.isInitialized = false;
    this.eventBuffer = [];
    this.userId = null;
    this.tabId = uuidv4();
    this.sequenceId = 0;
    this.queuedUpload = undefined;
    this.dispatchAttempt = 0;
    this.canFlush = true;
    this.store = null;
  }
}

export default new DpLogger();
