export const CAMEO_KIDS_SESSION_COOKIE_NAME = '@cameo/cameoKidsSession';

//TODO: Move to dynamically generated match client side in the future. This is fine for launch and the next few months
export const CAMEO_KIDS_PATHS = [
  '/kids',
  '/blippi',
  '/cecefromcocomelon',
  '/codyfromcocomelon',
  '/jjfromcocomelon',
  '/ninafromcocomelon',
  '/santaclaus',
  '/toothfairy',
  '/thomasthetankengine',
  '/trueandtherainbowkingdom',
];
