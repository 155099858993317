/* eslint-disable react/jsx-no-literals */
import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Text } from 'domains/web/components';
import { selectEvents } from 'state/modules/internalEvents/selectors';
import { clearInternalEvents } from 'state/modules/internalEvents/actions';

import { Input } from 'design-system/Components/Input';
import { Checkbox } from 'design-system/Components/Forms';
import copyToClipboard from 'copy-to-clipboard';
import { toast } from 'design-system/Components/Toast';
import { Share, Trash } from 'react-feather';
import {
  Root,
  Section,
  List,
  SectionTitle,
  Header,
  SectionWrapper,
  HeaderControls,
  ListItem,
  ColorMap,
  Legend,
  LegendItem,
  ColorBox,
} from './Styled';
import { EventItem } from './EventItem';

export const CameoEventsExplorer = () => {
  const dispatch = useDispatch();
  const events = useSelector(selectEvents);
  const [filterValue, setFilterValue] = useState('');
  const [hideEmptyValues, setHideEmptyValues] = useState(true);

  const filteredEvents = events
    .filter((event) =>
      JSON.stringify(event).toUpperCase().includes(filterValue.toUpperCase())
    )
    .sort((event1, event2) => (event2._userTs ?? 0) - (event1._userTs ?? 0));

  const handleClearEvents = useCallback(() => {
    dispatch(clearInternalEvents());
  }, [dispatch]);

  const handleExportEvents = useCallback(() => {
    copyToClipboard(JSON.stringify(filteredEvents));
    toast({
      message: 'Events copied',
      autoClose: 2500,
    });
  }, [dispatch, filteredEvents]);

  const handleFilterChange = useCallback(
    (value) => {
      setFilterValue(value);
    },
    [setFilterValue]
  );

  const handleHideEmptyValuesChange = useCallback(
    (key: string, value: boolean) => {
      setHideEmptyValues(value);
    },
    [setHideEmptyValues]
  );

  return (
    <Root>
      <Header>
        <SectionTitle>
          Cameo Events Explorer: {filteredEvents.length} Events
        </SectionTitle>
        <HeaderControls>
          <Checkbox
            name={'Hide empty values'}
            option={{
              id: 'hide-empty',
              text: 'Hide empty values',
              value: 'hide-empty',
              isChecked: hideEmptyValues,
            }}
            onChange={handleHideEmptyValuesChange}
          />
          <Button
            variant="secondary"
            size="small"
            onClick={handleClearEvents}
            width="fit-content"
          >
            <Trash />
            {'Clear events'}
          </Button>
          <Button
            variant="secondary"
            size="small"
            onClick={handleExportEvents}
            width="fit-content"
          >
            <Share />
            {'Export'}
          </Button>
        </HeaderControls>
      </Header>
      <Section>
        <SectionWrapper>
          <Input
            type="text"
            placeholderText="Filter: eventName, eventType, objectType..."
            title="Filter:"
            name="feature-flags-filter"
            onChange={handleFilterChange}
            value={filterValue}
          />
          <Box
            paddingBottom="xs"
            borderBottomWidth="1px"
            borderBottomColor="#FFF"
          >
            <Text as="h3" variant="title_s" fontWeight="bold" color="white">
              Time | Event Name | Event Type | Event Name | Event Details - Type
              | Object Details - Type
              <Legend>
                {Object.entries(ColorMap).map((colorPair) => (
                  <LegendItem key={colorPair[0]}>
                    <ColorBox color={colorPair[1]} />
                    {colorPair[0]}
                  </LegendItem>
                ))}
              </Legend>
            </Text>
          </Box>
          <List>
            {filteredEvents.map((event) => (
              <ListItem key={event._eventUUID}>
                <EventItem event={event} hideEmptyValues={hideEmptyValues} />
              </ListItem>
            ))}
          </List>
        </SectionWrapper>
      </Section>
    </Root>
  );
};
