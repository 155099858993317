export function getGlobalVariable(
  varName:
    | '__WEBPACK_IS_SERVER__'
    | '__WEBPACK_IS_CLIENT__'
    | '__WEBPACK_DEVELOPMENT_MODE__'
    | '__IS_SERVER__'
    | '__IS_CLIENT__'
    | 'module.hot'
    // Allow other strings while showing previous options in the integration
    // eslint-disable-next-line @typescript-eslint/ban-types
    | (string & {})
) {
  let value = null;

  try {
    // Try getting the raw value first
    if (varName === '__WEBPACK_IS_SERVER__') {
      value = __WEBPACK_IS_SERVER__;
    } else if (varName === '__WEBPACK_IS_CLIENT__') {
      value = __WEBPACK_IS_CLIENT__;
    } else if (varName === '__WEBPACK_DEVELOPMENT_MODE__') {
      value = __WEBPACK_DEVELOPMENT_MODE__;
    } else if (varName === 'module.hot') {
      // @ts-expect-error: ts(2339)
      value = module.hot;
    } else {
      // re-evaluate below
      throw new Error('re-evaluate');
    }
  } catch {
    try {
      // Try getting the value from `global`
      value = global[varName];
    } catch {
      try {
        // Try getting the value from `window`
        value = window[varName];
      } catch {
        // no-op
      }
    }
  }
  return value;
}
