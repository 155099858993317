import styled from 'styled-components';
import { theme } from '@cameo/theme';
import { animations, color, fonts, sizing, spacing } from 'design-system/style';

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  height: ${sizing(24)};
  width: ${sizing(24)};
  z-index: 1;
`;

export const Wrapper = styled.div<{ isRound: boolean }>`
  position: relative;
  margin: ${spacing.margin.small} ${spacing.margin.xxsmall};
  padding: 0;
  border: 0;

  input:not(:disabled) + label {
    cursor: pointer;
  }

  input:disabled + label {
    cursor: not-allowed;
    opacity: 0.3;
  }

  svg {
    transition: opacity ${animations.transition.FAST}ms ease-in;
    opacity: 0;
  }

  label {
    ${fonts.regularText['500']};
    display: block;
    color: ${color.grays.GRAY20};
    position: relative;
    padding-left: ${sizing(32)};
    max-width: ${sizing(400)};
    line-height: ${sizing(24)};

    &::before,
    &::after {
      content: ' ';
      transition: border-color ${animations.transition.FAST}ms ease-in,
        background-color ${animations.transition.FAST}ms ease-in,
        opacity ${animations.transition.FAST}ms ease-in;
      border-radius: ${({ isRound }) => (isRound ? sizing(50) : sizing(6))};
      height: ${sizing(24)};
      left: 0;
      position: absolute;
      top: 0;
      width: ${sizing(24)};
      background-color: transparent;
    }

    &::before {
      border: ${sizing(1)} solid ${theme.colors['grey-95']};
    }

    &::after {
      background-color: ${theme.colors.accent.default};
      border: ${sizing(1)} solid ${theme.colors.accent.default};
      opacity: 0;
    }
  }

  input[type='checkbox'] {
    position: absolute;
    visibility: hidden;
    height: 0;
    margin: 0;
    width: 0;
  }

  input:checked + label {
    &::after {
      opacity: 1;
    }

    svg {
      opacity: 1;
    }
  }
`;
