import * as React from 'react';
import type { CustomIconProps } from './types';

function BedIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M14.7629 10.6C14.6293 10.6 14.5624 10.4384 14.6568 10.3439V10.3439C14.685 10.3158 14.701 10.2776 14.7 10.2378C14.6946 10.0373 14.6627 9.8618 14.6043 9.6978C14.5064 9.42257 14.4008 9.1441 14.4008 8.85197V2.79999C14.4008 1.69542 13.5054 0.799988 12.4008 0.799988H3.60078C2.49621 0.799988 1.60078 1.69542 1.60078 2.79999V8.69444C1.60078 9.08929 1.45967 9.47079 1.36134 9.8532C1.32097 10.0102 1.30078 10.1652 1.30078 10.3V10.35C1.30078 10.4881 1.18885 10.6 1.05078 10.6V10.6C0.91271 10.6 0.800781 10.7119 0.800781 10.85V14.8C0.800781 15.0209 0.979867 15.2 1.20078 15.2V15.2C1.4217 15.2 1.60078 15.0209 1.60078 14.8V14.4C1.60078 13.9582 1.95895 13.6 2.40078 13.6H13.5008C13.9426 13.6 14.3008 13.9582 14.3008 14.4V14.8C14.3008 15.0209 14.4799 15.2 14.7008 15.2V15.2C14.9217 15.2 15.1008 15.0209 15.1008 14.8V10.805C15.1008 10.6918 15.009 10.6 14.8958 10.6H14.7629ZM1.80078 9.89999C1.80078 8.89999 2.70078 7.99999 3.80078 7.99999H12.2008C13.3008 7.99999 14.2008 8.89999 14.2008 9.89999V10.5C14.2008 10.5999 14.1009 10.6 14.1008 10.6C14.1008 10.6 14.1008 10.6 14.1008 10.6H1.90078C1.80079 10.6 1.80078 10.5 1.80078 10.5C1.80078 10.5 1.80078 10.5 1.80078 10.5V9.89999Z"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default BedIcon;
