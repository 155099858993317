import styled from 'styled-components';
import { color, sizing } from '../../style';

function getNotificationIndicatorDivStyles(
  notificationNumberString: string
): string {
  let fontSize: string;

  if (!notificationNumberString) {
    return `
      height: ${sizing(7)};
      width: ${sizing(7)};
    `;
  }

  switch (notificationNumberString.length) {
    case 1:
      fontSize = `${sizing(10)}`;
      break;
    case 2:
      fontSize = `${sizing(8)}`;
      break;
    default:
      fontSize = `${sizing(7.5)}`;
  }

  return `
    height: ${sizing(17)};
    width: ${sizing(17)};
    line-height: ${sizing(17)};
    text-align: center;
    font-size: ${fontSize}};
    font-weight: bold;
  `;
}

export const NotificationIndicatorSpan = styled.span<{ children: string }>`
  background-color: ${color.solids.PRIMARY};
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;

  ${({ children: notificationNumberString }) =>
    getNotificationIndicatorDivStyles(notificationNumberString)};
`;
