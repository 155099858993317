/* eslint-disable id-length */
import { BorderProps, LayoutProps, system } from 'styled-system';
import styled, { css } from 'styled-components';
import omit from 'lodash/omit';
import React, { useEffect, useState } from 'react';

import { getBorderProps, getLayoutProps } from 'domains/web/utils/filterProps';
import { getSourceUrl } from 'domains/web/utils/getSourceUrl';
import { ThemeInterface } from 'domains/web/theme/types';
import { Box } from '../Box';
import { customPropValidator } from '../utils/customPropValidator';

type BaseProps = {
  as?: React.ElementType | keyof JSX.IntrinsicElements;
  onClick?: (event) => void;
  size?: number;
  alt: string;
} & (
  | {
      imageUrlKey?: never;
      src: string;
    }
  | {
      imageUrlKey: string;
      src?: never;
    }
);

export type ImageProps = LayoutProps &
  BorderProps &
  BaseProps &
  React.ImgHTMLAttributes<HTMLImageElement> & {
    aspectRatio?: CSSStyleDeclaration['aspectRatio'];
    objectFit?: CSSStyleDeclaration['objectFit'];
    /**
     * @description Use JavaScript to load an image that's a quarter resolution of the image that we want to load
     * the caveat with this approach is that for images that are low weight, you will probably hurt the LCP score
     * because you are introducing a loader like element that prevents the page from being branded as completely loaded
     * by Lighthouse since there's a pending element in the loading queue, even though FCP and TTI scores will be improved.
     */
    progressiveLoad?: boolean;
    resizerWidth?: string | number;
    resizerHeight?: string | number;
  };

export const DSImage = ({
  as = 'div',
  imageUrlKey,
  onClick,
  src,
  progressiveLoad = false,
  height = 100,
  width = 100,
  resizerWidth,
  resizerHeight,
  ...rest
}: ImageProps) => {
  const imageArgs = {
    src,
    imageUrlKey,
    height,
    width,
    resizerWidth,
    resizerHeight,
  };
  // a quarter resolution of the original image
  const placeholderSrc = getSourceUrl({ ...imageArgs, resolution: 0.25 });
  // original image
  const sourceUrl = getSourceUrl(imageArgs);

  const [imgSrc, setImgSrc] = useState(
    progressiveLoad ? placeholderSrc : sourceUrl
  );
  const [loadingImgSrc, setLoadingImgSrc] = useState(progressiveLoad);

  useEffect(() => {
    if (progressiveLoad) {
      const img = new Image();
      img.src = sourceUrl;
      img.onload = () => {
        setImgSrc(sourceUrl);
        setLoadingImgSrc(false);
      };
    }
  }, [progressiveLoad, sourceUrl]);

  /**
   * Allow to refetch the image if the source url changes
   */
  useEffect(() => {
    if (imgSrc !== sourceUrl) {
      setImgSrc(sourceUrl);
    }
  }, [imgSrc, sourceUrl]);

  const layoutProps = getLayoutProps(rest);
  const borderProps = getBorderProps(rest);
  const imageProps = omit(
    rest,
    Object.keys(layoutProps),
    Object.keys(borderProps),
    'onClick'
  );

  /**
   * Dealing with cases in which a dev can set as="a" and href="https://cameo.com/username"
   * and the image container will be rendered as an anchor tag. Or as={Link} and to="/username" and
   * the image will be rendered as a Link component.
   *
   * It would be ideal to be able to have getImageProps(), but the list of props is too long
   */
  const containerProps = as !== 'div' ? { ...rest } : {};

  return (
    <ImageAnimation
      as={as}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      height={height}
      width={width}
      overflow="hidden"
      backgroundColor={sourceUrl ? 'transparent' : 'background.level3'}
      onClick={onClick}
      {...layoutProps}
      {...borderProps}
      {...containerProps}
    >
      <Img
        src={imgSrc}
        loading="lazy"
        preLoading={loadingImgSrc}
        {...imageProps}
      />
    </ImageAnimation>
  );
};

const Img = styled.img.withConfig({
  shouldForwardProp: customPropValidator(['preLoading']),
})<{ preLoading: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.15s ease-in-out;

  // Always display the alt text of the image in the middle of the container
  display: flex;
  justify-content: center;
  align-items: center;

  ${system({
    aspectRatio: {
      property: 'aspectRatio',
    },
    objectFit: {
      property: 'objectFit',
    },
  })}

  ${({ preLoading }) =>
    preLoading
      ? css`
          filter: blur(10px);
          inset: 10px;
        `
      : css`
          transition: filter 100ms ease-in;
        `}
`;

const ImageAnimation = styled(Box)`
  ${({ theme: t }) => {
    const theme = t as unknown as ThemeInterface;

    return css`
      display: block;
      transform: translate3d(0, 0, 0);

      img {
        transition: ${theme.transitions.default};
      }
    `;
  }}
`;
