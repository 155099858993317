import React, { Component, useEffect } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect, ReactReduxContext } from 'react-redux';
import debounce from 'lodash/debounce';
import { Helmet } from 'react-helmet-async';
import { withCookies } from 'react-cookie';
import { useLocation, withRouter } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import {
  actions as authActions,
  selectors as authSelectors,
} from 'state/modules/auth';
import { LocalizationToast } from 'components/LocalizationToast';
import { selectors as promotionSelectors } from 'state/modules/promotions';
import { finishFirstRender } from 'state/modules/asyncConnect';
import { getScreenSize } from 'state/modules/client';
import { selectors as userSelectors } from 'state/modules/users';
import {
  isSlideHeroTopOfLayout,
  isTheNewHeroPresent,
} from 'state/modules/browse/layouts/selectors';
import { getIsNavbarHidden } from 'state/modules/navbar/selectors';
import { selectors as routerSelectors } from 'state/modules/router';
import { getActiveUserPromotion } from 'utils/userPromotion';
import { push } from 'connected-react-router';
import qs from 'qs';
import { CameoDevTools } from 'components/CameoDevTools';
import { AppGlobalStyle } from 'components/AppGlobalStyle';
import { PromoBanner } from 'design-system/Components/PromoBanner';
import { ToastContainer } from 'design-system/Components/Toast';
import { config } from 'cameoConfig';
// import { getGlobalVariable } from 'utils/getGlobalVariable'
import {
  initWunderkindTracking,
  unregisterWunderkindTracking,
} from 'containers/App/Wunderkind/WunderkindTrackingUtil';
import { AppNav } from './AppNav';
import { OneTrustGlobalStyle } from './OneTrust/OneTrustGlobalStyle';
import { OneTrustInit } from './OneTrust/OneTrustUtil';
import { AppContainer, AppContent } from './Styled';

// if (getGlobalVariable('__WEBPACK_DEVELOPMENT_MODE__')) {
//   // Provides warnings when React elements are being caused to update unnecessarily.
//   // Only listed in dev dependencies so that it won't affect production bundle.
//   require('why-did-you-update').whyDidYouUpdate(React); // eslint-disable-line
// }

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
`;

const ATTENTIVE_COOKIE = 'attentiveVariant';

class App extends Component {
  static propTypes = {
    promotions: PropTypes.object.isRequired,

    loggedInUser: PropTypes.object,
    isIgSignup: PropTypes.bool.isRequired,
    clearAuthErrors: PropTypes.func.isRequired,

    pushState: PropTypes.func.isRequired,
    finishFirstRender: PropTypes.func.isRequired,
    getScreenSize: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,

    setAuthView: PropTypes.func.isRequired,
    cookies: PropTypes.object.isRequired,
    selectedUser: PropTypes.object,
    globalPromotion: PropTypes.object,
    history: PropTypes.object,
    isBusinessPage: PropTypes.bool,
    isContentFeedPage: PropTypes.bool,
    newHeroPresent: PropTypes.bool,
  };

  static contextType = ReactReduxContext;

  constructor(props) {
    super(props);

    this.state = {
      appNavHeight: 60,
    };

    this.onWindowResize = debounce(this.onWindowResize, 150);
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);
    this.onWindowResize();

    this.props.finishFirstRender();

    if (config.oneTrust && window.innerWidth <= 800) {
      OneTrustInit();
    }
  }

  componentDidUpdate(prevProps) {
    const { location, loggedInUser, pushState } = this.props;

    if (!prevProps.loggedInUser && loggedInUser) {
      const { search, state } = this.props.location;
      const queryParams = qs.parse(search, { ignoreQueryPrefix: true });
      const redirectPath = queryParams.redirect;

      if (redirectPath) {
        const to = state ? { pathname: redirectPath, state } : redirectPath;
        pushState(to);
      } else if (location.pathname === '/login') {
        pushState(`/`);
      }
    } else if (prevProps.loggedInUser && !loggedInUser) {
      pushState('/login');
    }

    // track in update vs mount since wunderkind can conditionally be added per page. Not ideal and potentially something we could simplify in the future
    initWunderkindTracking();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
    unregisterWunderkindTracking();
  }

  setAppNavHeightCallback = (_appNavHeight) => {
    this.setState({ appNavHeight: _appNavHeight });
  };

  onWindowResize = () => {
    this.props.getScreenSize();
  };

  renderPromotionalBanner = (promotion) => {
    return <PromoBanner {...promotion} />;
  };

  renderPendingTalentBanner = () => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    return (
      <PromoBanner
        name="Use the app to complete your Talent profile."
        linkUrl="https://app.cameo.com"
        linkText="Download the app"
        startsAt={today}
        expiresAt={tomorrow}
        hideCountdown
      />
    );
  };

  renderDevTools(environment, loggedInUser) {
    if (environment && environment === 'development') {
      return <CameoDevTools environment={environment} />;
    }

    if (environment && loggedInUser?.role === 'admin') {
      return <CameoDevTools environment={environment} />;
    }
    return null;
  }

  render() {
    const {
      loggedInUser,
      selectedUser,
      globalPromotion,
      isBusinessPage,
      isContentFeedPage,
      isNavbarHidden,
      newHeroPresent,
      isSlideHeroEnabled,
    } = this.props;

    const { appNavHeight } = this.state;
    const { environment } = config;
    const activeUserPromotion = getActiveUserPromotion(selectedUser || {});
    // Fallback to a global promotion if the individual promotion doesn't have a banner
    const promotion =
      activeUserPromotion && !activeUserPromotion?.hideBanner
        ? activeUserPromotion
        : globalPromotion;
    const isPendingTalent =
      loggedInUser && loggedInUser.role === 'pending-talent';

    const newHeroEnabled = newHeroPresent;
    const isCheckoutPage =
      this.props.location.pathname.includes('/book') &&
      !this.props.location.pathname.includes('/admin/book');

    const shouldHidePromoBanner =
      (promotion?.homepageOnly && this.props.location.pathname !== '/') ||
      this.props.location.pathname.includes('/gift/') ||
      isContentFeedPage ||
      this.props.location.pathname.includes('/recipient') ||
      this.props.location.pathname.includes('/enroll') ||
      this.props.location.pathname.includes('/kids') ||
      isBusinessPage ||
      isCheckoutPage;

    const shouldHideNavbar =
      isNavbarHidden ||
      this.props.location.pathname.includes('/gift/') ||
      this.props.location.pathname.includes('/subscribe') ||
      this.props.location.pathname.startsWith('/business') ||
      new RegExp(/business\/videos\/[0-9, a-z]+\/license-upgrade/g).test(
        this.props.location.pathname
      ) ||
      new RegExp(/business\/videos\/[0-9, a-z]+\/view-license/g).test(
        this.props.location.pathname
      ) ||
      new RegExp(/^\/business\/videos\/[0-9, a-z]+(\/)?$/g).test(
        this.props.location.pathname
      ) ||
      new RegExp(/review\/.*\/reaction$/g).test(this.props.location.pathname) ||
      new RegExp(/review\/.*\/scores$/g).test(this.props.location.pathname) ||
      (this.props.location.pathname.includes('/book/login') &&
        isBusinessPage) ||
      (this.props.location.pathname.includes('/book/signup') &&
        isBusinessPage) ||
      (isCheckoutPage && !isBusinessPage);

    const shouldUseGlobalStyle =
      !this.props.location.pathname.includes('/admin') &&
      !this.props.location.pathname.includes('/terms') &&
      !this.props.location.pathname.includes('/privacy') &&
      !this.props.location.pathname.includes('/store') &&
      !this.props.location.pathname.includes('/404');

    const shouldShowGradientBackground =
      (newHeroEnabled && this.props.location.pathname === '/') ||
      this.props.location.pathname === '/about';

    const homePageV2ExperimentActive =
      this.props.location.pathname === '/' ||
      this.props.location.pathname === 'home';

    const shouldMoveContentBehindNav =
      isSlideHeroEnabled && !homePageV2ExperimentActive;

    return (
      <>
        <ScrollToTop />
        <AppGlobalStyle />
        {shouldUseGlobalStyle && <GlobalStyle />}
        <OneTrustGlobalStyle />
        <AppContainer blankBackground={!shouldShowGradientBackground}>
          <Helmet
            {...{
              ...(config.smartling?.useContext === true ||
              config.smartling?.useContext === 'true'
                ? config.smartling?.helmetContext
                : {}),
              ...config.app.head,
            }}
          />
          {this.renderDevTools(environment, loggedInUser)}
          {!shouldHidePromoBanner &&
            promotion &&
            this.renderPromotionalBanner(promotion)}
          {isPendingTalent && this.renderPendingTalentBanner()}

          {!shouldHideNavbar && (
            <AppNav
              shouldShowGradientBackground={shouldShowGradientBackground}
              setAppNavHeightCallback={this.setAppNavHeightCallback}
            />
          )}
          <AppContent
            shouldMoveContentBehindNav={shouldMoveContentBehindNav}
            appNavHeight={appNavHeight}
          >
            {this.props.children}
          </AppContent>
          <LocalizationToast />
          <ToastContainer />
        </AppContainer>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const loggedInUser = authSelectors.getLoggedInUser(state);
  const selectedUser = userSelectors.getSelectedUser(state);

  return {
    // Partially signed up users coming from instagram will be logged in, but have no email
    isIgSignup: loggedInUser ? !loggedInUser.email : false,
    loggedInUser,
    selectedUser,
    isBusinessPage: routerSelectors.isBusinessPage(state),
    isContentFeedPage: routerSelectors.isContentFeedPage(state),
    isNavbarHidden: getIsNavbarHidden(state),
    promotions: state.promotions.promotions,
    globalPromotion: promotionSelectors.getPromotionalBanner(state),
    newHeroPresent: isTheNewHeroPresent(state),
    isSlideHeroEnabled: isSlideHeroTopOfLayout(state),
  };
};

const mapDispatchToProps = {
  finishFirstRender,
  pushState: push,
  getScreenSize,
  setAuthView: authActions.setAuthView,
  clearAuthErrors: authActions.clearAuthErrors,
};

const createConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, createConnect, withCookies)(App);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
