import * as React from 'react';
import type { CustomIconProps } from './types';

function UsersIconV2({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M5.5999 6.39998C6.92539 6.39998 7.9999 5.32546 7.9999 3.99998C7.9999 2.67449 6.92539 1.59998 5.5999 1.59998C4.27442 1.59998 3.1999 2.67449 3.1999 3.99998C3.1999 5.32546 4.27442 6.39998 5.5999 6.39998Z"
        fill="#F2F1F3"
      />
      <path
        d="M11.5999 7.19998C12.7045 7.19998 13.5999 6.30454 13.5999 5.19998C13.5999 4.09541 12.7045 3.19998 11.5999 3.19998C10.4953 3.19998 9.5999 4.09541 9.5999 5.19998C9.5999 6.30454 10.4953 7.19998 11.5999 7.19998Z"
        fill="#F2F1F3"
      />
      <path
        d="M1.29213 13.1424C0.974282 12.9389 0.79018 12.5768 0.836742 12.2023C1.13123 9.83359 3.15145 7.99998 5.59982 7.99998C8.04819 7.99998 10.0684 9.83308 10.3629 12.2017C10.4095 12.5762 10.2254 12.9383 9.90753 13.1418C8.66408 13.938 7.18585 14.4 5.59982 14.4C4.0138 14.4 2.53557 13.9384 1.29213 13.1424Z"
        fill="#F2F1F3"
      />
      <path
        d="M11.6 12.8C11.5717 12.8 11.5435 12.7998 11.5153 12.7994C11.5704 12.5619 11.5857 12.3108 11.5537 12.0536C11.4134 10.925 10.96 9.8936 10.2831 9.04929C10.6916 8.88837 11.1365 8.79998 11.6021 8.79998C13.1539 8.79998 14.4764 9.78183 14.9822 11.1582C15.0909 11.454 14.9635 11.7792 14.6936 11.942C13.7902 12.4866 12.7317 12.8 11.6 12.8Z"
        fill="#F2F1F3"
      />
    </svg>
  );
}

export default UsersIconV2;
