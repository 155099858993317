import analytics from 'analytics';

import { dpSessionManager } from 'analytics/utils/dpSessionManager';
import { v4 as uuidv4 } from 'uuid';
import { dpOptimizelyIdManager } from 'analytics/utils/dpOptimizelyIdManager';

// array of optimizely experiment flags

// This queue is used for events from the user that we aren't able to fire right away (usually because we're waiting on additional data)
let pendingEventQueue = [];

const flushQueue = () => {
  pendingEventQueue.forEach((pendingEvent) => {
    track(pendingEvent);
  });
  pendingEventQueue = [];
};

const handleEvent = ({ data, eventName }) => {
  // Capture session data.
  const cameoUUID = uuidv4();
  const pagePerformanceStatistics = getPagePerformanceStatistics();

  const baseEventProperties = {
    ...data,
    cameoSessionId: dpSessionManager.getSessionId(),
    cameoCookieSessionId: dpOptimizelyIdManager.getOptimizelyId(),
    cameoUUID,
    pagePerformance: pagePerformanceStatistics,
  };

  const event = {
    eventName: 'LegacyAmplitudeEvent',
    event_type: eventName,
    event_properties: baseEventProperties,
  };
  analytics.logEvent(event);
};

const typeToHandler = {
  event: handleEvent,
};

const getPagePerformanceStatistics = () => {
  try {
    let performanceStats = {};
    // This check is primarily for test builds, these should be supported* in all major browsers
    // * Safari doesn't support the 'navigation' type we use for getEntriesByType, but they have the function itself
    if (
      !window ||
      !window.performance ||
      !window.performance.getEntriesByType
    ) {
      return {};
    }

    const navData = window.performance.getEntriesByType('navigation')[0];

    if (navData) {
      performanceStats = {
        domInteractive: navData.domInteractive,
        domComplete: navData.domComplete,
        loadEventEnd: navData.loadEventEnd,
        requestStart: navData.requestStart,
        responseStart: navData.responseStart,
        responseEnd: navData.responseEnd,
      };
    } else {
      // If navData isn't available, we have to fallback to the deprecated performance.timing API
      // As of writing, this is the case for America's favorite web browser, Safari.
      const {
        fetchStart, // being used loosely as the "zero" point everything else is compared to
        domInteractive,
        domComplete,
        loadEventEnd,
        requestStart,
        responseStart,
        responseEnd,
      } = window.performance.timing;

      performanceStats = {
        domInteractive: domInteractive - fetchStart,
        domComplete: domComplete - fetchStart,
        loadEventEnd: loadEventEnd - fetchStart,
        requestStart: requestStart - fetchStart,
        responseStart: responseStart - fetchStart,
        responseEnd: responseEnd - fetchStart,
      };
    }

    // Clearing out any 0 or negative values, which come about when a stat is accessed before it's ready
    Object.keys(performanceStats).forEach((stat) => {
      if (performanceStats[stat] <= 0) {
        performanceStats[stat] = 'N/A';
      }
    });

    return performanceStats;
  } catch {
    return {};
  }
};

function init(configObject, data) {
  flushQueue();
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function setUser({ user, email }) {}

function track({ type, data, eventName, category }) {
  if (type in typeToHandler) {
    // Call handler function
    typeToHandler[type]({ data, eventName, category });
  } else {
    // Unable to find analytics action of 'type' specified.
    console.log(
      'Warning: Unable to find amplitude client action of specified type.'
    );
  }
}

function getSessionId() {
  return null;
}

function trackRevenueOnCoreProduct(price) {
  return null;
}

export default {
  getSessionId,
  track,
  setUser,
  init,
  trackRevenueOnCoreProduct,
};
