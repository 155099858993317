import { reducerWithInitialState } from 'typescript-fsa-reducers';
import resetPassword from './actions';
import { ResetPasswordState, INITIAL_STATE } from './types';

const reducer = reducerWithInitialState<ResetPasswordState>(INITIAL_STATE)
  .case(resetPassword.async.started, (state) => ({
    ...state,
    resettingPassword: true,
  }))
  .case(resetPassword.async.done, (state, payload) => {
    const newState = {
      ...state,
      resettingPassword: false,
      resetPasswordError: null,
      resetPasswordInfo: 'Your password has been reset.',
    };

    if (payload.result.isLoggedIn && payload.result.user) {
      newState.loggedInUser = payload.result.user;
    }

    return {
      ...newState,
    };
  })
  .case(resetPassword.async.failed, (state, payload) => ({
    ...state,
    resettingPassword: false,
    resetPasswordError: payload.error.message ?? 'Error resetting password.',
  }));

export default reducer.build();
