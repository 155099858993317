import { client } from '../helpers/ApiClient';

let currencyRates = {};
let currencyRateLastUpdate = 0;
const currencyRateTTLms = 43200000; // 12 hours

const fetchRates = async () => {
  return client
    .get('/payments/currency-conversion/rates')
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      return {};
    });
};

export const getCurrencyRateFromMemory = async (
  currencyCode
): Promise<number> => {
  if (currencyCode?.toLowerCase() === 'usd') return 1;
  let rate = currencyRates[currencyCode];
  if (
    !rate ||
    currencyRateLastUpdate + currencyRateTTLms < new Date().getTime()
  ) {
    const { rates } = await fetchRates();
    currencyRateLastUpdate = new Date().getTime();
    currencyRates = rates || {};
    rate = currencyRates[currencyCode];
  }
  return rate || 0;
};
