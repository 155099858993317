export const types = {
  start: 'sb/users/LOAD_USER',
  success: 'sb/users/LOAD_USER_SUCCESS',
  fail: 'sb/users/LOAD_USER_FAIL',
};

export const start = (state, action) => {
  const userId = state.userIds[action.usernameParam];
  return {
    ...state,
    loadingUser: true,
    selectedUserId: userId,
    loadingUserError: null,
  };
};

export const success = (state, action) => {
  return {
    ...state,
    selectedUserId: action.result.user._id,
    loadingUser: false,
    usersCharity: action.result.usersCharity,
    users: {
      ...state.users,
      [action.result.user._id]: {
        ...action.result.user,
        introOrder: action.result.introOrder,
        scheduledReturnEvent: action.result.scheduledReturnEvent,
      },
    },
    userIds: {
      ...state.userIds,
      [action.result.user.username]: action.result.user._id,
    },
    newUserCategories: action.result.newUserCategories,
    isUserLive: action.result.isUserLive,
    upcomingBroadcastEvents: action.result.upcomingBroadcastEvents || [],
    userMerch: action.result.merch,
    responseTimeDescription: action.result.responseTimeDescription,
  };
};

export const fail = (state, action) => {
  return {
    ...state,
    loadingUser: false,
    loadingUserError: action.error.message || action.error,
  };
};

export const service = (username, ref) => {
  let url = '/user/show';
  if (username) {
    url += `/${username}`;
  }
  if (ref) {
    url += `?ref=${ref}`;
  }
  return {
    types: [types.start, types.success, types.fail],
    usernameParam: username,
    promise: (client) => client.get(url),
  };
};
