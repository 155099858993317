import React, { useState, forwardRef, useEffect, CSSProperties } from 'react';
import { X, Eye, EyeOff } from 'react-feather';
import { normalizePhone } from 'utils/phoneNumber';
import { theme } from '@cameo/theme';
import { Box, Text } from 'domains/web/components';
import analytics from 'analytics';

import {
  Wrapper,
  InputWrapper,
  InputStyled,
  SideButton,
  ErrorMessage,
  Emoji,
  OutsideInputCounter,
} from './Styled';

import { getMaxLengthAndInputCount } from './getTextInputCount';

export type InputTypes =
  | 'email'
  | 'number'
  | 'tel'
  | 'password'
  | 'lowercase'
  | 'name'
  | 'text';
export type InputModes =
  | 'text'
  | 'decimal'
  | 'numeric'
  | 'tel'
  | 'search'
  | 'email'
  | 'url';

export type CounterOrientation = 'inside' | 'outside';

export interface Props {
  type?: InputTypes;
  isDisabled?: boolean;
  lightBackground?: boolean;
  shouldShowClearButton?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  title?: string;
  subTitle?: string;
  isFocused?: boolean;
  titleStyles?: object;
  inputStyles?: object;
  wrapperStyles?: CSSProperties;
  placeholderText?: string;
  value?: string;
  name: string;
  onBlur?: (value: string | React.FocusEvent<HTMLElement>) => void;
  onChange: (value: string) => void;
  testId?: string;
  onFocus?: () => void;
  isHidden?: boolean;
  inputMode?: InputModes;
  emojiSymbol?: string;
  emojiLabel?: string;
  clearExtraAction?: () => void;
  maxLength?: number;
  enableCounter?: boolean;
  maxLengthAndInputCount?: () => {
    isOverMaxLength: boolean;
    getTextInputCount: string;
    inputLength: number;
    maxLength: number;
  };
  counterOrientation?: CounterOrientation;
  errorTextAlign?: 'center' | 'right' | 'left';
  alertError?: boolean;
  icon?: React.ReactNode;
}

const TEL_INPUT_PATTERN = '^(?=.*[0-9])[- +()0-9]+$';

const InsideInputCounter = ({ isOverMaxLength, getTextInputCount }) => (
  <Box position="absolute" bottom="xs" right="sm">
    <Text
      variant="base_s"
      color={isOverMaxLength ? 'danger.red' : 'foreground.muted'}
    >
      {getTextInputCount}
    </Text>
  </Box>
);

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      type = 'text',
      title,
      subTitle,
      isDisabled,
      hasError,
      errorMessage,
      shouldShowClearButton = true,
      titleStyles,
      inputStyles,
      placeholderText = '',
      value: initialValue,
      name,
      onBlur,
      onChange,
      testId = '',
      wrapperStyles,
      lightBackground,
      onFocus,
      isHidden = false,
      inputMode = 'text',
      emojiSymbol,
      emojiLabel = 'emoji',
      clearExtraAction,
      maxLength,
      enableCounter = false,
      maxLengthAndInputCount,
      counterOrientation = 'inside',
      errorTextAlign,
      alertError,
      icon,
    }: Props,
    ref
  ) => {
    const [inputValue, onChangeText] = useState(
      initialValue ? `${initialValue}` : ''
    );
    const [showPassword, setShowPassword] = useState(false);

    const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      if (onChange) {
        onChange(newValue);
      }
      if (onChangeText) {
        onChangeText(newValue);
      }
    };

    const clearValue = () => {
      onChange('');
      onChangeText('');
      if (clearExtraAction) clearExtraAction();
    };

    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const shouldDisplayErrorMessage =
      hasError && !isDisabled && Boolean(errorMessage);

    let isOverMaxLength;
    let getTextInputCount;

    if (maxLengthAndInputCount) {
      const {
        isOverMaxLength: _isOverMaxLength,
        getTextInputCount: _getTextInputCount,
        maxLength: _maxLength,
      } = maxLengthAndInputCount();
      isOverMaxLength = _isOverMaxLength;
      getTextInputCount = _getTextInputCount;
      maxLength = _maxLength;
    } else {
      const {
        isOverMaxLength: _isOverMaxLength,
        getTextInputCount: _getTextInputCount,
      } = getMaxLengthAndInputCount({ inputValue, maxLength });
      isOverMaxLength = _isOverMaxLength;
      getTextInputCount = _getTextInputCount;
    }

    useEffect(() => {
      // if initial value changes, show that value
      onChangeText(initialValue);
    }, [initialValue]);

    useEffect(() => {
      if (inputValue !== null && inputValue?.length > maxLength) {
        analytics.trackSystem({
          objectDtl: {
            objectType: 'Textbox',
            objectName: 'MaxCharactersOnInputReached',
          },
          sourceDtl: {
            sourcePath: name,
            sourceType: 'Textbox',
            sourceValue: String(inputValue.length),
          },
          eventDtl: {
            eventType: 'View error',
            contents: {
              userInputText: inputValue,
            },
          },
        });
      }
    }, [inputValue, maxLength, name]);

    return (
      <Wrapper style={wrapperStyles || {}} hasEmoji={Boolean(emojiSymbol)}>
        {title?.length > 0 && (
          <Box marginBottom="xs">
            <Text
              variant="base_m"
              style={{
                ...titleStyles,
                ...(alertError &&
                  hasError && {
                    color: theme.colors.danger.emphasis,
                  }),
              }}
              id={`${name}-label`}
            >
              {title}
            </Text>
            {Boolean(subTitle) && (
              <Text variant="base_s" color="foreground.muted">
                {subTitle}
              </Text>
            )}
          </Box>
        )}
        <InputWrapper isHidden={isHidden}>
          {Boolean(emojiSymbol) && (
            <Emoji role="img" aria-label={emojiLabel} data-testid="Emoji">
              {emojiSymbol}
            </Emoji>
          )}
          <InputStyled
            id={name}
            ref={ref}
            name={name}
            aria-labelledby={`${name}-label`}
            type={type === 'password' && showPassword ? 'text' : type}
            data-testid={`Input-${testId}`}
            hasError={hasError}
            disabled={isDisabled}
            style={{ ...inputStyles }}
            placeholder={placeholderText}
            onBlur={onBlur}
            onChange={onValueChange}
            value={type === 'tel' ? normalizePhone(inputValue) : inputValue}
            lightBackground={lightBackground}
            onFocus={onFocus}
            inputMode={inputMode}
            {...(type === 'tel' && { pattern: TEL_INPUT_PATTERN })}
          />
          {Boolean(icon) && (
            <SideButton data-testid="Side-Icon">{icon}</SideButton>
          )}
          {!icon &&
            shouldShowClearButton &&
            type !== 'password' &&
            !enableCounter && (
              <SideButton onClick={clearValue} data-testid="Clear-Button">
                <X size={18} />
              </SideButton>
            )}
          {type === 'password' && (
            <SideButton
              data-testid="Password-Button"
              onClick={toggleShowPassword}
            >
              {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
            </SideButton>
          )}
          {enableCounter && maxLength && counterOrientation === 'inside' && (
            <InsideInputCounter
              isOverMaxLength={isOverMaxLength}
              getTextInputCount={getTextInputCount}
            />
          )}
        </InputWrapper>
        {enableCounter && maxLength && counterOrientation === 'outside' && (
          <OutsideInputCounter hasError={isOverMaxLength}>
            {getTextInputCount}
          </OutsideInputCounter>
        )}
        {shouldDisplayErrorMessage && (
          <ErrorMessage textAlign={errorTextAlign}>{errorMessage}</ErrorMessage>
        )}
      </Wrapper>
    );
  }
);
