import styled from 'styled-components';
import { animations, color, media, sizing } from 'design-system/style';
import { Wrapper as SearchBarWrapper } from 'design-system/Components/SearchBar/Styled';
import { rgba } from 'polished';
import { theme } from '@cameo/theme';

export const Wrapper = styled.nav<{
  isLoggedIn: boolean;
  isMobile: boolean;
  $scrollPercentage: number;
  $isTransparent: boolean;
}>`
  color: ${color.solids.WHITE};
  padding-top: ${sizing(0)};
  padding-bottom: ${sizing(0)};
  padding-left: ${sizing(20)};
  padding-right: ${sizing(20)};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 62rem) {
    flex-direction: row;
    height: auto;
  }

  align-items: center;
  position: relative;
  z-index: 101;
  transition: background-color ${animations.transition.NORMAL}ms linear;

  &,
  & ${SearchBarWrapper} {
    background-color: ${({ $scrollPercentage }) => {
      if ($scrollPercentage >= 60) {
        return rgba(theme.colors.background.level0, $scrollPercentage / 100);
      }

      return color.background.LEVEL2TRANSPARENT40;
    }};
  }
  @media screen and (min-width: ${media.queries.DESKTOP}) {
    top: 0;
    border-radius: 0;
  }
`;

export const NotificationIndicator = styled.div`
  background-color: ${color.solids.PRIMARY};
  border-radius: ${sizing(10)};
  height: ${sizing(10)};
  width: ${sizing(10)};
  position: absolute;
  top: 0;
  right: 0;
`;

export const DropDownNotificationIndicatorWrapper = styled.span`
  position: absolute;
  top: ${sizing(2)};
  right: ${sizing(-25)};
`;
