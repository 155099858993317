import * as React from 'react';
import type { CustomIconProps } from './types';

function ChristmasIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M14.6 15.2H1.40002C1.30002 15.2 1.20002 15.1 1.10002 15C1.00002 14.9 1.10002 14.8 1.20002 14.7L4.10001 12.1H2.60001C2.50001 12.1 2.40001 12 2.30001 11.9C2.30001 11.8 2.30002 11.7 2.40002 11.6L4.70001 8.99999H3.80001C3.70001 9.09999 3.60001 8.99999 3.60001 8.89999C3.60001 8.79999 3.60001 8.69999 3.60001 8.59999L5.90002 5.59999H5.00002C4.90002 5.59999 4.80002 5.49999 4.80002 5.39999C4.80002 5.29999 4.80002 5.19999 4.80002 5.09999L7.90002 0.799988C7.90002 0.799988 8.00002 0.799988 8.10002 0.799988C8.20002 0.799988 8.30002 0.799988 8.30002 0.899988L11.4 5.19999C11.5 5.29999 11.5 5.39999 11.4 5.49999C11.4 5.59999 11.3 5.69999 11.2 5.69999H10.3L12.6 8.69999C12.7 8.79999 12.7 8.89999 12.6 8.99999C12.6 9.09999 12.5 9.19999 12.3 9.09999H11.4L13.7 11.7C13.8 11.8 13.8 11.9 13.8 12C13.8 12.1 13.7 12.2 13.5 12.2H12L14.8 14.8C14.9 14.9 14.9 15 14.9 15.1C14.8 15.1 14.7 15.2 14.6 15.2Z"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default ChristmasIcon;
