import { ActiveOptimizelyFeatures } from './optimizely';
import {
  ActiveProducts,
  C4BProducts,
  LineItemType,
  OccasionBasedProducts,
  PriceApplicationMethod,
} from './pricing';
import {
  Auth0Provider,
  CameoProvider,
  Credentials as AuthCredentials,
  LoginMethod,
} from './auth';
import {
  ReportActions,
  ReportActionTypes,
  ReportItemReasons,
  ReportItemTypes,
  ReportStatusType,
  ReportSubtargets,
  ReportSubtargetTypes,
  ReportTargets,
} from './report';
import {
  SchedulerDayOfWeek,
  SchedulerEventPersonalTypes,
  UnavailableReasons as SchedulerEventPersonalUnavailableReasons,
} from './scheduler';
import { AvalaraCode } from './avalara';
import { GiftWrapperType } from './gift';
import { FanClubUserJoinSources } from './fan-clubs';
import { PerformanceTierType } from './performance-tier-type';
import { CameoRequestThrottlingReasonType } from './cameo-request-throttling-reason-type';
import { Status as BusinessVideoStatus } from './business-video';
import { SmsNotificationType } from './sms-notification-type';
import { MailingListSource } from './mailing-list';
import { NudgeStatus, UpdateTalentIdsMethod } from './nudge';
import { LikeTargets } from './likes';
import { TalentLeaderboardTypes } from './talent-leaderboard';
import { PriceModifierType } from './price-modifier';
import { ActiveExperiments } from './experiments';
import { ShareableSlug } from './shareable';

export { PriceModifierType } from './price-modifier';
export { StatusCode } from './status-code';
export { GamePadItemType } from './gamepad';
export { FormFieldNames, FormInputType } from '../../api/models/order/enums';
import { FormInputType } from '../../api/models/order/enums';
import { ProductType } from '../../api/models/cart/enums';
import { ProductPriceModifiers } from './pricing';
import { PaymentProcessor, PaymentTarget } from '../../api/models/cart/enums';
export * from '../../api/models/cart/enums';

export * from './cart';
export * from './order';
export * from './user';
export * from './promo-item';

export type Credentials = AuthCredentials;

export {
  ActiveExperiments,
  ActiveOptimizelyFeatures,
  ActiveProducts,
  Auth0Provider,
  AvalaraCode,
  BusinessVideoStatus,
  C4BProducts,
  CameoProvider,
  CameoRequestThrottlingReasonType,
  FanClubUserJoinSources,
  GiftWrapperType,
  LikeTargets,
  LineItemType,
  LoginMethod,
  MailingListSource,
  NudgeStatus,
  OccasionBasedProducts,
  PerformanceTierType,
  PriceApplicationMethod,
  ProductPriceModifiers,
  ProductType,
  ReportActions,
  ReportActionTypes,
  ReportItemReasons,
  ReportItemTypes,
  ReportStatusType,
  ReportSubtargets,
  ReportSubtargetTypes,
  ReportTargets,
  SchedulerDayOfWeek,
  SchedulerEventPersonalTypes,
  SchedulerEventPersonalUnavailableReasons,
  ShareableSlug,
  SmsNotificationType,
  TalentLeaderboardTypes,
  UpdateTalentIdsMethod,
};

export enum ALGORITHM_TYPES {
  top = 'top',
  trending = 'trending',
  new = 'new',
  popular = 'popular',
  categoryList = 'categoryList',
  relatedCategories = 'relatedCategories',
  active = 'active',
  model = 'model',
  onlineNow = 'onlineNow',
}

export enum BroadcastEventAgoraUserIds {
  CLOUD_RECORDING = 1,
  SCREENSHARE = 2,
}

export enum BroadcastEventChannelTypes {
  STAGE = 'STAGE',
  VIP = 'VIP',
}

export enum BroadcastEventLayoutTypes {
  EQUAL_SIZE = 'EQUAL_SIZE',
  PINNED_STREAMS = 'PINNED_STREAMS',
}

export enum BroadcastEventTicketCompTypes {
  EXCLUDE_PAYMENT = 'EXCLUDE_PAYMENT',
  INCLUDE_PAYMENT = 'INCLUDE_PAYMENT',
}

export enum BroadcastEventTicketStatusTypes {
  PURCHASED = 'PURCHASED',
  REDEEMED = 'REDEEMED',
  REFUNDED = 'REFUNDED',
}

export enum BroadcastEventTicketTypes {
  GENERAL = 'GENERAL',
  VIP = 'VIP',
}

export enum BroadcastEventUserEventTypes {
  USER_BROUGHT_ON_STAGE = 'USER_BROUGHT_ON_STAGE',
  USER_REMOVED_FROM_STAGE = 'USER_REMOVED_FROM_STAGE',
  USER_JOINED_EVENT = 'USER_JOINED_EVENT',
  USER_LEFT_EVENT = 'USER_LEFT_EVENT',
}

export enum BroadcastEventUserRoles {
  ADMIN = 'ADMIN',
  GENERAL = 'GENERAL',
  MODERATOR = 'MODERATOR',
  OWNER = 'OWNER',
  VIP = 'VIP',
}

export enum BroadcastEventUserStageStatusTypes {
  HOST = 'HOST',
  ON_STAGE = 'ON_STAGE',
  ON_STAGE_REQUESTED = 'ON_STAGE_REQUESTED',
  OFF_STAGE = 'OFF_STAGE',
}

export enum BroadcastEventStatusTypes {
  CANCELED = 'CANCELED',
  ENDED = 'ENDED',
  IN_PROGRESS = 'IN_PROGRESS',
  UPCOMING = 'UPCOMING',
}

export enum InteractionRequestTypes {
  CAMEO_LIVE = 'CAMEO_LIVE',
}

export enum InteractionRequestFulfillmentTypes {
  CAMEO_LIVE = 'CAMEO_LIVE',
  ZOOM = 'ZOOM',
}

export interface InteractionRequestFulfillmentDetails {
  eventType: InteractionRequestFulfillmentTypes;
  eventLink: string;
}

export enum InteractionRequestStatusTypes {
  ACCEPTED = 'ACCEPTED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  NEGOTIATING_TIME = 'NEGOTIATING_TIME',
  REQUESTED = 'REQUESTED',
  SCHEDULED = 'SCHEDULED',
}

export enum InteractionRequestEventTypes {
  ACCEPTED = 'ACCEPTED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  DECLINED = 'DECLINED',
  EDITED = 'EDITED',
  NEW_TIME_PROPOSED = 'NEW_TIME_PROPOSED',
  PAYMENT_TRANSFERRED = 'PAYMENT_TRANSFERRED',
  RESCHEDULE_REQUESTED = 'RESCHEDULE_REQUESTED',
  REFUNDED = 'REFUNDED',
  SCHEDULED = 'SCHEDULED',
  SEEN_BY_TALENT = 'SEEN_BY_TALENT',
  FLAGGED = 'FLAGGED',
}

export enum InteractionRequestParticipantSize {
  LARGE = 'Large group (5-10)',
  SMALL = 'Small group (1-5)',
  UNSURE = 'Unsure',
}

export enum CampaignTacticEnum {
  DONATION = 'donation',
  SCARCITY = 'scarcity',
  DISCOUNT = 'discount',
  SPEED = 'speed',
  BUNDLE = 'bundle',
  ASSET = 'asset',
  FREE_SPEED = 'free_speed',
}

export enum ChannelTypeEnum {
  LIMITED = 'limited',
  GROUP_CHAT_SUBSCRIPTION = 'group-chat-subscription',
  CRM = 'crm',
}

export enum ChannelRoleTypes {
  ADMIN = 'admin',
  MANAGER = 'manager',
  MEMBER = 'member',
}

export enum ChannelListingFilters {
  /*
   * This filter is confusing in relation to the universal DM feature.
   * If universal DMs are on, the ALL filter will not include favorited
   * channels.
   *
   * If Universal DMs is adopted as the primary DM model, the ALL
   * filter should be renamed.
   */

  // In Use
  ALL = 'all',
  PINNED = 'pinned',

  // Currently not in use. Left for future iterations.
  REQUESTS = 'requests',
  FAVORITES = 'favorites',
}

export enum ChannelTypeFilter {
  ALL = 'all',
  NON_CRM = 'nonCrm',
  UNREAD_NON_CRM = 'unreadNonCrm',
  CRM = 'crm',
}

/**
 * Enum to map a code identifier to a tag slug.
 */
export enum ChatCategories {
  FEATURED_FOR_PAID_DM = 'featured-in-paid-dm',
}

export enum CMS_AUDIENCES {
  allVisitors = 'allVisitors',
  nativeAppVisitor = 'nativeAppVisitor',
  webAppVisitor = 'webAppVisitor',
  activeUsers = 'activeUsers',
  androidUser = 'androidUser',
  iosUser = 'iosUser',
  businessEligible = 'businessEligible',
  isAdmin = 'admin',
}

export enum HiveModerationClassNames {
  SUGGESTIVE = 'suggestive',
  SEXUAL = 'sexual',
  SELF_HARM = 'self_harm',
  VIOLENCE = 'violence',
  BULLYING = 'yes_bullying',
  HATE = 'yes_hate',
  SPAM = 'yes_spam',
  INCITEMENT = 'yes_incitement',
}

export enum MessageReactionTypes {
  HEART = 'heart',
}

export enum MessageTypes {
  CAMEO_CALL = 'cameo-call',
  CAMEO_LIVE = 'cameo-live',
  CRM = 'crm',
  FAN_CLUB_BLOCKED_MESSAGE = 'fan-club-blocked-message',
  IMAGE = 'image',
  IMAGE_UPLOADING = 'image-uploading',
  PENDING_REQUEST = 'pending-request',
  POLL = 'poll',
  REQUEST = 'request',
  SCHEDULED_CAMEO_CALL = 'scheduled-cameo-call',
  STATUS = 'status',
  STICKER = 'sticker',
  STICKER_UPLOADING = 'sticker-uploading',
  TEXT = 'text',
  URL = 'url',
  TYPING_INDICATOR = 'typing-indicator',
  VIDEO = 'video',
  VIDEO_CALL = 'video-call',
  VIDEO_ORDER = 'video-order',
  VIDEO_ORDER_UPLOADING = 'video-order-uploading',
  VOICE_NOTE = 'voice-note',
  VOICE_NOTE_UPLOADING = 'voice-note-uploading',
  THANK_YOU_NOTE = 'thank-you-note',
}

export enum MessageTypesDB {
  CAMEO_CALL = 'cameo-call',
  CAMEO_LIVE = 'cameo-live',
  CRM = 'crm',
  FAN_CLUB_BLOCKED_MESSAGE = 'fan-club-blocked-message',
  IMAGE = 'image',
  POLL = 'poll',
  REQUEST = 'request',
  SCHEDULED_CAMEO_CALL = 'scheduled-cameo-call',
  STATUS = 'status',
  STICKER = 'sticker',
  TEXT = 'text',
  URL = 'url',
  VIDEO = 'video',
  VIDEO_CALL = 'video-call',
  VIDEO_ORDER = 'video-order',
  VOICE_NOTE = 'voice-note',
  THANK_YOU_NOTE = 'thank-you-note',
}

export enum VideoCallStates {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in-progress',
  MISSED = 'missed',
  UPCOMING = 'upcoming',
  UPCOMING_CANCELLED = 'upcoming-cancelled',
}

export enum PaymentMethodTypes {
  STRIPE = 'stripe', // seems like android is sending this only
  STRIPE_PAYMENT_INTENT = 'stripePaymentIntent',
  VIRTUAL = 'virtual', // ios only virtual credit
  APPLE_PAY = 'applePay', // used on safari web, still uses stripe though
  GOOGLE_PAY = 'googlePay',
  DIGITAL_WALLET = 'digitalWallet',
  CARD = 'card', // currently only used on web to signify a stripe charge
  PAYPAL = 'paypal',
  MULTIPLE = 'multiple', // accounts for multiple charges on the same order. So I can use card and virtual.
  ACT_BLUE = 'actBlue', // A political contribution
  WIN_RED = 'winRed', // A political contribution via WinRed
  AFFIRM = 'affirm',
  APPLE_IAP = 'appleIAP', // cart iap
  ANDROID_IAP = 'androidIAP', // cart iap
}

export enum PaymentMethodOwnerType {
  BUSINESS = 'business',
  USER = 'user',
}

/**
 * Active means that the payment method is still usable and stored in
 * the payment provider's platform.
 *
 * Inactive means that the payment method has been deleted from
 * the payment provider, and we have soft deleted this by marking
 * the method as inactive.
 */
export enum PaymentMethodStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum PayoutProvider {
  stripe = 'stripe',
  stripeMex = 'stripeMexico',
  stripeBra = 'stripeBrazil',
  paypal = 'paypal',
  pledge = 'pledge',
}

export enum PreventativeThrottling {
  DEFAULT_NUMBER_OF_ACTIONS_REQUIRED_FOR_PENALTY_BOX_GRADUATION = 3,
  MAXIMUM_NUMBER_OF_ACTIONS_ALLOWED = 5,
}

export enum DonationStatus {
  PENDING = 'pending',
  ERROR = 'error',
  COMPLETE = 'complete',
  UNKNOWN = 'unknown',
}

export enum TransferType {
  primary = 'primary',
  referrer = 'referrer',
  tip = 'tip',
}

export enum TransferAction {
  none = 'none',
  process = 'process',
  reverse = 'reverse',
  recreate = 'recreate',
}

/**
 * A purchase's associated product category/line
 */
export enum PurchaseType {
  message = 'message',
  order = 'order',
  businessOrder = 'businessOrder',
  call = 'call',
  cameoLiveEvent = 'cameoLiveEvent',
  interactionRequest = 'interactionRequest',
  sticker = 'sticker',
  vipFanClub = 'vipfanclub',
  tip = 'tip',
  offPlatform = 'offPlatform',
  licenseExtension = 'licenseExtension',
  thankYouNote = 'thankYouNote',
}

/**
 * Defines the type of Cameo entity receiving a transfer
 */
export enum TransferRecipientType {
  organization = 'organization',
  user = 'user',
}

export enum TransferStatus {
  /**
   * Transfer created, but no valid destination was set.
   */
  needsReconcile = 'needsReconcile',
  /**
   * Transfer is ready for processing
   */
  pending = 'pending',
  /**
   * Transfer request is being sent to the payment processor
   */
  processing = 'processing',
  /**
   * Transfer successfully sent to payment processor
   */
  sent = 'sent',
  /**
   * Payment processor has converted the transfer into a payout
   */
  paid = 'paid',
  /**
   * Transfer has been reversed at the payment processor
   */
  reversed = 'reversed',
  /**
   * Either processing or reversal has failed for the transfer.
   */
  error = 'error',
  /**
   * Tags a Transfer as having payouts handled manually
   */
  manualPayout = 'manualPayout',
}

export enum TransferRequestStatus {
  /**
   * Transfer request is under review
   */
  underReview = 'underReview',
  /**
   * Transfer request has been approved
   */
  approved = 'approved',
  /**
   * Transfer request has been rejected
   */
  rejected = 'rejected',
  /**
   * Transfer request is processing
   */
  processing = 'processing',

  /**
   * Transfer request has been completed and a transfer has been created
   */
  completed = 'completed',
  /**
   * Transfer request processing has failed and no transfer was created
   */
  error = 'error',
}

export enum TransferRequestType {
  message = 'message',
  order = 'order',
  businessOrder = 'businessOrder',
  call = 'call',
  cameoLiveEvent = 'cameoLiveEvent',
  sticker = 'sticker',
  vipFanClub = 'vipfanclub',
  tip = 'tip',
  offPlatform = 'offPlatform',
  marketing = 'marketing',
  brandPartnership = 'brandPartnership',
  opex = 'opex',
  minimumGuarantee = 'minimumGuarantee',
  referral = 'referral',
  talentDelight = 'talentDelight',
}

/**
 *
 */
export enum TransferAgreementType {
  campaign = 'campaign',
  minimumGuarantee = 'minimumGuarantee',
  mainCharity = 'mainCharity',
  other = 'other',
}

export enum RevenueCatSubscriptionWebhookEvents {}

export enum StripeSubscriptionWebhookEvents {
  CustomerSubscriptionCreated = 'customer.subscription.created',
  CustomerSubscriptionUpdated = 'customer.subscription.updated',
  CustomerSubscriptionDeleted = 'customer.subscription.deleted',
  CustomerSubscriptionPendingUpdateApplied = 'customer.subscription.pending_update_applied',
  CustomerSubscriptionPendingUpdateExpired = 'customer.subscription.pending_update_expired',
  CustomerSubscriptionTrialWillEnd = 'customer.subscription.trial_will_end',
}

export enum PoliticalCharityType {
  ACT_BLUE = 'actBlue',
  WIN_RED = 'winRed',
}

export enum RowTypes {
  aboutMe = 'aboutMe', // -- DEPRECATED ON WEB
  accordion = 'accordion',
  banner = 'banner',
  businessCameo = 'businessCameo', // -- DEPRECATED ON WEB
  carousel = 'carousel',
  checkerBoard = 'checkerBoard',
  collection = 'collection',
  disclaimer = 'disclaimer', // -- DEPRECATED ON WEB
  educationModule = 'educationModule', // -- DEPRECATED ON WEB
  educationSteps = 'educationSteps', // -- DEPRECATED ON WEB
  numberedSteps = 'numberedSteps',
  personalCameo = 'personalCameo',
  privateLive = 'privateLive', // -- DEPRECATED ON WEB
  quizModule = 'quizModule', // -- DEPRECATED ON WEB
  rankerBoard = 'rankerBoard',
  sixPack = 'sixPack', // -- DEPRECATED ON WEB
  slideHero = 'slideHero', // -- DEPRECATED ON WEB
  spotlight = 'spotlight',
  talentStatus = 'talentStatus',
  useCaseSection = 'useCaseSection',
  videoTower = 'videoTower',
}

export enum StreamRecordType {
  CategoryPreferenceSurvey = 'category_preference_survey',
}

export enum Occasion {
  advice = 'Advice',
  anniversary = 'Anniversary',
  announcement = 'Announcement',
  bedtime = 'Bedtime',
  bathtime = 'Bathtime',
  birthday = 'Birthday', // used right now for gift
  birthdayCelebration = 'Birthday',
  christmas = 'Christmas',
  congrats = 'Congrats',
  diwali = 'Diwali',

  brave = 'Be brave',
  easter = 'Easter',
  encouragement = 'Encouragement',
  fathersDay = "Father's Day",
  getReady = 'Get ready',
  getWell = 'Get well',
  gift = 'Gift',
  giveThanks = 'Give Thanks',
  graduation = 'Graduation',
  halloween = 'Halloween',
  hanukkah = 'Hanukkah',
  holiday = 'Holiday',
  homework = 'Homework',
  justForFun = 'Just for Fun',
  kwanzaa = 'Kwanzaa',
  mothersDay = "Mother's Day",
  newYear = 'New Year',
  other = 'Other',
  pepTalk = 'Pep Talk',
  pottyTraining = 'Potty training',
  pride = 'Pride',
  question = 'Question',
  roast = 'Roast',
  school = 'School',
  seasonsGreetings = 'Seasons greetings',
  thanksgiving = 'Thanksgiving',
  toothbrushTime = 'Toothbrush time',
  toothRelated = 'Tooth related',
  valentinesDay = "Valentine's Day",
  wedding = 'Wedding',
  weLoveYou = 'We love you',
  fantasyDraft = 'Fantasy football',
  friendship = 'Friendship',
  proudOfYou = 'Proud of you',

  // Legacy business booking types as of January 2022
  /** @deprecated */
  biz_events = 'Events',
  /** @deprecated */
  biz_marketing = 'Marketing',
  /** @deprecated */
  biz_internal = 'Internal Comms & HR',
  /** @deprecated */
  biz_sales = 'Sales',
  /** @deprecated */
  biz_other = 'Something Else',

  // Legacy business booking types as of March 2022
  /** @deprecated */
  c4b_internal = 'Something else',
  /** @deprecated */
  c4b_internal_events = 'Company internal events',
  /** @deprecated */
  c4b_external = 'Something else',

  // Business Booking Types
  c4b_internal_comms = 'Internal communications',
  c4b_external_events = 'Public events',
  c4b_external_marketing = 'Marketing',

  // Legacy occasion name
  /** @deprecated */
  mothersday = "Mother's Day",
}

export enum PriceRecommendationTypes {
  CORE_CAMEO = 'core-cameo',
}

export enum Pronoun {
  heHim = 'He/Him',
  sheHer = 'She/Her',
  theyThem = 'They/Them',
}

export enum PurchaserPronoun {
  he_Him = 'He/Him',
  she_Her = 'She/Her',
  they_Them = 'They/Them',
}

export enum Emojis {
  EVIL = '😈',
  FIRE = '🔥',
  HANDS = '🙌',
  HEART_EYES = '😍',
  MEGA = '📣',
  SCREAM = '😱',
  TADA = '🎉',
}

export enum ShareTypes {
  CopyLink = 'copyLink',
  Download = 'download',
  Facebook = 'facebook',
  Instagram = 'instagram',
  Messenger = 'fbMessenger',
  Native = 'native',
  Reddit = 'reddit',
  SMS = 'sms',
  Telegram = 'telegram',
  Twitter = 'twitter',
  Whatsapp = 'whatsapp',
  FanClub = 'fanClub',
}

export enum LiveEventStatusTypes {
  CANCELLED = 'cancelled',
  IN_PROGRESS = 'in-progress',
  ENDED = 'ended',
  PENDING = 'pending',
  UPCOMING = 'upcoming',
  NEVER_STARTED = 'never-started',
}

export enum LiveEventTicketStatusTypes {
  // ticket is purchased and ready to be redeemed
  PURCHASED = 'purchased',
  // ticket has been used to enter the line in a call
  REDEEMED = 'redeemed',
  // ticket has been refunded for any reason and now cannot be used
  REFUNDED = 'refunded',
  // ticket was not used during the call - funds were transferred to talent
  // ticket cannot be used
  UNUSED_AND_TRANSFERRED = 'unused-and-transferred',
}

export enum CameoCallsCampaign {
  DEFAULT = 'default',
  SANTA = 'santa',
  SPOTIFY_FANS_FIRST = 'spotify_fans_first',
  VALENTINES_DAY = 'valentines_day',
  CAMEO_CARES_2021 = 'cameo_cares_2021',
  CAMEO_CALLS_SANTA_2021 = 'cameo_calls_santa_2021',
}

export enum CustomerIntroduction {
  none = 'None',
  youDaRealMvp = 'You da real MVP',
  iLoveYourMovies = 'I LOVE your movies',
  iListenToYouAllTheTime = 'I listen to you all the time',
  goat = 'GOAT',
  theExcitementIsReal = 'The excitement is real',
  imYourBiggestFan = `I'm your biggest fan`,
  theyreGonnaLoveThis = `They're gonna LOVE this`,
  theyreGonnaLoseTheirShit = `They're gonna lose their sh*t`,
}

export enum ActivityEventKind {
  AFFILIATE = 'AFFILIATE',
  ASSET = 'ASSET',
  CORE_CAMEO_PRICE_RECOMMENDATION = 'CORE_CAMEO_PRICE_RECOMMENDATION',
  DAILY_LEADERBOARD = 'DAILY_LEADERBOARD',
  MONTHLY_LEADERBOARD_TOP_1 = 'MONTHLY_LEADERBOARD_TOP_1',
  MONTHLY_LEADERBOARD_TOP_2 = 'MONTHLY_LEADERBOARD_TOP_2',
  MONTHLY_LEADERBOARD_TOP_3 = 'MONTHLY_LEADERBOARD_TOP_3',
  MONTHLY_LEADERBOARD_DEFAULT = 'MONTHLY_LEADERBOARD_DEFAULT',
  DEFAULT_ACTIVITY_EVENT_TYPE = 'DEFAULT_ACTIVITY_EVENT_TYPE',
  IAP_RECEIPT_VALIDATION_FAILED = 'IAP_RECEIPT_VALIDATION_FAILED',
  INDIVIDUAL_ASSET = 'INDIVIDUAL_ASSET',
  INTERACTION_REQUEST_CREATED = 'INTERACTION_REQUEST_CREATED',
  INTRO_ORDER_SHARE = 'INTRO_ORDER_SHARE',
  MESSAGE_CREATED = 'MESSAGE_CREATED',
  NOTIFIED_FOLLOWERS = 'NOTIFIED_FOLLOWERS',
  OPT_IN = 'OPT_IN',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_COMMENTED = 'ORDER_COMMENTED',
  ORDER_COMPLETED = 'ORDER_COMPLETED',
  ORDER_DECLINED = 'ORDER_DECLINED',
  ORDER_DELIVERY = 'ORDER_DELIVERY',
  ORDER_EXPIRED = 'ORDER_EXPIRED',
  ORDER_LIKED = 'ORDER_LIKED',
  ORDER_NEW = 'ORDER_NEW',
  ORDER_REDO = 'ORDER_REDO',
  PAYOUT_CREATED = 'PAYOUT_CREATED',
  PAYOUT_PAID = 'PAYOUT_PAID',
  REDONE_ORDER_DELIVERY = 'REDONE_ORDER_DELIVERY',
  REVIEW_NEW = 'REVIEW_NEW',
  REVIEW_REDO = 'REVIEW_REDO',
  TALENT_ONBOARDED = 'TALENT_ONBOARDED',
  TALENT_PENDING = 'TALENT_PENDING',
  UPDATE_STRIPE_ACC = 'UPDATE_STRIPE_ACC',
  USER_CHANGED = 'USER_CHANGED',
  USER_CREATED = 'USER_CREATED',
  USER_SET_PROFILE_PHOTO = 'USER_SET_PROFILE_PHOTO',
  VCC_MESSAGE_CREATION_FAILED = 'VCC_MESSAGE_CREATION_FAILED',
  VCC_ORDER_CREATION_FAILED = 'VCC_ORDER_CREATION_FAILED',

  /**
   * C4B
   */
  C4B_LICENSE_EXTENSION_ACTIVATED = 'C4B_LICENSE_EXTENSION_ACTIVATED',
  C4B_LICENSE_EXTENSION_APPROVAL_EXPIRED = 'C4B_LICENSE_EXTENSION_APPROVAL_EXPIRED',
}

// TODO: Migrate existing templates from api/configSecrets.ts { sendgrid.templates }
export enum SendgridTemplate {
  TEST_TEMPLATE = 'thisisatest',
  ACCOUNT_PROMO_SIGNUP_CONFIRMATION = 'd-5374847aee354ed9827d443e858a5c38',
  C2C_REFERRAL_SEND = 'd-58559631b11d411fad15e97ec85cb35d',
  C2C_REFERRAL_CREATED = 'd-a944d1bc7c974b2b89dfea5c5d95281c',
  C2C_REFERRAL_FULFILLED = 'd-aae3451082764bd4b6f2ac2c27e55f6d',

  ORDER_RECEIPT = 'd-a7b1626ad3d84b8a89b7dd13b4824bdd',
  ORDER_RECEIPT_CREDITS = 'd-0676978fa3294feaa8a85846adc984b9',
  ORDER_DECLINED = 'd-3bff8b73c81e4a769a5c38b0e7795154',

  CONSUMER_NEW_ORDER = 'd-c3d5cf00d37842a592d2c2bd01a72d98',
  CONSUMER_NEW_ORDER_CREDITS = 'd-e84c4e09e8bf480e9591f4898173df12',
  CONSUMER_NEW_ORDER_EXPEDITED = 'd-aa8a1a843ac94c53a4a1a8438830eb0a',

  TALENT_NEW_REQUEST = 'd-5a08ad45b54b4825a43d515fc94c7c8f',
  TALENT_NEW_REQUEST_EXPEDITED = 'd-a311506c99b14065967c46c5ebb62785',

  REVIEW_REMINDER = 'd-909ff34037d84295b11dcface193bd95',
  // Todo: double check but this should be safe to deprecate
  MOTHERS_DAY_PROMO_CREDIT = 'd-2e78bde010274026a702bd0ae1bc558a',
}

export enum LiveEventEndReasons {
  TALENT_FINISHED_EVENT = 'talentFinishedEvent',
  TALENT_ENDED_EVENT = 'talentEndedEvent',
  ABANDONED = 'abandoned',
}

export enum LiveEventCallEndReasons {
  Finished = 'Finished',
  FanEndEarly = 'FanEndEarly',
  TalentEndEarly = 'TalentEndEarly',
  FanBadConnection = 'FanBadConnection',
  FanNeverConnected = 'FanNeverConnected',
  TalentBadConnection = 'TalentBadConnection',
  TalentNeverConnected = 'TalentNeverConnected',
}

export enum LiveEventCallTalentIssues {
  AudioWasBad = 'Audio was bad',
  CouldntSeeFanVideo = "Couldn't see their video",
  ProblemWithPhoto = 'Problem with the photobooth picture',
  TheyNeedToBeRefunded = 'They need to be refunded',
  Other = 'Other',
}

export enum LiveEventCallReportIssues {
  Inappropriate = 'Inappropriate behavior',
}

/**
 * Describes auditable events that can occur,
 * related to a ChargeDocument.
 */
export enum ChargeEventType {
  CREATE = 'create',
  CAPTURE = 'capture',
  REFUND = 'refund',
  PARTIAL_REFUND = 'partial_refund',
  PAYMENT_INTENT = 'payment_intent',
}

export enum RefundType {
  PAYMENT_METHOD = 'payment_method',
  CREDITS = 'credits',
}

export enum GrowthConstants {
  PROMOCODES_DISCOUNT_PERCENTAGE = 10,
}

export enum Capabilities {
  CHAT_THREADS = 'chatThreads',
  CHAT_LAZY_LOAD_CHANNEL_MEMBERS = 'chatLazyLoadChannelMembers',
}

export enum Headers {
  CAMEO_CALLS_MIN_VERSION = 'x-cameo-tag-cameocallsminversion',
  CAMEO_CALLS_VERSION = 'x-cameo-tag-cameocallsversion',
  CAMEO_AMPLITUDE_SESSIONID = 'x-cameo-amplitude-sessionid',
  Authorization = 'Authorization',
}

export enum UserSearchFilters {
  CameoDirectTalent = 'cameoDirect',
}

// matches fields in the order model
export enum OrderStatus {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
  DECLINED = 'declined',
  CANCELLED = 'cancelled',
  EXPIRING = 'expiring',
}

export enum LiveEventAgoraUserIds {
  TALENT = 1,
  PARTICIPANT = 2,
  CLOUD_RECORDING = 3,
}

export enum InvoiceLineType {
  other = 'other',
  cameo4Business = 'cameo4Business',
}

export enum ServiceFeeType {
  absolute = 'absolute',
  percentage = 'percentage',
  none = 'none',
}

export enum FanClubNotificationTypes {
  // If you add a property/value here, also add to fan-club-user model.
  REPLIES = 'replies',
  MENTIONS = 'mentions',
}

export enum NotificationDeliveryMethod {
  PUSH = 'push',
  EMAIL = 'email',
  // SMS   = 'sms', // not needed now, maybe in the future
}

export enum NotificationCategory {
  CAMEO = 'cameo',
  CORE_CAMEO = 'coreCameo',
  CAMEO_CALLS = 'cameoCalls',
  CAMEO_LIVE = 'cameoLive',
  DMS = 'directMessages',
  FAN_CLUBS_POSTS = 'fanClubsPosts',
  VIP_FAN_CLUBS_POSTS = 'vipFanClubsPosts',
}

export enum FanClubSubscriptionTypes {
  VIP = 'vip',
}

export enum FanClubSubscriptionEventTypes {
  INITIAL_PURCHASE = 'initial-purchase',
  RENEWAL_PURCHASE = 'renewal-purchase',
  REACTIVATION = 'reactivation',
  CANCELLATION = 'cancellation',
  SUBSCRIPTION_ENDED = 'subscription-ended',
}

export enum FanClubTierSelection {
  BASIC = 'basic',
  /** @deprecated */
  VIP = 'vip',
  /** @deprecated */
  REACTIVATE = 'reactivate',
  UNENROLL = 'unenroll',
  UNSUBSCRIBE = 'unsubscribe',
}

export enum PromptActions {
  CreateVideo = 'CreateVideo',
}

export enum PromptCriteria {
  EXPEDITED_ENABLED = 'expeditedEnabled',
  EXPEDITED_DISABLED = 'expeditedDisabled',
}

export enum ServiceFeeVariations {
  absoluteTwo = 'var_abs2',
  absoluteFive = 'var_abs5',
  percentFive = 'var_pct5',
}

export enum ThrottlingEventType {
  UPDATE = 'THROTTLING_UPDATE',
  ENABLE = 'THROTTLING_ENABLE',
  DISABLE = 'THROTTLING_DISABLE',
}

export enum OrderDeliveryType {
  EXPEDITED = 'EXPEDITED',
  STANDARD = 'STANDARD',
}

export enum CampaignNotificationType {
  ACTIVITY_EVENT = 'ACTIVITY_EVENT',
}

export enum IapSubscriptionSkuTargets {
  FAN_CLUB = 'fanclub',
}

export enum IapSubscriptionSkuPricePoints {
  P_199 = 1.99,
  P_499 = 4.99,
  P_999 = 9.99,
  P_1499 = 14.99,
  P_1999 = 19.99,
  P_2499 = 24.99,
  P_2999 = 29.99,
  P_3999 = 39.99,
  P_4999 = 49.99,
  P_5999 = 59.99,
}

export enum WalletActivityTypes {
  TRANSFER = 'transfer',
  PAYOUT = 'payout',
  PAYOUT_ERROR = 'payoutError',
}

export enum WalletActivityFilterType {
  ALL = 'all',
  PAYOUT = 'payout',
  CALL = 'call',
  VIP = 'vip_fan_club',
  TIP_STICKERS = 'tip_and_stickers',
  ORDER = 'order',
  BUSINESS_ORDER = 'businessOrder',
  MESSAGE = 'message',
  REFERRER = 'referrer',
  CAMEO_LIVE_EVENT = 'cameoLiveEvent',
}

// extends PurchaseType and TransferType
export enum WalletSummaryCategories {
  message = 'message',
  order = 'order',
  businessOrder = 'businessOrder',
  call = 'call',
  cameoLiveEvent = 'cameoLiveEvent',
  interactionRequest = 'interactionRequest',
  sticker = 'sticker',
  vipFanClub = 'vipfanclub',
  tip = 'tip',
  referrer = 'referrer',
  thankYouNote = 'thankYouNote',
  other = 'other',
}

export enum PromoCodeDiscountSource {
  PLATFORM = 'PLATFORM',
  BOTH = 'BOTH',
}

export enum WalletReportFrequency {
  WEEKLY = 'WEEKLY',
  BI_WEEKLY = 'BI_WEEKLY',
  MONTHLY = 'MONTHLY',
  DAILY = 'DAILY',
}

export enum CharityType {
  INTERNAL = 'internal',
  PLEDGE = 'pledge',
}

export enum TalentBookingOffers {
  PERSONAL_CAMEO = 'personalCameo',
  BUSINESS_CAMEO = 'businessCameo',
  DIRECT_MESSAGES = 'directMessages',
  PRIVATE_LIVE = 'privateLive',
  MERCH = 'merch', // tbd
}

export enum FreshBoxType {
  ActiveUserPromotion = 'activeUserPromotion',
  Charity = 'charity',
  OrderThrottling = 'orderThrottling',
  VirtualCharacter = 'virtualCharacter',
}

export enum SupportedWatermarks {
  default = 'default',
  kids = 'kids',
  ad = 'ad',
  __none__ = '__none__',
}

export enum OrderHistoryEventEnum {
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_COMPLETED = 'ORDER_COMPLETED',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_DECLINED = 'ORDER_DECLINED',
  ORDER_EXPIRED = 'ORDER_EXPIRED',
  ORDER_FAILED_UPLOAD = 'ORDER_FAILED_UPLOAD',
  ORDER_INVALIDATED = 'ORDER_INVALIDATED',
  ORDER_MESSAGE_TALENT = 'ORDER_MESSAGE_TALENT',
  ORDER_PENDING_UPLOAD = 'ORDER_PENDING_UPLOAD',
  ORDER_REACTION = 'ORDER_REACTION',
  ORDER_REACTION_VIEWED = 'ORDER_REACTION_VIEWED',
  ORDER_REDO = 'ORDER_REDO',
  ORDER_REPORTED = 'ORDER_REPORTED',
  ORDER_REVIEWED = 'ORDER_REVIEWED',
  ORDER_SHARED = 'ORDER_SHARED',
  ORDER_STARTED_RECORDING = 'ORDER_STARTED_RECORDING',
  ORDER_UPDATED = 'ORDER_UPDATED',
  ORDER_VIEWED_PURCHASER = 'ORDER_VIEWED_PURCHASER',
  ORDER_VIEWED_RECIPIENT = 'ORDER_VIEWED_RECIPIENT',
  ORDER_VIEWED_TALENT = 'ORDER_VIEWED_TALENT',
  THROTTLING_ACTIVE = 'THROTTLING_ACTIVE',
  EXTENSION_4_DAY = 'EXTENSION_4_DAY',
  EXTENSION_7_DAY = 'EXTENSION_7_DAY',
  EXTENSION_24_HOUR = 'EXTENSION_24_HOUR',
  EXTENSION_X_HOUR = 'EXTENSION_X_HOUR',
}

// Including all options presented in retool/available for purchase
export enum OrderExpirationHoursEnum {
  ONE_DAY = 24,
  TWO_DAYS = 48,
  THREE_DAYS = 72,
  FOUR_DAYS = 96,
  FIVE_DAYS = 120,
  SIX_DAYS = 144,
  SEVEN_DAYS = 166, // 7 days minus 2 hours to ensure authorization
}

export enum FulfillmentProvider {
  CANDLE = 'candle',
  CAMEO = 'cameo',
  CAMEO_ANIMATED = 'cameo_animated',
  POCKET_WATCH = 'pocket_watch',
  OTHER = 'other',
  AQUIFER = 'aquifer',
  SESAME_STREET = 'sesame_street',
}

export enum FulfillmentProviderRole {
  ADMIN = 'admin',
  SYSTEM = 'system',
}

export enum SignupPriceRecommendationCategory {
  ENTERTAINMENT = 'Entertainment',
  MUSIC = 'Music',
  SPORTS = 'Sports',
}

export enum SignupPriceRecommendationSubCategory {
  ACTOR_OR_ACTRESS = 'Actor/Actress',
  ADULT = 'Adult',
  AMERICAN_FOOTBALL = 'American Football',
  ANALYST_OR_BROADCASTER = 'Analyst/Broadcaster',
  BASEBALL = 'Baseball',
  BASKETBALL = 'Basketball',
  COLLEGE = 'College',
  COMBAT = 'Combat',
  COMEDY = 'Comedy',
  CREATOR = 'Creator',
  ELECTRONIC = 'Electronic',
  HIPHOP_OR_RNB = 'Hip-Hop/RnB',
  OTHER = 'Other',
  POP = 'Pop',
  REALITY_TV = 'Reality TV',
  ROCK = 'Rock',
  SIGNER_OR_SONGWRITER = 'Singer/Songwriter',
}

export enum SignupPriceRecommendationSocial {
  FACEBOOK = 'Facebook',
  INSTAGRAM = 'Instagram',
  TIKTOK = 'TikTok',
  TWITTER = 'Twitter',
  YOUTUBE = 'YouTube',
}

export enum SignupPriceRecommendationFollowers {
  F_0 = 0,
  F_10000 = 10000,
  F_30000 = 30000,
  F_100000 = 100000,
  F_300000 = 300000,
  F_1000000 = 1000000,
  F_3000000 = 3000000,
  F_10000000 = 10000000,
}

export const SignupPriceRecommendationCategoryTree = {
  [SignupPriceRecommendationCategory.ENTERTAINMENT]: [
    SignupPriceRecommendationSubCategory.ACTOR_OR_ACTRESS,
    SignupPriceRecommendationSubCategory.COMEDY,
    SignupPriceRecommendationSubCategory.CREATOR,
    SignupPriceRecommendationSubCategory.OTHER,
    SignupPriceRecommendationSubCategory.REALITY_TV,
    SignupPriceRecommendationSubCategory.ADULT,
  ],
  [SignupPriceRecommendationCategory.MUSIC]: [
    SignupPriceRecommendationSubCategory.ELECTRONIC,
    SignupPriceRecommendationSubCategory.HIPHOP_OR_RNB,
    SignupPriceRecommendationSubCategory.OTHER,
    SignupPriceRecommendationSubCategory.POP,
    SignupPriceRecommendationSubCategory.ROCK,
    SignupPriceRecommendationSubCategory.SIGNER_OR_SONGWRITER,
  ],
  [SignupPriceRecommendationCategory.SPORTS]: [
    SignupPriceRecommendationSubCategory.AMERICAN_FOOTBALL,
    SignupPriceRecommendationSubCategory.ANALYST_OR_BROADCASTER,
    SignupPriceRecommendationSubCategory.BASEBALL,
    SignupPriceRecommendationSubCategory.BASKETBALL,
    SignupPriceRecommendationSubCategory.COLLEGE,
    SignupPriceRecommendationSubCategory.COMBAT,
    SignupPriceRecommendationSubCategory.OTHER,
  ],
};

export const ENROLLMENTS_EXCLUDED_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'CU',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IE',
  'IR',
  'IS',
  'IT',
  'JM',
  'KP',
  'KE',
  'LI',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'RU',
  'SD',
  'SE',
  'SI',
  'SK',
  'SY',
  'VN',
];

export enum ReviewScoreNames {
  overallSatisfaction = 'overallSatisfaction',
  turnaroundTime = 'turnaroundTime',
  videoLength = 'videoLength',
  authenticity = 'authenticity',
  personalization = 'personalization',
  audioVideoQuality = 'audioVideoQuality',
}

export enum ReviewScoreValues {
  negative = -1,
  neutral = 0,
  positive = 1,
}

export enum TalentBadgeTypes {
  RESPONSIVE = 'responsive',
  HOLIDAY_2023 = 'holiday-2023',
}

export enum PostCardsProvider {
  HANDWRYTTEN = 'handwrytten',
}

export enum PostCardStatus {
  PENDING = 'pending',
  DECLINED = 'declined',
  DELIVERED = 'delivered',
  FAILED = 'failed',
  SEEN = 'seen',
  CHARGE_ERROR = 'charge_error',
}

export enum DigitalPostCardsProvider {
  AG = 'americanGreetings',
}

export enum DigitalPostCardStatus {
  PENDING = 'pending',
  DECLINED = 'declined',
  DELIVERED = 'delivered',
  FAILED = 'failed',
  SEEN = 'seen',
  CHARGE_ERROR = 'charge_error',
}

export enum CollageContentTypes {
  VIDEO = 'video',
  TEXT = 'text',
  IMAGE = 'image',
  EMPTY = 'empty',
}

export enum CollageGridItemTypes {
  CONTENT = 'content',
  ORDER = 'order',
  EMPTY = 'empty',
}

export enum ExternallySourcedGender {
  MALE = 'male',
  FEMALE = 'female',
  TRANS_MALE = 'trans_male',
  TRANS_FEMALE = 'trans_female',
  NON_BINARY = 'non_binary',
}

export enum ExternallySourcedAccountType {
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  SOUNDCLOUD = 'soundcloud',
  SNAPCHAT = 'snapchat',
  YOUTUBE = 'youtube',
  FACEBOOK = 'facebook',
  TIKTOK = 'tiktok',
  VIMEO = 'vimeo',
  OTHER = 'other',
  PINTEREST = 'pinterest',
  TWITCHTV = 'twitchtv',
  LINKEDIN = 'linkedin',
  SPOTIFY = 'spotify',
  TWITCH = 'twitch',
}

export enum ModerationStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  FLAGGED = 'flagged',
  PROCESSING = 'processing',
}

export enum UserStubStatus {
  AUTO_APPROVED = 'auto-approved',
  APPROVED = 'approved',
  DECLINED = 'declined',
  IN_REVIEW = 'in-review',
}

export const ExternallySourcedAgeRangesMap: Record<
  number,
  { min: string; max?: string }
> = Object.freeze({
  0: {
    min: '13',
    max: '17',
  },
  1: {
    min: '18',
    max: '24',
  },
  2: {
    min: '25',
    max: '34',
  },
  3: {
    min: '35',
    max: '44',
  },
  4: {
    min: '45',
    max: '54',
  },
  5: {
    min: '55',
  },
});

export enum BlogCategory {
  CAMEO_CARES = 'cameoCares',
  GIFT_GUIDES = 'giftGuides',
}

export enum EnrollmentSource {
  META_AD = 'metaAd',
}

export enum ReferralsBreakdownFilters {
  ACTIVE = 'active',
  PENDING = 'pending',
  PAST = 'past',
  ALL = 'all',
}

/**
 * Requested by legal to map to the legal line items
 *
 * IV.5.f.i.A - booking request review for Cameo drafted/edited script or video
 * IV.5.f.i.B - booking request review for customer with prior IV.5.f.i.A violation(s)
 * IV.5.f.ii - video use review for 5% of videos with watermark removal permitted
 * IV.5.f.iii - video use review for video reported via public reporting mechanism
 * IV.5.f.iv.B - video use re-review for video with prior IV.5.f.ii-iii violation
 * IV.5.f.v - video use review for customer with prior IV.5.f.ii-iii violation(s)
 */
export enum InternalReviewContentType {
  IV_5_F_I_A = 'IV.5.f.i.A',
  IV_5_F_I_B = 'IV.5.f.i.B',
  IV_5_F_II = 'IV.5.f.ii',
  IV_5_F_III = 'IV.5.f.iii',
  IV_5_F_IV_B = 'IV.5.f.iv.B',
  IV_5_F_V = 'IV.5.f.v',
}

export enum InternalReviewStatus {
  COMPLIANT = 'compliant',
  REJECTED = 'rejected',
}

export enum InternalReviewEventType {
  BAN_CUSTOMER = 'ban_customer',
  ENABLE_CUSTOMER = 'enable_customer',
  BAN_TALENT = 'ban_talent',
  ENABLE_TALENT = 'enable_talent',
}

export enum UserPolicyStatus {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  DISMISSED = 'dismissed',
  PENDING = 'pending',
}

export enum VariantCatalogType {
  CORE = 'core',
  SEASONAL = 'seasonal',
  CUSTOM = 'custom',
}

export const CameoEnums = {
  ActiveExperiments,
  ActiveOptimizelyFeatures,
  ActivityEventKind,
  ALGORITHM_TYPES,
  Auth0Provider,
  AvalaraCode,
  BlogCategory,
  BroadcastEventChannelTypes,
  BroadcastEventLayoutTypes,
  BroadcastEventStatusTypes,
  BroadcastEventTicketCompTypes,
  BroadcastEventTicketStatusTypes,
  BroadcastEventTicketTypes,
  BroadcastEventUserEventTypes,
  BroadcastEventUserRoles,
  BroadcastEventUserStageStatusTypes,
  BusinessVideoStatus,
  CameoCallsCampaign,
  CameoProvider,
  CampaignNotificationType,
  CampaignTacticEnum,
  Capabilities,
  ChannelListingFilters,
  ChannelRoleTypes,
  ChannelTypeEnum,
  ChannelTypeFilter,
  ChargeEventType,
  CharityType,
  ChatCategories,
  CMS_AUDIENCES,
  CollageContentTypes,
  CollageGridItemTypes,
  CustomerIntroduction,
  DigitalPostCardsProvider,
  DigitalPostCardStatus,
  DonationStatus,
  Emojis,
  ExternallySourcedAccountType,
  ExternallySourcedGender,
  FanClubSubscriptionTypes,
  FanClubTierSelection,
  FormInputType,
  FreshBoxType,
  FulfillmentProvider,
  FulfillmentProviderRole,
  GrowthConstants,
  IapSubscriptionSkuPricePoints,
  IapSubscriptionSkuTargets,
  InteractionRequestEventTypes,
  InteractionRequestFulfillmentTypes,
  InteractionRequestParticipantSize,
  InteractionRequestStatusTypes,
  InteractionRequestTypes,
  InvoiceLineType,
  LineItemType,
  LiveEventAgoraUserIds,
  LiveEventCallEndReasons,
  LiveEventCallReportIssues,
  LiveEventCallTalentIssues,
  LiveEventEndReasons,
  LiveEventStatusTypes,
  LiveEventTicketStatusTypes,
  LoginMethod,
  MessageReactionTypes,
  MessageTypes,
  ModerationStatus,
  NotificationCategory,
  NotificationDeliveryMethod,
  Occasion,
  OrderDeliveryType,
  OrderHistoryEventEnum,
  OrderStatus,
  PaymentMethodTypes,
  PaymentProcessor,
  PaymentTarget,
  PayoutProvider,
  PoliticalCharityType,
  PostCardsProvider,
  PreventativeThrottling,
  PriceApplicationMethod,
  PriceModifierType,
  PriceRecommendationTypes,
  ProductPriceModifiers,
  ProductType,
  PromoCodeDiscountSource,
  Pronoun,
  PurchaserPronoun,
  PurchaseType,
  ReferralsBreakdownFilters,
  ReviewScoreNames,
  ReviewScoreValues,
  RowTypes,
  SendgridTemplate,
  ServiceFeeType,
  StreamRecordType,
  SupportedWatermarks,
  TalentBookingOffers,
  ThrottlingEventType,
  TransferAction,
  TransferRecipientType,
  TransferRequestStatus,
  TransferRequestType,
  TransferStatus,
  TransferType,
  UserSearchFilters,
  UserStubStatus,
  UserPolicyStatus,
  VideoCallStates,
  WalletActivityFilterType,
  WalletActivityTypes,
  WalletReportFrequency,
  WalletSummaryCategories,
  InternalReviewContentType,
};
