import analytics from 'analytics';

export const trackRecentlyViewedUserClickEvent = (
  name: string,
  username: string,
  index: number
) => {
  const data = {
    eventName: 'Navigate',
    eventDtl: {
      eventType: 'Click',
    },
    objectDtl: {
      objectType: 'Avatar',
      objectName: 'RecentlyViewedUser',
      containerType: 'Search',
    },
    positionDtl: {
      col: index,
      rowLabelText: name,
      rowLabelType: 'User',
    },
    sourceDtl: {
      sourceKey: 'User',
      sourceValue: username,
    },
    targetDtl: {
      targetType: 'Profile',
      targetKey: 'User',
      targetPath: `/${username}`,
      targetValue: username,
    },
  } as const;

  analytics.trackAction(data);
};
