import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  compose,
  padding,
  PaddingProps,
  DisplayProps,
  display,
  variant,
  FontWeightProps,
} from 'styled-system';

import { ThemeInterface } from 'domains/web/theme/types';
import { ResponsiveVariant } from 'domains/web/theme/breakpoints';

type StyledSystemProps = PaddingProps &
  DisplayProps & {
    variant?: ResponsiveVariant<'base_xs' | 'base_s' | 'base_m' | 'base_l'>;
  } & FontWeightProps;
export type LinkProps = React.ComponentProps<typeof RRLink> &
  StyledSystemProps & {
    as?: React.ElementType | keyof JSX.IntrinsicElements;
  };

/**
 * A Link component with similar API than Text.
 * Doesn't play well with external links via `to={{ pathname: 'https://example.com' }}`.
 * If external link needed use like `<Link as="a" href="https://example.com" />`.
 */
export const Link = (props: LinkProps) => {
  return <StyledLink {...props} />;
};

const StyledLink = styled(RRLink)`
  ${({ theme: t }) => {
    const theme = t as unknown as ThemeInterface;

    const variants = {
      base_xxs: {
        fontWeight: theme.fonts.fontWeights.regular,
        fontSize: theme.fonts.fontSizes.base_xxs,
        lineHeight: theme.fonts.lineHeights.base_xxs,
      },
      base_xs: {
        fontWeight: theme.fonts.fontWeights.regular,
        fontSize: theme.fonts.fontSizes.base_xs,
        lineHeight: theme.fonts.lineHeights.base_xs,
      },
      base_s: {
        fontWeight: theme.fonts.fontWeights.regular,
        fontSize: theme.fonts.fontSizes.base_s,
        lineHeight: theme.fonts.lineHeights.base_s,
      },
      base_m: {
        fontWeight: theme.fonts.fontWeights.regular,
        fontSize: theme.fonts.fontSizes.base_m,
        lineHeight: theme.fonts.lineHeights.base_m,
      },
      base_l: {
        fontWeight: theme.fonts.fontWeights.regular,
        fontSize: theme.fonts.fontSizes.base_l,
        lineHeight: theme.fonts.lineHeights.base_l,
      },
    };

    return css`
      color: ${theme.colors.foreground.muted};
      display: inline-block;
      font-size: ${theme.fonts.fontSizes.base_m};
      font-weight: ${theme.fonts.fontWeights.medium};
      line-height: ${theme.fonts.lineHeights.base_m};
      text-decoration: none;
      transition: ${theme.transitions.default};

      &:hover {
        color: ${theme.colors.foreground.default};
      }

      ${compose(padding, display)}

      ${variant({ variants })}

      ${variant({
        prop: 'fontWeight',
        variants: {
          bold: {
            fontWeight: theme.fonts.fontWeights.bold,
          },
          medium: {
            fontWeight: theme.fonts.fontWeights.medium,
          },
          regular: {
            fontWeight: theme.fonts.fontWeights.regular,
          },
        },
      })}
    `;
  }}
`;
