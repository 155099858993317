export enum FormFieldNames {
  about = 'about',
  aboutRecipient = 'aboutRecipient',
  age = 'age',
  birthdate = 'birthdate',
  birthdatePlans = 'birthdatePlans',
  brandIntro = 'brandIntro',
  talkingPoints = 'talkingPoints',
  backgroundInfo = 'backgroundInfo',
  requiredLines = 'requiredLines',
  event = 'event',
  fathersDay = 'fathersDay',
  freeText = 'free-text',
  graduatingFrom = 'graduatingFrom',
  graduationLevel = 'graduationLevel',
  help = 'help',
  holidayDetails = 'holidayDetails',
  holidayOccasion = 'holidayOccasion',
  holidayCelebration = 'holidaysCelebration',
  holidayRelationship = 'holidaysRelationship',
  instructions = 'instructions',
  milestone = 'milestone',
  mothersDay = 'mothersDay',
  topicsToAvoid = 'topicsToAvoid',
  occasionDetails = 'occasionDetails',
  roastOccasion = 'roastOccasion',
  occasion = 'occasion',
  otherOccasion = 'otherOccasion',
  otherOccasionHybrid = 'otherOccasionHybrid',
  otherRelation = 'otherRelation',
  pepTalkRelation = 'pepTalkRelation',
  pepTalkReason = 'pepTalkReason',
  pepTalkOccasion = 'pepTalkOccasion',
  christmasCelebration = 'christmasCelebration',
  christmasRelationship = 'christmasRelationship',
  hanukkahCelebration = 'hanukkahCelebration',
  hanukkahRelationship = 'hanukkahRelationship',
  kwanzaaCelebration = 'kwanzaaCelebration',
  kwanzaaRelationship = 'kwanzaaRelationship',
  pride = 'pride',
  relation = 'relation',
  roastAbout = 'roastAbout',
  script = 'script',
  template = 'template',
  tone = 'tone',
  wishes = 'wishes',
  writeAScript = 'writeAScript',
  whyTalent = 'whyTalent',
  singleFieldInstructions = 'singleFieldInstructions',
  somethingSpecial = 'somethingSpecial',
  attachment = 'attachment',
  askQuestion = 'askQuestion',
  askAdvice = 'askAdvice',
  howTalentCanHelp = 'howTalentCanHelp',
  fantasyDraftHowCanTheyHelp = 'fantasyDraftHowCanTheyHelp',
  fantasyDraftLeagueName = 'fantasyDraftLeagueName',
  fantasyDraftTeams = 'fantasyDraftTeams',
  fantasyDraftOtherInfo = 'fantasyDraftOtherInfo',
  talentVideoVariantId = 'talentVideoVariantId',
  featureFlags = 'featureFlags',
  /**
   * Booking Form V2 Fields
   */
  sincerely = 'sincerely',
}

export enum FormInputType {
  Text = 'Text',
  TextBox = 'TextBox',
  Date = 'Date',
}
