const Braze = {
  getUser: () => {
    return Braze._safeCall(() => window.appboy.getUser());
  },

  changeUser: (userId: string) => {
    return Braze._safeCall(() => window.appboy.changeUser(userId));
  },

  setEmail: (email: string) => {
    return Braze._safeCall(() => Braze.getUser().setEmail(email));
  },

  addAlias: (aliasName: string, aliasLabel: string) => {
    return Braze._safeCall(() =>
      Braze.getUser().addAlias(aliasName, aliasLabel)
    );
  },

  setLanguage: (languageCode: string) => {
    return Braze._safeCall(() => Braze.getUser().setLanguage(languageCode));
  },

  setEmailNotificationSubscriptionType: (
    status: 'unsubscribed' | 'subscribed' | 'opted_in'
  ) => {
    return Braze._safeCall(() =>
      Braze.getUser().setEmailNotificationSubscriptionType(status)
    );
  },

  _safeCall: (fn: Function) => {
    try {
      return fn();
    } catch (e) {
      console.error(e);
    }
    return null;
  },
};

export default Braze;
