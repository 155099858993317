import noop from 'lodash/noop';
import React, {
  createContext,
  useReducer,
  PropsWithChildren,
  useContext,
} from 'react';

type PlaybackState = {
  [namespace: string]: {
    muted: boolean;
    currentlyPlaying: string;
  };
};

type PlaybackAction =
  | {
      type: 'SET_MUTED';
      namespace: string;
      muted: boolean;
    }
  | {
      type: 'SET_CURRENTLY_PLAYING';
      namespace: string;
      videoId: string;
    }
  | {
      type: 'RESET';
      namespace: string;
    };

const initialState: PlaybackState = {
  default: {
    muted: true,
    currentlyPlaying: '',
  },
};

const playbackOrchestratorReducer = (
  state: PlaybackState,
  action: PlaybackAction
): PlaybackState => {
  switch (action.type) {
    case 'SET_MUTED':
      return {
        ...state,
        [action.namespace]: {
          ...state[action.namespace],
          muted: action.muted,
        },
      };
    case 'SET_CURRENTLY_PLAYING':
      return {
        ...state,
        [action.namespace]: {
          ...state[action.namespace],
          currentlyPlaying: action.videoId,
        },
      };
    case 'RESET':
      return {
        ...state,
        [action.namespace]: {
          ...initialState.default,
        },
      };
    default:
      return state;
  }
};

const PlaybackContext = createContext<{
  state: PlaybackState;
  dispatch: React.Dispatch<PlaybackAction>;
}>({
  state: initialState,
  dispatch: noop,
});

const PlaybackProvider = ({ children }: PropsWithChildren<object>) => {
  const [state, dispatch] = useReducer(
    playbackOrchestratorReducer,
    initialState
  );

  return (
    <PlaybackContext.Provider value={{ state, dispatch }}>
      {children}
    </PlaybackContext.Provider>
  );
};

const usePlaybackContext = (): [
  PlaybackState,
  React.Dispatch<PlaybackAction>
] => {
  const { state, dispatch } = useContext(PlaybackContext);
  return [state, dispatch];
};

const setActiveVideo = (
  dispatch: React.Dispatch<PlaybackAction>,
  namespace: string,
  videoId: string
) => {
  dispatch({
    type: 'SET_CURRENTLY_PLAYING',
    namespace,
    videoId,
  });
};

const setMutePreference = (
  dispatch: React.Dispatch<PlaybackAction>,
  namespace: string,
  muted: boolean
) => {
  dispatch({
    type: 'SET_MUTED',
    namespace,
    muted,
  });
};

const resetPlaybackContext = (
  dispatch: React.Dispatch<PlaybackAction>,
  namespace: string
) => {
  dispatch({
    type: 'RESET',
    namespace,
  });
};

const getPlaybackContextByNamespace = (
  state: PlaybackState,
  namespace: string
) => {
  return state[namespace] || initialState.default;
};

const selectors = {
  getPlaybackContextByNamespace,
};

const actions = {
  resetPlaybackContext,
  setActiveVideo,
  setMutePreference,
};

export { PlaybackProvider, actions, usePlaybackContext, selectors };
