import reduceReducers from 'reduce-reducers';
import {
  reducer as loadMyCameosReducer,
  loadMyCameos,
  updateActivatedLicense,
} from './loadMyCameos';
import cancelOrderReducer from './cancelOrder';
import * as selectors from './selectors';
import { OrdersPageState } from './types';

const reducer = reduceReducers<OrdersPageState>(
  loadMyCameosReducer,
  cancelOrderReducer
);

const actions = {
  loadMyCameos,
  updateActivatedLicense,
};

export { actions, selectors };
export default reducer;
