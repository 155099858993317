import { ProductType } from '../../api/models/cart/enums';

export enum Status {
  CURRENT = 'CURRENT',
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
}

export enum DeliverySpeed {
  standard = 'standard',
  expedited = 'expedited',
}

export enum ActiveProducts {
  marketing = ProductType.shoutoutBusinessExternalMarketing,
  events = ProductType.shoutoutBusinessExternalEvents,
  internal = ProductType.shoutoutBusinessInternalComms,
}

export const LicensedBusinessVideoStatus = Object.freeze({
  Active: 'active',
  Canceled: 'canceled',
  Expired: 'expired',
  Inactive: 'inactive',
  Requested: 'requested',
  Scheduled: 'scheduled',
} as const);

export type LicensedBusinessVideoStatusValue =
  (typeof LicensedBusinessVideoStatus)[keyof typeof LicensedBusinessVideoStatus];

export const CompletedLicensedBusinessVideoStatuses: LicensedBusinessVideoStatusValue[] =
  [
    LicensedBusinessVideoStatus.Active,
    LicensedBusinessVideoStatus.Expired,
    LicensedBusinessVideoStatus.Inactive,
    LicensedBusinessVideoStatus.Scheduled,
  ];

export const IncompleteLicensedBusinessVideoStatuses: LicensedBusinessVideoStatusValue[] =
  [LicensedBusinessVideoStatus.Canceled, LicensedBusinessVideoStatus.Requested];
