// https://theme-ui.com/theme-spec/
import { toRem } from "./helpers";

export const space = {
  $0: 0,
  px: 1,
  "$0.5": 4,
  $1: 8,
  "$1.5": 12,
  $2: 16,
  "$2.25": 18,
  "$2.5": 20,
  $3: 24,
  $4: 32,
  $5: 40,
  $6: 48,
  $8: 64,
  $10: 80,
  $12: 96,
  $24: 192,
} as const;

export const globalColors = {
  "black-00": "#000000",
  "grey-07": "#121114",
  "grey-10": "#201E23",
  "grey-20": "#302D34",
  "grey-25": "#3F3B45",
  "grey-30": "#4D4953",
  "grey-40": "#68616F",
  "grey-50": "#817A8A",
  "grey-70": "#BFBAC4",
  "grey-95": "#F2F1F3",
  "purple-20": "#331C52",
  "purple-50": "#8036E0",
  "purple-70": "#BE8DFC",
  "red-20": "#402427",
  "red-40": "#CB2917",
  "red-70": "#FA826A",
  "cyan-20": "#174A53",
  "cyan-40": "#21CFE6",
  "cyan-70": "#83F0FF",
  "green-20": "#114E14",
  "green-60": "#5FDE6D",
  "green-70": "#90F0A2",
  "yellow-20": "#6A601C",
  "yellow-60": "#EBE446",
  "yellow-70": "#F5F384",
  "pink-20": "#511333",
  "pink-50": "#E5197B",
  "pink-60": "#FF58A6",
  "cobalt-20": "#232855",
  "cobalt-40": "#3914CC",
  "cobalt-70": "#778DFF",
} as const;

export const semanticColors = {
  foreground: {
    default: globalColors["grey-95"],
    muted: globalColors["grey-70"],
    subtle: globalColors["grey-50"],
  },
  background: {
    level0: globalColors["black-00"],
    level1: globalColors["grey-07"],
    level2: globalColors["grey-10"],
    level3: globalColors["grey-20"],
  },
  border: {
    subtle: globalColors["grey-20"],
    default: globalColors["grey-25"],
    emphasis: globalColors["grey-50"],
    focus: globalColors["grey-95"],
  },
  accent: {
    default: globalColors["purple-50"],
    emphasis: globalColors["purple-70"],
  },
  danger: {
    default: globalColors["red-40"],
    emphasis: globalColors["red-70"],
  },
  success: {
    default: globalColors["green-60"],
    emphasis: globalColors["green-70"],
  },
} as const;

/**
 * Theme-UI specific colors. Used for default text and background colors.
 */
const defaultColors = {
  text: semanticColors.foreground.default,
} as const;

export const colors = {
  ...globalColors,
  ...semanticColors,
  ...defaultColors,
} as const;

export const fontWeights = {
  regular: "400",
  medium: "500",
  bold: "600",
} as const;

export const fontSizes = {
  "$1.25": "1rem",
  "$1.5": "1.2rem",
  "$1.75": "1.4rem",
  $2: "1.6rem",
  "$2.25": "1.8rem",
  "$2.5": "2rem",
  $3: "2.4rem",
  "$3.5": "2.8rem",
  $4: "3.2rem",
  "$4.5": "3.6rem",
  $5: "4rem",
  "$5.5": "4.4rem",
  $6: "4.8rem",
  $8: "6.4rem",
} as const;

export const lineHeights = {
  $2: "1.6rem",
  "$2.5": "2rem",
  $3: "2.4rem",
  "$3.5": "2.8rem",
  $4: "3.2rem",
  $5: "4rem",
  "$5.5": "4.4rem",
  $6: "4.8rem",
  $8: "6.4rem",
} as const;

export const fonts = {
  body: 'Geist, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  heading:
    'Geist, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
} as const;

export const shadows = {
  high: "0px 2px 28px rgba(0, 0, 0, 0.35)",
  primary: "0px 2px 20px rgba(0, 0, 0, 0.35)",
  secondary: "0px 2px 16px rgba(0, 0, 0, 0.35)",
  tertiary: "0px 2px 4px rgba(0, 0, 0, 0.35)",
} as const;

// Breakpoints from old cameoweb theme
export const oldBreakpoints = [
  "374px", // MOBILE
  "768px", // TABLET
  "992px", // DESKTOP
  "1200px", // WIDE
] as const;

export const breakpoints = [
  "320px",
  "375px",
  "744px",
  "950px",
  "1128px",
  "1440px",
] as const;

export const sizes = Object.fromEntries(
  Object.entries(space).map(([key, val]) => [key, toRem(val)])
) as { [K in keyof typeof space]: string };

export const borderStyles = {
  none: "none",
  solid: "solid",
  dashed: "dashed",
} as const;

export const borderWidths = {
  $0: "0px",
  px: "1px",
  "$0.25": "2px",
  "$1.5": "12px",
} as const;

export const radii = {
  $0: "0px",
  $1: "8px",
  "$1.5": "12px",
  $2: "16px",
  $3: "24px",
} as const;

/**
 * Out of spec for now
 */
// const zIndices = {};
// const transitions = {};
