import { useSelector } from 'react-redux';
import * as layoutSelectors from 'state/modules/browse/layouts/selectors';
import * as routerSelectors from 'state/modules/router/selectors';
import { getCurrentPathname } from 'state/modules/router/selectors';
import { useKidsTalent } from 'utils/hooks/useKidsTalent';
import { AdminOrder } from 'types/src/api/models/order';
import { selectors as orderSelectors } from 'state/modules/orders';

const INCLUDE_LAYOUT_KEYS = ['kids', 'defaultTalentProfileLayout'];

// Had to use this hacky solution because useParams is not available on the nav bar component,
// due to the component being rendered outside the router.
const useIsKidsOrderOnPostCardPage = () => {
  const isPurchasePostcardPage = useSelector(routerSelectors.isPostCardPage);
  const pathname = useSelector(getCurrentPathname);
  // we mock pathname as a function on our test environment so this is a check to avoid failures in CI
  const [orderId] =
    typeof pathname === 'string' ? pathname?.split('/')?.slice(-1) : [];
  const order: AdminOrder = useSelector(orderSelectors.getOrderById(orderId));
  if (!isPurchasePostcardPage) return false;
  return order?.featureFlags?.isCameoKidsRequest;
};

export const useKidsExperience = () => {
  const layoutData = useSelector(layoutSelectors.getPageData);
  const isAnimatedCharacter = useKidsTalent({ layoutData });
  const isKidsOrder = useIsKidsOrderOnPostCardPage();

  const isKidsPage = useSelector(routerSelectors.isKidsPage);
  const includedLayout = INCLUDE_LAYOUT_KEYS.includes(layoutData?.layoutKey);

  return ((isAnimatedCharacter || isKidsPage) && includedLayout) || isKidsOrder;
};
