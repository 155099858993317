import queryString from 'qs';
import { AdminUser } from 'types/src/api/models/user';
import { Credentials, LoginMethod } from 'types/src/utils/enums';
import handleError from './handleError';
import login from './login';

export default function ({
  url = '',
}: Credentials[LoginMethod.MAGIC_LINK_OPEN]): Promise<AdminUser> {
  const [, query] = url.split(/#|\?/);
  if (query) {
    const {
      error_description: errorMessage,
      access_token: accessToken,
      token_type: tokenType,
    } = queryString.parse(query);

    if (errorMessage) {
      handleError('loginWithDeeplink')(new Error(errorMessage as string));
    }

    if (accessToken && tokenType) {
      return login({
        connection: LoginMethod.MAGIC_LINK_OPEN,
        credentials: {},
        headers: {
          Authorization: `${tokenType} ${accessToken}`,
        },
      }).catch(handleError('loginWithDeeplink'));
    }
  }

  return Promise.resolve(null); // Not a magic link; do nothing
}
