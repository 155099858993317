const ASYNC_CONNECT = {};

ASYNC_CONNECT.FIRST_RENDER_FINISHED = 'asyncConnect/FIRST_RENDER_FINISHED';

const initialState = {
  isFirstRender: true,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ASYNC_CONNECT.FIRST_RENDER_FINISHED:
      return {
        ...state,
        isFirstRender: false,
      };
    default:
      return state;
  }
}

export function finishFirstRender() {
  return {
    type: ASYNC_CONNECT.FIRST_RENDER_FINISHED,
  };
}
