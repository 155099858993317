/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';

import { ThemeInterface } from 'domains/web/theme/types';
import { compose, grid, GridProps } from 'styled-system';

type BaseProps = {
  type?: 'text';
  hasError?: boolean;
  children?: React.ReactNode;
  size?: 'medium' | 'large';
  /**
   * Affect the template of InputSlots (e.g. `1fr auto`)
   * used when the default template doesn't fit the use case
   */
  slotsGridTemplateColumns?: CSSStyleDeclaration['gridTemplateColumns'];
};

export type InputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  keyof BaseProps
> &
  BaseProps;

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      hasError,
      children,
      disabled,
      size = 'large',
      slotsGridTemplateColumns,
      ...rest
    }: InputProps,
    ref
  ) => (
    <InputMask
      className="form-input"
      hasError={hasError}
      disabled={disabled}
      size={size}
    >
      <InputSlotsLayout
        gridTemplateColumns={
          // Use `React.Children.count(children)` so consumers can conditionally
          // render using `{bool ? <Input.Slot /> : []}` to programmatically show an input slot
          // or not
          React.Children.count(children)
            ? slotsGridTemplateColumns || 'auto 1fr auto'
            : 'auto'
        }
      >
        <input type={type} {...rest} ref={ref} />
        {children}
      </InputSlotsLayout>
    </InputMask>
  )
);

/**
 * Holds the input extras (e.g. icons, buttons, etc.)
 */
const InputSlotsLayout = styled.div<GridProps>`
  display: grid;

  ${compose(grid)}
`;

export const LeftSlot = styled.div`
  ${() => {
    return css`
      align-items: center;
      display: flex;
      grid-column: 1;
      grid-row: 1;
    `;
  }}
`;

export const RightSlot = styled.div`
  ${() => {
    return css`
      align-items: center;
      display: flex;
      grid-column: 3;
      grid-row: 1;
    `;
  }}
`;

type InputMaskProps = {
  hasError: boolean;
  disabled: boolean;
  size: InputProps['size'];
  theme: ThemeInterface;
};

const InputMask = styled.div<InputMaskProps>`
  ${({ theme: t }) => {
    const theme = t as unknown as ThemeInterface;

    return css`
      appearance: none;
      background-color: ${theme.colors.background.level2};
      border-radius: ${theme.radii[1]};
      border: 0.15rem solid ${theme.colors.border.default};
      box-sizing: border-box;
      outline-offset: -0.2rem;
      outline: transparent solid 0.2rem;
      position: relative;
      transition: ${theme.transitions.default};
      width: 100%;

      &:focus,
      &:focus-within,
      &:focus-visible {
        outline-color: ${theme.colors.border.focus};
      }

      &:hover {
        border-color: ${theme.colors.border.emphasis};
      }

      ${InputMaskErrorStyle};
      ${InputMaskDisableStyle};

      input {
        ${InputStyle};
      }

      ${InjectedSlotStyle};
    `;
  }}
`;

const InputStyle = css`
  ${({ theme: t, size }: InputMaskProps) => {
    const theme = t as unknown as ThemeInterface;
    return css`
      background-color: transparent;
      border: 0;
      box-sizing: border-box;
      border-radius: ${theme.radii[1]};
      caret-color: ${theme.colors.accent.emphasis};
      color: ${theme.colors.foreground.default};
      font-family: ${theme.fonts.fontFamily.body};
      font-size: ${theme.fonts.fontSizes.base_m};
      font-weight: ${theme.fonts.fontWeights.regular};
      height: ${size === 'medium' ? '4.4rem' : '5.2rem'};
      line-height: ${theme.fonts.lineHeights.base_m};
      outline: none;
      padding: 0 ${theme.space.lg};
      width: 100%;

      &::placeholder {
        color: ${theme.colors.foreground.subtle};
      }
    `;
  }}
`;

const InputMaskErrorStyle = css<InputMaskProps>`
  ${({ theme: t, hasError }) => {
    const theme = t as unknown as ThemeInterface;
    return hasError
      ? css`
          border-color: ${theme.colors.danger.emphasis};
        `
      : ``;
  }}
`;

const InputMaskDisableStyle = css<InputMaskProps>`
  ${({ disabled }) => {
    return disabled
      ? css`
          opacity: 0.4;
          pointer-events: none;
        `
      : ``;
  }}
`;

/**
 * Slots are injected by the user of this component, therefore,
 * we can't rely on them to set the correct size.
 */
const InjectedSlotStyle = css`
  ${({ theme: t, size }: InputMaskProps) => {
    const theme = t as unknown as ThemeInterface;

    return css`
      ${LeftSlot},
      ${RightSlot} {
        height: 100%;

        svg {
          width: ${size === 'medium' ? '2rem' : '2.4rem'};
          height: ${size === 'medium' ? '2rem' : '2.4rem'};
        }
      }

      ${LeftSlot} {
        padding: 0 ${theme.space.lg};
      }

      ${RightSlot} {
        padding: 0 ${theme.space.lg};
      }
    `;
  }}
`;

export const InputSlots = {
  Right: RightSlot,
  Left: LeftSlot,
};
