import { client } from 'helpers/ApiClient';

type Args = {
  enabled: boolean;
};

export const getSearchNullState = ({ enabled }: Args) => ({
  queryKey: ['null-state'],
  queryFn: (): Promise<Cameo.Endpoints.Search.NullState.GET.Response> =>
    client.get('/search/null-state'),
  // Query result is valid for 1hr
  staleTime: 60 * 60 * 1000,
  enabled,
});
