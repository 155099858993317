/**
 * HTTP Status Codes
 * https://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html
 */
export enum StatusCode {
  // Success 2xx
  OK = 200,
  Accepted = 202,
  NoContent = 204,

  // Redirection 3xx
  /**
   * Use 301 redirects only when the page has been changed permanently (e.g. rewriting routes)
   * If you are redirecting to the 404 page, please use TemporaryRedirect (307) instead.
   */
  MovedPermanently = 301,
  Found = 302,
  SeeOther = 303,
  TemporaryRedirect = 307,

  // Client Error 4xx
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  NotAcceptable = 406,
  Conflict = 409,
  Gone = 410,
  PreconditionFailed = 412,
  Unprocessable = 422,
  RateLimited = 429,

  // Server Error 5xx
  ServerError = 500,
  NotImplemented = 501,
}
