import analytics from 'analytics';

export const trackResendCode = (label: string) => {
  analytics.trackAction({
    eventName: 'Engage',
    eventDtl: {
      eventType: 'Click',
    },
    targetDtl: {
      targetPath: window.location.href,
    },
    objectDtl: {
      objectType: 'Text',
      objectName: 'ResendCode',
      objectLabel: label,
    },
  });
};

export const trackError = (message: string) => {
  analytics.trackSystem({
    eventName: 'View',
    eventClass: 'ComponentLoad',
    eventDtl: { message, type: 'View error' },
    objectDtl: {
      objectType: 'Text',
      objectName: 'OTPError',
      containerType: 'Form',
    },
    targetDtl: {
      targetPath: window.location.href,
    },
  });
};

export const trackLogin = () => {
  analytics.track({
    eventName: 'Login',
    data: {
      type: 'email',
      location: 'login',
      path: window.location.href,
    },
  });
};
