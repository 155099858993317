export const types = {
  start: 'cameo/payments/orderFormTokens/start',
  success: 'cameo/payments/orderFormTokens/success',
  fail: 'cameo/payments/orderFormTokens/fail',
};

export const success = (state, action) => ({
  ...state,
  paypalToken: action.result.paypalToken,
});

export const get = () => ({
  types: [types.start, types.success, types.fail],
  promise: (client) => client.get('/payments/formTokens'),
});
