import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { GetContentFeed, clearContentFeed } from './actions';
import { ContentFeedState } from './types';

export const CONTENT_FEED_INITIAL_STATE: ContentFeedState = Object.freeze({
  isLoading: false,
  posts: [],
  error: null,
});

const contentFeedReducer = reducerWithInitialState<ContentFeedState>(
  CONTENT_FEED_INITIAL_STATE
);

contentFeedReducer.case(GetContentFeed.started, (state) => {
  return {
    ...state,
    isLoading: true,
    error: null,
  };
});

contentFeedReducer.case(GetContentFeed.done, (state, action) => {
  const posts =
    state.posts.length === 0
      ? [action.result.post, ...action.result.nextPosts]
      : // The current post will already have been fetched by previous calls
        // so we don't need to include it after the initial request.
        [...state.posts, ...action.result.nextPosts];

  return {
    ...state,
    isLoading: false,
    posts,
  };
});

contentFeedReducer.case(GetContentFeed.failed, (state, payload) => ({
  ...state,
  isLoading: false,
  response: null,
  error: payload.error.message,
}));

contentFeedReducer.case(clearContentFeed, (state) => ({
  ...state,
  posts: [],
  isLoading: false,
  error: null,
}));

export default contentFeedReducer.build();
