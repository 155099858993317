import React from 'react';
import styled from 'styled-components';
import { Wrapper as Avatar } from 'design-system/Components/Avatar';
import { Link } from 'components/CameoLink/Link';
import { theme } from 'domains/web/theme/og';
import { animations, color, fonts, media, utils, sizing } from '../../style';

export const ITEM_HEIGHT = 65;
export const MAX_ITEMS_TO_DISPLAY = 10;
export const LIST_PADDING_BOTTOM = 23;
export const LIST_PADDING_TOP = 20;

export const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{
  isOpen: boolean;
}>`
  -webkit-overflow-scrolling: touch;
  background-color: ${theme.colors.background.level2};
  left: 0;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  overflow-y: hidden;
  pointer-events: ${({ isOpen }) => (isOpen ? 'inherit' : 'none')};
  position: absolute;
  top: ${sizing(42)};
  transform: ${({ isOpen }) =>
    isOpen ? `translate3d(0,${sizing(8)},0)` : 'translate3d(0,0,0)'};
  transition: opacity ${animations.transition.NORMAL}ms ease,
    transform ${animations.transition.NORMAL}ms ease;
  width: 100%;

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    border-radius: ${sizing(20)};
    left: auto;
    min-width: ${sizing(480)};
    right: 0;
    top: ${sizing(8)};
    z-index: 200; //TODO: GET A GLOBAL INDEX FUNCTION ON DESIGN SYSTEM
  }
`;

export const Image = styled.img`
  align-items: center;
  background-color: ${color.grays.GRAY60};
  border-radius: ${sizing(40)};
  color: ${color.solids.MUTED};
  display: flex;
  font-size: ${sizing(16)};
  height: ${sizing(40)};
  justify-content: center;
  margin-right: ${sizing(12)};
  object-fit: cover;
  transition: background-color ${animations.transition.FAST}ms ease;
  width: ${sizing(40)};
`;

export const AvatarContainer = styled.div`
  align-items: center;
  background-color: ${color.grays.GRAY60};
  border-radius: ${sizing(40)};
  color: ${color.solids.MUTED};
  display: flex;
  font-size: ${sizing(16)};
  height: ${sizing(40)};
  justify-content: center;
  margin-right: ${sizing(12)};
  object-fit: cover;
  width: ${sizing(40)};

  > .fa {
    font-size: ${sizing(16)} !important;
  }

  ${Avatar} {
    margin-left: 0;
  }
`;

export const Items = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['isFirstItemTitle', 'yPosition'].includes(prop),
})<{
  isFirstItemTitle: boolean;
  yposition: number;
}>`
  ${({ isFirstItemTitle, yposition }) => {
    const listPaddingTop = isFirstItemTitle ? 0 : LIST_PADDING_TOP;
    const listPaddingBottom = yposition;
    const ADDITIONAL_OFFSET = 56;

    return `
      height: auto;
      overflow-y: auto;
      padding-top: ${sizing(listPaddingTop)};
      padding-bottom: ${listPaddingBottom + ADDITIONAL_OFFSET}px;

      @media screen and (min-width: ${media.queries.DESKTOP}) {
          padding-bottom: ${sizing(LIST_PADDING_BOTTOM)};
          max-height: ${sizing(
            ITEM_HEIGHT * (MAX_ITEMS_TO_DISPLAY + 1) +
              LIST_PADDING_BOTTOM +
              listPaddingTop
          )}
          }
        `;
  }}

  ${utils.hideScrollbars}
}
`;

export const Item = styled(({ to, href, ...rest }) => {
  return to ? <Link to={to} href={href} {...rest} /> : <span {...rest} />;
}).withConfig({
  shouldForwardProp: (prop) => !['highlighted'].includes(prop as string),
})<{ highlighted: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: ${sizing(12)} ${sizing(16)};
  text-decoration: none;
  transition: background-color ${animations.transition.FAST}ms ease;

  &:hover {
    background-color: ${theme.colors.foreground.subtle};
    text-decoration: none;

    ${Image} {
      background-color: ${color.grays.GRAY70};
    }
  }

  ${({ highlighted }) =>
    highlighted
      ? `
      background-color: ${theme.colors.foreground.subtle};
      text-decoration: none;

      ${Image} {
        background-color: ${color.grays.GRAY70};
      }`
      : ''}

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    padding: ${sizing(12)} ${sizing(26)};
  }
`;

export const Name = styled.span`
  ${fonts.boldText['500']};
  color: ${color.solids.WHITE};
  margin: 0;
  display: block;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  > strong {
    ${fonts.boldText['500']};
  }

  > span {
    ${fonts.regularText['500']};
  }
`;

export const Profession = styled.span`
  ${fonts.regularText['200']};
  color: ${color.solids.MUTED};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - ${sizing(52)});
`;

export const Query = styled(Item)<{ disabled?: boolean }>`
  margin-top: ${sizing(1)};

  ${({ disabled }) => disabled && 'pointer-events: none;'}

  ${Name} {
    ${fonts.regularText['500']};
    color: ${color.grays.GRAY20};
  }
`;

export const EmptyState = styled(Item)`
  pointer-events: none;
`;

export const Heading = styled(Item)`
  padding: ${sizing(20)} ${sizing(16)} ${sizing(10)};
  pointer-events: none;

  ${Name} {
    ${fonts.boldText['500']};
    color: ${color.solids.WHITE};
  }

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    padding: ${sizing(20)} ${sizing(26)} ${sizing(10)};
  }
`;
