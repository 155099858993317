/* eslint-disable import/no-named-as-default */
/* eslint-disable id-length */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  actions as authActions,
  selectors as authSelectors,
} from 'state/modules/auth';
import { selectors as navbarSelectors } from 'state/modules/navbar';
import { actions as userActions } from 'state/modules/users';
import { selectors as clientSelectors } from 'state/modules/client';
import { selectors as routerSelectors } from 'state/modules/router';

import { NavColorContext } from 'containers/App/NavColorContext';
import { trackLogInClick } from 'components/NavBar/analytics';
import { useWindowScrollPercentage } from 'utils/hooks';
import { isSlideHeroTopOfLayout } from 'state/modules/browse/layouts/selectors';
import { AdminUser } from 'types/src/api/models/user';
import { Box } from 'domains/web/components';
import GlobalSiteSearch from 'components/GlobalSiteSearch';
import { BookingDraft } from 'domains/booking/BookingDraft/BookingDraft';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useIsProminentSearchBar } from 'utils/hooks/useIsProminentSearchBar';
import { NavDrawer } from './NavDrawer';
import NavButtons from './NavButtons';
import { Logo } from './Logo';
import { Wrapper } from './Styled';
import { UserAvatar } from './UserAvatar';

type OwnProps = {
  isFullWidth?: boolean;
  renderLoginButton?: () => React.ReactNode;
};

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  OwnProps;

export const Navigation = ({
  loggedInUser,
  isDesktop,
  isLargeDesktop,
  logout,
  history,
  getUserUnreadChannelCount,
  isBusinessPage,
  isCheckoutPage,
  isContentFeedPage,
  notifications,
  isSlideHeroEnabled,
  location,
  unreadChannelCount,
}: Props) => {
  const [isMobileDrawerExpanded, setIsMobileDrawerExpanded] = useState(false);
  const [isSearchOpenGlobal, setIsSearchOpenGlobal] = useState(false);
  const isBusinessSiteSearchEnabled = true;
  const isLandingPage = location.pathname.startsWith('/landing/');
  const isCollagePage = useSelector(routerSelectors.isCollagePage);

  useEffect(() => {
    if (loggedInUser && getUserUnreadChannelCount) {
      getUserUnreadChannelCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLean = (isCheckoutPage && isBusinessPage) || isLandingPage;
  const shouldShowSearch =
    !isLean &&
    (!isBusinessPage || isBusinessSiteSearchEnabled) &&
    !isCollagePage;
  const navColor = useContext(NavColorContext);

  const handleDrawerMenuToggle = useCallback(() => {
    setIsMobileDrawerExpanded(!isMobileDrawerExpanded);
  }, [isMobileDrawerExpanded]);

  const shouldDisplayKidsLink = true;

  // TODO Optimizely to eppo. Check for dead code removal. Only about-link_business-link variation was enabled
  const shouldDisplayMerchLink = false;

  const { scrollPercentage } = useWindowScrollPercentage({
    enabled: navColor.gradientActive || isSlideHeroEnabled,
    step: 20,
    heightCap: 100,
  });

  const handleLogin = () => {
    trackLogInClick();
    history.push(`/login?redirect=${location.pathname}${location.search}`);
  };

  const shouldUseProminentSearchBar = useIsProminentSearchBar();
  const isAnyDesktop = isDesktop || isLargeDesktop;
  const isFixedSearchEnabled = shouldUseProminentSearchBar;
  const [isMobileSearchOpen, setIsMobileSearchOpen] = React.useState(false);

  const handleOnClickSearch = () => {
    setIsMobileSearchOpen((state) => !state);
  };

  const hideSearch = () => {
    setIsMobileSearchOpen(false);
  };

  const handleOnGlobalSiteSearchIsOpenChange = (state) => {
    if (!state) setIsMobileSearchOpen(false);
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (isMobileSearchOpen) {
      window.addEventListener('scroll', hideSearch);
    } else {
      window.removeEventListener('scroll', hideSearch);
    }
  }, [isMobileSearchOpen]);

  if (isContentFeedPage) return null;

  return (
    <>
      <Wrapper
        isLoggedIn={!!loggedInUser}
        isMobile={!isAnyDesktop}
        $scrollPercentage={scrollPercentage}
        $isTransparent={navColor.gradientActive || undefined}
        className="cameoNav"
      >
        <Box
          boxSizing="content-box"
          display="flex"
          flexDirection="column"
          maxWidth="content"
          mx="auto"
          width="100%"
        >
          <Box
            display={isSearchOpenGlobal ? 'none' : 'flex'}
            justifyContent="space-between"
            py={3}
            pb={{ _: isFixedSearchEnabled ? 0 : 3, lg: 3 }}
            width="100%"
          >
            <Box
              alignItems="center"
              display="inline-flex"
              flex={{ sm: 8 }}
              ml={{ _: 0, lg: 4 }}
              mr={{ _: 0, sm: 'auto' }}
              order={{ _: 1, lg: 2 }}
              minWidth="fit-content"
            >
              <NavButtons
                isLean={isLean}
                handleDrawerMenuToggle={handleDrawerMenuToggle}
                shouldDisplayKidsLink={shouldDisplayKidsLink}
                shouldDisplayMerchLink={shouldDisplayMerchLink}
                shouldRenderLogo={false}
              />
            </Box>
            <Box
              alignItems="center"
              display="inline-flex"
              order={{ _: 2, lg: 1 }}
              flex={{ sm: 1 }}
              mr={{ _: 'auto', sm: 0 }}
            >
              <Logo />
            </Box>
            <Box
              display="inline-flex"
              alignItems="center"
              order={3}
              flex={{ sm: 8 }}
            >
              <Box
                align-items="center"
                display="flex"
                gap={4}
                justifyContent="flex-end"
                position="relative"
                width="100%"
              >
                {shouldShowSearch && (
                  <Box
                    data-testid="inline-search"
                    display={{
                      _: isMobileSearchOpen ? 'flex' : 'none',
                      lg: 'flex',
                    }}
                    backgroundColor={
                      navColor.gradientActive
                        ? 'transparent'
                        : 'background.level0'
                    }
                    flex={1}
                    justify-content="flex-end"
                    left={0}
                    pb={{ _: 3, lg: 0 }}
                    position={{ _: 'fixed', lg: 'static' }}
                    px={{ _: 5, lg: 0 }}
                    right={0}
                    top={60}
                    // Don't do this at home
                    css={`
                      [data-testid='SearchResults'] {
                        top: 4.4rem;
                      }
                    `}
                  >
                    <GlobalSiteSearch
                      forBusiness={isBusinessPage}
                      isDesktop={isAnyDesktop}
                      onIsOpenStateChange={handleOnGlobalSiteSearchIsOpenChange}
                    />
                  </Box>
                )}
                <BookingDraft />
                {!isFixedSearchEnabled && (
                  <Box
                    display={{ _: 'inline-flex', lg: 'none' }}
                    alignItems="center"
                    role="button"
                    onClick={handleOnClickSearch}
                  >
                    <MagnifyingGlassIcon width={24} height={24} />
                  </Box>
                )}
                <UserAvatar
                  isAnyDesktop={isAnyDesktop}
                  loggedInUser={loggedInUser}
                  logout={logout}
                  notifications={notifications}
                  unreadChannelCount={unreadChannelCount}
                />
              </Box>
            </Box>
          </Box>
          {shouldShowSearch && (
            <Box
              data-testid="bottom-search"
              display={{
                _: isFixedSearchEnabled ? 'flex' : 'none',
                lg: 'none',
              }}
              backgroundColor={
                navColor.gradientActive ? 'transparent' : 'background.level1'
              }
              flex={1}
              marginX={-5}
              paddingX={5}
              paddingY={3}
              justify-content="flex-end"
            >
              <GlobalSiteSearch
                forBusiness={isBusinessPage}
                isDesktop={isAnyDesktop}
                setIsSearchOpenGlobal={setIsSearchOpenGlobal}
              />
            </Box>
          )}
        </Box>
      </Wrapper>
      <NavDrawer
        handleDrawerMenuToggle={handleDrawerMenuToggle}
        handleLogin={handleLogin}
        isUserLoggedIn={Boolean(loggedInUser)}
        isVisible={isMobileDrawerExpanded}
        shouldDisplayMerchLink={shouldDisplayMerchLink}
        shouldDisplayKidsLink={shouldDisplayKidsLink}
      />
    </>
  );
};

const mapDispatchToProps = {
  logout: authActions.logout,
  startAuthFlow: authActions.authFlow.startAuthFlow,
  getUserUnreadChannelCount: userActions.getUserUnreadChannelCount,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isBusinessPage: routerSelectors.isBusinessPage(state),
    isCheckoutPage: routerSelectors.isCheckoutPage(state),
    isContentFeedPage: routerSelectors.isContentFeedPage(state),
    notifications: navbarSelectors.getNotifications(state),
    loggedInUser: authSelectors.getLoggedInUser(state) as AdminUser,
    isDesktop: clientSelectors.getIsDesktop(state),
    isLargeDesktop: clientSelectors.getIsLargeDesktop(state),
    unreadChannelCount: navbarSelectors.getUnreadChannelCount(state),
    isSlideHeroEnabled: isSlideHeroTopOfLayout(state),
  };
};

const createConnect = connect(mapStateToProps, mapDispatchToProps);
const NavBar = createConnect(withRouter(Navigation));

export default NavBar;
