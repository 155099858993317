import Cameo from 'types';
import { RehydratedLicenseGrant } from 'types/src/api/models/license';

export type LoadMyCameosResponse = Omit<
  Cameo.Endpoints.Order.MyCameos.GET.Response,
  'licenses'
> & {
  licenses: Cameo.Models.License.SerializedLicenseGrant[];
};

export type LoadMyCameosState = {
  loading: boolean;
  error: string;
  offset: number;
  myCameos: Omit<LoadMyCameosResponse, 'licenses'> & {
    licenses: RehydratedLicenseGrant[];
  };
};

export type OrdersPageState = LoadMyCameosState;

export type OrderCardProps = {
  order?: Cameo.Models.Order.FanOrder & { collageIds?: string[] };
  talentUser: Cameo.Models.User.PublicUser;
  status: 'purchased' | 'received';
  license?: Cameo.Models.License.RehydratedLicenseGrant;
};

export const INITIAL_STATE: LoadMyCameosState = Object.freeze({
  error: null,
  loading: false,
  offset: 0,
  myCameos: {
    gifts: [],
    orders: [],
    users: [],
    bookedByUsers: [],
    licenses: [],
  },
});
