import actionCreatorFactory from 'typescript-fsa';
import { ORDER } from 'state/modules/orders';
import * as get from './get';
import * as orderFormTokens from './orderFormTokens';
import * as selectors from './selectors';

const initialState = {};

export const LOGOUT_ACTION_TYPE = 'auth/LOGOUT_SUCCESS';
const CLEAR_EXISTING_PAYMENT_INFO = 'CLEAR_EXISTING_PAYMENT_INFO';
const actionCreator = actionCreatorFactory();
export const clearExistingPaymentInfo = actionCreator(
  CLEAR_EXISTING_PAYMENT_INFO
);

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case get.types.success:
      return {
        ...state,
        ...get.success(state, action),
      };
    case LOGOUT_ACTION_TYPE:
      return initialState;
    case orderFormTokens.types.success:
      return {
        ...state,
        ...orderFormTokens.success(state, action),
      };
    case ORDER.CREATE_SUCCESS:
      return {
        ...state,
        orderPendingPayment: {
          id: action.result.order._id,
          authToken: action.result.order.authToken,
          price: action.result.order.price,
        },
      };
    case CLEAR_EXISTING_PAYMENT_INFO:
      return {
        ...state,
        orderPendingPayment: undefined,
      };
    default:
      return state;
  }
}

const actions = {
  getBalanceForUser: get.service,
  getOrderFormTokens: orderFormTokens.get,
  clearExistingPaymentInfo,
};

export { actions, selectors };
