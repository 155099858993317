import * as React from 'react';
import type { CustomIconProps } from './types';

function ToiletIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M2.99961 0.799988C2.59961 0.799988 2.19961 0.899988 1.99961 1.19999C1.79961 1.49999 1.59961 1.89999 1.59961 2.19999V7.99999V8.69999C1.59961 10.7 2.79961 12.4 4.49961 13.4V15.1H11.6996V13.4C13.3996 12.3 14.5996 10.7 14.5996 8.69999V7.99999H13.8996H7.29961V2.29999C7.29961 1.89999 7.19961 1.49999 6.89961 1.29999C6.59961 1.09999 6.19961 0.899988 5.89961 0.899988H2.99961V0.799988Z"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M7.3 8H1.5"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default ToiletIcon;
