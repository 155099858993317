import * as React from 'react';
import type { CustomIconProps } from './types';

function FireIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M14.4515 9.00746C14.8512 8.56649 15.0935 8.068 15.2086 7.49921C15.2631 7.24357 15.2812 6.97515 15.3176 6.71312C15.3297 6.62365 15.3357 6.52779 15.366 6.4511C15.4993 6.13155 15.8627 6.07403 16.0989 6.34884C16.3109 6.60448 16.5228 6.86651 16.7045 7.14132C17.7644 8.72626 18.4912 10.4646 18.8365 12.3755C19.0605 13.6281 19.0727 14.8807 18.7577 16.1206C18.4791 17.2262 17.9522 18.1848 17.2072 19.0156C16.5047 19.7953 15.6749 20.3577 14.724 20.7284C14.6513 20.754 14.5363 20.754 14.4757 20.7092C14.4273 20.6709 14.397 20.5495 14.4151 20.4728C14.827 18.6833 14.4273 17.0409 13.6036 15.4751C13.2402 14.7849 12.792 14.1585 12.3135 13.5514C12.0955 13.283 11.9077 13.2766 11.6897 13.545C10.8357 14.5803 10.1574 15.7115 9.73945 17.0217C9.36394 18.1912 9.31549 19.3735 9.60621 20.5686C9.61832 20.607 9.62438 20.6517 9.63649 20.6901C9.68494 20.9137 9.55776 21.048 9.35183 20.984C9.0914 20.901 8.82491 20.8307 8.57659 20.7156C8.14051 20.5175 7.70444 20.3258 7.29259 20.0829C6.45678 19.578 5.88746 18.7984 5.48773 17.8908C4.99714 16.7916 4.91235 15.6221 5.07588 14.427C5.26969 13.0274 5.7845 11.7683 6.54157 10.6052C7.11695 9.72325 7.68627 8.82852 8.23742 7.9274C8.75829 7.07102 9.17013 6.15072 9.40028 5.15374C9.52142 4.6169 9.57593 4.07367 9.56381 3.52406C9.55776 3.35789 9.58804 3.20451 9.72128 3.09586C9.89087 2.96165 10.0665 2.98083 10.2482 3.07669C10.975 3.46654 11.6109 3.9842 12.1681 4.60412C13.2583 5.8184 14.0396 7.2244 14.4212 8.85408C14.4212 8.9116 14.4333 8.94995 14.4515 9.00746Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default FireIcon;
