import { createStore as _createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { getGlobalVariable } from 'utils/getGlobalVariable';
import createReducer from './modules/reducer';
import { clientMiddleware, analyticsMiddleware } from './middleware';

export function createStore(history, client, data) {
  // Sync dispatched route actions to the history
  const reduxRouterMiddleware = routerMiddleware(history);

  const middleware = [
    // If the action is a thunk, pass it the redux api
    // otherwise proceed through the middleware chain
    ({ dispatch, getState }) =>
      (next) =>
      (action) =>
        typeof action === 'function'
          ? action(dispatch, getState)
          : next(action),
    analyticsMiddleware(),
    clientMiddleware(client),
    reduxRouterMiddleware,
  ];

  const composeEnhancers =
    typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (noop) => noop;

  let finalCreateStore;
  if (
    getGlobalVariable('__WEBPACK_DEVELOPMENT_MODE__') &&
    getGlobalVariable('__WEBPACK_IS_CLIENT__')
  ) {
    finalCreateStore = compose(
      applyMiddleware(...middleware),
      composeEnhancers
      // persistState(window.location.href.match(/[?&]debug_session=([^&]+)\b/))
    )(_createStore);
  } else {
    finalCreateStore = applyMiddleware(...middleware)(_createStore);
  }

  const store = finalCreateStore(createReducer(history), data);

  if (
    getGlobalVariable('__WEBPACK_DEVELOPMENT_MODE__') &&
    getGlobalVariable('module.hot')
  ) {
    module.hot.accept('./modules/reducer', () => {
      store.replaceReducer(createReducer(history));
    });
  }

  return store;
}
