import React, { useState } from 'react';
import { EventBaseFull } from '@cameo/events';
import { format } from 'date-fns';
import styled from 'styled-components';
import { Text } from 'domains/web/components';
import { ColorMap } from 'components/CameoDevTools/CameoEventsExplorer/Styled';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  cursor: pointer;
  display: flex;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  overflow: auto;
`;

export const ListItem = styled.li`
  display: flex;
  width: 100%;
`;

export const Label = styled.span`
  display: flex;
  margin-right: 16px;
`;

export const Value = styled.span`
  word-break: break-word;
  display: flex;
`;

type Props = {
  event: EventBaseFull;
  hideEmptyValues?: boolean;
};

export const EventItem = ({ event, hideEmptyValues }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const { eventName, eventDtl, objectDtl } =
    (event as any)?.event_properties || {};

  return (
    <Root>
      <Header onClick={toggleExpanded}>
        <Text
          variant="base_l"
          color={
            ColorMap[(event as any).event_type] ||
            ColorMap[(event as any).eventName || 'white']
          }
        >
          {event._userTs
            ? format(new Date(event._userTs), 'HH:mm:ss')
            : 'Invalid'}
          {' - '}
          {event.eventName}
          {' - '}
          {(event as any).event_type ?? 'Unknown'}
          {' - '}
          {eventName ?? 'NULL'}
          {' - '}
          {eventDtl?.eventType ?? 'NULL'}
          {' - '}
          {objectDtl?.objectType ?? 'NULL'}
        </Text>
      </Header>
      {!!expanded && (
        <List>
          {Object.keys(event)
            .filter(
              (key) => !hideEmptyValues || (hideEmptyValues && event[key])
            )
            .map((key) => {
              return (
                <ListItem key={key}>
                  <Label>
                    <Text variant="base_s" color="foreground.muted">
                      {key}
                      {':'}
                    </Text>
                  </Label>
                  <Value>
                    <Text variant="base_m" color="foreground.muted">
                      {JSON.stringify(event[key]) || '""'}
                    </Text>
                  </Value>
                </ListItem>
              );
            })}
        </List>
      )}
    </Root>
  );
};
