import { reducerWithInitialState } from 'typescript-fsa-reducers';
import requestPasswordReset from './actions';
import { RequestPasswordResetState, INITIAL_STATE } from './types';

const reducer = reducerWithInitialState<RequestPasswordResetState>(
  INITIAL_STATE
)
  .case(requestPasswordReset.async.started, (state) => ({
    ...state,
    requestingPasswordReset: true,
    requestPasswordResetInfo: null,
  }))
  .case(requestPasswordReset.async.done, (state) => ({
    ...state,
    requestingPasswordReset: false,
    requestPasswordResetError: null,
    requestPasswordResetInfo:
      'Check your email for reset password instructions',
  }))
  .case(requestPasswordReset.async.failed, (state, payload) => ({
    ...state,
    requestingPasswordReset: false,
    requestPasswordResetError: payload.error.message ?? 'Something went wrong.',
  }));

export default reducer.build();
