import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { Credentials } from 'types/src/utils/enums';
import { AuthService } from 'services';
import authActionCreatorFactory from '../utils/authActionCreatorFactory';
import { ActionError, SupportedLoginMethods } from '../types';
import { LoginWithParams, LoginWithResponse, LoginWithService } from './types';

const asyncActionCreator = authActionCreatorFactory<LoginWithResponse>();

export const action = asyncActionCreator<
  LoginWithParams,
  LoginWithResponse,
  ActionError
>('LOGIN_WITH', ({ connection, credentials }) =>
  AuthService.loginWith[connection](credentials)
);

/**
 * Wraps supported login methods in thunkToAction
 * so the authenticated user is injected into redux state
 * @example
 *  import { actions as Auth } from 'state/modules/auth';
 *  import { LoginMethod } from 'types/src/utils/enums';
 *  Auth.loginWith[LoginMethod.DATABASE]({ email, password });
 *  // or
 *  Auth.loginWith.email({ email, password });
 */
export const service: LoginWithService = SupportedLoginMethods.reduce(
  (all, method) => ({
    ...all,
    [method]: thunkToAction((credentials: Credentials[typeof method]) =>
      action({ connection: method, credentials })
    ),
  }),
  {} as LoginWithService
);

export default action;
