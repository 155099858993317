import actionCreatorFactory from 'typescript-fsa';

export const actionCreator = actionCreatorFactory('cameo/clientInfo/eppoState');

/**
 * Set if eppo is initialized
 */
export const setEppoInitialized = actionCreator<boolean>(
  'cameo/clientInfo/eppoState/setEppoInitialized'
);
