import React, { useState, useEffect, useCallback } from 'react';
import { ArrowRight } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Text } from 'domains/web/components';
import { useCallbackWithDelay } from 'utils/hooks';
import { NavBarCategoryItem } from 'types/src/api/endpoints/explore/categories';
import {
  Wrapper,
  L1Column,
  L2Column,
  CategoryListItem,
  CategoryListItemLink,
  ViewAllLink,
} from './Styled';
import { trackItemHover, trackCategoryClick } from '../analytics';

type CategoryFlyoutProps = {
  categories: NavBarCategoryItem[];
  onCategoryClick: () => void;
};

export const CategoryFlyout = ({
  categories = [],
  onCategoryClick,
}: CategoryFlyoutProps) => {
  const { t } = useTranslation('NavBar');
  const [activeCategory, setActiveCategory] = useState<NavBarCategoryItem>();
  const { delayCallback, cancelDelayedCallback } = useCallbackWithDelay({});

  // set activeCategory if categories change
  useEffect(() => {
    setActiveCategory(categories[0]);
  }, [categories]);

  const handleL1CategoryOnMouseEnter = useCallback(
    (category) => {
      setActiveCategory(category);
      delayCallback(() => {
        trackItemHover(`item-categoryflyout-${category.slug}`);
      });
    },
    [delayCallback]
  );

  const handleCategoryClick = useCallback(
    (event, path, slug) => {
      event.stopPropagation();
      trackCategoryClick(path, `item-categoryflyout-${slug}`);
      onCategoryClick();
    },
    [onCategoryClick]
  );

  return categories?.length ? (
    <Wrapper data-testid="CategoryFlyout">
      <L1Column>
        {categories.map((category) => {
          return (
            <CategoryListItem
              active={category.slug === activeCategory?.slug}
              key={category.slug}
              onMouseEnter={() => handleL1CategoryOnMouseEnter(category)}
              onMouseLeave={cancelDelayedCallback}
              title={category.name}
            >
              <CategoryListItemLink
                to={`/browse/${category.materializedPath}`}
                onClick={(e) =>
                  handleCategoryClick(
                    e,
                    `/browse/${category.materializedPath}`,
                    category.slug
                  )
                }
              >
                <Text color="foreground.default">{category.name}</Text>
              </CategoryListItemLink>
            </CategoryListItem>
          );
        })}
      </L1Column>
      <L2Column>
        {activeCategory?.children?.map((category) => (
          <CategoryListItem key={category.slug} title={category.name}>
            <CategoryListItemLink
              to={`/browse/${category.materializedPath}`}
              onClick={(e) =>
                handleCategoryClick(
                  e,
                  `/browse/${category.materializedPath}`,
                  category.slug
                )
              }
            >
              <Text color="foreground.default">{category.name}</Text>
            </CategoryListItemLink>
          </CategoryListItem>
        ))}
        {activeCategory && (
          <li>
            <ViewAllLink
              to={`/browse/${activeCategory.materializedPath}`}
              onClick={(e) =>
                handleCategoryClick(
                  e,
                  `/browse/${activeCategory.materializedPath}`,
                  'l2-view-all'
                )
              }
            >
              <Text as="h3" variant="title_s" fontWeight="bold" color="white">
                {t('viewAll', 'View all')}
              </Text>
              <ArrowRight size={16} strokeWidth={4} />
            </ViewAllLink>
          </li>
        )}
      </L2Column>
    </Wrapper>
  ) : null;
};
