import actionCreatorFactory from 'typescript-fsa';
import { getCurrencyRateFromMemory } from 'services/getCurrencyRate';

const actionCreator = actionCreatorFactory('cameo/clientInfo/i18n');

interface LocaleUpdateProps {
  userId?: string;
  currency?: string;
  language?: string;
  region?: string;
}

interface LocaleReturnProps {
  currency?: string;
  language?: string;
  region?: string;
}

export const UpdateUserPreferences = actionCreator.async<
  LocaleUpdateProps,
  LocaleReturnProps,
  any
>('cameo/i18n/update-preferences');

export function updateUserPreferences({
  userId,
  currency,
  language,
  region,
}: LocaleUpdateProps) {
  return {
    types: [
      UpdateUserPreferences.started.type,
      UpdateUserPreferences.done.type,
      UpdateUserPreferences.failed.type,
    ],
    promise: (client) => {
      const data = {
        currency,
        language,
        region,
      };
      if (userId) {
        return client
          .patch(`/user/${userId}/preferences`, { data })
          .then(() => data)
          .catch(console.error);
      }
      return Promise.resolve(data);
    },
  };
}

export const UpdateConversionRateForCurrency = actionCreator.async<
  string,
  number,
  any
>('cameo/i18n/update-currencyRate');

export function updateConversionRateForCurrency(currencyCode: string) {
  return {
    types: [
      UpdateConversionRateForCurrency.started.type,
      UpdateConversionRateForCurrency.done.type,
      UpdateConversionRateForCurrency.failed.type,
    ],
    promise: () => getCurrencyRateFromMemory(currencyCode),
  };
}

export const updateCurrency = actionCreator<string>(
  'cameo/i18n/update-currencyCode'
);

export const updateCurrencyRate = actionCreator<number>(
  'cameo/i18n/update-currencyRate'
);

export const updateLanguage = actionCreator<string>(
  'cameo/i18n/update-languageCode'
);

export const updateRegion = actionCreator<string>(
  'cameo/i18n/update-countryCode'
);
