import React, { useCallback } from 'react';
import { LazyAvatar } from 'design-system/Components/Avatar';
import { StarCardUserFields } from 'types/src/api/models/user';
import { Link } from 'components/CameoLink/Link';
import { useSearchNullStateOverrides } from 'utils/c4b/consumerOverrides/SearchNullStateOverridesProvider/SearchNullStateOverridesProvider';
import { AvatarsContainer, Name, RecentlyViewedUserContainer } from './Styled';
import { trackRecentlyViewedUserClickEvent } from './analytics';
import { Title } from '../Styled';

export interface RecentlyViewedUsersProps {
  title: string;
  users: StarCardUserFields[];
  closeSearchResultsDropdown?: () => void;
}

type RecentlyViewedUserProps = {
  imageUrlKey: string;
  name: string;
  username: string;
  index: number;
  closeSearchResultsDropdown?: () => void;
};

const RecentlyViewedUser = ({
  name,
  imageUrlKey,
  username,
  index,
  closeSearchResultsDropdown,
}: RecentlyViewedUserProps) => {
  const { generateProfilePageUrl } = useSearchNullStateOverrides();

  const onClick = useCallback(() => {
    if (closeSearchResultsDropdown) {
      closeSearchResultsDropdown();
    }
    trackRecentlyViewedUserClickEvent(name, username, index);
  }, [name, username, index, closeSearchResultsDropdown]);

  const linkUrl = generateProfilePageUrl
    ? generateProfilePageUrl(username)
    : `/${username}`;

  return (
    <RecentlyViewedUserContainer>
      <Link to={linkUrl} onClick={onClick}>
        <LazyAvatar
          imageKey={imageUrlKey}
          name={name}
          size={72}
          backgroundColor="none"
        />
        <Name title={name}>{name}</Name>
      </Link>
    </RecentlyViewedUserContainer>
  );
};

export const RecentlyViewedUsers = ({
  users = [],
  title,
  closeSearchResultsDropdown,
}: RecentlyViewedUsersProps) => {
  if (users.length === 0) {
    return null;
  }

  return (
    <>
      <Title>{title}</Title>
      <AvatarsContainer>
        {users.map((user, index) => {
          const { _id, name, imageUrlKey, username } = user;

          return (
            <RecentlyViewedUser
              imageUrlKey={imageUrlKey}
              index={index}
              key={_id}
              name={name}
              username={username}
              closeSearchResultsDropdown={closeSearchResultsDropdown}
            />
          );
        })}
      </AvatarsContainer>
    </>
  );
};
