import React, { useState, useCallback } from 'react';
import { ChevronDown, ChevronUp, Menu } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Text } from 'domains/web/components';
import { MenuItems } from 'state/modules/navbar/types';
import { useCallbackWithDelay } from 'utils/hooks';
import {
  DesktopButtons,
  MenuButton,
  MobileButtons,
  NavButton,
  Wrapper,
  FlyoutButtonContainer,
  NavButtonContainer,
} from './Styled';
import { Logo } from '../Logo';
import { CategoryFlyout } from '../CategoryFlyout';
import {
  trackItemClick,
  trackItemHover,
  trackItemClickWithoutTargetDtl,
} from '../analytics';

const HOVER_CALLBACK_DELAY = 200;

type Props = {
  handleDrawerMenuToggle: () => void;
  shouldDisplayMerchLink: boolean;
  shouldDisplayKidsLink: boolean;
  isLean: boolean;
  shouldRenderLogo?: boolean;
};

const CategoriesButtonWithFlyout = () => {
  const { t } = useTranslation('NavBar');
  const [isHovered, setIsHovered] = useState(false);
  const { delayCallback, cancelDelayedCallback } = useCallbackWithDelay({
    delay: HOVER_CALLBACK_DELAY,
  });

  const handleCategoriesButtonOnMouseEnter = useCallback(() => {
    setIsHovered(true);
    delayCallback(() => {
      trackItemHover(`item-navbar-${MenuItems.Categories}`);
    });
  }, [delayCallback]);

  const handleCategoriesButtonOnMouseLeave = useCallback(() => {
    setIsHovered(false);
    cancelDelayedCallback();
  }, [cancelDelayedCallback]);

  return (
    <FlyoutButtonContainer
      isFlyoutVisible={isHovered}
      onClick={() =>
        trackItemClick(
          'All-categories',
          '/categories',
          `item-navbar-${MenuItems.Categories}`
        )
      }
      onMouseEnter={handleCategoriesButtonOnMouseEnter}
      onMouseLeave={handleCategoriesButtonOnMouseLeave}
    >
      <NavButton to="/browse">
        <Text variant="base_m">{t('categories', 'Categories')}</Text>
        {isHovered ? (
          <ChevronUp size={16} style={{ marginLeft: 8 }} />
        ) : (
          <ChevronDown size={16} style={{ marginLeft: 8 }} />
        )}
      </NavButton>
      <CategoryFlyout onCategoryClick={() => setIsHovered(false)} />
    </FlyoutButtonContainer>
  );
};

const EnrollAsTalentButton = () => {
  const { t } = useTranslation('NavBar');
  const { delayCallback, cancelDelayedCallback } = useCallbackWithDelay({
    delay: HOVER_CALLBACK_DELAY,
  });

  const handleOnMouseEnter = useCallback(() => {
    delayCallback(() => {
      trackItemHover(`item-navbar-${MenuItems.Enroll}`);
    });
  }, [delayCallback]);

  const handleOnMouseLeave = useCallback(() => {
    cancelDelayedCallback();
  }, [cancelDelayedCallback]);

  return (
    <NavButton
      to="/enroll"
      onClick={() =>
        trackItemClick(
          'enroll-as-talent',
          '/enroll',
          `item-navbar-${MenuItems.Enroll}`
        )
      }
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <Text>{t('enroll-as-talent', 'Join as talent')}</Text>
    </NavButton>
  );
};

const NavButtons = ({
  handleDrawerMenuToggle,
  shouldDisplayMerchLink,
  shouldDisplayKidsLink,
  isLean,
  shouldRenderLogo = true,
}: Props) => {
  const { t } = useTranslation('NavBar');
  const { delayCallback, cancelDelayedCallback } = useCallbackWithDelay({
    delay: HOVER_CALLBACK_DELAY,
  });

  const handleDrawerMenuButtonClick = useCallback(() => {
    trackItemClickWithoutTargetDtl(MenuItems.DrawerMenuOpen);
    handleDrawerMenuToggle();
  }, [handleDrawerMenuToggle]);

  return (
    <Wrapper>
      {!isLean && (
        <MobileButtons>
          <MenuButton
            data-testid="menu-button"
            onClick={handleDrawerMenuButtonClick}
          >
            <Menu size={24} />
          </MenuButton>
        </MobileButtons>
      )}
      {shouldRenderLogo && <Logo />}
      {!isLean && (
        <DesktopButtons>
          <CategoriesButtonWithFlyout />
          {shouldDisplayMerchLink && (
            <NavButtonContainer>
              <NavButton
                to=""
                href="https://merch.cameo.com"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() =>
                  delayCallback(() => {
                    trackItemHover(`item-navbar-${MenuItems.Merch}`);
                  })
                }
                onMouseLeave={cancelDelayedCallback}
                onClick={() =>
                  trackItemClick(
                    'Merch',
                    'https://merch.cameo.com',
                    `item-navbar-${MenuItems.Merch}`
                  )
                }
              >
                <Text>{t('merch', 'Merch')}</Text>
              </NavButton>
            </NavButtonContainer>
          )}
          {shouldDisplayKidsLink && (
            <NavButtonContainer>
              <NavButton
                to="/kids"
                onMouseEnter={() =>
                  delayCallback(() => {
                    trackItemHover(`item-navbar-${MenuItems.Kids}`);
                  })
                }
                onMouseLeave={cancelDelayedCallback}
                onClick={() =>
                  trackItemClick(
                    'Kids',
                    '/kids',
                    `item-navbar-${MenuItems.Kids}`
                  )
                }
              >
                <Text>{t('forKids', 'For kids')}</Text>
              </NavButton>
            </NavButtonContainer>
          )}
          <NavButtonContainer>
            <NavButton
              to="/business"
              onMouseEnter={() =>
                delayCallback(() => {
                  trackItemHover(`item-navbar-${MenuItems.Business}`);
                })
              }
              onMouseLeave={cancelDelayedCallback}
              onClick={() =>
                trackItemClick(
                  'Business',
                  '/business',
                  `item-navbar-${MenuItems.Business}`
                )
              }
            >
              <Text>{t('forBusiness', 'For business')}</Text>
            </NavButton>
          </NavButtonContainer>
          <NavButtonContainer>
            <NavButton
              to="/about"
              onMouseEnter={() =>
                delayCallback(() => {
                  trackItemHover(`item-navbar-${MenuItems.About}`);
                })
              }
              onMouseLeave={cancelDelayedCallback}
              onClick={() =>
                trackItemClick(
                  'About',
                  '/about',
                  `item-navbar-${MenuItems.About}`
                )
              }
            >
              <Text>{t('howItWorks', 'How it works')}</Text>
            </NavButton>
          </NavButtonContainer>
          <EnrollAsTalentButton />
        </DesktopButtons>
      )}
    </Wrapper>
  );
};

export default NavButtons;
