import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import inRange from 'lodash/inRange';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import { SuggestionsResponse } from 'services/search';
import { suggest, suggestForBusiness } from 'services';
import { createGlobalStyle } from 'styled-components';
import SearchBar from 'design-system/Components/SearchBar';
import { useGetQueryParams } from 'utils/hooks/useGetQueryParams';
import SearchResults from 'design-system/Components/SearchResults';
import {
  ItemGroup,
  SearchData,
  SearchItem,
} from 'design-system/Components/SearchResults/types';
import {
  isAlgoliaEnabled,
  isC4BAlgoliaEnabled,
  isTalentNeuralSearchEnabled,
} from 'utils/algoliaHelpers';
import algoliaAnalytics from 'analytics/clients/algoliaAnalytics';
import { breakpoints } from 'domains/web/theme/breakpoints';
import { generateQueryId } from './utils';
import {
  createItemSelectionTracker,
  createQuerySelectionTracker,
  trackQueryChange,
  trackQueryResult,
  trackSearchBarClick,
} from './analytics';
import { Container, Link, Wrapper } from './Styled';

type Props = {
  forBusiness?: boolean;
  isDesktop?: boolean;
  onIsOpenStateChange?: (state: boolean) => void;
  setIsSearchOpenGlobal?: (state: boolean) => void;
};

type RunSuggesterResponse = SuggestionsResponse & {
  query: string;
  error?: any;
};

const runSuggester = async (
  query: string,
  ltrEnabled: boolean,
  queryId: number,
  forBusiness?: boolean,
  algoliaEnabled?: boolean,
  c4bAlgoliaEnabled?: boolean,
  talentNeuralSearchEnabled?: boolean
): Promise<RunSuggesterResponse> => {
  try {
    const formattedQueryString = query.toLowerCase().trim();

    const results = forBusiness
      ? await suggestForBusiness(
          {
            query: formattedQueryString,
            queryId: queryId.toString(),
          },
          c4bAlgoliaEnabled
        )
      : await suggest(
          {
            query: formattedQueryString,
            queryId: queryId.toString(),
            ...(talentNeuralSearchEnabled && {
              variation: 'talent_neural_search',
            }),
          },
          algoliaEnabled
        );

    trackQueryResult({ query, queryId, results, ltrEnabled });

    return { ...results, query };
  } catch (searchError) {
    return {
      error: searchError,
      results: [],
      query,
    };
  }
};

const DisableBodyScroll = createGlobalStyle`
  body {
    overflow: hidden;

    @media screen and (min-width: ${breakpoints.lg}) {
      overflow: auto;
    }
  }
`;

export const GlobalSiteSearch = ({
  forBusiness,
  isDesktop,
  onIsOpenStateChange = noop,
  setIsSearchOpenGlobal,
}: Props) => {
  const getQueryParams = useGetQueryParams();
  const [query, setQuery] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [suggestions, setSuggestions] = useState<SearchData>([]);
  const [algorithm, setAlgorithm] = useState('');
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();
  const lastRequestedQuery = useRef('');
  const queryIdRef = useRef(generateQueryId());
  const minQueryLength = 1;
  const maxQueryLength = 100;

  let data: SearchData = [];

  const container = useRef(null);

  // in order to keep isSearchOpen state on sync with NavBar component
  // _setIsSearchOpen should never be called directly. Always call setIsSearchOpen
  const [isSearchOpen, _setIsSearchOpen] = useState(false);
  // isSearchOpenRef is only needed to access isSearchOpen inside event
  // listener function handleClickOutside. For every other purpose,
  // isSearchOpen should be used.
  // more info in the following link:
  // https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
  const isSearchOpenRef = useRef(isSearchOpen);
  const setIsSearchOpen = useCallback(
    (newValue) => {
      isSearchOpenRef.current = newValue;
      _setIsSearchOpen(newValue);
      if (setIsSearchOpenGlobal) {
        setIsSearchOpenGlobal(newValue);
      }
      if (onIsOpenStateChange) onIsOpenStateChange(newValue);
    },
    [onIsOpenStateChange, _setIsSearchOpen]
  );

  const [isSearchBarFocused, setIsSearchBarFocused] = useState(false);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleSearchBarFocusAndBlur = useCallback(() => {
    setIsSearchBarFocused(!isSearchBarFocused);
  }, [isSearchBarFocused]);

  const handleClickOutside = useCallback(
    (event: React.MouseEvent | KeyboardEvent | MouseEvent) => {
      if (
        isSearchOpenRef.current &&
        container.current &&
        !container.current.contains(event.target)
      ) {
        setIsSearchOpen(false);
      }
    },
    [setIsSearchOpen]
  );

  const mlLTREnabled = false;

  const empty = query.length < minQueryLength || suggestions.length === 0;

  const updatedInitialData: SearchData = [];

  if (empty) {
    data = updatedInitialData;
  } else {
    data = suggestions;
  }

  const closeSearchResultsDropdown = useCallback(() => {
    // close SearchResults dropdown
    setIsSearchOpen(false);
    // reset highlighted SearchResult
    setHighlightedIndex(0);
  }, []);

  const exitSearch = useCallback(() => {
    // clear SearchInput
    setQuery('');
    closeSearchResultsDropdown();
  }, [setQuery, closeSearchResultsDropdown]);

  const resetSessionId = useCallback(() => {
    queryIdRef.current = generateQueryId();
  }, []);

  const handleResultsReceived = useCallback((results: RunSuggesterResponse) => {
    /**
     *  Discards results if they don't belong to the last suggestion
     *  API call response in order to get coherent results
     */
    if (results.query !== lastRequestedQuery.current) {
      return;
    }

    if (results.error) {
      setError(results.error?.message || results.error);
      return;
    }
    setError('');

    const items: SearchData = results.results.map((suggestion) => {
      const type = suggestion.type === 'user' ? 'miniProfile' : suggestion.type;
      return {
        _id: suggestion._id,
        group: ItemGroup.Suggestion,
        data: suggestion.item,
        slug: suggestion.slug,
        type,
        matchedFields: suggestion.matchedFields?.map(
          (field) => `${suggestion.type}.${field}`
        ),
        explainBlock: suggestion.explainBlock,
        aaQueryId: suggestion?.aaQueryId,
        aaIndex: suggestion?.aaIndex,
      };
    });

    setSuggestions(items);
  }, []);

  const handleRequest = useCallback(
    debounce(async (val: string, queryId: number) => {
      setError('');
      lastRequestedQuery.current = val || '';
      handleResultsReceived(
        await runSuggester(
          val,
          mlLTREnabled,
          queryId,
          forBusiness,
          isAlgoliaEnabled(),
          isC4BAlgoliaEnabled(),
          isTalentNeuralSearchEnabled()
        )
      );
      setLoadingSuggestions(false);
      if (mlLTREnabled) {
        setAlgorithm('allan-ltr');
      }
    }, 100),
    []
  );

  const handleSearchBarClick = useCallback(() => {
    if (!isSearchOpen) {
      setIsSearchOpen(true);
      setHighlightedIndex(0);

      trackSearchBarClick({ data, queryIdRef, algorithm, query });
    }
  }, [isSearchOpen, setIsSearchOpen, algorithm, data, query]);

  const handleCancelButtonClick = useCallback(() => {
    exitSearch();
  }, [exitSearch]);

  const cleanSlug = useCallback((slug: string) => {
    return slug && slug[0] === '/' ? slug.slice(1) : slug;
  }, []);

  const trackItemSelection = useCallback(
    createItemSelectionTracker({ data, query, queryIdRef, algorithm }),
    [algorithm, data, query]
  );

  const handleResultsItemClick = useCallback(
    (item, listIndex, itemType) => {
      trackItemSelection(item, listIndex, itemType);
      algoliaAnalytics.trackSearchResultClick({ item, listIndex, itemType });

      closeSearchResultsDropdown();
    },
    [closeSearchResultsDropdown, trackItemSelection]
  );

  const handleQueryClick = useCallback(
    (queryName, listIndex) => {
      createQuerySelectionTracker({
        data,
        query,
        queryIdRef,
        algorithm,
      })(queryName, listIndex);
      closeSearchResultsDropdown();
    },
    [algorithm, query, closeSearchResultsDropdown, data]
  );

  const handleSearchBarChange = useCallback((value) => {
    setQuery(value);
    setHighlightedIndex(0);

    if (value.length > 0) {
      trackQueryChange({
        queryIdRef,
        query: value,
        algorithm,
        isEnterPressed: false,
      });
    } else {
      resetSessionId();
      setAlgorithm('');
      setSuggestions([]);
      setError('');
    }

    if (inRange(value?.length, minQueryLength, maxQueryLength)) {
      setLoadingSuggestions(true);
      handleRequest(value, queryIdRef.current);
    }
  }, []);

  const isValidQuery = useCallback(() => {
    return inRange(query?.length, minQueryLength, maxQueryLength);
  }, [query]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      event.stopPropagation();
      const length = data.length + 1;

      if (event.key === 'Esc' || event.key === 'Escape') {
        exitSearch();
      }

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        setHighlightedIndex((highlightedIndex + 1) % length);
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault();
        setHighlightedIndex(
          highlightedIndex > 0 ? (highlightedIndex - 1) % length : length - 1
        );
      }

      if (event.key === 'Enter') {
        event.preventDefault();
        trackQueryChange({
          queryIdRef,
          query,
          algorithm,
          isEnterPressed: true,
        });
        const highlightedItem = data[highlightedIndex - 1];

        // selects the manually highlighted item if is valid
        if (isValidHighlightedItem(highlightedItem, highlightedIndex)) {
          handleItemSelection(highlightedItem, highlightedIndex, true);
        } else if (isValidQuery()) {
          history.push(generateSearchPageUrl(query));
        }

        closeSearchResultsDropdown();
      }
    },
    [isValidQuery, data, highlightedIndex]
  );
  const isValidHighlightedItem = useCallback(
    (item: SearchItem, index: number) => {
      // exist a highlighted item and is selectable
      return index !== 0 && isItemSelectable(item);
    },
    []
  );

  const isItemSelectable = useCallback(
    (item) => item && item.type !== 'title',
    []
  );

  const handleItemSelection = useCallback(
    (item: SearchItem, index, shouldTrackSelection = false) => {
      let url = generateProfilePageUrl(
        item.data?.username,
        item._id,
        item?.aaQueryId
      );
      let itemType = 'User';

      if (item.type === 'category' || item.type === 'tag') {
        const slug = cleanSlug(item.data?.slug);
        url = generateBrowsePageUrl(slug, item.type);
        itemType = item.type === 'category' ? 'Category' : 'Tag';
      }

      if (shouldTrackSelection) {
        trackItemSelection(item, index - 1, itemType);
      }

      history.push(url);
    },
    []
  );

  const handleMouseEnter = useCallback((index) => {
    setHighlightedIndex(index + 1);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHighlightedIndex(0);
  }, []);

  const generateSearchPageUrl = useCallback(
    (queryStr: string) => {
      const formattedQueryString = queryStr.toLowerCase().trim();
      const queryParams = getQueryParams({
        q: formattedQueryString,
        qid: queryIdRef.current,
      });
      return `/search${queryParams}`;
    },
    [getQueryParams]
  );

  const generateProfilePageUrl = useCallback(
    (username: string, _id: string, aaQueryId?: string) => {
      const queryParams = getQueryParams({
        qid: queryIdRef.current,
        ...(aaQueryId && { aaQueryId }),
      });
      return `/${username || _id}${queryParams}`;
    },
    [getQueryParams]
  );

  const generateBrowsePageUrl = useCallback(
    (slug: string, type: 'category' | 'tag') => {
      const queryParams = getQueryParams({
        qid: queryIdRef.current,
      });
      const urlSuffix = `${cleanSlug(slug)}${queryParams}`;

      let url = '';
      if (type === 'category') {
        // Temporary work around to send traffic to the new landing page
        if (slug === 'reality-tv/reality-tv-cbs/survivor') {
          url = `/survivor`;
        } else {
          url = `/browse/${urlSuffix}`;
        }
      } else if (slug === 'mothersday') {
        url = `/l/${urlSuffix}`;
      } else {
        url = `/tags/${urlSuffix}`;
      }

      return url;
    },
    [cleanSlug, getQueryParams]
  );

  return (
    <>
      {isSearchOpen && <DisableBodyScroll />}
      <Wrapper isOpen={isSearchOpen} isSearchBarFocused={isSearchBarFocused}>
        <Container ref={container}>
          <SearchBar
            isDesktop={isDesktop}
            isOpen={isSearchOpen}
            onChange={handleSearchBarChange}
            onClick={handleSearchBarClick}
            onKeyDown={handleKeyDown}
            onFocusOrBlur={handleSearchBarFocusAndBlur}
            value={query}
          />
          {isSearchOpen && !isDesktop && (
            <Link onClick={handleCancelButtonClick}>{'Cancel'}</Link>
          )}
          <SearchResults
            data={data}
            generateBrowsePageUrl={generateBrowsePageUrl}
            generateProfilePageUrl={generateProfilePageUrl}
            generateSearchPageUrl={generateSearchPageUrl}
            highlightedItemIndex={highlightedIndex}
            isEmpty={empty}
            isErrored={!!error}
            isLoading={loadingSuggestions}
            isOpen={isSearchOpen}
            minQueryLength={minQueryLength}
            onItemClick={handleResultsItemClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onQueryClick={handleQueryClick}
            closeSearchResultsDropdown={closeSearchResultsDropdown}
            query={query}
          />
        </Container>
      </Wrapper>
    </>
  );
};

export default GlobalSiteSearch;
