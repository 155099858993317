import React from 'react';
import {
  toast as toastifyToast,
  ToastContainer as ToastifyToastContainer,
  ToastContainerProps,
} from 'react-toastify';
import styled, { keyframes } from 'styled-components';
import { color, sizing, fonts } from 'design-system/style';

const CONTAINER_ID = 'RIGHT_BOTTOM_CONTAINER';

const ToastifyBounceInRight = keyframes`
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(${sizing(3000)}, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-${sizing(25)}, 0, 0); }
  75% {
    transform: translate3d(${sizing(10)}, 0, 0); }
  90% {
    transform: translate3d(-${sizing(5)}, 0, 0); }
  to {
    transform: none; }
`;

const ToastifyTrackProgress = keyframes`
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
`;

const ToastifyBounceOutRight = keyframes`
  20% {
    opacity: 1;
    transform: translate3d(-${sizing(20)}, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(${sizing(2000)}, 0, 0); }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${sizing(12)} ${sizing(8)};

  position: static;
  width: 359px;
  height: 68px;
  left: calc(50% - 359px / 2);
  top: calc(50% - 68px / 2);

  background: #ffffff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.13);
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
`;

const Action = styled.text`
  position: static;
  width: 49px;
  height: 20px;
  left: calc(50% - 49px / 2 + 139px);
  top: calc(50% - 20px / 2);

  ${fonts.mediumText['300']};

  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.1px;

  color: #1e1b1e;

  cursor: pointer;
`;

const TextWrapper = styled.div`
  /* Frame 1445 */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: ${sizing(222)};
  height: ${sizing(44)};
  left: ${sizing(64)};
  top: ${sizing(12)};
`;

const GlyphWrapper = styled.ellipse`
  position: static;
  width: ${sizing(40)};
  height: ${sizing(40)};
  left: ${sizing(16)};
  top: ${sizing(14)};

  border-radius: 50%;

  background: #ff037c;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 ${sizing(8)};
`;

const Header = styled.h4`
  position: static;
  width: 222px;
  height: 20px;
  left: calc(50% - 222px / 2);
  top: calc(50% - 20px / 2 - 12px);

  ${fonts.boldText['200']};

  display: flex;
  align-items: center;
  letter-spacing: -0.1px;

  color: #1e1b1e;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 2px 0px;
`;

const Description = styled.caption`
  position: static;
  width: 222px;
  height: 20px;
  left: calc(50% - 222px / 2);
  top: calc(50% - 20px / 2 + 12px);

  ${fonts.mediumText['200']};

  display: flex;
  align-items: center;
  letter-spacing: -0.1px;

  color: #1e1b1e;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
`;

const ToastComponent = ({
  Glyph,
  message,
  description,
  actionText,
  onClick,
}) => {
  return (
    <Wrapper>
      <GlyphWrapper>
        <Glyph />
      </GlyphWrapper>

      <TextWrapper>
        <Header>{message}</Header>
        <Description>{description}</Description>
      </TextWrapper>

      <Action onClick={onClick}>{actionText}</Action>
    </Wrapper>
  );
};

export const WrappedTooltipContainer = ({
  className,
  ...rest
}: ToastContainerProps & { className?: string }) => (
  <div className={className}>
    <ToastifyToastContainer
      {...rest}
      closeOnClick={false}
      position="bottom-right"
      hideProgressBar
      newestOnTop
      draggable={false}
      autoClose={5000}
      pauseOnHover
      closeButton={false}
      enableMultiContainer
      containerId={CONTAINER_ID}
    />
  </div>
);

export const ToastContainer = styled(WrappedTooltipContainer)`
  .Toastify__toast-container {
    z-index: 9999;
    -webkit-transform: translate3d(0, 0, ${sizing(9999)});
    position: fixed;
    padding: ${sizing(4)};
    max-width: ${sizing(500)};
    box-sizing: border-box;
    color: ${color.solids.WHITE};
  }

  .Toastify__toast-container--bottom-right {
    bottom: 5em;
    right: 5em;
  }

  @media only screen and (max-width: 480px) {
    .Toastify__toast-container {
      width: 100vw;
      max-width: 100%;
      padding: 0 1.6rem;
      left: 0;
      margin: 0;
    }

    .Toastify__toast-container--bottom-right {
      bottom: 0;
      left: 50%;
      margin-left: -${sizing(195)};
    }
  }

  .Toastify__toast {
  }

  @media only screen and (max-width: 480px) {
    .Toastify__toast {
      margin-bottom: 2rem;
    }
  }

  .Toastify__toast-body {
    margin: auto 0;
    width: 100%;
  }

  .Toastify__bounce-enter--bottom-right {
    animation-name: ${ToastifyBounceInRight};
  }

  .Toastify__bounce-exit--bottom-right {
    animation-name: ${ToastifyBounceOutRight};
  }

  .Toastify__progress-bar {
    animation: ${ToastifyTrackProgress} linear 1;
  }
`;

export const toast = ({ Glyph, message, description, actionText, onClick }) => {
  return toastifyToast(
    <ToastComponent
      Glyph={Glyph}
      message={message}
      description={description}
      actionText={actionText}
      onClick={onClick}
    />,
    {
      containerId: CONTAINER_ID,
    }
  );
};
