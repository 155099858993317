import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { LayoutsResponse } from 'types/src/api/endpoints/explore/endpoints/layouts';
import { GetLayout, GetShelf, ResetLayout } from './actions';

export interface LayoutsState {
  layoutData: LayoutsResponse;
  loadingLayout: boolean;
  layoutError: any;
}

const LAYOUTS_INITIAL_STATE = Object.freeze<LayoutsState>({
  layoutData: {
    pageContent: {
      items: [],
    },
  },
  loadingLayout: true,
  layoutError: null,
});

const layoutsReducer = reducerWithInitialState(LAYOUTS_INITIAL_STATE);

layoutsReducer.case(GetLayout.started, (state) => {
  return {
    ...state,
    layoutData: {
      pageContent: {
        items: [],
      },
    },
    loadingLayout: true,
    layoutError: null,
  };
});

layoutsReducer.case(GetLayout.done, (state, payload) => {
  return {
    ...state,
    layoutData: payload.result,
    loadingLayout: false,
  };
});

layoutsReducer.case(GetLayout.failed, (state, error) => {
  return {
    ...state,
    layoutData: {
      pageContent: {
        items: [],
      },
    },
    loadingLayout: false,
    layoutError: error.error,
  };
});

layoutsReducer.case(GetShelf.started, (state) => {
  return {
    ...state,
    loadingLayout: true,
    layoutError: null,
  };
});

layoutsReducer.case(GetShelf.done, (state, payload) => {
  return {
    ...state,
    layoutData: {
      pageContent: {
        items: [payload.result],
      },
    },
    loadingLayout: false,
    layoutError: null,
  };
});

layoutsReducer.case(GetShelf.failed, (state, error) => {
  return {
    ...state,
    layoutData: {
      pageContent: {
        items: [],
      },
    },
    loadingLayout: false,
    layoutError: error.error,
  };
});

layoutsReducer.case(ResetLayout, () => LAYOUTS_INITIAL_STATE);

export default layoutsReducer.build();
