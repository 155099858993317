import styled from 'styled-components';
import { fonts, media, sizing } from 'design-system/style';

export const ColorMap = {
  System: 'fuchsia',
  Navigation: 'lime',
  Action: 'cyan',
  FeatureDecision: 'orange',
  ExperimentDecision: 'mediumpurple',
  PageViewWeb: 'firebrick',
};

export const SectionWrapper = styled.div``;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  ${SectionWrapper} {
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    ${SectionWrapper} {
      margin-bottom: 0;
    }
  }
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    flex-direction: row;
  }
`;

export const HeaderControls = styled.span`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > * {
    margin-left: 12px;
  }

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    flex-direction: row;
  }
`;

export const SectionTitle = styled.div`
  ${fonts.boldText[400]};
  margin-bottom: ${sizing(16)};
`;

export const SessionIdentifierLabel = styled.span`
  margin-bottom: ${sizing(16)};
`;

export const SectionSubtitle = styled.span`
  ${fonts.boldText[300]};
  margin-bottom: ${sizing(8)};
`;

export const Section = styled.div`
  display: grid;
  grid-column-gap: ${sizing(24)};
  grid-template-columns: 1fr;

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    grid-template-columns: 1fr;
  }
`;

export const Legend = styled.ul`
  display: inline-flex;
  list-style: none;
  margin: 0;
  overflow: auto;
  padding-left: 10%;
  align-items: center;
`;

export const LegendItem = styled.li`
  display: flex;
  padding: 8px 0;
  width: 100%;
  margin-right: 8px;
  :last-of-type {
    border-bottom: none;
  }
`;

export const ColorBox = styled.span<{ color: string }>`
  border: 1px solid;
  background-color: ${({ color }) => color};
  padding-right: 20px;
  margin-right: 5px;
`;

export const List = styled.ul`
  height: ${sizing(350)};
  list-style: none;
  margin: 0;
  overflow: auto;
  padding: 0;
`;

export const ListItem = styled.li`
  display: flex;
  padding: 8px 0;
  border-bottom: 1px solid #bebbbf;
  width: 100%;

  :last-of-type {
    border-bottom: none;
  }
`;
