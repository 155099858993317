import * as Likes from 'types/src/api/endpoints/likes';
import { AdminUser, PublicUser } from 'types/src/api/models/user';
import actionCreatorFactory from 'typescript-fsa';
import { PublicLike } from 'types/src/api/models/likes';
import { AdminOrder } from 'types/src/api/models/order';

const actionCreator = actionCreatorFactory('cameo/user/likes');

export const AddLike = actionCreator.async<
  Likes.AddLike.POST.RequestBody,
  Likes.AddLike.POST.Response,
  any
>('ADD_LIKE');

export const addLike = (
  userId: AdminUser['_id'],
  data: Likes.AddLike.POST.RequestBody
) => ({
  ...data,
  types: [AddLike.started.type, AddLike.done.type, AddLike.failed.type],
  promise: (client) => {
    return client.post(`/user/${userId}/likes`, {
      data: {
        target: data.target,
        targetId: data.targetId,
      },
    });
  },
});

export const RemoveLike = actionCreator.async<
  Likes.AddLike.POST.RequestBody,
  Likes.RemoveLike.DELETE.Response,
  any
>('REMOVE_LIKE');

export const removeLike = (
  userId: AdminUser['_id'],
  data: Likes.RemoveLike.DELETE.RequestBody
) => ({
  ...data,
  types: [
    RemoveLike.started.type,
    RemoveLike.done.type,
    RemoveLike.failed.type,
  ],
  promise: (client) => {
    return client.del(`/user/${userId}/likes`, {
      data: {
        target: data.target,
        targetId: data.targetId,
      },
    });
  },
});

export const GetLikes = actionCreator.async<
  void,
  Array<PublicLike & { targetData: AdminOrder & { ownerId: PublicUser } }>,
  any
>('GET_LIKES');

export const getLikesByUser = (userId: AdminUser['_id']) => ({
  types: [GetLikes.started.type, GetLikes.done.type, GetLikes.failed.type],
  promise: (client) => {
    return client.get(`/user/${userId}/likes`);
  },
});
