import { LOCATION_CHANGE } from 'connected-react-router';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { GetLayout } from 'state/modules/browse/layouts/actions';
import { GetUserUnreadChannelCount } from 'state/modules/users/getUserUnreadChannelCount';
import {
  addNotifications,
  removeNotifications,
  toggleNavbar,
  GetNavbarCategories,
} from 'state/modules/navbar/actions';
import { MenuItems, Notification } from 'state/modules/navbar/types';
import { CategoryMetadata } from 'types/src/api/endpoints/explore/endpoints/layouts';
import { types as loadActionTypes } from '../auth/load';
import login from '../auth/login/actions';

export const NAV_BAR_INITIAL_STATE = Object.freeze({
  navLeftOptions: [],
  navRightOptions: [],
  isLoading: false as boolean,
  // this doesn't belong here
  previousPathnames: [],
  notifications: [] as Notification[],
  unreadChannelCount: 0 as number,
  isNavbarHidden: false as boolean,
  navbarCategories: [],
});

const navbarReducer = reducerWithInitialState(NAV_BAR_INITIAL_STATE);

navbarReducer.case(GetNavbarCategories.done, (state, action) => {
  return {
    ...state,
    navbarCategories: action.result,
  };
});

navbarReducer.cases([GetLayout.started], (state) => {
  return {
    ...state,
    isLoading: true,
  };
});

navbarReducer.case(GetLayout.done, (state, payload) => {
  return {
    ...state,
    navLeftOptions:
      (payload.result.pageMetadata as CategoryMetadata)?.breadcrumb ?? [],
    navRightOptions:
      (payload.result.pageMetadata as CategoryMetadata)?.navigationCategories ??
      [],
    isLoading: false,
  };
});

navbarReducer.case(toggleNavbar, (state, payload) => {
  return {
    ...state,
    isNavbarHidden: payload,
  };
});

navbarReducer.case(GetUserUnreadChannelCount.done, (state, payload) => {
  return {
    ...state,
    unreadChannelCount: payload.result,
  };
});

// @ts-ignore
navbarReducer.case({ type: LOCATION_CHANGE }, (state, payload) => {
  // @ts-ignore
  const pathname = payload?.location?.pathname;
  const isFollowingPath = !!pathname.match(/\/following$/);
  const updatedNotifications = isFollowingPath
    ? state.notifications.filter(
        (notification) => notification !== MenuItems.Following
      )
    : state.notifications;

  return {
    ...state,
    previousPathnames: [...state.previousPathnames.slice(-1), pathname].filter(
      Boolean
    ),
    notifications: updatedNotifications,
  };
});

navbarReducer.case(removeNotifications, (state, payload) => {
  const newNotificationsState = state.notifications.filter(
    (notification) => !payload.includes(notification)
  );
  return {
    ...state,
    notifications: newNotificationsState,
  };
});

navbarReducer.case(addNotifications, (state, payload) => {
  return {
    ...state,
    notifications: Array.from(new Set([...state.notifications, ...payload])),
  };
});

navbarReducer.case(login.async.done, (state, payload) => {
  return {
    ...state,
    notifications: Array.from(
      new Set([...state.notifications, ...[MenuItems.Orders]])
    ),
  };
});

export default navbarReducer.default((state, action) => {
  // Populate the navbar state with any relevant user-specific info
  if (action.type === loadActionTypes.success) {
    return {
      ...state,
      notifications: Array.from(
        new Set([...state.notifications, ...[MenuItems.Orders]])
      ),
    };
  }
  return {
    ...state,
  };
});
