export const types = {
  start: 'sb/users/SAVE',
  success: 'sb/users/SAVE_SUCCESS',
  fail: 'sb/users/SAVE_FAIL',
};

export const start = (state) => ({
  ...state,
  savingUser: true,
  saveUserError: null,
});

export const success = (state, action) => ({
  ...state,
  users: {
    ...state.users,
    [action.result.user._id]: {
      // Keep data that we previously had about the user
      ...state.users[action.result.user._id],
      // But overwrite any attributes that were changed
      ...action.result.user,
    },
  },
  userIds: {
    ...state.userIds,
    [action.result.user.username]: action.result.user._id,
  },
  savingUser: false,
});

export const fail = (state, action) => ({
  ...state,
  saveUserError: action.error.message || action.error,
  savingUser: false,
});

export const service = (userId, changes) => {
  return {
    types: [types.start, types.success, types.fail],
    promise: (client) =>
      client.post(`/user/update/${userId}`, { data: changes }),
  };
};
