import { useCallback, useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

const CALLBACK_THROTTLE_THRESHOLD = 100;

interface ScrollPercentage {
  scrollPercentage: number;
}

interface Params {
  enabled?: boolean;
  /*
   * Only attach event listener when enabled is true.
   */

  step?: number;
  /*
   * Update on multiple of 'step' pixels (scroll position). The lower the value, the more renders will occur.
   * A trade-off between performance (higher values) and smoothness (lower values)
   */

  heightCap?: number;
  /*
   * Set height used to calculate scroll ratio. Default to full document height.
   */
}

const useWindowScrollPercentage = ({
  enabled = true,
  step = 1,
  heightCap,
}: Params): ScrollPercentage => {
  const [scrollPercentage, setScrollPercentage] = useState(() => 0);

  const handleScrollEvent = useCallback(() => {
    setScrollPercentage(() => {
      const _heightCap = heightCap || document.body.clientHeight;

      if (window.scrollY < 0) return 0;
      if (window.scrollY > _heightCap) return 100;

      const _scrollPercentage = (window.scrollY / _heightCap) * 100;

      return Math.floor(_scrollPercentage / step) * step;
    });
  }, [heightCap]);

  useEffect(() => {
    const throttledHandleScrollEvent = throttle(
      handleScrollEvent,
      CALLBACK_THROTTLE_THRESHOLD
    );

    if (window && document && enabled) {
      window.addEventListener('scroll', throttledHandleScrollEvent, {
        passive: true,
      });
    }
    return () =>
      window.removeEventListener('scroll', throttledHandleScrollEvent);
  }, [enabled, handleScrollEvent]);

  // Immediately calculate percentage on 'enabled' prop change, bypassing the event listener
  // This fixed the state change bug from non-gradient page -> gradient page
  useEffect(() => {
    if (window && document && enabled) handleScrollEvent();
  }, [enabled]);

  return { scrollPercentage: enabled ? scrollPercentage : 100 };
};

export default useWindowScrollPercentage;
