import * as React from 'react';
import type { CustomIconProps } from './types';

function BellOnIcon({ height, width }: CustomIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0356 11.911C19.3782 13.1773 20.0149 14.3226 20.8684 15.2699C21.0349 15.4547 21.0989 15.71 21.0393 15.9516C20.9797 16.1931 20.8042 16.3893 20.5709 16.4755C19.0269 17.0455 17.4105 17.4659 15.7396 17.7192C15.7465 17.812 15.75 17.9056 15.75 18C15.75 20.0711 14.0711 21.75 12 21.75C9.92894 21.75 8.25001 20.0711 8.25001 18C8.25001 17.9056 8.25351 17.812 8.2604 17.7192C6.58934 17.4659 4.97287 17.0455 3.42875 16.4755C3.19539 16.3893 3.01992 16.1931 2.96033 15.9516C2.90073 15.71 2.96476 15.4547 3.13126 15.2699C4.44879 13.8074 5.24981 11.8731 5.24981 9.75V9.7498L5.25001 9V8.9998C5.25012 5.27197 8.27215 2.25 12 2.25C12.4196 2.25 12.8303 2.28829 13.2287 2.36156C12.9025 2.7887 12.6323 3.26093 12.4292 3.76729C12.2876 3.75584 12.1445 3.75 12 3.75C9.10052 3.75 6.75001 6.10051 6.75001 9V9.0002L6.74981 9.75V9.7502C6.74977 11.8596 6.07916 13.8136 4.94026 15.4091C6.31914 15.848 7.75395 16.1617 9.23148 16.337C10.139 16.4446 11.0628 16.5 11.9998 16.5C12.937 16.5 13.8609 16.4446 14.7685 16.3369C16.2459 16.1617 17.6806 15.8479 19.0594 15.4091C18.3346 14.3938 17.7995 13.2333 17.5061 11.98C17.669 11.9932 17.8337 12 18 12C18.3531 12 18.6992 11.9695 19.0356 11.911ZM9.75001 18C9.75001 17.9662 9.75075 17.9326 9.75221 17.8993C10.4927 17.966 11.2424 18 11.9998 18C12.7574 18 13.5072 17.9659 14.2478 17.8992C14.2493 17.9326 14.25 17.9662 14.25 18C14.25 19.2426 13.2427 20.25 12 20.25C10.7574 20.25 9.75001 19.2426 9.75001 18Z"
        fill="#F2F1F3"
      />
      <path
        d="M18 3V9M21 6L15 6"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BellOnIcon;
