/**
 * Add all the props that somehow belong to the styled-system.
 * Custom props should be adding to the `extraProps` parameter
 */
const STYLED_SYSTEM_PROPS = new Set([
  'aspectRatio',
  'marginBottom',
  'textOverflow',
  'textWrap',
  'whiteSpace',
]);

/**
 * Prevent some common encountered props on design system to be passed to the DOM
 *
 * @see https://styled-components.com/docs/api#shouldforwardprop
 * @see https://stackoverflow.com/a/69730540/1422380
 */
export const customPropValidator = (extraProps?: string[]) => {
  const extraPropsSet = new Set(extraProps);

  return (prop: string, defaultValidatorFn: (_prop: string) => boolean) => {
    return (
      defaultValidatorFn(prop) &&
      !STYLED_SYSTEM_PROPS.has(prop) &&
      !extraPropsSet.has(prop)
    );
  };
};
