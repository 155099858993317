import styled from 'styled-components';
import { Link } from 'components/CameoLink/Link';
import { sizing, media } from '../../../style';

export const CategoriesContainer = styled.div`
  display: flex;
  gap: ${sizing(12)};
  flex-wrap: wrap;

  @media (min-width: ${media.queries.DESKTOP}) {
    & > a {
      // hide the last category on desktop
      &:last-child {
        display: none;
      }
    }
  }
`;

export const StyledLink = styled(Link)`
  &:hover,
  &:active {
    text-decoration: none;
  }
`;
