import * as React from 'react';
import type { CustomIconProps } from './types';

function HanukkahIcon({ height, width }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M9.4 5.20001H2.6C2 5.20001 1.5 5.60001 1.5 6.10001V11.6C1.5 11.9 1.6 12.1 1.9 12.3L5.3 14.9C5.7 15.2 6.4 15.2 6.8 14.9L10.2 12.3C10.4 12.1 10.6 11.9 10.6 11.6V6.20001C10.6 5.70001 10.1 5.20001 9.4 5.20001Z"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M6.60039 4.49999V1.39999C6.60039 1.06862 6.33176 0.799988 6.00039 0.799988C5.66902 0.799988 5.40039 1.06862 5.40039 1.39999V4.49999C5.40039 4.83136 5.66902 5.09999 6.00039 5.09999C6.33176 5.09999 6.60039 4.83136 6.60039 4.49999Z"
        stroke="#F2F1F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default HanukkahIcon;
