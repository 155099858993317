import * as React from 'react';
import type { CustomIconProps } from './types';

export function PollIcon({ height, width, color }: CustomIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3L14 3L14 21H10L10 3Z"
        stroke={color as string}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 8L22 8V21H18L18 8Z"
        stroke={color as string}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 13H6L6 21H2L2 13Z"
        stroke={color as string}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
