import Cameo from 'types';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actionCreator } from './utils';
import INITIAL_STATE from './initialState';
import { FanClubsState, Error } from './types';

export const LoadFollowing = actionCreator.async<
  Cameo.Endpoints.FanClub.Following.GET.Query,
  Cameo.Endpoints.FanClub.Following.GET.Response,
  Error
>('LOAD_FOLLOWING');

export const loadFollowing = () => ({
  types: [
    LoadFollowing.started.type,
    LoadFollowing.done.type,
    LoadFollowing.failed.type,
  ],
  promise: (client) => {
    return client.get('/fanClub/following');
  },
});

export const reducer = reducerWithInitialState<FanClubsState>(INITIAL_STATE)
  .case(LoadFollowing.started, (state) => ({
    ...state,
    followingLoading: true,
    followingError: null,
  }))
  .case(LoadFollowing.done, (state, { result }) => ({
    ...state,
    followingLoading: false,
    following: result.users,
  }))
  .case(LoadFollowing.failed, (state, { error }) => ({
    ...state,
    followingLoading: false,
    followingError: error.message,
  }))
  .build();
