import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

function getWindowDimensions() {
  if (typeof window === 'undefined')
    return {
      width: 0,
      height: 0,
    };

  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (typeof window === 'undefined') return () => false;
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    const debouncedHandleResize = debounce(handleResize, 500);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []);

  return windowDimensions;
};
