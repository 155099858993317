/* eslint-disable no-restricted-syntax */
import Cameo from 'types';
import { DeepPartial } from 'redux';
import { TrackFunc } from '../types';

export default function trackAction(trackFunc: TrackFunc) {
  return (data: DeepPartial<Cameo.Utils.Analytics.ActionEventSchema>) => {
    trackFunc({
      eventName: 'Action',
      data,
    });
  };
}
