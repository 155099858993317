import React from 'react';
import { NotificationIndicatorSpan } from './Styled';

export interface NotificationIndicatorProps {
  count?: number;
}

export const NotificationIndicator: React.FunctionComponent<
  NotificationIndicatorProps
> = (props: NotificationIndicatorProps) => {
  const { count, ...rest } = props;
  let countString;

  if (count === 0 || count === undefined || count === null) {
    countString = '';
  } else {
    countString = count < 100 ? count.toString() : '99+';
  }
  return (
    <NotificationIndicatorSpan {...rest}>
      {countString}
    </NotificationIndicatorSpan>
  );
};
