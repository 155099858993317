import { useSelector } from 'react-redux';
import { selectors as authSelectors } from 'state/modules/auth';
import { AdminUser } from 'types/src/api/models/user';

export const useIsUserBriefcaseEligible = () => {
  const loggedInUser: AdminUser = useSelector(authSelectors.getLoggedInUser);

  return (
    loggedInUser?.featureFlags?.c4bIsBusinessPurchaser ||
    !!loggedInUser?.briefcasePreferences?.lastActiveBusinessId
  );
};
