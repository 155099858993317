/* eslint-disable id-length */
export interface RetryOptions {
  retries?: number;
  interval?: number;
  exponentialBackoff?: boolean;
}

export function retry<T>(
  fn: () => Promise<T>,
  retryOptions: RetryOptions = {}
): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    const {
      retries = 3,
      interval = 500,
      exponentialBackoff = true,
    } = retryOptions;

    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retries === 1) {
            if (
              typeof window !== 'undefined' &&
              error.name === 'ChunkLoadError'
            ) {
              // If a chunk loading error occurs, reload the page.
              window.location.reload();
            }
            reject(error);
            return;
          }

          // Recursive retry with updated options
          retry(fn, {
            retries: retries - 1,
            interval: exponentialBackoff ? interval * 2 : interval,
            exponentialBackoff,
          }).then(resolve, reject);
        }, interval);
      });
  });
}
