/**
 * Formats the phone number input into a readable form depending on current contents
 * We only capture user phone numbers in a few places
 * and primarily we focus on collecting US numbers.
 * This function for now only handles 10 digits domestic numbers including US and Canada.
 * @param {string} value
 * @return {string}
 */
export const normalizePhone = (value) => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) return onlyNums;
  if (onlyNums.length <= 6)
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 7)}`;
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`;
};
