/* eslint-disable id-length */
import { BorderProps, LayoutProps, SpaceProps } from 'styled-system';

export const getSpaceProps = (props): Partial<SpaceProps> => {
  const values = {
    margin: props.margin,
    marginBottom: props.marginBottom,
    marginLeft: props.marginLeft,
    marginRight: props.marginRight,
    marginTop: props.marginTop,
    marginX: props.marginX,
    marginY: props.marginY,
    mb: props.mb,
    ml: props.ml,
    mr: props.mr,
    mt: props.mt,
    mx: props.mx,
    my: props.my,
    padding: props.padding,
    paddingBottom: props.paddingBottom,
    paddingLeft: props.paddingLeft,
    paddingRight: props.paddingRight,
    paddingTop: props.paddingTop,
    paddingX: props.paddingX,
    paddingY: props.paddingY,
    pb: props.pb,
    pl: props.pl,
    pr: props.pr,
    pt: props.pt,
    px: props.px,
    py: props.py,
  };

  const entries = Object.entries(values);
  // remove undefined properties
  const filtered = entries.filter(([, value]) => Boolean(value));
  // reconstruct object
  return Object.fromEntries(filtered);
};

export const getLayoutProps = (props): Partial<LayoutProps> => {
  const values = {
    display: props.display,
    height: props.height,
    maxHeight: props.maxHeight,
    maxWidth: props.maxWidth,
    minHeight: props.minHeight,
    minWidth: props.minWidth,
    overflow: props.overflow,
    overflowX: props.overflowX,
    overflowY: props.overflowY,
    size: props.size,
    verticalAlign: props.verticalAlign,
    width: props.width,
  };

  const entries = Object.entries(values);
  // remove undefined properties
  const filtered = entries.filter(([, value]) => Boolean(value));
  // reconstruct object
  return Object.fromEntries(filtered);
};

export const getBorderProps = (props): Partial<BorderProps> => {
  const values = {
    border: props.border,
    borderWidth: props.borderWidth,
    borderStyle: props.borderStyle,
    borderColor: props.borderColor,
    borderRadius: props.borderRadius,
    borderTop: props.borderTop,
    borderTopWidth: props.borderTopWidth,
    borderTopStyle: props.borderTopStyle,
    borderTopColor: props.borderTopColor,
    borderTopLeftRadius: props.borderTopLeftRadius,
    borderTopRightRadius: props.borderTopRightRadius,
    borderRight: props.borderRight,
    borderRightWidth: props.borderRightWidth,
    borderRightStyle: props.borderRightStyle,
    borderRightColor: props.borderRightColor,
    borderBottom: props.borderBottom,
    borderBottomWidth: props.borderBottomWidth,
    borderBottomStyle: props.borderBottomStyle,
    borderBottomColor: props.borderBottomColor,
    borderBottomLeftRadius: props.borderBottomLeftRadius,
    borderBottomRightRadius: props.borderBottomRightRadius,
    borderLeft: props.borderLeft,
    borderLeftWidth: props.borderLeftWidth,
    borderLeftStyle: props.borderLeftStyle,
    borderLeftColor: props.borderLeftColor,
    borderX: props.borderX,
    borderY: props.borderY,
  };

  const entries = Object.entries(values);
  // remove undefined properties
  const filtered = entries.filter(([, value]) => Boolean(value));
  // reconstruct object
  return Object.fromEntries(filtered);
};
