import { ApplyInitialActivationMutation } from 'domains/c4b/graphql/generated/graphql';
import { AdminOrder } from 'types/src/api/models/order';
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import logoutAction from '../auth/logout/actions';
import { rehydrateLicense } from '../businessVideos/utils/rehydrateLicense';
import {
  LoadMyCameosState,
  LoadMyCameosResponse,
  INITIAL_STATE,
} from './types';

const actionCreator = actionCreatorFactory('cameo/ordersPage');
const LoadMyCameos = actionCreator.async<void, LoadMyCameosResponse, any>(
  'LOAD_MY_CAMEOS'
);
const UpdateActivatedLicense = actionCreator<
  ApplyInitialActivationMutation['ApplyInitialActivation']
>('UPDATE_ACTIVATED_LICENSE');
const LoadMyPaginatedCameos = actionCreator.async<
  void,
  LoadMyCameosResponse,
  any
>('LOAD_MY_PAGINATED_CAMEOS');

export const reducer = reducerWithInitialState<LoadMyCameosState>(INITIAL_STATE)
  .case(LoadMyCameos.started, (state) => ({
    ...state,
    ...INITIAL_STATE,
    loading: true,
    error: null,
  }))
  .case(LoadMyCameos.done, (state, { result }) => {
    return {
      ...state,
      myCameos: {
        ...result,
        licenses: result?.licenses?.map((license) =>
          rehydrateLicense({ license })
        ),
        orders: result.orders.filter(
          (order: AdminOrder) => !order.isPendingPayment
        ),
      },
      offset: result.orders.length,
      loading: false,
    };
  })
  .case(LoadMyCameos.failed, (state, action) => ({
    ...state,
    loading: false,
    error: action?.error?.message,
  }))
  .case(LoadMyPaginatedCameos.started, (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  // Support the paginated case, which preserves existing entries
  .case(LoadMyPaginatedCameos.done, (state, { result }) => {
    const formattedResult: LoadMyCameosState['myCameos'] = {
      ...result,
      licenses: result?.licenses?.map((license) =>
        rehydrateLicense({ license })
      ),
      orders: result.orders.filter(
        (order: AdminOrder) => !order.isPendingPayment
      ),
    };

    const nextMyCameoState: LoadMyCameosState['myCameos'] = Object.keys(
      state.myCameos
    ).reduce(
      (bag, key) => {
        if (Array.isArray(bag[key])) {
          bag[key] = (bag[key] ?? []).concat(formattedResult[key] ?? []);
        }
        return bag;
      },
      { ...state.myCameos }
    );

    return {
      ...state,
      offset: state.offset + (result?.orders?.length ?? 0),
      myCameos: nextMyCameoState,
      loading: false,
    };
  })
  .case(LoadMyPaginatedCameos.failed, (state, action) => ({
    ...state,
    loading: false,
    error: action?.error?.message,
  }))
  .case(UpdateActivatedLicense, (state, payload) => {
    const updatedLicenses =
      state?.myCameos?.licenses?.map((license) => {
        if (payload._id === license._id) {
          return rehydrateLicense({ license: payload });
        }

        return license;
      }) ?? [];

    return {
      ...state,
      myCameos: {
        ...state.myCameos,
        licenses: updatedLicenses,
      },
    };
  })
  // Ensure any order state is cleared on logout
  .case(logoutAction.async.done, (state) => ({
    ...INITIAL_STATE,
  }))
  .build();

export type LoadMyCameosParams = {
  query?: {
    bookedFor?:
      | Cameo.Models.Order.BookedForDescription
      | Cameo.Models.Order.BookedForDescription[];
    includeGifts?: string;
  };
  preserveExistingStateEntries?: boolean;
  offset?: number;
  limit?: number;
};
export function loadMyCameos(params: LoadMyCameosParams = {}) {
  const searchParams = new URLSearchParams({
    includeGifts: params?.query?.includeGifts ?? 'true',
  });

  if (params.query?.bookedFor) {
    if (Array.isArray(params.query.bookedFor)) {
      searchParams.set('bookedFor', params.query.bookedFor.join(','));
    } else {
      searchParams.set('bookedFor', params.query.bookedFor);
    }
  }

  const endpoint = `/order/myCameos/${params.offset ?? 0}/${
    params.limit ?? 200
  }?${searchParams.toString()}`;

  const Action = params.preserveExistingStateEntries
    ? LoadMyPaginatedCameos
    : LoadMyCameos;

  return {
    types: [Action.started.type, Action.done.type, Action.failed.type],
    promise: (client) => client.get(endpoint),
  };
}

export function updateActivatedLicense({
  licenseData,
}: {
  licenseData: ApplyInitialActivationMutation['ApplyInitialActivation'];
}) {
  return {
    type: UpdateActivatedLicense,
    payload: licenseData,
  };
}
