export const types = {
  start: 'cameo/feature/update/start',
  success: 'cameo/feature/update/success',
  fail: 'cameo/feature/update/fail',
};

export const success = (state, action) => ({
  ...state,
  promotions: {
    ...state.promotions,
    [action.result.name]: action.result.data,
  },
});

export const service = (name, data) => ({
  types: [types.start, types.success, types.fail],
  promise: (client) =>
    client.post('/promotion/update', { data: { name, data } }),
});
