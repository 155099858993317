import { client } from 'helpers/ApiClient';

export const getCurrentUserQueryKey = 'loadAuth' as const;

export const getCurrentUser = () => ({
  queryKey: [getCurrentUserQueryKey],
  queryFn: (): Promise<Cameo.Models.User.AdminUser> => client.get(`/loadAuth`),
  options: {
    // Query result is valid for 15 minutes
    staleTime: 15 * 60 * 1000,
  },
});
